import {DoubleRailingAndExtendedEnabledFunc, StraightStairHookProps} from "../StraightStairV2Types";
import {useMutationHandler} from "../../custom-hook/useMutationHandler";
import {
    CopyStraightStairSegmentMutation,
    CreateStraightStairSegmentMutation,
    StepType,
    StraightStairSegmentFragmentFragment,
    StraightStairSupportFragmentFragment,
    StringerMaterial,
    useCalculateNumberOfStepsLazyQuery,
    useCalculateSegmentGeometryLazyQuery,
    useCopyStraightStairSegmentMutation,
    useCreateStraightStairSegmentMutation,
    useDeleteStraightStairSegmentMutation
} from "../../generated/graphql";
import {useCallback, useMemo} from "react";
import {
    calculateT1Width,
    convertStepTypeToPlatformType,
    getRecommendedPlatformRailingLength,
    getSegmentId
} from "../StraightStairUtils";
import {STRAIGHT_STAIR_TAB_STAIR} from "../StraightStairConstants";
import {getStringerMaterialOptions} from "../StraightStairOptions";
import {debounce} from "lodash";

type Props = StraightStairHookProps & {
    updateActiveTab: (tab: string) => void
}

const useSegmentHook = ({straightStairV2, setStraightStairV2, updateActiveTab}: Props) =>
{
    const [calculateSegmentGeometryQuery] = useCalculateSegmentGeometryLazyQuery()

    const {
        executeMutation: createSegmentMutation,
        loading: createSegmentLoading
    } = useMutationHandler(useCreateStraightStairSegmentMutation)

    const {
        executeMutation: deleteSegmentMutation,
        loading: deleteSegmentLoading
    } = useMutationHandler(useDeleteStraightStairSegmentMutation)

    const {
        executeMutation: copySegmentMutation,
        loading: copySegmentLoading
    } = useMutationHandler(useCopyStraightStairSegmentMutation)

    const [calculateNumberOfStepsQuery, {loading: calculateNumberOfStepsLoading}] = useCalculateNumberOfStepsLazyQuery()

    const setStraightStairSegments = useCallback((onUpdate: (newSegments: StraightStairSegmentFragmentFragment[]) => StraightStairSegmentFragmentFragment[]) =>
    {
        setStraightStairV2(prevState =>
        {
            if (!prevState)
            {
                return null
            }
            return {...prevState, segments: onUpdate([...prevState.segments])}
        })
    }, [setStraightStairV2])

    const onAddSegmentSuccess = useCallback((data: CreateStraightStairSegmentMutation | null) =>
    {
        const segment = data?.createStraightStairSegment
        if (segment)
        {
            setStraightStairSegments((newSegments) =>
            {
                newSegments.push(segment)
                if (straightStairV2?.isSamePreferredMaterial)
                {
                    segment.platformMaterialType = straightStairV2?.preferredMaterial
                }
                if (straightStairV2?.isSameStringerMaterial)
                {
                    segment.platformFrameMaterial = straightStairV2?.stringerMaterial
                }
                if (straightStairV2?.maxLoad)
                {
                    segment.platformMaxLoad = straightStairV2?.maxLoad
                }
                return newSegments
            })

            updateActiveTab(getSegmentId(segment.id))
        }
    }, [setStraightStairSegments, updateActiveTab, straightStairV2?.isSamePreferredMaterial, straightStairV2?.preferredMaterial,
        straightStairV2?.isSameStringerMaterial, straightStairV2?.stringerMaterial, straightStairV2?.maxLoad])

    const addSegment = useCallback(async () =>
    {
        if (!straightStairV2)
        {
            return
        }

        await createSegmentMutation({variables: {straightStairV2Id: straightStairV2.id}},
            {onSuccess: onAddSegmentSuccess})
    }, [straightStairV2, createSegmentMutation, onAddSegmentSuccess])

    const onDeleteSegmentSuccess = useCallback((id: string) =>
    {
        setStraightStairSegments((newSegments) =>
        {
            return newSegments.filter((e) => e.id !== id)
        })

        updateActiveTab(STRAIGHT_STAIR_TAB_STAIR)
    }, [setStraightStairSegments, updateActiveTab])

    const deleteSegment = useCallback(async (id: string) =>
    {
        if (!straightStairV2)
        {
            return
        }
        await deleteSegmentMutation({variables: {id: id}},
            {onSuccess: () => onDeleteSegmentSuccess(id)})
    }, [deleteSegmentMutation, onDeleteSegmentSuccess, straightStairV2])

    const onCopySegmentSuccess = useCallback((data: CopyStraightStairSegmentMutation | null) =>
    {
        const segment = data?.copyStraightStairSegment
        if (segment)
        {
            setStraightStairSegments((newSegments) =>
            {
                newSegments.push(segment)
                return newSegments
            })

            updateActiveTab(getSegmentId(segment.id))
        }
    }, [setStraightStairSegments, updateActiveTab])

    const copySegment = useCallback(async (id: string) =>
    {
        if (!straightStairV2)
        {
            return
        }

        await copySegmentMutation({variables: {id: id}},
            {onSuccess: onCopySegmentSuccess})
    }, [straightStairV2, copySegmentMutation, onCopySegmentSuccess])

    const updateSegmentGeometry = useCallback(async (segment: {
        id: string,
        additionalStep: boolean,
        numberOfSteps: number,
        overlap: number,
        stairHeight: number,
        stepDepth: number,
        stepWidth: number
    }) =>
    {
        const {additionalStep, numberOfSteps, overlap, stairHeight, stepDepth, stepWidth} = segment
        if (!numberOfSteps || !overlap || !stairHeight || !stepDepth || !stepWidth)
        {
            return
        }

        const response = await calculateSegmentGeometryQuery({
            variables: {
                segment: {additionalStep, numberOfSteps, overlap, stairHeight, stepDepth, stepWidth}
            }
        })

        if (!response.data?.calculateSegmentGeometry)
        {
            return
        }

        setStraightStairSegments((newSegments) =>
        {
            const index = newSegments.findIndex(e => e.id === segment.id);
            if (index === -1)
            {
                return newSegments;
            }

            const updatedSegment = {...newSegments[index]};
            updatedSegment.geometry = response.data?.calculateSegmentGeometry
            newSegments[index] = updatedSegment;
            return newSegments;
        })
    }, [calculateSegmentGeometryQuery, setStraightStairSegments])

    const debounceUpdateSegmentGeometry = useMemo(() => debounce(updateSegmentGeometry, 500), [updateSegmentGeometry]);

    const isDoubleRailingAndExtendedEnabled: DoubleRailingAndExtendedEnabledFunc = useCallback((options) =>
    {
        const {railingSide, wallHandrail, extraHandrail} = options
        if (railingSide === 0 && wallHandrail === 0)
        {
            return false
        }
        if (railingSide > 0 && wallHandrail === 0 && !extraHandrail)
        {
            return false
        }
        return true
    }, [])

    const setPlatformRailingLength = useCallback((stringerMaterial: StringerMaterial,
                                                  newSegment: StraightStairSegmentFragmentFragment,
                                                  railingKey: "L1" | "L2" | "W1" | "W2") =>
    {
        const checked = newSegment[`platformRailing${railingKey}Checked`]
        const length = newSegment[`platformRailing${railingKey}Length`]

        newSegment[`platformRailing${railingKey}Length`] = getRecommendedPlatformRailingLength(railingKey,
            newSegment, checked, length, calculateT1Width(stringerMaterial, newSegment.stepWidth)
        )
    }, [])

    const updateSegment = useCallback((id: string, key: string, value: any) =>
    {
        if (!straightStairV2)
        {
            return
        }

        setStraightStairSegments((newSegments) =>
        {
            const index = newSegments.findIndex(e => e.id === id);
            if (index === -1)
            {
                return newSegments;
            }

            const updatedSegment = {...newSegments[index]};
            updatedSegment[key] = value;
            if (key === "platformIsMinFrameMaterial" || key === "platformMaterialType")
            {
                updatedSegment.platformFrameMaterial = getStringerMaterialOptions(updatedSegment.platformMaterialType)[0]
            }
            if (key === "railingSide")
            {
                if (value === 0)
                {
                    updatedSegment.extraHandrail = false
                }
            }
            if (key === "platformIntegrated")
            {
                if (updatedSegment.platformLength > 2000)
                {
                    updatedSegment.platformLength = 2000
                }
                updatedSegment.platformWidth = updatedSegment.stepWidth
                updatedSegment.platformType = convertStepTypeToPlatformType(straightStairV2.stepType, updatedSegment.platformWidth)
                updatedSegment.platformMaxLoad = straightStairV2.maxLoad

                updatedSegment.platformMaterialType = straightStairV2.preferredMaterial
                updatedSegment.platformFrameMaterial = straightStairV2.stringerMaterial
                updatedSegment.platformSCP1 = "W1"
                updatedSegment.platformSCP2 = "W2"
            }

            const stringerMaterial = straightStairV2?.stringerMaterial

            if (key === "platformRailingL1Checked" && value)
            {
                setPlatformRailingLength(stringerMaterial, updatedSegment, "L1")
            }
            if (key === "platformRailingL2Checked" && value)
            {
                setPlatformRailingLength(stringerMaterial, updatedSegment, "L2")
            }
            if (key === "platformRailingW1Checked" && value)
            {
                setPlatformRailingLength(stringerMaterial, updatedSegment, "W1")
            }
            if (key === "platformRailingW2Checked" && value)
            {
                setPlatformRailingLength(stringerMaterial, updatedSegment, "W2")
            }

            if (key === "platformWidth" && straightStairV2.stepType === StepType.StandardGrate)
            {
                updatedSegment.platformType = convertStepTypeToPlatformType(straightStairV2.stepType, value)
            }

            const isDoubleRailingAndExtendedEnable = isDoubleRailingAndExtendedEnabled(updatedSegment)
            if (!isDoubleRailingAndExtendedEnable)
            {
                updatedSegment.doubleRailing = false
                updatedSegment.railingExtended = false
            }

            newSegments[index] = updatedSegment;

            const updateGeometryKeys = ["additionalStep", "numberOfSteps", "overlap", "stairHeight", "stepDepth", "stepWidth",]
            if (updateGeometryKeys.includes(key))
            {
                debounceUpdateSegmentGeometry(updatedSegment)
            }

            return newSegments;
        })
    }, [debounceUpdateSegmentGeometry, isDoubleRailingAndExtendedEnabled, setStraightStairSegments,
        straightStairV2, setPlatformRailingLength])

    const calculateNumberOfSteps = useCallback(async (segmentId: string) =>
    {
        const segment = straightStairV2?.segments?.find((e) => e.id === segmentId)
        if (!segment)
        {
            return
        }

        const {stairHeight, stepDepth, overlap} = segment
        const response = await calculateNumberOfStepsQuery({variables: {stairHeight, stepDepth, overlap}})
        const newNumberOfSteps = response.data?.calculateNumberOfSteps

        if (newNumberOfSteps)
        {
            updateSegment(segmentId, "numberOfSteps", newNumberOfSteps)
        }
    }, [straightStairV2, calculateNumberOfStepsQuery, updateSegment])

    const updateSupports = useCallback((segmentId: string, supports: StraightStairSupportFragmentFragment[]) =>
    {
        setStraightStairSegments((newSegments) =>
        {
            const segmentIndex = newSegments.findIndex(e => e.id === segmentId);
            if (segmentIndex === -1)
            {
                return newSegments;
            }

            newSegments[segmentIndex].supports = [...supports]
            return newSegments
        })
    }, [setStraightStairSegments])

    return {
        createSegmentLoading, deleteSegmentLoading, copySegmentLoading, calculateNumberOfStepsLoading,
        addSegment, deleteSegment, updateSegment, copySegment,
        calculateNumberOfSteps,
        isDoubleRailingAndExtendedEnabled,
        updateSupports,
    }
}

export default useSegmentHook;