import PaginatedDataGrid from "../../common/data-grid/PaginatedDataGrid";
import {
    GetArticleRowsInProductionDocument,
    useGetSpiralStairSegmentInProductionCountByWeeklyLazyQuery,
    WeeklySpiralStairSegmentInProductionCountRow
} from "../../generated/graphql";
import React, {memo, useEffect, useMemo, useState} from "react";
import {ColumnDefinition} from "../../common/data-grid/ColumnDefinition";
import {Link} from "react-router-dom";
import ColumnDate from "../../common/data-grid/columns/ColumnDate";
import ProductionProgressBar, {calculateProgress} from "../../common/ProductionProgressBar";
import ColumnPurchaseOrderPrioritisation from "../../common/data-grid/columns/ColumnPurchaseOrderPrioritisation";
import ColumnExtra from "../../common/data-grid/columns/spiral-stair/ColumnExtra";
import ColumnRadius from "../../common/data-grid/columns/spiral-stair/ColumnRadius";
import ColumnDirection from "../../common/data-grid/columns/spiral-stair/ColumnDirection";
import ColumnSurfaceTreatmentOverview from "../../common/data-grid/columns/spiral-stair/ColumnSurfaceTreatmentOverview";
import ColumnRalColors from "../../common/data-grid/columns/spiral-stair/ColumnRalColors";
import ColumnDrawings from "../../common/data-grid/columns/spiral-stair/ColumnDrawings";
import {FORMAT_WEEK_NUMBER, formatToSwedishTime} from "../../UTIL";
import {GridValueFormatterParams} from "@mui/x-data-grid";
import {Table} from "reactstrap";
import {articleDetailLink} from "../../utils/ArticleUtils";

const KEY_TABLE = "TABLE_SCHEDULER_TASKS"
const SEGMENT_IN_PRODUCTION_WEEK = 8

const SegmentInProductionCountTable = memo(({rows}: { rows: WeeklySpiralStairSegmentInProductionCountRow[] }) =>
{
    const sortedRows = rows.sort((a, b) => a.weekNumber.localeCompare(b.weekNumber))
    return <Table bordered style={{maxWidth: "800px"}}>
        <thead>
        <tr>
            <th>Week number</th>
            {
                sortedRows.map((e) => <th>{e.weekNumber}</th>)
            }
        </tr>
        </thead>
        <tbody>
        <tr>
            <th>No of sections</th>
            {
                sortedRows.map((e) => <th style={{textAlign: 'right'}}>{e.count}</th>)
            }
        </tr>
        </tbody>
    </Table>
})

const InProductionTasksPage = () =>
{
    const [querySegmentInProductionCountByWeek] = useGetSpiralStairSegmentInProductionCountByWeeklyLazyQuery()
    const [weeklySegmentCountRows, setWeeklySegmentCountRows] = useState<WeeklySpiralStairSegmentInProductionCountRow[]>([])

    const columns: ColumnDefinition [] = useMemo(() =>
    {
        return [
            {
                field: 'purchaseOrder.order.number',
                headerName: 'Order no.',
                customFilterField: 'purchaseOrder.order.number',
                customSortField: "purchaseOrder.order.number",
                customSearchField: 'purchaseOrder.order.number',
                searchable: true,
                renderCell: (params) =>
                {
                    return <Link to={`/orderDetail/${params.row.purchaseOrder.order.id}`}>
                        {params.value}
                    </Link>
                },
            },
            ColumnPurchaseOrderPrioritisation(),
            {
                field: 'article.artNo',
                headerName: 'Article no.',
                searchable: true,
                renderCell: (params) =>
                {
                    const article = params.row.article
                    return articleDetailLink(article)
                }
            },
            {
                field: 'segmentCount',
                headerName: 'No of sections',
                filterable: false,
                type: 'number',
                width: 90,
                valueGetter: (params) =>
                {
                    return params.row?.article?.spiralStair?.segments
                },
                renderCell: (params) =>
                {
                    return params.value?.length
                },
            },
            ColumnRadius((params) => params.row?.article?.spiralStair?.segments),
            ColumnExtra((params) => params.row?.article?.spiralStair?.extra),
            ColumnDirection((params) => params.row?.article?.spiralStair?.direction),
            ColumnSurfaceTreatmentOverview((params) =>
            {
                const spiralStair = params.row?.article?.spiralStair
                return spiralStair ? {
                    surfaceTreatmentOverview: spiralStair.surfaceTreatmentOverview,
                    platingType: spiralStair.platingType
                } : null
            }),
            {
                field: 'weekNumber',
                type: 'date',
                filterable: false,
                customSortField: 'purchaseOrder.order.preferredDeliveryDate',
                valueGetter: (params) =>
                {
                    const value = params.row.purchaseOrder.order.preferredDeliveryDate
                    return value ? new Date(value) : null
                },
                valueFormatter: (params: GridValueFormatterParams<Date>) =>
                {
                    const value = params.value
                    return formatToSwedishTime(value, FORMAT_WEEK_NUMBER)
                },
                parseFilterValue: (value) => new Date(value),
            },
            ColumnDate('purchaseOrder.order.preferredDeliveryDate', 'Preferred delivery date'),
            ColumnRalColors(params => params.row?.article?.spiralStair?.simpleSurfaceTreatments),
            ColumnDrawings((p) => p.row.purchaseOrder.order.files),
            {
                field: 'progress',
                sortable: false,
                filterable: false,
                valueGetter: (params) =>
                {
                    const articleRow = params.row
                    return calculateProgress(articleRow)
                },
                renderCell: (params) =>
                {
                    return <ProductionProgressBar id={params.row?.id} progress={params.value}
                                                  productionStatus={params.row?.productionStatus}/>
                },
            },
        ]
    }, [])

    const defaultSort = useMemo(() =>
    {
        return {sortModel: [{field: 'purchaseOrder.order.preferredDeliveryDate', sort: 'asc'}]}
    }, [])

    useEffect(() =>
    {
        const fetch = async () =>
        {
            const response = await querySegmentInProductionCountByWeek({
                variables: {
                    date: new Date(),
                    week: SEGMENT_IN_PRODUCTION_WEEK
                }
            })
            setWeeklySegmentCountRows(response?.data?.spiralStairSegmentInProductionCountByWeekly ?? [])
        }
        fetch().then()
    }, [querySegmentInProductionCountByWeek]);

    return <div>
        <SegmentInProductionCountTable rows={weeklySegmentCountRows}/>
        <PaginatedDataGrid definition={{
            tableKey: KEY_TABLE,
            columns: columns,
            initState: {
                sorting: defaultSort
            }
        }} query={GetArticleRowsInProductionDocument}/>
    </div>
}

export default InProductionTasksPage;