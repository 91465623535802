import React, {useCallback, useState} from 'react';
import {Formik} from 'formik';
import {Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Input, Label, Row, Spinner} from 'reactstrap';
import {saveAs} from 'file-saver';
import Auth from "../../Auth";
import config from "../../config";
import NotificationPopup from "../../common/lib/NotificationPopup";
import {map} from "lodash";
import {useLocale} from "../../LocaleProvider";

const InventoryStockTakingPage = () => {
    const [currencies, setCurrencies] = useState({
        NOK: 0,
        EUR: 0
    });

    const {t} = useLocale()

    const onSubmit = useCallback(async (values, {resetForm}) => {
        console.log(values);
        try {
            const token = Auth.getToken();
            const formData = new FormData();
            formData.append("file", values.file, values.file.name);
            formData.append("date", values.date);
            const res = await fetch(`${config.INVENTORY_URI}stock-transactions/stocktaking?date=${new Date(values.date).getTime()}`,
                {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    body: formData
                });
            console.log(res);
            if (res.ok) {
                NotificationPopup.success(`Processed stocktaking`);
            } else {
                NotificationPopup.error(`failed to process stocktaking: ${(await res.json()).message}`)
            }
        } catch (e) {
            console.error(`failed. ${e.message}`);
        }

    }, []);
    return <Row>
        <Col lg={4} sm={6}>
            <Card>
                <CardHeader>
                    {t('Stocktaking template')}
                </CardHeader>
                <CardBody>
                    <Form>
                        <FormGroup>
                            <Label>
                                {t('Set currency rates')}
                            </Label>
                            {map(currencies, (rate, currency) => {
                                return <div key={currency}>
                                    <p>{currency}</p>
                                    <Input value={rate}
                                           onChange={e => setCurrencies({
                                               ...currencies,
                                               [currency]: e.target.value
                                           })}
                                           type="number" min={0}/>
                                </div>
                            })}
                        </FormGroup>
                    </Form>
                    <Button onClick={async () => {
                        const token = Auth.getToken();
                        const res = await fetch(`${config.INVENTORY_URI}stock-transactions/export`, {
                            method:"POST",
                            headers: {
                                Authorization: `Bearer ${token}`,
                                "Content-Type":"application/json"
                            },
                            body:JSON.stringify({
                                currencyRates: map(currencies, (rate, currency) => ({
                                    currency,
                                    rate: parseFloat(rate)
                                })).concat({currency: "SEK", rate: 1.0})
                            })
                        });
                        const blob = await res.blob();
                        saveAs(blob, `stock.csv`);

                    }}>
                        {t('Download template')}
                    </Button>
                </CardBody>
            </Card>

            <Formik initialValues={{date: ""}}
                    onSubmit={onSubmit}>
                {({isSubmitting, handleChange, handleSubmit, values, setFieldValue}) =>
                    <Form onSubmit={handleSubmit}>
                        <FormGroup>
                            <Label>
                                {t('Upload CSV File')}...
                            </Label>
                            <Input required name={"file"} type={"file"} onChange={e =>
                                setFieldValue("file", e.currentTarget.files[0])}/>
                        </FormGroup>
                        <FormGroup>
                            <Label>
                                {t('Select date for stocktaking')}
                            </Label>
                            <Input required name={"date"} type={"date"} onChange={handleChange} values={values.date}/>
                        </FormGroup>
                        {!isSubmitting && <Button type={"submit"}>
                            {t('Submit')}
                        </Button>}
                        {isSubmitting && <Spinner animation='border' color={"warning"}/>}
                    </Form>
                }
            </Formik>
        </Col>
    </Row>
}


export default InventoryStockTakingPage
