import {GridDensity} from "@mui/x-data-grid/models/gridDensity";
import React, {memo, useCallback, useState} from "react";
import {
    GridColumnResizeParams,
    GridColumnVisibilityModel,
    GridPaginationModel, GridSortModel,
    GridValidRowModel,
} from "@mui/x-data-grid";
import StripedDataGrid from "./StripedDataGrid";
import {withRouter} from "react-router";
import {DataGridProProps, useGridApiRef} from "@mui/x-data-grid-pro";
import CustomDataGridToolbar from "./CustomDataGridToolbar";
import {createColumns} from "./ColumnDefinition";
import CustomDataGridPagination from "./CustomDataGridPagination";
import {GridCallbackDetails} from "@mui/x-data-grid/models/api";
import ExpandArrow from "../icons/ExpandArrow";
import CollapseArrow from "../icons/CollapseArrow";
import {GridFilterModel} from "@mui/x-data-grid/models/gridFilterModel";
import {ExportGroup} from "../../types/data-grid/ExportGroup";
import {useLocale} from "../../LocaleProvider";

export type TableButton = {
    name: string
    filters?: GridFilterModel
    sort?: GridSortModel
}

export type BaseDataGridProps = DataGridProProps<GridValidRowModel> & {
    tableKey: string
    disableTooBar?: boolean
    onPageChange?: (pageModel: GridPaginationModel, details: GridCallbackDetails) => {}
    exportGroup?: ExportGroup
}

const KEY_COLUMN_VISIBLE = '_COLUMN_VISIBLE'
const KEY_RESIZE = '_RESIZE'
const KEY_DENSITY = '_DENSITY'
const KEY_COLUMN_ORDER = '_COLUMN_ORDER'
const KEY_COLUMN_PIN = '_COLUMN_PIN'
export const KEY_PAGE_SIZE = '_PAGE_SIZE'

const BaseDataGrid = (props: BaseDataGridProps) =>
{
    const {tableKey, onPageChange} = props
    const apiRef = useGridApiRef();
    const { t } = useLocale();

    const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>(
        localStorage.getItem(`${tableKey}${KEY_COLUMN_VISIBLE}`)
            ? {...JSON.parse(localStorage.getItem(`${tableKey}${KEY_COLUMN_VISIBLE}`)!)}
            : props.initialState?.columns?.columnVisibilityModel ?? {}
    );

    const [columnsWidth, setColumnsWidth] = useState<any>(
        JSON.parse(localStorage.getItem(`${tableKey}${KEY_RESIZE}`) ?? '{}')
    );

    const [density, setDensity] = useState<GridDensity>(localStorage.getItem(`${tableKey}${KEY_DENSITY}`) as GridDensity ?? 'standard')

    const [pinnedColumns, setPinnedColumns] = useState(JSON.parse(localStorage.getItem(`${tableKey}${KEY_COLUMN_PIN}`) ?? '{}'))

    const onColumnVisibleChange = useCallback((columnModel: GridColumnVisibilityModel) =>
    {
        localStorage.setItem(`${props.tableKey}${KEY_COLUMN_VISIBLE}`, JSON.stringify(columnModel))
        setColumnVisibilityModel(columnModel)
    }, [props.tableKey]);

    const onColumnWidthChange = useCallback((columnResizeParam: GridColumnResizeParams) =>
    {
        const newColumnWidth = {...columnsWidth, [columnResizeParam.colDef.field]: columnResizeParam.width}

        setColumnsWidth(newColumnWidth)
        localStorage.setItem(`${tableKey}${KEY_RESIZE}`, JSON.stringify(newColumnWidth))
    }, [columnsWidth, tableKey]);

    const onDensityChange = useCallback((v) =>
    {
        const newDensity = v?.density?.value
        if (newDensity && newDensity !== density)
        {
            localStorage.setItem(`${tableKey}${KEY_DENSITY}`, newDensity)
            setDensity(newDensity)
        }
    }, [tableKey, density])

    const onColumnOrderChange = useCallback((_) =>
    {
        const newColumnOrdering = apiRef.current.getAllColumns().map((e) => e.field)
        localStorage.setItem(`${tableKey}${KEY_COLUMN_ORDER}`, JSON.stringify(newColumnOrdering));
    }, [tableKey, apiRef])

    const onPinnedChange = useCallback((v) =>
    {
        localStorage.setItem(`${tableKey}${KEY_COLUMN_PIN}`, JSON.stringify(v));
        setPinnedColumns(v)
    }, [tableKey])

    const onPaginationModelChange = useCallback((pageModel: GridPaginationModel, details) =>
    {
        localStorage.setItem(`${tableKey}${KEY_PAGE_SIZE}`, `${pageModel.pageSize}`)
        if (onPageChange)
        {
            onPageChange(pageModel, details)
        }
    }, [tableKey, onPageChange]);

    return (
        <StripedDataGrid
            {...props}
            apiRef={apiRef}
            autoHeight
            getRowHeight={() => 'auto'}
            sx={{
                '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {py: '2px'},
                '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {py: '8px'},
                '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {py: '20px'},
            }}
            getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            }
            initialState={
                {
                    ...props.initialState,
                    columns: columnVisibilityModel,
                }
            }
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={onColumnVisibleChange}
            pagination={true}
            slots={{
                toolbar: props.disableTooBar ? null : (props?.slots?.toolbar ?? CustomDataGridToolbar),
                pagination: CustomDataGridPagination,
                detailPanelExpandIcon: ExpandArrow,
                detailPanelCollapseIcon: CollapseArrow
            }}
            slotProps={{
                toolbar: {exportGroup: props.exportGroup},
            }}
            rows={props.rows}
            columns={createColumns(props.columns, columnsWidth,
                JSON.parse(localStorage.getItem(`${tableKey}${KEY_COLUMN_ORDER}`) ?? '[]'),
                t
            )}
            onColumnWidthChange={onColumnWidthChange}
            density={density}
            onStateChange={onDensityChange}
            onColumnOrderChange={onColumnOrderChange}
            pinnedColumns={pinnedColumns}
            onPinnedColumnsChange={onPinnedChange}
            onPaginationModelChange={onPaginationModelChange}
        />
    );
}

export default memo(withRouter(BaseDataGrid));