import {
    BomFragmentFragment,
    Comparator, HandrailOverNoOfPolesFragmentFragment,
    SpiralStairStatus,
    useBomRemovedSubscription,
    useBomUpdatedSubscription,
    useCheckedBomMutation,
    useCreateBoMsAndHandrailOverNoOfPolesMutation,
    useGetCommentsQuery,
    useGetPackagesLazyQuery, useHandrailOverNoOfPolesUpdatedSubscription,
    useRemoveBillOfMaterialMutation,
    useUnCheckedBomMutation,
    useUpdateBillOfMaterialMutation,
    useUpdateSpiralStairMutation,
    useUpdateSpiralStairStatusMutation
} from "../../../generated/graphql";
import React, {useCallback, useEffect, useRef, useState} from "react";
import NotificationPopup from "../../../common/lib/NotificationPopup";
import {
    Badge,
    Button,
    Dropdown, DropdownItem, DropdownMenu,
    DropdownToggle,
    Input,
    ModalBody,
    ModalFooter,
    Spinner,
} from "reactstrap";
import {getSpiralStairStatusColor} from "../../SpiralStairStatusExt";
import {ok} from "assert";
import CommentList from "../../../common/comment/CommentList";
import Collapser from "../../../common/Collapser";
import {formatToSwedishTime} from "../../../UTIL";
import CreatePackageModalPanel from "../../../package/component/CreatePackageModalPanel";
import { BomListPanel } from "./BomListPanel";
import {SpiralStairInBomType} from "../SpiralStairInBomType";
import PackageTablePanel from "./PackageInBomTable";
import HandrailOverNoOfPolesListPanel from "./HandrailOverNoOfPolesListPanel";
import {ApolloError} from "@apollo/client";
import {debounce} from "lodash";

const CommentsPanel = (props: { articleId: string }) =>
{
    const {articleId} = props
    const {data} = useGetCommentsQuery({variables: {articleId: articleId}})

    return <>
        <Collapser label={`Show comments(${data?.comments.length ?? 0})`} open={false}>
            <h5>Comments</h5>
            <CommentList articleId={articleId} comments={data?.comments ?? []}/>
        </Collapser>
    </>

}


export default (props: {
    orderId: string,
    articleId: string,
    boms: BomFragmentFragment[],
    isEditable: boolean,
    toggle: () => void,
    spiralStair: SpiralStairInBomType
    setSpiralStairs:  React.Dispatch<React.SetStateAction<any[]>>,
    orderNumber?: string
}, element: JSX.Element = <>
    <div>Comment list</div>
</>) =>
{
    const {
        orderId,
        articleId,
        isEditable,
        toggle,
        spiralStair,
        setSpiralStairs,
        orderNumber
    } = props

    const [createBoMsAndHandrailOverNoOfPolesMutation, {loading: isCreating}] = useCreateBoMsAndHandrailOverNoOfPolesMutation();
    const [updateSpiralStair] = useUpdateSpiralStairMutation();
    const [updateSpiralStairStatusMutation] = useUpdateSpiralStairStatusMutation();
    const [checkedBomMutation] = useCheckedBomMutation();
    const [unCheckedBomMutation] = useUnCheckedBomMutation();
    const [updateBomMutation] = useUpdateBillOfMaterialMutation()
    const [removeBomMutation] = useRemoveBillOfMaterialMutation()
    const [getPackagesQuery, {data: packages}] = useGetPackagesLazyQuery()

    const {data: responseBomUpdatedSubscription, error: errorBomUpdatedSubscription} = useBomUpdatedSubscription({variables: {orderId}})
    const {data: responseBomRemovedSubscription, error: errorBomRemovedSubscription} = useBomRemovedSubscription({variables: {orderId}})
    const {data: responseHandrailOverNoOfPolesUpdatedSubscription, error: errorHandrailOverNoOfPolesUpdatedSubscription} = useHandrailOverNoOfPolesUpdatedSubscription({variables: {spiralStairId: spiralStair.spiralStairId}})

    const [statusDropDownExpand, setStatusDropDownExpand] = useState(false)
    const [spiralStairBom, setSpiralStairBom] = useState<BomFragmentFragment[]>(props.boms)
    const [freeTextInputValue, setFreeTextInputValue] = useState(spiralStair?.freeText ?? '');

    const onUpdateSpiralStairSuccess = useCallback((id, fields) =>
    {
        setSpiralStairs(prevState =>
        {
            const index = prevState.findIndex((e) => e.articleId === id)
            if (index === -1)
            {
                return prevState
            }
            prevState[index] = {...prevState[index], ...fields}
            return [...prevState]
        })
    }, [setSpiralStairs])

    const createBoms = useCallback(async () =>
    {
        const response = await createBoMsAndHandrailOverNoOfPolesMutation({
            variables: {
                orderId,
                articleId,
                spiralStairId: spiralStair.spiralStairId
            }
        })
        const data = response.data?.createBillOfMaterials ?? []
        setSpiralStairBom(data)
    }, [createBoMsAndHandrailOverNoOfPolesMutation, setSpiralStairBom, orderId, articleId, spiralStair.spiralStairId])

    const updatesHandrailOverNoOfPolesQueue = useRef<HandrailOverNoOfPolesFragmentFragment[]>([]);

    const onHandrailOverNoOfPolesListUpdated = useCallback((newHandrailOverNoOfPoles: HandrailOverNoOfPolesFragmentFragment | undefined) =>
    {
        if (!newHandrailOverNoOfPoles)
        {
            return;
        }

        const processUpdates = debounce(() =>
        {
            setSpiralStairs(prevState =>
            {
                const updatedState = [...prevState];

                updatesHandrailOverNoOfPolesQueue.current.forEach(update =>
                {
                    const indexSpiralStair = updatedState.findIndex(e => e.spiralStairId === update.spiralStairId);
                    if (indexSpiralStair === -1)
                    {
                        return;
                    }

                    const spiralStair = updatedState[indexSpiralStair];
                    const handrailOverNoOfPolesList = [...spiralStair.handrailOverNoOfPolesList];

                    const index = handrailOverNoOfPolesList.findIndex(e => e.id === update.id);
                    if (index === -1)
                    {
                        handrailOverNoOfPolesList.push(update);
                    } else
                    {
                        handrailOverNoOfPolesList[index] = update;
                    }

                    updatedState[indexSpiralStair] = {
                        ...spiralStair,
                        handrailOverNoOfPolesList,
                    };
                });

                updatesHandrailOverNoOfPolesQueue.current = [];
                return updatedState;
            });
        }, 300);

        updatesHandrailOverNoOfPolesQueue.current.push(newHandrailOverNoOfPoles);
        processUpdates();
    }, [setSpiralStairs]);

    const onFreeTextSave = useCallback(async () =>
    {
        try
        {
            await updateSpiralStair({
                variables: {
                    id: articleId,
                    spiralStair: {
                        freeText: freeTextInputValue
                    }
                }
            })
            onUpdateSpiralStairSuccess(articleId, {freeText: freeTextInputValue})
            NotificationPopup.success("Free text save success")
        } catch (e)
        {
            NotificationPopup.error(`Free text save failed. error: ${e}`)
        }
    }, [freeTextInputValue, articleId, updateSpiralStair, onUpdateSpiralStairSuccess])

    const updateSpiralStairStatus = useCallback(async (updateStatus) => {
        try {
            const response = await updateSpiralStairStatusMutation({variables: {articleId: articleId, status: updateStatus}});
            const data = response.data?.updateSpiralStairStatus;
            ok(data, `Update spiral stair status response error`);
            onUpdateSpiralStairSuccess(articleId, {
                status: data.status,
                statusUpdatedBy: data.statusUpdatedBy,
                statusUpdatedAt: data.statusUpdatedAt,
            })
        } catch (e) {
            NotificationPopup.error(`Update spiral stair status error: ${e}`);
        }

    }, [updateSpiralStairStatusMutation, articleId, onUpdateSpiralStairSuccess]);

    const handleUpdateBomSuccess = useCallback((newBom?: BomFragmentFragment) =>
    {
        if (!newBom)
        {
            return
        }
        setSpiralStairBom((prevState) =>
        {
            const index = prevState.findIndex((e) => e.artNo === newBom.artNo)
            if (index === -1)
            {
                prevState.push(newBom)
            } else
            {
                prevState[index] = newBom
            }
            return [...prevState]
        })
    }, [])

    const onCheckedBomClick = useCallback(async (bom: BomFragmentFragment) =>
    {
        if (!bom.artNo || (bom.quantity ?? 0) <= 0)
        {
            NotificationPopup.error(`Error: No article or quantity.`)
            return
        }

        const response = await checkedBomMutation({
            variables: {
                bom: {
                    orderId: orderId,
                    articleId: articleId,
                    artNo: bom.artNo,
                    quantity: bom.quantity ?? 0
                }
            }
        })
        handleUpdateBomSuccess(response.data?.checkedBom)
    }, [checkedBomMutation, articleId, orderId, handleUpdateBomSuccess])

    const onUnCheckedBomClick = useCallback(async (bom: BomFragmentFragment) =>
    {
        if (!window.confirm(`Are you sure you want to uncheck ${bom.artNo}-${bom.name}?`))
        {
            return
        }

        const response = await unCheckedBomMutation({
            variables: {id:bom.id}
        })
        handleUpdateBomSuccess(response.data?.unCheckedBom)
    }, [unCheckedBomMutation, handleUpdateBomSuccess])

    const onRemoveBomClick = useCallback(async (bom: BomFragmentFragment) =>
    {
        const isExistBom = bom.id !== ''
        if (!isExistBom)
        {
            setSpiralStairBom((prevState) => prevState.filter(e => e !== bom))
            return
        }

        if (window.confirm(`Are you sure you want to delete ${bom.artNo}-${bom.name}?`))
        {
            await removeBomMutation({variables: {id: bom.id}})
            setSpiralStairBom((prevState) => prevState.filter(e => e !== bom))
        }
    }, [removeBomMutation])

    const updateBom = useCallback(async (bom:BomFragmentFragment)=> {
        if (!bom.artNo || (bom.quantity ?? 0) <= 0)
        {
            NotificationPopup.error(`Error: No article or quantity.`)
            return
        }
        await updateBomMutation({
            variables: {
                bom: {
                    orderId: orderId,
                    articleId: articleId,
                    artNo: bom.artNo,
                    quantity: bom.quantity ?? 0
                }
            }
        })
    }, [updateBomMutation, orderId, articleId])

    useEffect(() =>
    {
        const newBom = responseBomUpdatedSubscription?.bomUpdated
        if (newBom)
        {
            handleUpdateBomSuccess(newBom)
        }
    }, [responseBomUpdatedSubscription, handleUpdateBomSuccess]);

    useEffect(() =>
    {
        const id = responseBomRemovedSubscription?.bomRemoved?.id
        if (id)
        {
            setSpiralStairBom((prevState) => prevState.filter(e => e.id !== id))
        }
    }, [responseBomRemovedSubscription]);

    useEffect(() =>
    {
        if (responseHandrailOverNoOfPolesUpdatedSubscription?.handrailOverNoOfPolesUpdated)
        {
            onHandrailOverNoOfPolesListUpdated(responseHandrailOverNoOfPolesUpdatedSubscription.handrailOverNoOfPolesUpdated)
        }
    }, [responseHandrailOverNoOfPolesUpdatedSubscription, onHandrailOverNoOfPolesListUpdated]);

    useEffect(() =>
    {
        const articleRowId = spiralStair.articleRow?.id
        if (articleRowId)
        {
            const fetch = async () =>
            {
                await getPackagesQuery({
                    variables: {
                        filtering:
                            {
                                items: [{
                                    key: "packageItems.some.articleRow.id",
                                    value: Number(articleRowId), comparator: Comparator.Equals
                                }]
                            }
                    },
                })
            }
            fetch().then()
        }
    }, [getPackagesQuery, spiralStair.articleRow])

    const notifyError = useCallback((type: string, error: ApolloError) =>
    {
        NotificationPopup.error(`Failed to subscription ${type}. please refresh page. message: ${error.message}`)
    }, [])

    useEffect(() =>
    {
        if (errorBomUpdatedSubscription)
        {
            notifyError(`bom updated`, errorBomUpdatedSubscription)
        }
    }, [errorBomUpdatedSubscription, notifyError]);

    useEffect(() =>
    {
        if (errorBomRemovedSubscription)
        {
            notifyError(`bom removed`, errorBomRemovedSubscription)
        }
    }, [errorBomRemovedSubscription, notifyError]);

    useEffect(() =>
    {
        if (errorHandrailOverNoOfPolesUpdatedSubscription)
        {
            notifyError(`handrail over no of poled updated`, errorHandrailOverNoOfPolesUpdatedSubscription)
        }
    }, [errorHandrailOverNoOfPolesUpdatedSubscription, notifyError]);

    return <>
        <ModalBody>
            <h5>{spiralStair.articleRow?.article?.artNo}
                <span>
                    <Dropdown style={{display: "inline-block"}} isOpen={statusDropDownExpand} toggle={()=>{setStatusDropDownExpand(!statusDropDownExpand)}} >
                        <DropdownToggle nav>
                            <Badge style={{marginLeft: "4px"}}
                                   color={getSpiralStairStatusColor(spiralStair.status)}>{spiralStair.status}▼</Badge>
                        </DropdownToggle>
                        <DropdownMenu>
                            {
                                [SpiralStairStatus.Pending, SpiralStairStatus.Prepackaged, SpiralStairStatus.Finished].map((e, index) =>
                                {
                                    return <DropdownItem key={`dropdown-status-${index}`} onClick={() => updateSpiralStairStatus(e)}>
                                        <Badge color={getSpiralStairStatusColor(e)}>{e}</Badge>
                                    </DropdownItem>
                                })
                            }
                        </DropdownMenu>
                    </Dropdown>
                </span>
            </h5>
            {
                spiralStairBom.length === 0
                    ? <Button onClick={createBoms}
                              disabled={isCreating}>
                        Create Bill of materials
                        {isCreating && <Spinner/>}
                    </Button>
                    : <BomListPanel bomList={spiralStairBom} isEditable={isEditable}
                                    setBomList={bomList => setSpiralStairBom(bomList)}
                                    onCheckedBomClick={onCheckedBomClick}
                                    onUnCheckedBomClick={onUnCheckedBomClick}
                                    onUpdateBom={updateBom}
                                    onRemoveBomClick={onRemoveBomClick}/>
            }
            {spiralStair.statusUpdatedBy?.displayName && <p style={{textAlign: 'right'}}>
                Updated status at {formatToSwedishTime(spiralStair.statusUpdatedAt)} by {spiralStair.statusUpdatedBy?.displayName}
            </p>}
            <hr/>

            <HandrailOverNoOfPolesListPanel handrailOverNoOfPolesList={spiralStair.handrailOverNoOfPolesList}
                                            onUpdate={onHandrailOverNoOfPolesListUpdated}
                                            segments={spiralStair.segments}/>
            <h5>Free text</h5>
            <Input
                type="textarea"
                placeholder="Enter free text here"
                value={freeTextInputValue}
                onChange={(e) => setFreeTextInputValue(e.target.value)}/>
            <Button style={{marginTop: '5px'}} size={"sm"} disabled={spiralStair?.freeText === freeTextInputValue}
                    onClick={onFreeTextSave}>Save free text
            </Button>

            <hr/>
            <CommentsPanel articleId={articleId}/>
            <hr/>
            {packages && <PackageTablePanel packages={packages.result?.list}
                                            artNo={spiralStair.articleRow?.article?.artNo}/>}
        </ModalBody>

        <ModalFooter>
            {spiralStair.articleRow?.id && <CreatePackageModalPanel orderId={orderId}
                                                                    articleRowIds={[spiralStair.articleRow.id]}
                                                                    openSearchOrder={false}
                                                                    artNo={spiralStair.articleRow.article?.artNo ?? ''}
                                                                    orderNumber={orderNumber}
                                                                    linkedPackage={{
                                                                        packages: packages?.result?.list,
                                                                        artNo: spiralStair.articleRow?.article?.artNo
                                                                    }}/>}
            <Button onClick={toggle} size={"sm"}>Close</Button>
        </ModalFooter>
    </>
}
