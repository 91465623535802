import React from 'react';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap';
import {withRouter} from "react-router";
import {IsAdmin} from "./Auth";

class NavBarDropDown extends React.Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            dropdownOpen: false
        };
        this.to = this.to.bind(this);
    }

    toggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    to(path)
    {
        return () => this.props.history.push(path);
    }

    render() {
        return (
        <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
            <DropdownToggle nav caret>
                More..
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem onClick={this.to(`/stockArticles`)}>
                    Stock articles
                </DropdownItem>
                <DropdownItem onClick={this.to(`/suppliers`)}>
                    Suppliers
                </DropdownItem>
                <DropdownItem onClick={this.to(`/reports`)}>
                    Reports
                </DropdownItem>
                <DropdownItem onClick={this.to(`/projects`)}>
                    Projects
                </DropdownItem>
                <DropdownItem onClick={this.to(`/contacts`)}>
                    Contacts
                </DropdownItem>
                <DropdownItem onClick={this.to(`/purchaseOrders`)}>
                    Purchase Orders
                </DropdownItem>
                <DropdownItem onClick={this.to(`/contact-list`)}>
                    Contact list
                </DropdownItem>
                <DropdownItem onClick={this.to(`/spiralStairs`)}>
                    Spiral stairs
                </DropdownItem>
                <DropdownItem onClick={this.to(`/logisticsPO`)}>
                    POs ready to receive
                </DropdownItem>
                <DropdownItem onClick={this.to('/economy-control')}>
                    Economy Control
                </DropdownItem>
                <IsAdmin>
                    <DropdownItem onClick={this.to('/pricelist')}>
                        Price List
                    </DropdownItem>
                    <DropdownItem onClick={this.to('/membership')}>
                        Membership
                    </DropdownItem>
                </IsAdmin>
                <DropdownItem onClick={this.to('/documents')}>
                    Documents
                </DropdownItem>
                <DropdownItem onClick={this.to('/straight-stair-pricing')}>
                    Straight Stair Pricing
                </DropdownItem>
                <DropdownItem onClick={this.to(`/image-gallery`)}>
                    Image gallery
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
        );
    }
}

export default withRouter(NavBarDropDown)
