import WorkflowComment from "../../../types/WorkflowComment";
import {Table} from "reactstrap";
import {formatToSwedishTime} from "../../../UTIL";
import React from "react";
import {useLocale} from "../../../LocaleProvider";

export const CommentTable = ({comments, requiresMoreInformationComment}:
                                 { comments?: WorkflowComment[], requiresMoreInformationComment?: string }) =>
{
    const {t} = useLocale()
    return <Table striped bordered hover>
        <thead>
        <tr>
            <th>{t('Comment')}</th>
            <th>{t('Commenter')}</th>
            <th>{t('Created at')}</th>
        </tr>
        </thead>
        <tbody>
        {
            requiresMoreInformationComment && <tr>
                <td>(Old) {requiresMoreInformationComment}</td>
            </tr>
        }

        {
            comments?.map((e, index) =>
            {
                return <tr key={index}>
                    <td>{e.comment}</td>
                    <td>{e.user}</td>
                    <td>{formatToSwedishTime(e.createdAt)}</td>
                </tr>
            })
        }
        </tbody>
    </Table>
}