import React, {useCallback, useMemo, useState} from 'react';
import {
    Row,
    Navbar,
    Nav,
    NavItem,
    NavbarToggler,
    Collapse,
    NavLink,
    NavbarBrand,
} from 'reactstrap';
import {Link} from 'react-router-dom';
import {InventoryRoute} from "../InventoryRouters";
import config, {environment} from "../../config";
import {withRouter} from "react-router";
import {InventoryTenantId} from "../../generated/inventoryGraphql";
import {useLocale} from "../../LocaleProvider";
import {useTenant} from "../../TenantProvider";

const InventoryLabel = () => {
    let text = ""
    if (config.environment === environment.LOCAL) {
        text = "DEBUG"
    } else if (config.environment === environment.DEVELOP) {
        text = "DEVELOP"
    } else {
        text = ""
    }

    return <NavbarBrand style={{color: "white"}} href={"/"}>{text}</NavbarBrand>
}

const InventoryHeader = (props) => {

    const [navbarOpen, setNavbar] = useState(false);
    const {t} = useLocale();
    const {tenantId} = useTenant()

    const toggleNavbar = useCallback(() =>{
            setNavbar(i => !i)
        }, [])

    const items = useMemo(() => {
        const items =  [
            {
                name: t("Tasks"),
                to: InventoryRoute.INDEX
            },
            {
                name: t("Articles"),
                to: InventoryRoute.ARTICLES
            },
            {
                name: t("Orders"),
                to: InventoryRoute.ORDERS
            },
            {
                name: t("Purchase Orders"),
                to: InventoryRoute.PURCHASE_ORDERS
            },
            {
                name: t("Suppliers"),
                to: InventoryRoute.SUPPLIERS
            },
            {
                name: t("Reports"),
                to: InventoryRoute.REPORTS
            },
            {
                name: t("Stocktaking"),
                to: InventoryRoute.STOCK_TAKING
            },
            {
                name: t("Receiving Article"),
                to: InventoryRoute.CODE_SCANNER
            },
            {
                name: t("Draw job"),
                to: InventoryRoute.DRAW_JOBS,
            },
        ]

        if (tenantId === InventoryTenantId.Espr) {
            items.push({
                name: t("Scheduler"),
                to: InventoryRoute.SCHEDULER
            })
        }

        return items
    }, [t, tenantId]);

    return <div>
        <Row>
            <Navbar className={"inventoryNavBar navbar-light"} expand={"md"}>
                <InventoryLabel/>
                <NavbarToggler onClick={toggleNavbar}/>
                <Collapse isOpen={navbarOpen} navbar>
                    <Nav navbar onSelect={toggleNavbar}>
                        {items.map(({name, to}) =>
                            <NavItem key={name}>
                                <NavLink>
                                    <Link to={to}>
                                        {name}
                                    </Link>
                                </NavLink>
                            </NavItem>
                        )}
                    </Nav>
                </Collapse>
            </Navbar>
        </Row>
    </div>
}

export default withRouter(InventoryHeader);
