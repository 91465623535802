import {PurchaseOrderFragmentFragment} from "../../../generated/graphql";
import BoldTitleWithMessage from "../../BoldTitleWithMessage";
import {useMemo} from "react";
import {FORMAT_DATE, formatToSwedishTime} from "../../../UTIL";
import {useLocale} from "../../../LocaleProvider";

type Props = {
    purchaseOrder: PurchaseOrderFragmentFragment | null | undefined
}

const ESPLOrderDeliveryInfo = ({purchaseOrder}: Props) =>
{
    const {t} = useLocale()

    const purchaseOrderDeliveryDate = useMemo(() =>
    {
        return formatToSwedishTime(purchaseOrder?.deliveryDate, FORMAT_DATE)
    }, [purchaseOrder?.deliveryDate]);

    if (!purchaseOrder)
    {
        return null
    }

    return <>
        <BoldTitleWithMessage title={`${t('Delivery date')}:`} message={purchaseOrderDeliveryDate}/>
        <BoldTitleWithMessage title={`${t('Delivery title')}:`} message={purchaseOrder.deliveryTitle}/>
        <BoldTitleWithMessage title={`${t('Delivery street')}:`} message={purchaseOrder.deliveryStreet}/>
        <BoldTitleWithMessage title={`${t('Delivery postal code')}:`} message={purchaseOrder.deliveryPostalCode}/>
        <BoldTitleWithMessage title={`${t('Delivery city')}:`} message={purchaseOrder.deliveryCity}/>
        <BoldTitleWithMessage title={`${t('Delivery country')}:`} message={purchaseOrder.deliveryCountry}/>
        <BoldTitleWithMessage title={`${t('Delivery free text')}:`} message={purchaseOrder.deliveryFreeText}/>
    </>
}

export default ESPLOrderDeliveryInfo;