import React, {useCallback, useEffect, useState} from "react"
import {Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Spinner} from "reactstrap";
import SearchArticle from "./SearchArticle";
import {Formik} from "formik";
import gql from "graphql-tag";
import {useLazyQuery} from "@apollo/client";
import {useStdMutation} from "../../spiralStair/lib/Util";
import CodeScanner from "../components/CodeScanner";
import beep from "../assets/beep.mp3"
import {useLocale} from "../../LocaleProvider";

const eanCodesQuery = gql`
    query searchEanCodes($code:String!){
        result:eanCodes(
            code:$code
        ){
            id
            code
            article{
                artNo
            }
            createdAt
        }
    }`;

const pendingTransitionQuery = gql`
    query pendingTransition($artNo:String!){
        result:pendingTransition(
            artNo:$artNo
        )
    }`;

const ArticleInputModal = ({open, toggle, artNo, pendingTransition, onSubmit}) =>
{
    const {t} = useLocale()
    if (pendingTransition <= 0)
    {
        return <Modal show={open} onHide={toggle}>
            This article has pending transition: {pendingTransition} and can not fill in any PO's transition.<br/>
            Note: PO with finish status won't count in pending transition.
        </Modal>
    }

    return <Modal isOpen={open} toggle={toggle}>
        <ModalHeader toggle={toggle}>{t('Stock transition')}</ModalHeader>
        <ModalBody>
            {t('Enter quantity and will fill in PO\'s transition from old to latest.')}
            <Col>
                <Label>{t('Article No.')}:</Label>
                <p>{artNo}</p>
            </Col>
            <Col>
                <Label>{t('Pending Transition')}:</Label>
                <p>{pendingTransition}</p>
            </Col>

            <Formik initialValues={{quantity: "1"}} onSubmit={onSubmit}>
                {({handleChange, isSubmitting, handleSubmit, values}) =>
                {
                    if (parseInt(values.quantity) > pendingTransition) values.quantity = pendingTransition;
                    if (parseInt(values.quantity) < 1) values.quantity = "1";

                    return <Form onSubmit={handleSubmit}>
                        <FormGroup className="mb-3" >
                            <Label>{t('quantity')} (1~{pendingTransition}): </Label>
                            <Input name={"quantity"} value={values.quantity} style={{maxWidth: "500px"}}
                                   onChange={handleChange}/>
                        </FormGroup>
                        <Button onClick={toggle} type={"submit"}
                                style={{float: "right", marginLeft: "7px"}}>{t('Submit')}</Button>
                        <Button onClick={toggle} style={{float: "right", marginLeft: "7px"}}>{t('Cancel')}</Button>
                    </Form>
                }
                }
            </Formik>
        </ModalBody>
    </Modal>
}

const InventoryCodeScannerPage = () =>
{
    const [scannerActive, setScannerActive] = useState(false);
    const [incomingCode, setIncomingCode] = useState(null);
    const [loading, setLoading] = useState(false);
    const [artNo, setArtNo] = useState(null);
    const [updatedPOs, setUpdatedPOs] = useState<any>(null);


    const [audioElement, setAudioElement] = useState<HTMLAudioElement | null>(null);
    const [searchArticleModalOpen, setSearchArticleModalOpen] = useState(false);
    const [articleInputModalOpen, setArticleInputModalOpen] = useState(false);
    const {t} = useLocale()

    const [getEanCodes, {data: eanCodesData}] = useLazyQuery(eanCodesQuery, {fetchPolicy: 'network-only'});
    const [getPendingTransition, {data: pendingTransitionData}] = useLazyQuery(pendingTransitionQuery, {fetchPolicy: 'network-only'});
    const [fillInPendingTransition] = useStdMutation("Fill in pending transition",
        gql`mutation fillInPendingTransition($quantity:Int!, $artNo:String!){
            fillInPendingTransition(quantity: $quantity, artNo: $artNo){
                id
            }
        }`, {refetchQueries: ["eanCodesByArticleId"]});

    const [createEANCode] = useStdMutation("Create EAN Code",
        gql`mutation createEANCode($eanCode:EANCodeInputType!){
            result:createEANCode(eanCode: $eanCode){
                id
            }
        }`, {refetchQueries: ["eanCodesByArticleId"]});

    useEffect(() =>
    {
        const myAudioElement = new Audio(beep);
        myAudioElement.addEventListener("canplaythrough", event =>
        {
            /* the audio is now playable; play it if permissions allow */
            setAudioElement(myAudioElement)
        });
        return () =>
        {
            myAudioElement.pause();
        }
    }, []);

    useEffect(() =>
    {
        setLoading(false);

        if (!eanCodesData) return;
        console.log(eanCodesData.result);
        if (eanCodesData.result.length > 0)
        {
            const artNo = eanCodesData.result[0].article.artNo;
            setArtNo(artNo);
            getPendingTransition({variables: {artNo}});
            setArticleInputModalOpen(true);
        } else
        {
            setSearchArticleModalOpen(true);
        }
    }, [eanCodesData, setArtNo, getPendingTransition, setArticleInputModalOpen, setSearchArticleModalOpen]);

    const handleScanSuccess = useCallback(async (decodedText) =>
    {
        console.log("decodedText", decodedText)
        setIncomingCode(decodedText);
        setScannerActive(false);
        getEanCodes({variables: {code: decodedText}});
        setLoading(true);
        await audioElement?.play();
    }, [setIncomingCode, setScannerActive, audioElement, getEanCodes, setLoading]);

    const handleScanError = useCallback((e) =>
    {
    }, []);

    const handleSearchArticleChange = useCallback(async (articleId) =>
    {
        await createEANCode({
            variables: {eanCode: {code: incomingCode, articleId: parseInt(articleId)}}
        });
    }, [incomingCode, createEANCode]);

    const handleArticleInputSubmit = useCallback(async (values) =>
    {
        const updatedPO: any = await fillInPendingTransition({
            variables: {quantity: parseInt(values.quantity), artNo}
        });
        if (updatedPO.data.fillInPendingTransition.length > 0)
        {
            setUpdatedPOs(updatedPO.data.fillInPendingTransition.map(d => d.id));
        }

    }, [fillInPendingTransition, artNo]);

    const toggleSearchArticleModal = useCallback(() =>
    {
        setSearchArticleModalOpen(!searchArticleModalOpen);
    }, [searchArticleModalOpen, setSearchArticleModalOpen]);

    const toggleArticleInputModal = useCallback(() =>
    {
        setArticleInputModalOpen(!articleInputModalOpen);
    }, [articleInputModalOpen, setArticleInputModalOpen]);

    return <>
        <SearchArticle open={searchArticleModalOpen} toggle={toggleSearchArticleModal}
                       returnArtNoCallback={handleSearchArticleChange}/>
        <ArticleInputModal open={articleInputModalOpen}
                           toggle={toggleArticleInputModal}
                           artNo={artNo}
                           pendingTransition={pendingTransitionData?.result}
                           onSubmit={handleArticleInputSubmit}/>

        {loading && <Spinner variant={"warning"} animation='border'/>}

        <CodeScanner scannerActive={scannerActive} pageActive={true} scanErrorCallback={handleScanError}
                     scanSuccessCallback={handleScanSuccess}/>

        {updatedPOs && <>{t('Updated following PO')}: {updatedPOs.map(poId =>
        {
            return <a key={poId} style={{marginLeft: "7px"}} href={`purchaseOrderDetail/${poId}`}>{poId}</a>
        })}<br/></>}
        <br/>
        {!scannerActive && incomingCode && <div>Scan code: {incomingCode} <Button onClick={() =>
        {
            setScannerActive(true)
        }}>{t('Continue scan')}</Button></div>}
        {!scannerActive && !incomingCode && <div><Button onClick={() =>
        {
            setScannerActive(true)
        }}>Start scan</Button></div>}
    </>
}

export default InventoryCodeScannerPage