import React, {memo, useCallback, useMemo, useState} from "react";
import {
    CreateStraightStairSupportMutation, StraightStairSupportFragmentFragment,
    StraightStairSupportType
} from "../../generated/graphql";
import {Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table} from "reactstrap";
import NewFormRenderer from "../../common/NewFormRenderer";
import Pen from "../../common/icons/Pen";
import Trash from "../../common/icons/Trash";
import {enumToOptions} from "../../UTILmore";
import {PATH_STRAIGHT_STAIR_IMAGE} from "../StraightStairConstants";
import NotificationPopup from "../../common/lib/NotificationPopup";
import {GetSupportMaterialTypeFunc} from "../hooks/useSupportHook";
import {useLocale} from "../../LocaleProvider";

const SupportModal = memo(({support, isOpen, toggle, onUpdateClick,getSupportMaterialTypes}:
                               {
                                   support: StraightStairSupportFragmentFragment,
                                   isOpen: boolean, toggle: () => void
                                   onUpdateClick: (support: StraightStairSupportFragmentFragment) => void
                                   getSupportMaterialTypes: GetSupportMaterialTypeFunc
                               }) =>
{
    const [supportData, setSupportData] = useState(support)
    const {t} = useLocale()

    const generateMaterialOptions = useCallback(() =>
    {
        const materialList = getSupportMaterialTypes(supportData.length, supportData.type)
        return materialList.map((e) =>
        {
            return {id: e, name: e.replace("material", "")}
        })
    }, [supportData.length, supportData.type, getSupportMaterialTypes]);

    const [materialOptions, setMaterialOptions] = useState(generateMaterialOptions)

    const onChange = useCallback((key: string, value: string) =>
    {
        setSupportData(prevState => ({...prevState, [key]: value}))
    }, [])

    const onLengthBlur = useCallback(() =>
    {
        const materialOptions = generateMaterialOptions()
        setMaterialOptions(materialOptions)

        setSupportData(prevState =>
        {
            const foundMaterial = materialOptions.find((e) => e.id === prevState.material)
            if (foundMaterial)
            {
                return prevState
            }
            return {...prevState, material: materialOptions[0].id}
        })
    }, [generateMaterialOptions])

    const onUpdate = useCallback(() =>
    {
        const {length, type} = supportData
        if (type === StraightStairSupportType.Console && length >= 2000)
        {
            NotificationPopup.error(`Maximum height of the console: 2000`)
            return
        }

        onUpdateClick(supportData)
        toggle()
    }, [onUpdateClick, supportData, toggle])

    const getSupportImage = useCallback((support: StraightStairSupportFragmentFragment) =>
    {
        let fileName: string
        if (support.adj)
        {
            fileName = 'Suppadj'
        } else
        {
            fileName = "Supports"
        }
        return `${PATH_STRAIGHT_STAIR_IMAGE}/ADJ/${fileName}.JPG`
    }, [])

    const supportFormDefinition = useMemo(() =>
    {
        return [
            {
                name: "type",
                type: "select",
                options: enumToOptions(StraightStairSupportType)
            },
            {
                name: "length", type: "number",
                label: "Height of the support",
                min: 250,
                max: 8000,
                step: 10,
                hint: '(250~8000mm)',
                onCustomBlur: onLengthBlur
            },
            {
                name: "material",
                type: "select",
                options: materialOptions,
                disableSort: true
            },
            {name: "quantity", type: "number",},
            {name: "adj", label: "Adjustable poles", type: "checkbox"},
        ]
    }, [materialOptions, onLengthBlur])

    return <Modal isOpen={isOpen} toggle={toggle} size={"lg"}>
        <ModalHeader isOpen={isOpen} toggle={toggle}>{t('Edit Support')}</ModalHeader>
        <ModalBody>
            <Row>
                <Col>
                    <NewFormRenderer
                        columns={2}
                        formDefinition={supportFormDefinition}
                        object={supportData}
                        onChange={onChange}/>
                </Col>
                <Col sm={5}>
                    <img src={getSupportImage(supportData)} alt={"support"} width={"100%"}/>
                </Col>
            </Row>

        </ModalBody>
        <ModalFooter>
            <Button color="success" onClick={onUpdate}>{t('Update')}</Button>
        </ModalFooter>
    </Modal>
})


const SupportRow = memo(({support, onEditClick, onDeleteClick}: {
    support: StraightStairSupportFragmentFragment,
    onEditClick: (id: string) => void, onDeleteClick: (id: string) => void
}) =>
{
    const textAlignRight: any = {textAlign: "right"}

    return <tr key={`support${support.id}`}>
        <td>{support.type}</td>
        <td style={textAlignRight}>{support.length}</td>
        <td>{support.material.replace("material", "")}</td>
        <td style={textAlignRight}>{support.quantity}</td>
        <td>{support.adj ? "V" : "X"}</td>
        <td>
            <span className="link" onClick={() => onEditClick(support.id)}>
                <Pen/>
            </span>
            <span className="link" style={{marginLeft: "12px"}}
                  onClick={() => onDeleteClick(support.id)}>
                <Trash/>
            </span>
        </td>
    </tr>
})

const StraightStairSupportsPanel = memo(({supports, addSupport, deleteSupport, updateSupport, getSupportMaterialTypes}:
                                             {
                                                 supports: StraightStairSupportFragmentFragment[],
                                                 addSupport: () => Promise<CreateStraightStairSupportMutation | null | undefined>,
                                                 deleteSupport: (id: string) => void,
                                                 updateSupport: (support: StraightStairSupportFragmentFragment) => void,
                                                 getSupportMaterialTypes: GetSupportMaterialTypeFunc
                                             }) =>
{
    const [support, setSupport] = useState<StraightStairSupportFragmentFragment | null>(null)
    const {t} = useLocale()

    const toggleSupportModal = useCallback(() => setSupport(null), [])

    const onEditClick = useCallback((id) =>
    {
        const support = supports.find(e => e.id === id)
        if (support)
        {
            setSupport(support)
        }
    }, [supports])

    const handleDeleteClick = useCallback((id: string) =>
    {
        if (window.confirm(t(`Are you sure you want to delete support?`)))
        {
            deleteSupport(id)
        }
    }, [deleteSupport, t])

    const handleAddClick = useCallback(async () =>
    {
        const response = await addSupport()
        if (response?.createStraightStairSupport)
        {
            setSupport(response.createStraightStairSupport)
        }
    }, [addSupport])

    return <>
        <h3>{t('Supports')}</h3>
        {
            supports.length > 0
                ? <Table striped responsive bordered>
                    <thead>
                    <tr>
                        <th>{t('Type')}</th>
                        <th>{t('Height')}(mm)</th>
                        <th>{t('Material')}</th>
                        <th>{t('Quantity')}</th>
                        <th>{t('ADJ')}</th>
                        <th>{t('Actions')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        supports.map((support) =>
                        {
                            return <SupportRow key={`support${support.id}`}
                                               support={support}
                                               onEditClick={onEditClick}
                                               onDeleteClick={handleDeleteClick}/>
                        })
                    }
                    </tbody>
                </Table>
                : <p>{t('No supports')}</p>
        }

        <Button color="primary" style={{marginTop: "4px"}} onClick={handleAddClick}>{t('Add support')}</Button>

        {support &&
            <SupportModal support={support} isOpen={true} toggle={toggleSupportModal} onUpdateClick={updateSupport}
                          getSupportMaterialTypes={getSupportMaterialTypes}/>}
        <hr/>
    </>
})

export default StraightStairSupportsPanel