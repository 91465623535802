import React, {useCallback, useState} from 'react';
import {useMutation, useQuery} from '@apollo/client';
import {Alert, FormGroup, Label, Input} from "reactstrap";
import FormRenderer from "../../common/FormRenderer";
import UpdateOrderMutation from '../../order/graphql/UpdateOrderMutation.graphql';
import GetOrder from '../../order/graphql/GetOrder.graphql';
import OrderInfo from "../../common/task-workflow/OrderInfo";
import {AddCommentForm} from "../../common/task-workflow/comment/AddCommentForm";
import DrawingsFileList from "../../common/task-workflow/files/DrawingsFileList";
import {useLocale} from "../../LocaleProvider";

export default function CustomerResponse({variables, onSubmit, toggle}) {
    const {orderId, autoDrawArticles, articles, comments, purchaseOrderId} = variables;
    const onlyAutomated = (autoDrawArticles && autoDrawArticles.length > 0) && (!articles || articles.length === 0);

    const [comment] = useState('');
    const [approve, setApprove] = useState(true);
    const {t} = useLocale()

    const {data} = useQuery(GetOrder, {
        variables: {id: variables.orderId},
    });

    const handleOptionChange = useCallback((event) => {
        setApprove(event.target.value === 'approve');
    }, [setApprove]);

    const RadioApprove = useCallback(({text, value, checked}) => {
        return <FormGroup check>
            <Label check style={{fontSize: '16px'}}>
                <Input
                    type="radio"
                    value={value}
                    checked={checked}
                    onChange={handleOptionChange}/>
                {text}
            </Label>
        </FormGroup>
    }, [handleOptionChange])


    return <div>
        <OrderInfo orderId={orderId}/>
        <DrawingsFileList purchaseOrderId={purchaseOrderId}/>
        <p>{t('All comments for changes should be written on the drawing and saved under files.')}</p>
        <hr/>
        {onlyAutomated && <Alert color={"warning"}>
            This drawjob only contains automated articles. If you select does not approve the drawjob will be assigned
            to
            a engineer for manual handling. If you're able to generate a new drawing after the customer's response
            you can wait with finishing this until you either need manual handling(by an engineer) or the customer
            approves.
        </Alert>}

        {data && data.order &&
            <div>
                <RadioApprove value="approve"
                              text={t("Approve")}
                              checked={approve}/>
                <RadioApprove value="notApprove"
                              text={t("Not Approve")}
                              checked={!approve}/>
                <br/>
                {approve ? <ApproveSection onSubmit={onSubmit} comment={comment} orderId={orderId}
                                           data={data} toggle={toggle}/>
                    : <NotApproveSection onSubmit={onSubmit} comments={comments}/>
                }
            </div>
        }
    </div>
}

const ApproveSection = ({onSubmit, comment, orderId, data, toggle}) => {
    const [updateOrder] = useMutation(UpdateOrderMutation);
    const {t} = useLocale()

    return <div>
        <h4>{t('Check preferred delivery date')}</h4>
        <p>{t('Please check and confirm the preferred delivery date of the order. The 4 weeks delivery time can only start from when the customer accepts the drawing.')}</p>
        <FormRenderer formDefinition={[{
            name: "preferredDeliveryDate",
            type: "date",
            required: true,
            defaultValue: data.order.preferredDeliveryDate ? data.order.preferredDeliveryDate : null
        }]} onSubmit={async (form) => {
            if (window.confirm(t(`Have you made sure that the measurements on the drawing(s) and in the system are correct and that the price is up to date?`))) {
                await updateOrder({
                    variables: {
                        id: orderId,
                        order: {
                            preferredDeliveryDate: form.object.preferredDeliveryDate
                        }
                    }
                });
                onSubmit({
                    comment: {type: "string", value: comment},
                    approved: {type: 'boolean', value: true}
                })
            }
            toggle();
        }}/>
    </div>
}

const NotApproveSection = ({onSubmit, comments}) => {
    const {t} = useLocale()
    const handleAddComment = useCallback((newComments) => {
        onSubmit({
            approved: {type: 'boolean', value: false},
            comments: {
                value: JSON.stringify(newComments),
                type: "json"
            }
        })
    }, [onSubmit])

    return <div>
        <h4>{t('Are you sure you wish to Not approve these drawings?')}</h4>
        <AddCommentForm submit={handleAddComment}
                        comments={comments}
                        btnText={t("Not Approve")}/>
    </div>
}