import React, {useEffect, useMemo, useState} from 'react';
import {Table} from "reactstrap";
import {useLazyQuery} from "@apollo/client";
import gql from "graphql-tag";
import {articleDetailLink} from "../../utils/ArticleUtils";
import {useLocale} from "../../LocaleProvider";
import {isInventoryPath} from "../../inventory/InventoryRouters";
import {withRouter} from "react-router";

const getOrderArticlesName = gql`
    query getOrderArticlesName($id:ID!)
    {
        order(id:$id)
        {
            articleRows {
                article {
                    id
                }
                name
            }
        }
    }
`;
const ArticleList = ({articles, orderId, isOnInventory}) => {
    const [getData] = useLazyQuery(getOrderArticlesName);
    const [newNameArticles, setNewNameArticles] = useState(articles);
    const {t} = useLocale()

    useEffect(()=>{
        const init = async () => {
            const res = await getData({variables:{id:orderId} });

            if(res.data.order)
            {
                const newNameArticles = articles.map(thisArticle => {
                    let name = thisArticle.name;
                    res.data.order.articleRows.forEach(articleRow => {
                        if(articleRow.article && articleRow.article.id === thisArticle.id){
                            name = articleRow.name;
                        }
                    });
                    return {...thisArticle, name};
                });
                setNewNameArticles(newNameArticles);
            }
        }

        init().then();
    }, [articles, orderId, getData, setNewNameArticles]);

    return  <Table striped bordered hover>
        <thead>
        <tr>
            <th>{t('Art No')}</th>
            <th>{t('Name')}</th>
        </tr>
        </thead>
        <tbody>
        {newNameArticles && newNameArticles.map(article => <tr key={article.id}>
            <td>
                {articleDetailLink(article, article.artNo, isOnInventory)}
            </td>
            <td>{article.name}</td>
        </tr>)}
        </tbody>

    </Table>
}

const ArticlesAndAutoDrawArticlesOverview = ({articles, autoDrawArticles, orderId,location}) => {
    const {t} = useLocale()

    const isOnInventory = useMemo(() => {
        return isInventoryPath(location)
    }, [location])

    return <>
        <h4>{t('Articles')}</h4>
        <ArticleList articles={articles} orderId={orderId} isOnInventory={isOnInventory}/>
        {autoDrawArticles && autoDrawArticles.length > 0 && <>
            <h4>Auto drawing articles</h4>
            <ArticleList articles={autoDrawArticles} orderId={orderId} isOnInventory={isOnInventory}/>
        </>}
    </>
}

export default withRouter(ArticlesAndAutoDrawArticlesOverview);