import React, {useCallback, useState} from "react";
import {Badge, Spinner, Table} from "reactstrap";
import {downloadVismaInvoicePdf, FORMAT_DATE, formatCurrency, formatToSwedishTime} from "../../UTIL";
import Collapser from "../Collapser";
import ColumnId from "../data-grid/columns/ColumnId";
import NonPaginatedDataGrid from "../data-grid/NonPaginatedDataGrid";
import PdfIcon from "../icons/PdfIcon";
import {InvoiceFragmentFragment, InvoiceStatus} from "../../generated/graphql";
import {InvoiceStatusBadge} from "./InvoiceStatusBadge";

const TABLE_KEY = "ORDER_INVOICES"

type Props = {
    invoices: InvoiceFragmentFragment[] | undefined | null
}

export default ({invoices}: Props) =>
{
    const [isDownloading, setIsDownloading] = useState(false)
    const downloadPdf = useCallback(async (invoice) =>
    {
        if (isDownloading)
        {
            return
        }
        setIsDownloading(true)
        await downloadVismaInvoicePdf(invoice.id, invoice.referenceNumber)
        setIsDownloading(false)
    }, [isDownloading, setIsDownloading])

    return <div>
        {
            invoices?.filter((e) => e.status !== InvoiceStatus.Hold).map((invoice) =>
            {
                return <div key={invoice.id}>
                    <h3>{invoice.referenceNumber}</h3>
                    <TypeBadge type={invoice.type}/>
                    <InvoiceStatusBadge invoiceStatus={invoice.status}/>
                    <span className='link' onClick={() => downloadPdf(invoice)}>
                           <PdfIcon/>
                        {isDownloading && <Spinner/>}
                    </span>
                    <Table striped style={{maxWidth: '300px', marginTop: "8px"}}>
                        <tbody>
                        <TableRow title='Total w/o VAT' content={invoice.detailTotalInCurrency}/>
                        <TableRow title='VAT' content={invoice.vatTotalInCurrency}/>
                        <TableRow title='Outstanding amount' content={invoice.balanceInCurrency}/>
                        <TableRow title='Grand total' content={invoice.amountInCurrency}/>
                        </tbody>
                    </Table>
                    <p>Currency: {invoice.currencyId}</p>
                    <p>Document date: {formatToSwedishTime(invoice.documentDate, FORMAT_DATE)}</p>
                    <p>Due date: {formatToSwedishTime(invoice.dueDate, FORMAT_DATE)}</p>
                    <Collapser label={"Details"}>
                        <Details invoice={invoice}/>
                    </Collapser>

                    <div style={{backgroundColor: "#313131", height: "1px", width: "100%", margin: '20px 0px'}}/>
                </div>
            })
        }
    </div>
}

const TableRow = ({title, content}) =>
{
    return <tr>
        <th>{title}</th>
        <td style={{textAlign: 'right'}}>{formatCurrency(content)}</td>
    </tr>
}

const TypeBadge = ({type}) =>
{
    let color;

    switch (type)
    {
        case 'Invoice':
            color = 'warning';
            break;
        case 'CreditNote':
            color = 'success';
            break;
        default:
            color = 'secondary';
    }

    return <Badge color={color}>{type}</Badge>
}

const Details = ({invoice}) =>
{
    return <NonPaginatedDataGrid
        definition={{
            tableKey: {TABLE_KEY},
            columns: columns(),
            initState: {columns: columnVisibilityModel},
            density: "compact"
        }}
        data={invoice.invoiceLines}
    />
}

const columns = () =>
{
    return [
        {
            headerName: "Art no",
            field: "inventoryNumber",
            width: 100,
        },
        {headerName: "Name", field: "description"},
        {
            headerName: "Quantity",
            field: "quantity",
            type: 'number',
            width: 70,
            renderCell: (params) => <div style={{textAlign: "right"}}>{params.value}</div>
        },
        {
            headerName: "Price per unit",
            field: "unitPriceInCurrency",
            type: 'number',
            renderCell: (params) => <div style={{textAlign: "right"}}>{formatCurrency(params.value)}</div>
        },
        {
            headerName: "Discount",
            field: "discountPercent",
            type: 'number',
            width: 80,
            renderCell: (params) => <div style={{textAlign: "right"}}>{`${params.value} %`}</div>
        },
        {
            headerName: "Total amount",
            field: "amountInCurrency",
            type: 'number',
            renderCell: (params) => <div style={{textAlign: "right"}}>{formatCurrency(params.value)}</div>
        },
        ColumnId(),
    ]
}

const columnVisibilityModel = {columnVisibilityModel: {id: false}}

