import React, {useCallback, useState} from "react";
import CommentsPanel from "../../common/task-workflow/comment/CommentsPanel";
import ESPLOrderInfo from "../../common/task-workflow/espl/ESPLOrderInfo";
import ESPLOrderArticleList from "../../common/task-workflow/espl/ESPLOrderArticleList";
import TaskApproveFooter from "../../common/task-workflow/TaskApproveFooter";
import {useGetPurchaseOrderQuery} from "../../generated/graphql";
import ESPLOrderDeliveryInfo from "../../common/task-workflow/espl/ESPLOrderDeliveryInfo";
import Spinner from "reactstrap/lib/Spinner";
import {useLocale} from "../../LocaleProvider";

const ConfirmAndSchedule = ({variables, onSubmit, toggle}) =>
{
    const {purchaseOrderId} = variables

    const [comments, setComments] = useState(variables.confirmDetailComments ?? [])
    const {data, loading} = useGetPurchaseOrderQuery({variables: {id: purchaseOrderId}})
    const {t} = useLocale()

    const submit = useCallback(async (approve: boolean) =>
    {
        const variables = {
            confirmDetailComments: {
                value: JSON.stringify(comments),
                type: "json"
            },
            isDateOfDeliveryAllowed: {
                type: 'boolean',
                value: approve
            }
        }
        await onSubmit(variables)
    }, [onSubmit, comments]);

    const onApprove = useCallback(async () =>
    {
        await submit(true)
    }, [submit]);

    const onReject = useCallback(async () =>
    {
        if (window.confirm(t(`Please confirm if more information is needed (if comments are to be added, please add them first)`)))
        {
            await submit(false)
        }
    }, [submit, t]);

    if (loading)
    {
        return <Spinner/>
    }

    return <>
        {
            data?.purchaseOrder && <>
                <ESPLOrderInfo purchaseOrder={data?.purchaseOrder}/>
                <h5 style={{padding: '0', margin: '4px 0 0 0' }}>{t('Confirm Delivery Info')}</h5>
                <ESPLOrderDeliveryInfo purchaseOrder={data?.purchaseOrder}/>
                <h5 style={{marginTop: '10px'}}>{t('Article Rows')}</h5>
                <ESPLOrderArticleList purchaseOrder={data?.purchaseOrder}/>
                <hr/>
            </>
        }
        <CommentsPanel comments={comments}
                       setComments={(newComments) => setComments(newComments)}/>
        <TaskApproveFooter onApprove={onApprove} onReject={onReject} toggle={toggle}/>
    </>
}

export default ConfirmAndSchedule;