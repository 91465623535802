import React, {useMemo} from "react";
import {PROCESS_DEFINITION_KEY_ESPL_DRAW_JOB} from "../../Constants";
import ESPLOrderInfo from "./espl/ESPLOrderInfo";
import OrderInfo from "./OrderInfo";


const DrawJobOrderInfo = ({task, variables, children}) =>
{
    const isESPLDrawJob = useMemo(() =>
    {
        return task.processDefinitionKey === PROCESS_DEFINITION_KEY_ESPL_DRAW_JOB
    }, [task.processDefinitionKey]);

    return <div style={{marginTop: "8px"}}>
        {
            isESPLDrawJob
                ? <ESPLOrderInfo purchaseOrderId={variables.purchaseOrderId}/>
                : <OrderInfo orderId={variables.orderId}/>
        }
        {children}
    </div>
}

export default DrawJobOrderInfo;