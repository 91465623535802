import {
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector, GridToolbarExport,
    GridToolbarFilterButton, GridToolbarQuickFilter
} from "@mui/x-data-grid";
import React, {memo} from "react";
import {ExportGroup} from "types/data-grid/ExportGroup";
import {IsAdmin, IsEconomy} from "../../Auth";

type Props = {
    exportGroup?: ExportGroup
}

const CustomExport = ({exportGroup}: Props) =>
{
    switch (exportGroup)
    {
        case ExportGroup.all:
            return <GridToolbarExport/>;
        case ExportGroup.economy:
            return <IsEconomy altRender={null}>
                <GridToolbarExport/>
            </IsEconomy>
        case ExportGroup.admin:
            return <IsAdmin>
                <GridToolbarExport/>
            </IsAdmin>
        default:
            return null;
    }
};

const CustomDataGridToolbar = ({exportGroup}: Props) =>
{
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton/>
            <GridToolbarFilterButton/>
            <GridToolbarDensitySelector/>
            <CustomExport exportGroup={exportGroup}/>
            <GridToolbarQuickFilter debounceMs={500}/>
        </GridToolbarContainer>
    );
}

export default memo(CustomDataGridToolbar)
