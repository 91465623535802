const ColumnDrawJobOrder = (headerName: string) =>
{
    return {
        field: 'order.number',
        headerName: headerName,
        width: 100,
        renderCell: (params) =>
        {
            const task = params.row;
            return task.order.number;
        },
    }
}

export default ColumnDrawJobOrder;