import {useCallback, useEffect, useMemo, useState} from "react";
import {
    StraightStairSurfaceTreatmentFragmentFragment,
    StraightStairV2FragmentFragment,
    UpdateStraightStairV2InputType,
    useStraightStairV2LazyQuery,
    useUpdateStraightStairV2Mutation
} from "../../generated/graphql";
import {cloneDeep, isEqual, omit} from "lodash";
import {useMutationHandler} from "../../custom-hook/useMutationHandler";
import {ArticleIdProps, ArticleRowVo} from "../StraightStairV2Types";
import NotificationPopup from "../../common/lib/NotificationPopup";
import {generateNewStringer} from "../stringer/StringerGenerator";
import useExtraRailingHook from "./useExtraRailingHook";
import useExtraHook from "./useExtraHook";
import useSeparateKickHook from "./useSeparateKickHook";
import useSegmentHook from "./useSegmentHook";
import useStraightStairPriceHook from "./useStraightStairPriceHook";
import useStairTabHook from "./useStairTabHook";
import useStraightStairValidationHook from "./useStraightStairValidationHook";
import useUpdatePurchaseOrderPriceHook from "../../common/update-purchase-order-price/useUpdatePurchaseOrderPriceHook";
import useUpdateStraightStairHook from "./useUpdateStraightStairHook";


export default ({articleRowId, articleId}: ArticleIdProps) =>
{
    const [straightStairV2Old, setStraightStairV2Old] = useState<StraightStairV2FragmentFragment | null>(null)
    const [articleRowOld, setArticleRowOld] = useState<ArticleRowVo>(null)

    const [straightStairV2, setStraightStairV2] = useState<StraightStairV2FragmentFragment | null>(null)
    const [articleRow, setArticleRow] = useState<ArticleRowVo>(null)

    const {activeTab, updateActiveTab} = useStairTabHook()
    const {validationErrors} = useStraightStairValidationHook({straightStairV2})

    const {
        addExtraRailingLoading, deleteExtraRailingLoading,
        addExtraRailing, deleteExtraRailing, updateExtraRailing,
    } = useExtraRailingHook({straightStairV2, setStraightStairV2})

    const {
        addExtraLoading, deleteExtraLoading,
        addExtra, deleteExtra, updateExtra,
    } = useExtraHook({straightStairV2, setStraightStairV2})

    const {
        addSeparateKickPlateLoading, deleteSeparateKickPlateLoading,
        addSeparateKickPlate, deleteSeparateKickPlate, updateSeparateKickPlate,
    } = useSeparateKickHook({straightStairV2, setStraightStairV2})

    const {updateStraightStair, updateStringerMaterial} = useUpdateStraightStairHook({setStraightStairV2})

    const {
        createSegmentLoading, deleteSegmentLoading, copySegmentLoading, calculateNumberOfStepsLoading,
        addSegment, deleteSegment, updateSegment, copySegment,
        calculateNumberOfSteps,
        isDoubleRailingAndExtendedEnabled,
        updateSupports
    } = useSegmentHook({straightStairV2, setStraightStairV2, updateActiveTab})

    const {
        priceSuggestion,
        getPrice, getPriceError
    } = useStraightStairPriceHook({
        articleId,
        articleRowId
    })

    const onUpdateP0PriceSuccess = useCallback((data?: { id: string, price?: number | null | undefined }) =>
    {
        const handle = (prevState: StraightStairV2FragmentFragment | null) =>
        {
            const articleRow = prevState?.purchaseOrder?.articleRows?.find((e) => e.id === data?.id)
            if (!prevState || !articleRow || data?.price == null)
            {
                return prevState
            }

            articleRow.price = data.price
            return {...prevState}
        }
        setStraightStairV2(handle)
        setStraightStairV2Old(handle)
    }, [])

    const {
        newPriceModalData,
        closeNewPriceModal,
        updatePurchaseOrderPrice,
        checkNewPriceData,
        updateArticleRowLoading,
        articleRowInPurchaseOrder
    } = useUpdatePurchaseOrderPriceHook({purchaseOrder: straightStairV2?.purchaseOrder,
        priceSuggestion, articleId,
        onSuccess: onUpdateP0PriceSuccess
    })

    const [getStraightStairV2Query, {loading}] = useStraightStairV2LazyQuery()

    const {
        executeMutation: updateMutation,
        loading: updateLoading
    } = useMutationHandler(useUpdateStraightStairV2Mutation)

    const stringerOptions = useMemo(() =>
    {
        if (straightStairV2 == null || straightStairV2.segments == null)
        {
            return []
        }

        const options = generateNewStringer({...straightStairV2, segments: straightStairV2.segments})
        updateStringerMaterial(options)
        return options
    }, [straightStairV2, updateStringerMaterial])

    const updateSurfaceTreatments = useCallback((surfaceTreatments: StraightStairSurfaceTreatmentFragmentFragment[]) =>
    {
        setStraightStairV2(prevState =>
        {
            if (!prevState)
            {
                return null
            }
            return {...prevState, surfaceTreatments: [...surfaceTreatments]}
        })
    }, [])

    const getStraightStairV2 = useCallback(async () =>
    {
        try
        {
            const response = await getStraightStairV2Query(
                {
                    variables: {
                        articleId: articleId,
                        articleRowId: articleRowId ?? '-1',
                        includeArticleRow: articleRowId != null
                    }
                })
            const straightStairV2 = response.data?.straightStairV2
            if (!straightStairV2)
            {
                return
            }

            setStraightStairV2Old(cloneDeep(straightStairV2))
            setStraightStairV2(cloneDeep(straightStairV2))

            const articleRow = response.data?.articleRow
            if (!articleRow)
            {
                return
            }

            const ar = {
                ...articleRow,
                supplierId: articleRow.article?.supplierId,
                weldability: articleRow.article?.weldability
            }
            setArticleRowOld(ar)
            setArticleRow(cloneDeep(ar))
        } catch (e: any)
        {
            NotificationPopup.error(`${e?.message}`)
        }
    }, [articleId, articleRowId, getStraightStairV2Query])

    const save = useCallback(async () =>
    {
        if (!straightStairV2 || !articleRow || !articleRowId)
        {
            return
        }

        const data: UpdateStraightStairV2InputType = {
            name: articleRow.name,
            price: articleRow.price,
            supplierId: articleRow.supplierId,
            straightStair: {
                ...omit(straightStairV2, "id", "__typename", "articleId",
                    "surfaceTreatments", "segments", "extraRailings",  "extras",
                    "priceSuggestion", "purchaseOrder", "separateKickPlates"),
                segments: straightStairV2.segments.map((e) =>
                {
                    return {
                        ...omit(e, "__typename", "straightStairV2Id", "geometry","supports"),
                        supports: e.supports.map((s) => omit(s, "__typename")),
                    }
                }),
                extraRailings: straightStairV2.extraRailings.map((e) => omit(e, "__typename")),
                surfaceTreatments: straightStairV2.surfaceTreatments.map((e) => omit(e, "__typename")),
                extras: straightStairV2.extras.map((e) => omit(e, "__typename")),
                separateKickPlates: straightStairV2.separateKickPlates.map((e) => omit(e, "__typename"))
            }
        }

        await updateMutation({
            variables: {
                articleRowId: articleRowId,
                data: data
            },
        }, {
            onSuccess: async () =>
            {
                await getStraightStairV2()
                const priceSuggestion = await getPrice()
                if (priceSuggestion)
                {
                    checkNewPriceData(priceSuggestion?.inPrice)
                }
                await getStraightStairV2()
            }
        })
    }, [updateMutation, articleRow, articleRowId, straightStairV2,
        getStraightStairV2, checkNewPriceData, getPrice])

    const hasUnsavedChange = useMemo(() =>
    {
        if (!articleRow || !straightStairV2 || !articleRowOld || !straightStairV2Old)
        {
            return false
        }

        return !isEqual(articleRowOld, articleRow) ||
            !isEqual(straightStairV2, straightStairV2Old)
    }, [articleRowOld, straightStairV2Old, articleRow, straightStairV2])

    const isLoading = useMemo(() =>
    {
        return loading || updateLoading ||
            createSegmentLoading || deleteSegmentLoading || copySegmentLoading ||
            addExtraRailingLoading || deleteExtraRailingLoading ||
            addExtraLoading || deleteExtraLoading ||
            calculateNumberOfStepsLoading || updateArticleRowLoading ||
            addSeparateKickPlateLoading || deleteSeparateKickPlateLoading
    }, [loading, updateLoading,
        createSegmentLoading, deleteSegmentLoading, copySegmentLoading,
        addExtraRailingLoading, deleteExtraRailingLoading,
        addExtraLoading, deleteExtraLoading,
        calculateNumberOfStepsLoading, updateArticleRowLoading,
        addSeparateKickPlateLoading, deleteSeparateKickPlateLoading])

    const isDisplayUpdatePOPriceButton = useMemo(() =>
    {
        if (!articleRowInPurchaseOrder?.price || !priceSuggestion?.inPrice)
        {
            return false
        }
        const isSamePrice = articleRowInPurchaseOrder.price.toFixed(2) === priceSuggestion.inPrice.toFixed(2)
        return straightStairV2?.purchaseOrder != null && !isSamePrice && !hasUnsavedChange
    }, [straightStairV2?.purchaseOrder, articleRowInPurchaseOrder, hasUnsavedChange, priceSuggestion])

    useEffect(() =>
    {
        getStraightStairV2().then()
    }, [getStraightStairV2]);

    return {
        isLoading, save, hasUnsavedChange,
        straightStairV2, updateStraightStair,
        activeTab, updateActiveTab,
        articleRow, setArticleRow,
        addSegment, deleteSegment, updateSegment, copySegment,
        updateSupports,
        addExtraRailing, deleteExtraRailing, updateExtraRailing,
        addExtra, deleteExtra, updateExtra,
        updateSurfaceTreatments,
        calculateNumberOfSteps,
        validationErrors, getPriceError,
        priceSuggestion,
        isDoubleRailingAndExtendedEnabled,
        stringerOptions,
        addSeparateKickPlate, deleteSeparateKickPlate, updateSeparateKickPlate,
        isDisplayUpdatePOPriceButton, articleRowInPurchaseOrder,
        newPriceModalData, closeNewPriceModal, updatePurchaseOrderPrice, checkNewPriceData,
    }
}