import React, {useState} from 'react';
import ScheduleArticle from "../../scheduler/components/calender/ScheduleArticle";
import {Badge, Button, ButtonGroup} from "reactstrap";
import {Link} from "react-router-dom";
import {QUERY_PARAMS_IS_INVENTORY} from "../../Constants";

export default ({onSubmit, variables}) => {
    const {purchaseOrderId} = variables;
    const [estimationListEmpty, setEstimationListEmpty] = useState(false);

    const submit = ()=>
    {
        onSubmit();
    };

    return <div>
        <ScheduleArticle purchaseOrderId={purchaseOrderId} setEstimationListEmpty={setEstimationListEmpty}/>
        <ButtonGroup vertical>
            <Link to={`/purchaseOrderDetail/${purchaseOrderId}?${QUERY_PARAMS_IS_INVENTORY}=true`} target="_blank" className="btn btn-outline-primary">Open Order</Link>

            <Button color="success" onClick={submit} disabled={!estimationListEmpty}>Finish estimating</Button>
            {!estimationListEmpty && <Badge color={"light"} style={{color:"red"}}>
                Complete all estimation in list to finish task
            </Badge>}
        </ButtonGroup>
    </div>

}
