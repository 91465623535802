import {Card, CardBody, CardHeader} from "reactstrap";
import React from "react";
import NewFormRenderer from "../NewFormRenderer";
import {useLocale} from "../../LocaleProvider";

export default ({articleRow, onChange}) => {
    const {t} = useLocale()
    return (<Card>
        <CardHeader>
            {t('Article Row')}
        </CardHeader>
        <CardBody>
            <NewFormRenderer
                formDefinition={[
                    {name: "name", type: "text", required: true},
                    {name: "quantity", type: "number", required: true},
                    {name: "unit", type: "text", required: true},
                    {name: "price", type: "number", required: true},
                ]}
                columns={2}
                object={articleRow}
                onChange={onChange}
            />
        </CardBody>
    </Card>)
}