import React, {Component, useCallback} from 'react';
import {Card, CardBody, CardFooter, CardText, Col, Input, Label, Row} from "reactstrap";
import ModalForm from "../ModalForm";
import FormRenderer, {selectRenderer} from "../FormRenderer";
import { Mutation} from '@apollo/client/react/components';
import CreateComment from './graphql/CreateComment.graphql';
import PropTypes from 'prop-types';
import Trash from "../icons/Trash";
import gql from "graphql-tag";
import {formatToSwedishTime} from "../../UTIL";
import {sortByCreatedAt} from "../../utils/SortUtils";
import {Link} from "react-router-dom";
import Paragraph from "../Paragraph";
import {useLocale, withLocale} from "../../LocaleProvider";

const DeleteComment = gql`
mutation deleteComment($id:ID!)
{
    deleteComment(id:$id)
}`;

const CommentCard = ({comment})=> {
    const {t} = useLocale()

    const onDeleteClick = useCallback(async (deleteComment) => {
        if (window.confirm(t(`Are you sure you want to delete this comment?`))) {
            await deleteComment({
                variables: {id: comment.id},
                refetchQueries: ["getOrder", "quote", "getPurchaseOrder", "getComments"]
            })
        }
    }, [comment.id, t])

    return <Card style={{marginTop: '8px'}}>
        <CardBody>
            <CardText>
                {comment.content}
            </CardText>
            <Mutation mutation={DeleteComment}>
                {deleteComment =>
                    <span style={{float: "right"}} className="link"
                          onClick={() => onDeleteClick(deleteComment)}>
                             <Trash/>
                        </span>
                }
            </Mutation>
        </CardBody>
        <CardFooter>
            {comment.creator.displayName} - {formatToSwedishTime(comment.createdAt)}
            {
                comment.purchaseOrder && <Link style={{display:"block"}}
                                               to={`/purchaseOrderDetail/${comment.purchaseOrder.id}`}>
                    Left on PO: {comment.purchaseOrder.label}
                </Link>
            }
        </CardFooter>
    </Card>
}

class CommentList extends Component
{
    constructor(props) {
        super(props);
        this.state = {
            content: '',
            orderId: this.props.orderId,
            purchaseOrderId: this.props.purchaseOrderId
        };
    }

    updateContent(e) {
        this.setState({
            content: e.target.value
        });
    }

    onPurchaseOrderChanged(value) {
        this.setState({
            orderId: null,
            purchaseOrderId: value.id
        });
    }

    render()
    {
        const {t} = this.props
        return <div>
            <ModalForm title={t("Create comment")}  activationButtonText={t("Create comment")}>
                {toggle=>
                {
                    return <Mutation mutation={CreateComment} >
                        {createComment =>
                            <>
                                <Label>{t('Content')}</Label>
                                <Input type="textarea" value={this.state.inputValue}
                                       onChange={this.updateContent.bind(this)}/>
                                {
                                    this.props.purchaseOrders && <div style={{margin:"10px 0"}}>
                                        <Paragraph>{t('Left comment on purchase order')}</Paragraph>
                                        {selectRenderer({
                                            options: this.props.purchaseOrders.map((e) => ({id: e.id, name: `${e.number}-${e.supplier?.name}`})),
                                            isClearable: true
                                        }, this.state.purchaseOrderId, this.onPurchaseOrderChanged.bind(this))}
                                    </div>
                                }

                                <FormRenderer
                                    columns={2}
                                    formDefinition={[
                                        {name: "doSendMail", type: "checkbox", label:"Notify logistics team"},
                                        {name: "doSendMailToProduction", type: "checkbox", label:"Notify production"},
                                        {name: "doSendAssignee", type: "checkbox", label:`${t('Notify')} ${this.props.assignee}`, hideField: ()=>!this.props.assignee},
                                        {name: "doSendEconomy", type: "checkbox", label:"Notify economy"},
                                    ]}
                                    onSubmit={async form =>
                                    {
                                        const object = form.object
                                        const content = this.state.content
                                        const res = await createComment({
                                            variables:
                                                {
                                                    comment: {
                                                        ...object,
                                                        content,
                                                        quoteId: this.props.quoteId,
                                                        orderId: this.state.orderId,
                                                        articleId: this.props.articleId,
                                                        purchaseOrderId: this.state.purchaseOrderId,
                                                    },
                                                },
                                            refetchQueries:["getOrder","quote","getPurchaseOrder", "getComments"]
                                        });
                                        console.log(res);
                                        toggle();
                                    }
                                    }/>
                            </>
                        }
                    </Mutation>
                }}
            </ModalForm>
            <Row style={{marginTop: "10px"}}>
                <Col md={6} lg={4}>
                    {this.props.comments.sort((a, b) => sortByCreatedAt(a, b, false))
                        .map((comment) => <CommentCard comment={comment}/>)}
                </Col>
            </Row>
        </div>
    }

}

CommentList.propTypes = {
    comments:PropTypes.arrayOf(PropTypes.object).isRequired,
    orderId:PropTypes.string,
    quoteId:PropTypes.string,
    articleId: PropTypes.string,
    assignee: PropTypes.string,
    purchaseOrderId: PropTypes.string,
    purchaseOrders: PropTypes.arrayOf(PropTypes.object),
};
export default withLocale(CommentList);
