import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import {ApolloClient, InMemoryCache, ApolloProvider, HttpLink, ApolloLink, split} from '@apollo/client';
import { onError } from "@apollo/client/link/error";
import {BrowserRouter} from 'react-router-dom';
import config from './config';
import Auth from './Auth';
import Logger from "./Logger";
import {createUploadLink} from 'apollo-upload-client';
import {reduce, some} from "lodash";
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import StackTrace from 'stacktrace-js';
import NotificationPopup from "./common/lib/NotificationPopup";
import {GraphQLWsLink} from "@apollo/client/link/subscriptions";
import {createClient} from "graphql-ws";
import {getMainDefinition} from "@apollo/client/utilities";
import {LocaleProvider} from "./LocaleProvider";
import {TenantProvider} from "./TenantProvider";

window.onerror = (msg, url, line, col, error)=>
{
    StackTrace.fromError(error).then((stackFrames)=>
    {

        Logger.error(`Uncaught error. ${error.message} stack: ${stackFrames.reduce((frame,acc)=>`${acc}\n${frame.toString()}`,"")}`,{user:Auth.getUsername(),msg,url,
            line,col,error:JSON.stringify(error, Object.getOwnPropertyNames(error))});
    }).catch((err)=>
    {
        Logger.error(`Stacktrace failed. ${err.message}`,{error:JSON.stringify(error, Object.getOwnPropertyNames(error))});
    });
};

document.addEventListener("mousewheel", function(event){
    if(document.activeElement.type === "number"){
        document.activeElement.blur();
    }
});

window.addEventListener("unhandledrejection",function(event)
{
    const error = event.reason;
    StackTrace.fromError(error).then((stackFrames)=>
    {

        Logger.error(`Uncaught error. ${error.message} stack: ${stackFrames.reduce((frame,acc)=>`${acc}\n${frame.toString()}`,"")}`,{user:Auth.getUsername(),error:JSON.stringify(error, Object.getOwnPropertyNames(error))});
        NotificationPopup.error(`Encountered an error ${error.message}`);
    }).catch((err)=>
    {
        Logger.error(`Stacktrace failed. ${err.message}`,{error:JSON.stringify(error, Object.getOwnPropertyNames(error))});
    });
});

const middlewareAuthLink = new ApolloLink((operation, forward) => {
    const token = Auth.getToken();
    if(!token)
    {
        return null;
    }
    const authorizationHeader = token ? `bearer ${token}` : null ;
    operation.setContext({
        headers: {
            authorization: authorizationHeader
        }
    });
    return forward(operation)
});

const logoutLink = onError((error) =>
{
    if(some(error.graphQLErrors,error=>
    error.message === "Unauthorized"
    ))
    {
        console.log("logging out");
        Auth.deauthenticateUser();
        return error.forward();
        // window.location.reload();
    }
    else
    {
        if (error.graphQLErrors)
        {
            const message = reduce(error.graphQLErrors,(acc,{ message, locations, path }) =>
            `${acc}\n [GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,"");
            Logger.error(message,{error,user:Auth.getUsername()});
        }
        else if (error.networkError)
        {
            Logger.error(`[Network error]: ${error.networkError}`,{error,user:Auth.getUsername()});
        }
        else {
            Logger.error(`[Other error]: ${error.message}`,{message:error.message,errorMsg:error.toString(),error,user:Auth.getUsername()});
        }

    }
});


// const httpLinkWithAuthToken = logoutLink.concat(middlewareAuthLink).concat(createUploadLink({ uri: config.GRAPHQL_URI }))
// .concat(new HttpLink({ uri: config.GRAPHQL_URI }));

const httpLinkWithAuthToken = (uri)=> {
    return logoutLink.concat(middlewareAuthLink)
        .concat(createUploadLink({ uri: uri }))
        .concat(new HttpLink({ uri: uri }));
}

const wsLink = () => {
    return new GraphQLWsLink(createClient({
        url: config.WS_URI,
        connectionParams: async ()=> ({
            authToken: Auth.getToken(),
        }),
        reconnect: true,
    }));
}

const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        );
    },
    wsLink(),
    httpLinkWithAuthToken(config.GRAPHQL_URI),
);

const client = new ApolloClient({
    name:`CRM-${window.location.hostname}`,
    link: splitLink,
    cache: new InMemoryCache(),
    defaultOptions:
    {
        query:
        {
            fetchPolicy:'network-only'
        },
        watchQuery:
        {
            fetchPolicy:'network-only'
        }
    }
});

const inventoryClient = new ApolloClient({
    name: `inventory-${window.location.hostname}`,
    link: httpLinkWithAuthToken(config.INVENTORY_GRAPHQL_URI),
    cache: new InMemoryCache(),
    defaultOptions:
        {
            query: {fetchPolicy: 'network-only'},
            watchQuery: {fetchPolicy: 'network-only'}
        }
});


ReactDOM.render(
    <BrowserRouter>
        <ApolloProvider client={client}>
            <LocaleProvider>
                <TenantProvider>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <App inventoryClient={inventoryClient}/>
                    </MuiPickersUtilsProvider>
                </TenantProvider>
            </LocaleProvider>
        </ApolloProvider>
    </BrowserRouter>
    , document.getElementById('root'));
//This is for caching, to load faster and enable offline capabilities. Downside: Users will only see new code after updating..
//https://github.com/facebookincubator/create-react-app/blob/master/packages/react-scripts/template/README.md#making-a-progressive-web-app
// registerServiceWorker();
