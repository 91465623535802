import React, {useCallback, useMemo, useState} from 'react';
import {Alert, Table, UncontrolledTooltip} from "reactstrap";
import UploadFile, {CustomerDocCategoryType} from "../../common/file/UploadFile";
import _, {sortBy} from 'lodash';
import StdQuery from "../../common/StdQuery";
import GetOrder from '../../order/graphql/GetOrder.graphql';
import TaskFooter from "../../common/task-workflow/TaskFooter";
import Paragraph from "../../common/Paragraph";
import {filterArticles, useArticleSelect} from "../DrawJob/util";
import CommentsPanel from "../../common/task-workflow/comment/CommentsPanel";
import OrderInfo from "../../common/task-workflow/OrderInfo";
import CustomerDocsFileList from "../../common/task-workflow/files/CustomerDocsFileList";
import {PROCESS_DEFINITION_KEY_ESPL_PURCHASE_ORDER, SUPPLIER_ID_ESPL, SUPPLIER_ID_ESPR} from "../../Constants";
import {useLocale} from "../../LocaleProvider";
import {downloadFile, formatToSwedishTime, mapSeries} from "../../UTIL";
import {useMutationHandler} from "../../custom-hook/useMutationHandler";
import {useUpdateFileMutation} from "../../generated/graphql";

const transformSelectedArticles = (selectedArticles,articles)=>
{
    return _.filter(_.map(selectedArticles,(val,key)=>
    {
        if(val)
        {
            return _.find(articles, ar => ar.id === key);
        }
        else{
            return null;
        }
    }),_.negate(_.isNull));
}


const prepareVariables = (order,selectedArticles,autoArticles, comments)=>
{
    const articles = _.filter(_.map(order.articleRows,ar=>ar.article),_.negate(_.isNull));
    const selectedArticlesMap = transformSelectedArticles(selectedArticles,articles);
    const selectedAutoArticlesMap = transformSelectedArticles(autoArticles,articles);
    return {
        articles:{
            value: JSON.stringify(selectedArticlesMap),
            type: "json",
        },
        autoDrawArticles:{
            value: JSON.stringify(selectedAutoArticlesMap),
            type: "json",
        },
        comments: {
            value: JSON.stringify(comments),
            type: "json"
        }
    };
}



const ArticleSelect = ({onChange,articles,selectedArticles})=>
{
    const {t} = useLocale()
    return <div>
        <Table>
            <thead>
            <tr>
                <th>
                        <span className="link" id="SelectAllTooltip" onClick={()=>
                        {
                            const selectedArticles = {};
                            articles.forEach(article =>{ selectedArticles[article.id]=true});
                            onChange(selectedArticles);
                        }}>
                        {t('Selected')}
                        </span>
                    <UncontrolledTooltip target='SelectAllTooltip'>{t('Select all articles')}</UncontrolledTooltip>
                </th>
                <th>{t('Art No')}</th>
                <th>{t('Name')}</th>
            </tr>
            </thead>
            <tbody>
            {articles.map(article => <tr key={article.id}>

                <td>
                    <input className='big-checkbox' required={true} type='checkbox'
                           checked={selectedArticles[article.id] || false}
                           onChange={(e)=>
                           {
                               const val = e.target.checked;
                               onChange(sel=>({...sel,[article.id]:val}))
                           }}/>
                </td>
                <td>{article.artNo}</td>
                <td>{article.name}</td>
            </tr>)}
            </tbody>
        </Table>
    </div>
}

const ArticleSelectionGuide = ({isESPLPurchaseOrder}) => {
    const {t} = useLocale()
    return <>
        <Alert color="warning">
            <Paragraph>{t('If no articles needs to be drawn, just click submit.')}</Paragraph>
            <Paragraph>{t('Do not select the same articles for both categories.')}</Paragraph>
        </Alert>
        <ol>
            {isESPLPurchaseOrder ? <li>{t('Select articles to be drawn')}</li>
                : <li>{t('Select automatically drawn articles')}</li>}
            {!isESPLPurchaseOrder && <li>{t('Select automatically drawn articles')}</li>}
            <li>{t('Upload customer documentation for selected articles')}</li>
        </ol>
    </>
}

const OrderFileSelect = ({files, orderFileIdsToPurchaseOrder, setOrderFileIdsToPurchaseOrder})=>
{
    const {t} = useLocale()

    const onAllClicked = useCallback(()=> {
        setOrderFileIdsToPurchaseOrder(files.map((e)=>e.id))
    },[files, setOrderFileIdsToPurchaseOrder])

    const onCheckChanged = useCallback((isChecked, id) => {
        setOrderFileIdsToPurchaseOrder(prevState => {
            if (isChecked) {
                prevState.push(id)
                return [...prevState]
            }
            return prevState.filter(e => e !== id)
        })
    }, [setOrderFileIdsToPurchaseOrder])

    return <>
        <Table striped bordered hover>
            <thead>
            <tr>
                <th>
                    <span className="link" id="SelectAllTooltip" onClick={onAllClicked}>
                    {t('Selected')}
                    </span>
                </th>
                <th>{t('Filename')}</th>
                <th>{t('Created at')}</th>
                <th>{t('Creator')}</th>
                <th>{t('Category')}</th>
            </tr>
            </thead>
            <tbody>
            {sortBy(files, file => file.createdAt).reverse().map(file => {
                return <tr key={file.id}>
                    <td>
                        <input className='big-checkbox' type='checkbox'
                               checked={orderFileIdsToPurchaseOrder.find((e) => e === file.id)}
                               onChange={(e) => {
                                   const val = e.target.checked;
                                   onCheckChanged(val, file.id)
                               }}/>
                    </td>
                    <td>
                        <span className='link' onClick={async () => {
                            await downloadFile(file.id, file.filename);
                        }}>
                            {file.filename}
                        </span>
                    </td>
                    <td>{formatToSwedishTime(file.createdAt)}</td>
                    <td>{file.creator ? file.creator.displayName : (file.creatorId || "N/A")}</td>
                    <td>{file.fileCategory?.name || ''}</td>
                </tr>
            })}
            </tbody>
        </Table>
    </>
}

export default ({toggle, variables, update, onSubmit, task}) => {
    const [autoArticles, setAutoArticles, selectedArticles, setSelectedArticles] = useArticleSelect(variables);
    const [comments, setComments] = useState(variables.comments ?? [])
    const [orderFileIdsToPurchaseOrder, setOrderFileIdsToPurchaseOrder] = useState([])
    const {executeMutation} = useMutationHandler(useUpdateFileMutation)

    const {t} = useLocale()

    const isESPLPurchaseOrder = useMemo(() => {
        return task.processDefinitionKey === PROCESS_DEFINITION_KEY_ESPL_PURCHASE_ORDER
    }, [task.processDefinitionKey]);

    const updateFilesToPo = useCallback(async () => {
        await mapSeries(orderFileIdsToPurchaseOrder, async (fileId) => {
            await executeMutation({
                variables: {
                    id: fileId,
                    file: {orderId: null, purchaseOrderId: variables.purchaseOrderId}
                },
                refetchQueries: ["getOrder", "getFiles", "getPurchaseOrder"]
            })
        })
    }, [executeMutation, orderFileIdsToPurchaseOrder, variables.purchaseOrderId])

    const save = useCallback(async (order) => {
        await updateFilesToPo()
        update(prepareVariables(order, selectedArticles, autoArticles, comments));
    }, [selectedArticles, autoArticles, update, comments, updateFilesToPo]);

    const submit = useCallback(async (order) => {
        await updateFilesToPo()
        onSubmit(prepareVariables(order, selectedArticles, autoArticles, comments));
    }, [selectedArticles, autoArticles, onSubmit, comments, updateFilesToPo]);

    return <StdQuery query={GetOrder} variables={{id:variables.orderId}}>
        {data =>
        {
            const order = data.order;
            const articles = order.articleRows
                .map(ar => ({
                    ...ar.article,
                    name: ar.name,
                    index: ar.index
                }))
                .filter(article => {
                    if (!(article.id && article.artNo)) {
                        return false
                    }
                    const supplierId = article?.supplier?.id;
                    const targetSupplierId = isESPLPurchaseOrder ? SUPPLIER_ID_ESPL : SUPPLIER_ID_ESPR
                    return supplierId === `${targetSupplierId}`
                })
                .sort((a, b) => a.index - b.index);
            return <div>
                <OrderInfo order={order} />
                <ArticleSelectionGuide isESPLPurchaseOrder={isESPLPurchaseOrder}/>
                <h4>{isESPLPurchaseOrder ? t(`Articles to be drawn`) : t(`Articles that require manual handling`)}</h4>
                <ArticleSelect selectedArticles={selectedArticles} articles={filterArticles(autoArticles,articles)}
                               onChange={setSelectedArticles} />
                {
                    !isESPLPurchaseOrder && <>
                        <h4>{t('Articles that are automatically drawn')}</h4>
                        <ArticleSelect selectedArticles={autoArticles} articles={filterArticles(selectedArticles, articles)}
                                       onChange={setAutoArticles}/>
                    </>
                }

                <CommentsPanel comments={comments}
                               setComments={(newComments) => {
                                   setComments(newComments)
                               }}/>
                <hr/>
                <CustomerDocsFileList purchaseOrderId={variables.purchaseOrderId} onDelete={()=>{}}/>
                <UploadFile purchaseOrderId={variables.purchaseOrderId} fileCategory={CustomerDocCategoryType} />
                <hr/>
                <h4>{t('Move order files to purchase order')}</h4>
                <OrderFileSelect files={order.files} orderFileIdsToPurchaseOrder={orderFileIdsToPurchaseOrder} setOrderFileIdsToPurchaseOrder={setOrderFileIdsToPurchaseOrder}/>

                <TaskFooter toggle={toggle} save={() => save(order)}
                            submit={() => submit(order)}/>
            </div>
        }}
    </StdQuery>
}

