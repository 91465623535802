import React, {Component} from 'react';
import {Label} from "reactstrap";
import {FORMAT_DATE, formatToSwedishTime} from "../UTIL";
import {withLocale} from "../LocaleProvider";


class CreateUpdateInfo extends Component{

    constructor()
    {
        super();
        this.state = { isOpen:false};
    }

    toggle()
    {
        this.setState({isOpen:!this.state.isOpen});
    }

    render()
    {
        const obj = this.props.object;
        const {t} = this.props

        return (
        <div>
                <Label>{t('Created at')}</Label>
                <p>{formatToSwedishTime(obj.createdAt, FORMAT_DATE)}</p>
                <Label>{t('Created by')}</Label>
                <p>{obj.creator?.displayName ?? obj.creator ?? "Missing"}</p>
                <Label>{t('Updated at')}</Label>
                <p>{formatToSwedishTime(obj.updatedAt, FORMAT_DATE)}</p>
        </div>);
    }
}

export default withLocale(CreateUpdateInfo);