import gql from 'graphql-tag';
import React, { useCallback, useState} from 'react';
import {
    Button,
    Card, CardBody,
    CardHeader,
    Col,
    Form,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    Spinner,
    Table
} from 'reactstrap';
import ModalForm from "../ModalForm";
import ReactDropZone from 'react-dropzone';
import {selectRenderer} from "../FormRenderer";
import {useUploadFiles} from "../../UTIL";
import Paragraph from "../Paragraph";
import {useLocale} from "../../LocaleProvider";

export const DrawingsCategoryType = {
    id:"1",
    name:"Construction"
};

export const ProductionDrawingsCategoryType = {
    id:"8",
    name:"Production"
};

export const CustomerDocCategoryType = {
    id:"2",
    name:"Quote"
};

const CategoryUploadInfoTable = () => {
    const {t} = useLocale()
    return <Card style={{margin: "16px 0"}}>
        <CardHeader style={{fontSize: "20px"}}>
            {t('What to upload')}
        </CardHeader>
        <CardBody>
            <Table size="sm">
                <thead>
                <tr>
                    <th>

                    </th>
                    <th>
                        {t('Description')}
                    </th>
                    <th>
                        {t('Responsible role(s)')}
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td style={{fontWeight: 600}}>
                        {t('Quote')}
                    </td>
                    <td>
                        {t('Quote, drawings from customer (all types of files, dw, pdf etc.), autodrawings, information and specifications from customer.')}
                    </td>
                    <td>
                        {t('Sales')}
                    </td>
                </tr>
                <tr>
                    <td style={{fontWeight: 600}}>
                        {t('Construction')}
                    </td>
                    <td>
                        {t('Customer drawings, revisions and revision requirements')}
                    </td>
                    <td>
                        {t('Sales, Construction')}
                    </td>
                </tr>
                <tr>
                    <td style={{fontWeight: 600}}>
                        {t('Signed order confirmation')}
                    </td>
                    <td>
                        {t('Customer signed order confirmation and associated drawing.')}
                    </td>
                    <td>
                        {t('Sales')}
                    </td>
                </tr>
                <tr>
                    <td style={{fontWeight: 600}}>
                        {t('Production')}
                    </td>
                    <td>
                        {t('Drawings for production')}
                    </td>
                    <td>
                        {t('Construction')}
                    </td>
                </tr>
                <tr>
                    <td style={{fontWeight: 600}}>
                        {t('Economy')}
                    </td>
                    <td>
                        {t('Order and PO')}
                    </td>
                    <td>
                        {t('Sales')}
                    </td>
                </tr>
                <tr>
                    <td style={{fontWeight: 600}}>
                        {t('Quality documents')}
                    </td>
                    <td>
                        {t('DOP')}
                    </td>
                    <td>
                        {t('Logistics')}
                    </td>
                </tr>
                </tbody>
            </Table>
        </CardBody>
    </Card>
}

const CategoryModal = (props) => {
    const {toggle, isOpen, defaultFileCategory, onSubmit} = props
    const [selectedFileCategory, setSelectedFileCategory] = useState(defaultFileCategory || null);
    const {t} = useLocale()

    return <Modal toggle={toggle} isOpen={isOpen}>
        <ModalHeader toggle={toggle}>{t('File Category Selection')}</ModalHeader>
        <ModalBody>
            <Label>{t('File category')}</Label>
            <small>{t('(Sets the category for all uploaded files)')}</small>
            {selectRenderer({query: gql`query{fileCategories{id name}}`, required: true},
                selectedFileCategory, val => setSelectedFileCategory(val))}
            <br/>
            <Button disabled={!selectedFileCategory}
                onClick={() => {
                onSubmit(selectedFileCategory)
                toggle()
            }}>{t('Submit')}</Button>
            <CategoryUploadInfoTable/>
        </ModalBody>
    </Modal>
}

export default ({
                    fileCategory,
                    toggle,
                    onSubmit,
                    orderId,
                    quoteId,
                    supplierId,
                    purchaseOrderId,
                    noModal,
                    noCategory = false,
                    purchaseOrders
                }) => {
    const [files,setFiles] = useState([]);
    const [loading,setLoading] = useState(false);
    const [isOpenCategoryModal, setIsOpenCategoryModal] = useState(false)
    const [fileOrderId, setFileOrderId] = useState(orderId)
    const [filePurchaseOrderId, setFilePurchaseOrderId] = useState(purchaseOrderId)
    const {t} = useLocale()

    const toggleCategoryModal = useCallback(()=> {
        setIsOpenCategoryModal(prevState => !prevState)
    },[setIsOpenCategoryModal])

    const uploadFiles = useUploadFiles();

    const submit = useCallback(async(selectedFileCategory)=>
    {
        setLoading(true);
        const uploadedFiles = await uploadFiles(
            files,
            {
                quoteId,
                orderId: fileOrderId,
                supplierId,
                purchaseOrderId: filePurchaseOrderId,
                fileCategoryId:selectedFileCategory?.id
            });
        setLoading(false);
        setFiles([]);
        // setSelectedFileCategory(null);
        onSubmit && onSubmit(uploadedFiles);
    },[uploadFiles,setLoading,onSubmit,fileOrderId,quoteId,supplierId,filePurchaseOrderId,files]);

    const onPurchaseOrderChanged = useCallback((value) => {
        setFileOrderId(null)
        setFilePurchaseOrderId(value.id)
    }, [])

    const form = (toggle) => <Form onSubmit={async (event) => {
        event.preventDefault();
        await submit(null);
        toggle && toggle();
    }}>
        <ul>
            {files.map(file => {
                return (<li key={file.name}>{file.name}</li>)
            })}
        </ul>

        <Row>
            <Col md={5}>
                <ReactDropZone
                    style={{
                        width: '100%', height: '100px', border: '1px dashed black',
                        display: 'flex', alignItems: 'center', justifyContent: 'center'
                    }}
                    onDrop={(accepted) => {
                        setFiles(files => files.concat(accepted));
                    }}>
                    <h5>{t('Drop your files here or click me')}</h5>
                </ReactDropZone>
            </Col>
        </Row>
        {
            purchaseOrders && <Row style={{marginTop: "16px"}}>
                <Col md={5}>
                    <Paragraph>{t('Upload to purchase order')}</Paragraph>
                    {selectRenderer({
                        options: purchaseOrders.map((e) => ({id: e.id, name: `${e.number}-${e.supplier?.name}`})),
                        isClearable: true
                    }, filePurchaseOrderId, onPurchaseOrderChanged)}
                </Col>
            </Row>
        }

        <br/>


        {!loading && <Button
            onClick={noCategory ? () => {
            } : toggleCategoryModal}
            type={noCategory ? "submit" : "button"}
            disabled={files.length === 0}>
            {t('Submit')}
        </Button>}

        {loading && <Spinner color="warning"/>}

        <CategoryModal isOpen={isOpenCategoryModal} toggle={toggleCategoryModal}
                       defaultFileCategory={fileCategory || null}
                       onSubmit={async (selectedFileCategory) => {
                           await submit(selectedFileCategory)
                           toggle && toggle();
                       }}/>
    </Form>;

    if(noModal)
    {
        return form(toggle);
    }
    else
    {
        return <ModalForm onToggle={()=>setFiles([])} title={t('Upload files')}
                          activationButtonText={t('Upload files')}>
            {(toggle)=>form(toggle)}
        </ModalForm>;
    }
}


