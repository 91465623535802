import {Button, Col, Row, Spinner, TabContent, TabPane} from 'reactstrap';
import React, {useCallback, useMemo, useState} from "react";
import gql from "graphql-tag";
import {useMutation, useQuery} from "@apollo/client";
import {GetInventoryArticleDocument, InventoryArticle} from "../../generated/inventoryGraphql";
import NotificationPopup from "../../common/lib/NotificationPopup";
import NavBar from "../components/NavBar";
import {withRouter} from "react-router";
import Auth from "../../Auth";
import ArticleDetailPanel from "./ArticleDetailPanel";
import PurchaseOrdersByArtNoList from "./PurchaseOrdersByArtNoList";
import ReserveQuantitiesList from "./ReserveQuantitiesList";
import FileUploadPage from "../components/FileUploadPage";
import EANCodePage from "./EANCodePage";
import OnlinePriceListPage from "./OnlinePriceListPage";
import CommentsPanel from "./comment/CommentsPanel";
import {useLocale} from "../../LocaleProvider";

const KEY_ADMIN = "inventoryAdmin"

const deleteArticleMutation = gql`
    mutation deleteArticle($id:Int!){
        deleteArticle(id:$id)
    }`;

enum NavTab
{
    DETAIL = "Details",
    PURCHASE_ORDER = "Purchase Order",
    RESERVED_QUANTITIES = "Reserved Quantities",
    FILES = "Files",
    EAN_CODE = "EAN Code",
    ONLINE_PRICES = "Online prices",
    COMMENTS = "Comments"
}

const InventoryArticleDetailPage = (props) =>
{
    const history = props.history;

    const articleId = ((props.match.params as any).id);
    const [EANCodePageActive, setEANCodePageActive] = useState(false);
    const [OnlinePricePageActive, setOnlinePricePageActive] = useState(false);
    const {t} = useLocale()

    const [activeTab, setActiveTab] = useState(NavTab.DETAIL)

    const {data, loading} = useQuery(GetInventoryArticleDocument, {variables: {id: articleId}});
    const [deleteArticle] = useMutation(deleteArticleMutation);

    const isAdmin = useMemo(() => {
        return Auth.getGroups().includes(KEY_ADMIN)
    }, [])

    const isDeleted = useMemo(() =>
    {
        const article = data?.article
        return article && article.deleteAt != null
    }, [data])

    const onTabClick = useCallback((newTab: NavTab) =>
    {
        setActiveTab(newTab)
        switch (newTab)
        {
            case NavTab.EAN_CODE:
                setEANCodePageActive(true);
                setOnlinePricePageActive(false);
                break;
            case NavTab.ONLINE_PRICES:
                setEANCodePageActive(false);
                setOnlinePricePageActive(true);
                break;
            default:
                setEANCodePageActive(false);
                setOnlinePricePageActive(false)
        }

    }, [setActiveTab])

    if (loading)
    {
        return <Spinner variant={"warning"} animation='border'/>
    }
    if (!data?.article)
    {
        return <h2 style={{color: "#999"}}>Article does not exist or has been deleted.</h2>
    }
    const article: InventoryArticle = data.article;

    const onDeleteClick = async () =>
    {
        if (window.confirm(`Are you sure you want to delete article: ${article.name}`))
        {
            await deleteArticle({
                    variables: {id: parseInt(article.id)},
                    refetchQueries: ["articles"]
                }
            );
            NotificationPopup.info(`Removed article ${article.name}`);
            history.goBack()
        }
    }

    return <div style={{marginBottom: "100px"}}>
        <Row>
            <Col>
                <h1 style={isDeleted ? {textDecoration: 'line-through', color: 'red'} : undefined}>
                    {article.artNo} - {article.name}
                </h1>
            </Col>
            {
                (isAdmin && !isDeleted) && <Col sm={2}>
                    <Button style={{marginTop: "8px"}} variant={'secondary'} onClick={onDeleteClick}>{t(`Delete`)}</Button>
                </Col>
            }

        </Row>
        <Row>
            <Col sm={12}>
                <NavBar enumType={NavTab} onClick={onTabClick} activeTab={activeTab}/>

                <TabContent activeTab={activeTab} style={{marginTop: "8px"}}>
                    <TabPane tabId={NavTab.DETAIL}>
                        <ArticleDetailPanel article={article} isEditable={isAdmin} isDeleted={isDeleted}/>
                    </TabPane>
                    <TabPane tabId={NavTab.PURCHASE_ORDER}>
                        <PurchaseOrdersByArtNoList artNo={article.artNo ?? ""}/>
                    </TabPane>
                    <TabPane tabId={NavTab.RESERVED_QUANTITIES}>
                        <ReserveQuantitiesList data={article.reservedQuantities}/>
                    </TabPane>
                    <TabPane tabId={NavTab.FILES}>
                        <FileUploadPage articleId={parseInt(article.id)}/>
                    </TabPane>
                    <TabPane tabId={NavTab.COMMENTS}>
                        <CommentsPanel articleId={article.id}/>
                    </TabPane>
                    <TabPane tabId={NavTab.EAN_CODE}>
                        <EANCodePage pageActive={EANCodePageActive} articleId={parseInt(article.id)}/>
                    </TabPane>
                    <TabPane tabId={NavTab.ONLINE_PRICES}>
                        <OnlinePriceListPage pageActive={OnlinePricePageActive} artNo={article.artNo}/>
                    </TabPane>
                </TabContent>
            </Col>
        </Row>
    </div>
}


export default withRouter(InventoryArticleDetailPage)