import {PreferredMaterialType, StringerMaterial} from "../generated/graphql";


export const maxLoadOptions = [
    {id: 3, name: "3"},
    {id: 5, name: "5"}
]

export const scpOptions = [
    {id: "L1", name: "L1"},
    {id: "L2", name: "L2"},
    {id: "W1", name: "W1"},
    {id: "W2", name: "W2"}]

export const preferredMaterialOptions = [
    {id: PreferredMaterialType.Pls, name: "Flat bar"},
    {id: PreferredMaterialType.Upe, name: "UPE"},
]

export enum StairTypeAndRailing
{
    industrial = "Industrial",
    childSafeFlatBarSimple = "ChildSafe Flat Bar Simple",
    childSafeFlatBarStandard = "ChildSafe Flat Bar Standard",
    childSafeRodSimple = "ChildSafe Rod Simple",
    childSafeStandardTube = "ChildSafe Standard Tube",
}

export const getStringerMaterialOptions = (preferredMaterial: PreferredMaterialType) =>
{
    switch (preferredMaterial)
    {
        case PreferredMaterialType.Pls:
            return [StringerMaterial.Pls150x8, StringerMaterial.Pls150x10, StringerMaterial.Pls200x8, StringerMaterial.Pls200x10, StringerMaterial.Pls250x8, StringerMaterial.Pls250x10]
        case PreferredMaterialType.Upe:
            return [StringerMaterial.Upe140, StringerMaterial.Upe160, StringerMaterial.Upe180, StringerMaterial.Upe200]
    }
}