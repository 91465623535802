import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {downloadFile, FORMAT_DATE_TIME, formatToSwedishTime} from "../../UTIL";
import gql from "graphql-tag";
import {graphql} from '@apollo/client/react/hoc'
import Trash from "../icons/Trash";
import ColumnCreatedAt from "../data-grid/columns/ColumnCreatedAt";
import NonPaginatedDataGrid from "../data-grid/NonPaginatedDataGrid";
import {useGetFileCategoriesQuery, useUpdateFileMutation} from "../../generated/graphql";
import {MultiSelectFilterOperators} from "../data-grid/MultiSelectInput";
import {Link} from "react-router-dom";
import Pen from "../icons/Pen";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner} from "reactstrap";
import NewFormRenderer from "../NewFormRenderer";
import {useMutationHandler} from "../../custom-hook/useMutationHandler";
import NotificationPopup from "../lib/NotificationPopup";

const KEY_TABLE = "TABLE_FILES"

const deleteFileMutation = gql`
    mutation deleteFile($id:ID!)
    {
        deleteFile(id:$id)
    }
`;

const EditFileModal = ({file, isOpen, toggle, purchaseOrders}) => {
    const {data} = useGetFileCategoriesQuery()
    const {executeMutation, loading} = useMutationHandler(useUpdateFileMutation)

    const [form, setForm] = useState({})

    const formDefinition = useMemo(() => {
        const formDefinition = [
            {
                name: 'fileCategoryId', label: 'Category', type: 'select',
                options: data?.fileCategories ?? []
            }
        ]

        if (purchaseOrders) {
            formDefinition.push({
                name: 'purchaseOrderId',
                label: 'Purchase Order',
                type: 'select',
                options: purchaseOrders.map((e) => ({id: e.id, name: `${e.number}-${e.supplier?.name}`}))
            })
        }

        return formDefinition
    }, [data?.fileCategories, purchaseOrders]);

    const onChange = useCallback((key, value) => {
        setForm(prevState => {
            if (key === 'purchaseOrderId') {
                prevState.orderId = null
            }

            return {...prevState, [key]: value}
        })
    }, []);

    const onUpdatedSuccess = useCallback(() => {
        NotificationPopup.success("Successfully updated file.")
        toggle()
    }, [toggle]);

    const onSubmit = useCallback(async () => {
        if (!file) {
            return
        }
        await executeMutation({
            variables: {id: file.id, file: form},
            refetchQueries: ["getOrder", "getFiles", "quote", "getSupplier", "getPurchaseOrder"]
        }, {onSuccess: onUpdatedSuccess})
    }, [file, form, executeMutation, onUpdatedSuccess]);

    useEffect(() => {
        if (!file) {
            setForm({})
            return
        }
        setForm({fileCategoryId: file.fileCategory?.id, purchaseOrderId: file.purchaseOrder?.id})
    }, [file]);

    return <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Edit {file?.filename}</ModalHeader>
        <ModalBody>
            <NewFormRenderer formDefinition={formDefinition}
                             object={form}
                             onChange={onChange}/>
        </ModalBody>
        <ModalFooter>
            <Button color="success" disabled={loading} onClick={onSubmit}>Submit</Button>
            {loading && <Spinner/>}
        </ModalFooter>
    </Modal>
}

const FilesSubpanel = (props) => {
    const {files} = props;

    const [editFile, setEditFile] = useState(null)
    const {data} = useGetFileCategoriesQuery()

    const deleteFile = useCallback(async (file) => {
        if (window.confirm(`Are you sure you want to remove file ${file.filename}?`)) {
            await props.deleteFile({variables: {id: file.id}, refetchQueries: ["getOrder", "quote", "getSupplier", "getPurchaseOrder"]});
        }
    }, [props])

    const getColumns = useMemo(() => {
        const columns =  [
            {
                field: 'filename',
                headerName: 'Filename',
                width: 400,
                renderCell: (params) => {
                    const value = params.value
                    return <span className='link' onClick={async () => {
                        await downloadFile(params.id, value);
                    }}>{value}</span>
                }
            },
            {
                field: 'fileCategory.name',
                headerName: 'File Category',
                type: "singleSelect",
                customFilterOperators: MultiSelectFilterOperators(data?.fileCategories
                    ?.sort((a, b) => a.name.localeCompare(b.name))
                    ?.map((e) => ({id: e.name, name: e.name})) ?? [],
                    "Category")
            },
            {
                field: 'creator.displayName',
                headerName: 'Creator',
            },
            ColumnCreatedAt((value) => formatToSwedishTime(value, FORMAT_DATE_TIME)),
        ]
        if (props.purchaseOrders) {
            columns.push({
                field: 'purchaseOrder',
                headerName: 'Upload PO',
                renderCell: (params) => {
                    const purchaseOrder = params.value
                    return purchaseOrder ?
                        <Link to={`/purchaseOrderDetail/${purchaseOrder.id}`}>{purchaseOrder.label}</Link>
                        : null
                }
            })
        }
        columns.push({
            field: 'actions',
            filterable: false,
            sortable: false,
            width: "80",
            renderCell: (params) => {
                const file = params.row
                return <>
                     <span className={"link"} onClick={()=> setEditFile(file)}>
                        <Pen/>
                    </span>
                    <span className='link' style={{paddingLeft: '8px'}}
                          onClick={() => deleteFile(file)}>
                        <Trash/>
                    </span>
                </>
            }
        })

        return columns
    }, [data, deleteFile, props.purchaseOrders])

    const quickFilterButtons = useMemo(() => {
        if (!data || !data.fileCategories) {
            return []
        }
        const list = data.fileCategories.map((e) => e.name)
        return list.map((e) => (
            {
                name: e,
                filters: {
                    items: [{
                        field: 'fileCategory.name',
                        value: [e],
                        operator: 'in'
                    }]
                }
            }
        ))
    }, [data])

    return <div style={{margin: "16px 0"}}>
        <NonPaginatedDataGrid
        definition={{
            tableKey: KEY_TABLE,
            columns: getColumns,
            pageSize: 100,
            initState: {
                sorting: {
                    sortModel: [
                        {field: 'fileCategory.name', sort: 'asc'},
                        {field: 'createdAt', sort: 'asc'},
                    ]
                },
                columns: {columnVisibilityModel: {id: false}},
            },
            buttons: props.noCategory ? null : quickFilterButtons
        }}
        data={files}/>

        <EditFileModal isOpen={editFile !== null}
                       file={editFile}
                       purchaseOrders={props.purchaseOrders}
                       toggle={() => setEditFile(null)}/>
    </div>
}

export default graphql(deleteFileMutation, {name: "deleteFile"})(FilesSubpanel);
