import React, {Component} from 'react';
import {Alert} from "reactstrap";
import CommentsPanel from "../../common/task-workflow/comment/CommentsPanel";
import CustomerAndDrawingFileList from "../../common/task-workflow/files/CustomerAndDrawingFileList";
import ArticlesAndAutoDrawArticlesOverview from "../../common/task-workflow/ArticlesAndAutoDrawArticlesOverview";
import TaskFooter from "../../common/task-workflow/TaskFooter";
import DrawJobOrderInfo from "../../common/task-workflow/DrawJobOrderInfo";

export default class ReviewDrawings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            comments: props.variables.comments ?? [],
        };
    }
    render()
    {
        const { articles, orderId, purchaseOrderId, autoDrawArticles, initiator, drawingComment } = this.props.variables;
        return <DrawJobOrderInfo task={this.props.task} variables={this.props.variables}>
            <ArticlesAndAutoDrawArticlesOverview articles={articles} autoDrawArticles={autoDrawArticles} orderId={orderId}/>
            <hr/>
            {drawingComment && drawingComment.length > 0 && <><h4>Comment from {initiator}</h4>
                <Alert color="warning">
                     <pre>
                         {drawingComment}
                     </pre>
                </Alert></>}
            <CustomerAndDrawingFileList purchaseOrderId={purchaseOrderId}/>
            <hr/>
            <CommentsPanel comments={this.state.comments}
                           setComments={(newComments) => {
                              this.setState({comments: newComments});
                          }}/>
            <hr/>
            <TaskFooter toggle={this.props.toggle} submit={() => this.props.onSubmit({})}/>
        </DrawJobOrderInfo>
    }
}

