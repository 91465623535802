import {PlatformType, StepType, StraightStairSegmentFragmentFragment, StringerMaterial} from "../generated/graphql";
import {STRAIGHT_STAIR_TAB_SEGMENT} from "./StraightStairConstants";


export const isAsnEnabled = (stepType: StepType) =>
{
    return stepType === StepType.StandardGrate || stepType === StepType.TightMeshGrate
}

export const convertStepTypeToPlatformType = (stepType: StepType, platformWidth: number)=>
{
    switch (stepType)
    {
        case StepType.StandardGrate:
            if (platformWidth < 1350)
            {
                return PlatformType.StandardGrate30
            } else
            {
                return PlatformType.StandardGrate40
            }
        case StepType.TightMeshGrate:
            return PlatformType.TideMeshGrate
        case StepType.PlateForConcrete:
            return PlatformType.PlateForConcrete
        case StepType.PrepForCarpet:
            return PlatformType.PrepForCarpet
        case StepType.PrepForWood:
            return PlatformType.PrepForWood
        case StepType.PrepForTiles:
            return PlatformType.PrepForTiles
        case StepType.PrepForTerrazzo:
            return PlatformType.PrepForTerrazzo
        case StepType.Tearplate:
            return PlatformType.Tearplate
    }
}


export const getSegmentId = (id: string) =>
{
    return `${STRAIGHT_STAIR_TAB_SEGMENT}${id}`
}

export const calculateT1Width = (stairStringerMaterial: StringerMaterial, stepsWidth: number) =>
{
    let h: number
    switch (stairStringerMaterial) // Stringer_calculator_Data: X22~X33
    {
        case StringerMaterial.Pls150x10:
        case StringerMaterial.Pls200x10:
        case StringerMaterial.Pls250x10:
            h = 10
            break;
        case StringerMaterial.Pls150x8:
        case StringerMaterial.Pls200x8:
        case StringerMaterial.Pls250x8:
            h = 8
            break;
        case StringerMaterial.Pls200x12:
        case StringerMaterial.Pls300x12:
            h = 12
            break;
        case StringerMaterial.Upe140:
            h = 65
            break;
        case StringerMaterial.Upe160:
            h = 70
            break;
        case StringerMaterial.Upe180:
            h = 75
            break;
        case StringerMaterial.Upe200:
            h = 80
            break;
    }
    return 2 * h + stepsWidth
}

export const getRecommendedPlatformRailingLength = (side: "L1" | "L2" | "W1" | "W2",
                                                    segment: StraightStairSegmentFragmentFragment,
                                                    checked: boolean,
                                                    platformRailingLength: number,
                                                    t1Width: number) =>
{
    if (!checked)
    {
        return 0;
    }
    let TB2 = platformRailingLength
    const CB6 = segment.platformSCP1
    const CB7 = segment.platformSCP2
    const TB10 = t1Width

    const TB8 = (side === "L1" || side === "L2")
        ? segment.platformLength
        : segment.platformWidth

    if (CB6 === side || CB7 === side)
    {
        if (TB2 >= (TB8 - TB10) || (TB8 - TB10) > 150)
        {
            if ((TB8 - TB10) > 0)
            {
                TB2 = TB8 - TB10;
            } else
            {
                TB2 = 0;
            }
        }
    } else
    {
        TB2 = TB8;
        if (TB2 < 150)
        {
            TB2 = 0;
        }
        if (TB2 > TB8)
        {
            TB2 = TB8;
        }
    }
    if (TB2 < 150)
    {
        TB2 = 0;
    }

    return TB2
}