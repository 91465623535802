import React, { useCallback, useState } from 'react';
import FileList from '../../common/file/FileList';
import CommentsPanel from "../../common/task-workflow/comment/CommentsPanel";
import CustomerAndDrawingFileList from "../../common/task-workflow/files/CustomerAndDrawingFileList";
import TaskApproveFooter from "../../common/task-workflow/TaskApproveFooter";
import {useLocale} from "../../LocaleProvider";

export default ({variables,onSubmit,toggle})=>
{
    const {purchaseOrderId, moreInformationAddedFiles} = variables;
    const [comments, setComments] = useState(variables.comments ?? [])
    const {t} = useLocale()

    const handleOnSubmit = useCallback(async (approve) => {
        await onSubmit({
            moreInformationApproved:
                {
                    type: "Boolean",
                    value: approve
                },
            comments: {
                value: JSON.stringify(comments),
                type: "json"
            },
        });
    }, [onSubmit, comments])

    const approve = useCallback(async () => {
        await handleOnSubmit(true)
    }, [handleOnSubmit]);

    const reject = useCallback(async () => {
        if (window.confirm(`Are you sure you want to reject? If you have comments, please make sure to add them before proceeding`)) {
            await handleOnSubmit(false)
        }
    }, [handleOnSubmit])

    return <div>
        <h4>{t('Added files')}</h4>
        <FileList files={moreInformationAddedFiles}/>
        <hr/>
        <CustomerAndDrawingFileList purchaseOrderId={purchaseOrderId}/>
        <hr/>
        <CommentsPanel comments={comments} setComments={setComments}/>
        <TaskApproveFooter toggle={toggle} onReject={reject} onApprove={approve}/>
    </div>
}
