import ESPLOrderInfo from "../../common/task-workflow/espl/ESPLOrderInfo";
import React, {useCallback} from "react";
import ESPLOrderArticleList from "../../common/task-workflow/espl/ESPLOrderArticleList";
import TaskFooter from "../../common/task-workflow/TaskFooter";
import {useGetPurchaseOrderQuery, useUpdatePurchaseOrderMutation} from "../../generated/graphql";
import Spinner from "reactstrap/lib/Spinner";
import ESPLOrderDeliveryInfo from "../../common/task-workflow/espl/ESPLOrderDeliveryInfo";
import {useLocale} from "../../LocaleProvider";

const ReadyForPickup = ({onSubmit, variables, toggle}) =>
{
    const {purchaseOrderId} = variables
    const {data, loading} = useGetPurchaseOrderQuery({variables: {id: purchaseOrderId}})
    const {t} = useLocale()
    const [updatePOMutation] = useUpdatePurchaseOrderMutation({refetchQueries: ["getPurchaseOrder"]})

    const handleSubmit = useCallback(async () =>
    {
        await updatePOMutation({
            variables: {
                id: purchaseOrderId,
                purchaseOrder: {readyForPickupDate: new Date()}
            }
        })
        onSubmit()
    }, [onSubmit, purchaseOrderId, updatePOMutation]);

    if (loading)
    {
        return <Spinner/>
    }

    return <>
        {
            data?.purchaseOrder && <>
                <ESPLOrderInfo purchaseOrder={data?.purchaseOrder}/>
                <h5 style={{padding: '0', margin: '4px 0 0 0' }}>{t('Delivery Info')}</h5>
                <ESPLOrderDeliveryInfo purchaseOrder={data?.purchaseOrder}/>
                <h5 style={{marginTop: '10px'}}>{t('Article Rows')}</h5>
                <ESPLOrderArticleList purchaseOrder={data?.purchaseOrder}/>
            </>
        }
        <TaskFooter toggle={toggle} submit={handleSubmit} submitLabel={`Mark as ready for pickup`}/>
    </>
}

export default ReadyForPickup;