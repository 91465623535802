import React, {Component} from 'react';
import MyViewer from "./lib/CustomBpmnJsViewer/CustomViewer";
import {withApollo} from '@apollo/client/react/hoc'
import gql from "graphql-tag";
import PropTypes from 'prop-types';
import _ from 'lodash';
import {withLocale} from "../LocaleProvider";

class BPMDiagram extends Component{

    constructor(props)
    {
        super();
        this.state = {uniq:(Math.random()*100).toFixed(0)}
    }
    async componentDidMount()
    {
        let width = `1000px`;
        let height = '300px';
        if(this.props.width)
        {
            width = `${this.props.width}px`;
        }
        if(this.props.height)
        {
            height = `${this.props.height}px`;
        }
        const viewer = new MyViewer({
            container: `#js-canvas${this.state.uniq}`,
            width,
            height,

        });

        const res = await this.props.client.query({
            query:gql`query getXML($processInstanceId:String!)
            {
                getXML(processInstanceId:$processInstanceId)
            }`,
            variables:{
                processInstanceId:this.props.processInstanceId
            }
        });
        viewer.importXML(res.data.getXML, (err) =>{
            if (err)
            {
                console.log('error: ' + err.message);
                console.error(err);
            }
            else
            {
                const canvas = viewer.get('canvas');
                canvas.zoom('fit-viewport');
                _.forEach(this.props.activityIds,(activityId)=>
                {
                    canvas.addMarker(activityId, 'highlight');
                });


            }
        });

    }

    render()
    {
        const {t} = this.props
        return (
        <div>
            <small style={{color:"#155cb5"}}>{t('Navigate by DRAGGING with mouse and ZOOM with CTRL+SCROLL')}</small>
            <div className='bpmn-diagram' id={`js-canvas${this.state.uniq}`}></div>
        </div>);
    }
}

BPMDiagram.propTypes =
{
    processInstanceId:PropTypes.string.isRequired,
    activityIds:PropTypes.arrayOf(PropTypes.string),
    width:PropTypes.number
};

export default withApollo(withLocale(BPMDiagram));
