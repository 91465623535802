import React, {useCallback, useState} from "react";
import {Button, Modal, ModalBody, ModalHeader} from "reactstrap";
import Collapser from "../../Collapser";
import WorkflowComment from "../../../types/WorkflowComment";
import {CommentTable} from "./CommentTable";
import {AddCommentForm} from "./AddCommentForm";
import {useLocale} from "../../../LocaleProvider";

const AddCommentModal = ({submit, open, toggle, comments, title}:
                             {
                                 submit: (newComments) => void, open: boolean, toggle: any,
                                 comments?: WorkflowComment[], title?: string
                             }) =>
{
    const {t} = useLocale()
    return <Modal isOpen={open}>
        <ModalHeader toggle={toggle}>
            {title ? t(title) : t(`Add comment`)}
        </ModalHeader>
        <ModalBody>
            <AddCommentForm comments={comments} submit={submit}/>
        </ModalBody>
    </Modal>
}

const CommentsPanel = ({comments, oldComment, setComments}:
                           {
                               comments: WorkflowComment[],
                               setComments: any,
                               oldComment?: string
                           }) =>
{

    const [addCommentModal, setAddCommentModal] = useState(false)
    const {t} = useLocale()

    const toggleAddCommentModal = useCallback(() =>
    {
        setAddCommentModal(value => !value)
    }, [setAddCommentModal])

    const handleAddComment = useCallback((newComments) =>
    {
        setComments(newComments)
        toggleAddCommentModal()
    }, [setComments, toggleAddCommentModal])

    return <Collapser label={t('Comments')} open>
        <h4>{t('Comments')}</h4>
        <CommentTable comments={comments} requiresMoreInformationComment={oldComment}/>
        <Button onClick={toggleAddCommentModal}>{t('Add comment')}</Button>
        <AddCommentModal open={addCommentModal}
                         toggle={toggleAddCommentModal}
                         comments={comments}
                         submit={handleAddComment}/>
    </Collapser>
}

export default CommentsPanel;