import React, {useState} from 'react';
import {Button, ButtonGroup, Col, Row, Spinner, UncontrolledTooltip} from "reactstrap";
import QuestionMark from "../icons/QuestionMark";
import {useLocale} from "../../LocaleProvider";

export default ({submit,save,toggle,submitLabel, customButtons
} : {
    submit: () => Promise<void>,
    save?: () => Promise<void>,
    toggle:()=>void,
    submitLabel?:string,
    customButtons?: React.ReactNode[],
})=> {
    const [loading,setLoading] = useState(false);
    const {t} = useLocale()

    return <React.Fragment>
        <hr/>
        <Row className="justify-content-between">
            <Col sm={2}>
                <Button onClick={()=>toggle()}>
                    {t('Cancel')}
                </Button>
            </Col>
            <Col sm={4} style={{textAlign: 'right'}}>
                <ButtonGroup>
                    {save && <Button color="primary" disabled={loading} onClick={async()=>
                    {
                        setLoading(true);
                        await save();
                        setLoading(false);
                    }}>
                        {t('Save')}
                        <span id="save-info">
                                <QuestionMark/>
                            </span>
                        <UncontrolledTooltip target="save-info" >
                            {t('Save data in task without completing the task.')}
                        </UncontrolledTooltip>
                    </Button>}
                    {customButtons ? customButtons : null}

                    {!loading && <Button color="success" onClick={async()=>
                    {
                        setLoading(true);
                        await submit();
                        setLoading(false);
                    }}>
                        {submitLabel ? t(submitLabel) : t('Complete task')}
                    </Button>}
                    {loading && <Spinner color={'warning'}/>}
                </ButtonGroup>
            </Col>
        </Row>
    </React.Fragment>
}
