import {Button} from "reactstrap";
import React, {useCallback} from "react";
import {useLocale} from "../../LocaleProvider";

type Props = {
    onConfirm: () => void
}

const RequestMoreInformationButton = ({onConfirm}: Props) =>
{
    const {t} = useLocale()
    const onClick = useCallback(() =>
    {
        if (window.confirm(t(`Please confirm if more information is needed (if comments are to be added, please add them first)`)))
        {
            onConfirm()
        }
    }, [onConfirm, t])

    return <Button style={{marginTop: "16px", display: "block"}}
                   color={'danger'}
                   onClick={onClick}>
        {t('Request more information')}
    </Button>
}

export default RequestMoreInformationButton;