import gql from "graphql-tag";
import {useLazyQuery} from "@apollo/client";
import {Col, Row, Spinner, Table} from "reactstrap";
import React, {useEffect, useMemo} from "react";
import {PurchaseOrderFragmentFragment} from "../../../generated/graphql";
import {useLocale} from "../../../LocaleProvider";
import {formatCurrency, poTotal} from "../../../UTIL";

type Props = {
    purchaseOrder?: PurchaseOrderFragmentFragment | null | undefined
    purchaseOrderId?: string
}

const queryPurchaseOrder = gql`
    query purchaseOrder($id: ID!) {
        purchaseOrder(id: $id) {
            currency
            articleRows {
                name
                price
                quantity
                discount
                article {
                    artNo
                }
                stockArticle {
                    artNo
                }
            }
        }
    }
`

const ESPLOrderArticleList = ({purchaseOrder, purchaseOrderId}: Props) =>
{
    const [getPurchaseOrderLazyQuery, {
        loading,
        data
    }] = useLazyQuery(queryPurchaseOrder, {variables: {id: purchaseOrderId}});
    const {t} = useLocale()

    const localPurchaseOrder = useMemo(() =>
    {
        return purchaseOrder ?? data?.purchaseOrder
    }, [purchaseOrder, data])

    const total = useMemo(() =>
    {
        if (!localPurchaseOrder?.articleRows)
        {
            return 0
        }

        return poTotal(localPurchaseOrder)
    }, [localPurchaseOrder])

    useEffect(() =>
    {
        if (purchaseOrderId && !purchaseOrder)
        {
            getPurchaseOrderLazyQuery({variables: {id: purchaseOrderId}})
        }
    }, [purchaseOrder, purchaseOrderId, getPurchaseOrderLazyQuery])

    if (loading)
    {
        return <Spinner/>
    }

    return <Row>
        <Col>
            <Table striped>
                <thead>
                <tr>
                    <th>{t('Art No')}</th>
                    <th>{t('Name')}</th>
                    <th>{t('Price')}</th>
                    <th>{t('Quantity')}</th>
                    <th>{t('Discount')}</th>
                    <th>{t('Total Amount')}</th>
                </tr>
                </thead>
                <tbody>
                {localPurchaseOrder?.articleRows?.map(articleRow =>
                {
                    const styleTextRight:any = {textAlign: "right"}
                    const {price, quantity, discount} = articleRow
                    const total = price * quantity * ((100 - discount) / 100)
                    return <tr key={articleRow.id}>
                        <td>{articleRow.stockArticle?.artNo ?? articleRow.article?.artNo}</td>
                        <td>{articleRow.name}</td>
                        <td style={styleTextRight}>{formatCurrency(price)}</td>
                        <td style={styleTextRight}>{quantity}</td>
                        <td style={styleTextRight}>{discount ? `${discount}%` : '-'}</td>
                        <td style={styleTextRight}>{formatCurrency(total)}</td>
                    </tr>
                })}
                </tbody>
            </Table>
            <Row style={{textAlign: 'right'}}>
                <Col>
                    <p>Total: {formatCurrency(total)} {localPurchaseOrder?.currency ?? ''}</p>
                </Col>
            </Row>
        </Col>
    </Row>
}

export default ESPLOrderArticleList