import {useMutationHandler} from "../../custom-hook/useMutationHandler";
import {
    CreateStraightStairSupportMutation,
    StraightStairSupportFragmentFragment, StraightStairSupportMaterialType,
    StraightStairSupportType,
    useCreateStraightStairSupportMutation,
    useDeleteStraightStairSupportMutation
} from "../../generated/graphql";
import {useCallback} from "react";

type Props = {
    supports: StraightStairSupportFragmentFragment[]
    segmentId: string,
    updateSegmentSupports: (segmentId: string, supports: StraightStairSupportFragmentFragment[]) => void
}

export type GetSupportMaterialTypeFunc =(length: number, type: StraightStairSupportType)=> StraightStairSupportMaterialType[]

const useSupportHook = ({segmentId, updateSegmentSupports, supports}: Props) =>
{
    const {
        executeMutation: createSupportMutation,
        loading: addSupportLoading
    } = useMutationHandler(useCreateStraightStairSupportMutation)

    const {
        executeMutation: deleteSupportMutation,
        loading: deleteSupportLoading
    } = useMutationHandler(useDeleteStraightStairSupportMutation)

    const updateSupport = useCallback((support: StraightStairSupportFragmentFragment) =>
    {
        const index = supports.findIndex(e => e.id === support.id);
        supports[index] = support;
        updateSegmentSupports(segmentId, supports)
    }, [updateSegmentSupports, supports, segmentId])

    const onCreateSupportSuccess = useCallback((data: CreateStraightStairSupportMutation | null) =>
    {
        const support = data?.createStraightStairSupport
        if (support)
        {
            supports.push(support)
            updateSegmentSupports(segmentId, supports)
        }
    }, [updateSegmentSupports, supports, segmentId])

    const addSupport = useCallback(async () =>
    {
        if (!segmentId)
        {
            return
        }

        return await createSupportMutation({variables: {segmentId: segmentId}},
            {onSuccess: onCreateSupportSuccess})
    }, [createSupportMutation, onCreateSupportSuccess, segmentId])

    const onDeleteSupportSuccess = useCallback((id: string) =>
    {
        updateSegmentSupports(segmentId, supports.filter((e) => e.id !== id))
    }, [updateSegmentSupports, supports, segmentId])

    const deleteSupport = useCallback(async (id: string) =>
    {
        await deleteSupportMutation({variables: {id: id}},
            {onSuccess: () => onDeleteSupportSuccess(id)})
    }, [deleteSupportMutation, onDeleteSupportSuccess])

    const getSupportMaterialTypes: GetSupportMaterialTypeFunc = useCallback((length, type) =>
    {
        const list: StraightStairSupportMaterialType[] = []

        if (type === StraightStairSupportType.Pole)
        {
            if (length <= 4000)
            {
                list.push(StraightStairSupportMaterialType.Material70)
            }
            if (length <= 6000)
            {
                list.push(StraightStairSupportMaterialType.Material80)
            }
            list.push(StraightStairSupportMaterialType.Material100)
            return list
        } else
        {
            if (length <= 1600)
            {
                list.push(StraightStairSupportMaterialType.Material70)
            }
            if (length <= 1800)
            {
                list.push(StraightStairSupportMaterialType.Material80)
            }
            list.push(StraightStairSupportMaterialType.Material100)
        }
        return list
    }, [])

    return {
        addSupport, deleteSupport, updateSupport,
        addSupportLoading, deleteSupportLoading,
        getSupportMaterialTypes
    }
}

export default useSupportHook;