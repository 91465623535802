import StdQuery from "../../StdQuery";
import getFilesQuery from "../../../workflow-forms/DrawJob/graphql/GetFiles.graphql";
import {filter} from "lodash";
import {
    CustomerDocCategoryType,
    DrawingsCategoryType,
    ProductionDrawingsCategoryType
} from "../../file/UploadFile";
import React from "react";
import FileList from "../../file/FileList";
import {useLocale} from "../../../LocaleProvider";

const CustomerAndDrawingFileList = ({purchaseOrderId}: { purchaseOrderId: string }) =>
{
    const {t} = useLocale()
    return <>
        <h4>{t('All customer documentation')}</h4>
        <StdQuery query={getFilesQuery} variables={{purchaseOrderId}}>
            {data =>
            {
                const drawings = filter(data.files, file => file.fileCategory.id === DrawingsCategoryType.id || file.fileCategory.id === ProductionDrawingsCategoryType.id);
                const customerDocumentation = filter(data.files, file => file.fileCategory.id === CustomerDocCategoryType.id);
                return <React.Fragment>
                    <h5>{t('Customer documentation files')}</h5>
                    <FileList files={customerDocumentation}/>
                    <h5>{t('Uploaded drawing files')}</h5>
                    <FileList files={drawings}
                              onDelete={() => {}}/>
                </React.Fragment>
            }
            }
        </StdQuery>
    </>
}

export default CustomerAndDrawingFileList;