import FileSaver from 'file-saver';
import gql from 'graphql-tag';
import _, {each,find} from 'lodash';
import Auth from "./Auth";
import currencyFormatter from 'currency-formatter';
import logger from "./Logger";
import config from "./config";
import Select from 'react-select';
import {Badge} from "reactstrap";
import StdQuery from "./common/StdQuery";
import {selectRenderer} from "./common/FormRenderer";
import {CreateContact, ProjectPicker} from "./quote/QuoteDetailPage";
import {useMutation} from "@apollo/client";
import NotificationPopup from "./common/lib/NotificationPopup";
import React from "react";
import {format} from "date-fns-tz";
import {utcToZonedTime} from "date-fns-tz";
import {URL_PURCHASE_ORDER_INVOICE} from "./Constants";

const searchQuery = gql`
query search($term:String!){
   searchCompanies(term:$term)
   {
     id
     name
   }
}

`;


export const useUploadFiles = ()=>
{
    const [noopIt] = useMutation(gql`mutation noop{noop}`,
    {refetchQueries:["getOrder","getFiles","quote", "getSupplier", "getPurchaseOrder"],});
    return async (files,metadata)=>
    {
        const formData = new FormData();
        const token = Auth.getToken();
        const authorizationHeader = token ? `bearer ${token}` : null ;
        if(!files)
        {
            throw new Error(`No files in call to upload files`);
        }
        each(files,file=>
        {
            formData.append("file",file,file.name)
        });

        each(metadata,(val,key)=>{
            if(val)
            {
                formData.append(key,val);
            }
        });

        const res = await fetch(
        `${config.BACKEND_URI}upload`,
        {
            method: "POST",
            headers: {
                Authorization: authorizationHeader,
            },
            body: formData,
        });
        await noopIt();
        if(res.ok)
        {
            return (await res.json()).createdFiles;
        }
        else
        {
            console.error(res);
            NotificationPopup.error(`Failed to upload files. ${res.status} ${res.statusText}`);
            throw new Error(`Failed to upload files`);
        }
    }

}

export const companySelectOptions = (client) => {
    return async function(input){
        const res = await client.query({
            query: searchQuery,
            variables: {
                term: input
            }
        });
        return res.data.searchCompanies.map((company) => {
            return {value: company.id, label: company.name};
        });
    };

};

export const mapSeries = async (map,fn)=>
{
    const newMap = [];
    await _.reduce(map,async (acc,n,key)=>
    {
        await acc;
        const mapped = await fn(n,key);
        newMap.push(mapped);
    },Promise.resolve());
    return newMap;
};


export const trimKeys = (obj,keys) =>
{
    if(!keys)
    {
        keys = [];
    }
    return _.omit(obj,["__typename","id"].concat(keys));
};

export const formatCurrency = (value)=>
{
    return currencyFormatter.format(value, {
        code: "SEK", precision: 2, thousand: " ", format:"%v"
    });
};

export const anyFilterCaseInsensitive = (filter,row) =>
{
    const id = filter.id;
    if(_.isArray(filter.value) && filter.value.length === 0)
    {
        return true;
    }
    if (row[id]){
        if(_.isArray(filter.value))
        {
            return _.some(filter.value,val => row[id].toString().toLowerCase().indexOf(val.toLowerCase())!==-1)
        }
        return row[id].toString().toLowerCase().indexOf(filter.value.toLowerCase())!==-1;
    }
    return false;

};

export const anyFilterCaseInsensitiveWithNull = (filter,row) =>
{
    const id = filter.id;
    if(filter.value === null)
    {
        return row[id] == null;
    }
    if (row[id]){
        return row[id].toString().toLowerCase().indexOf(filter.value.toLowerCase())!==-1;
    }
    return false;

};

export const filterCaseInsensitive = (filter,row) =>
{
    const id = filter.id;
    if (row[id]){
        return row[id].toString().toLowerCase().startsWith(filter.value.toLowerCase());
    }
    return false;

};

export const getFieldNamesFromFragment = (fragment) =>
{
    const def = fragment.definitions[0];
    return _.map(def.selectionSet.selections,(selection)=>
    {
        if(selection.kind !== 'Field')
        {
            throw new Error(`getFieldsFromFragment. Encountered selection that is not a field`);
        }
        return selection.name.value;
    })
};

export const filteredFieldNamesFromFragment = (fragment)=>
{
    const def = fragment.definitions[0];
    const res = [];
    _.forEach(def.selectionSet.selections,(selection)=>
    {
        if(selection.kind !== 'Field')
        {
            throw new Error(`getFieldsFromFragment. Encountered selection that is not a field`);
        }
        if(!selection.selectionSet)
        {
            res.push(selection.name.value);
        }

    });
    return res;

};

export const capitalizeFirstLetter =(string) =>{
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const labelize = (string) =>
{
    return capitalizeFirstLetter(string.replace(/([A-Z]+)/g, "$1").replace(/([A-Z][a-z])/g, " $1")).trim();
};


export const downloadFile = (id,filename)=>
{
    return _downloadFile(`${config.BACKEND_URI}file/${id}`,`${filename}`);
};

const _downloadFile = (url,filename, requestInit)=>
{
    const token = Auth.getToken();
    const authorizationHeader = token ? `bearer ${token}` : null ;
    return fetch(url,{
        ...requestInit,
        headers:new Headers({
            'Content-Type': 'application/json',
            authorization: authorizationHeader
        })
    }).then(async res =>
    {
        if(res.status !== 200 && res.status !== 201)
        {
            const json = await res.json();
            console.log(json);
            return Promise.reject(new Error(`${json.err}`));
        }
        return res.blob();
    }).then(blob =>
    {
        FileSaver.saveAs(blob,filename);
    }).catch(e=>
    {
        //TODO: add proper notification here
        logger.error(`Failed to download file`,{msg:e.message,stack:e.stack});
        NotificationPopup.error(`${url} ${filename} Failed to download file: ${e.message}`);
    })
};

const downloadPDF = (urlComponent,id,filename, isDisplayDeliveryDate)=>
{
    return _downloadFile(`${config.BACKEND_URI}pdf/${urlComponent}/${id}?isDisplayDeliveryDate=${isDisplayDeliveryDate ?? true}`,`${filename}.pdf`);
};

export const downloadDailyTaskListPdf = (date) =>
{
    return downloadPDF(`dailyTaskList`,date.toISOString(),`dailyTaskList-${date.toLocaleString()}`);
};

export const downloadPurchaseOrderPDF = (purchaseOrder)=>
{
    return downloadPDF("purchaseOrder",purchaseOrder.id,
    `PO-${purchaseOrder.number}-${purchaseOrder.supplier.name}`);
};

export const downloadOrderPDF = (id,filename, isDisplayDeliveryDate)=>
{
    return downloadPDF("order",id,filename, isDisplayDeliveryDate);
};

export const downloadCargoListPDF = (id,filename)=>
{
    return downloadPDF("cargoList",id,filename);
};

export const downloadCargoListPDFBySelectedArticleRow = (request, filename) => {
    const init = {
        method: "POST",
        body: JSON.stringify(request),
    }
    return _downloadFile(`${config.BACKEND_URI}pdf/cargoList/articleRows`, `${filename}.pdf`, init);
};

export const downloadInvoicePDF = (id,filename,invoiceNumber)=>
{
    return _downloadFile(`${config.BACKEND_URI}pdf/invoice/order/${id}/${invoiceNumber}`,`${filename}.pdf`);
};
export const downloadPurchaseOrderInvoicePDF = (id,filename)=>
{
    return _downloadFile(`${URL_PURCHASE_ORDER_INVOICE}${id}`,`${filename}.pdf`);
};

export const downloadCEDocumentByArticleRowId = (articleRowId, locale, filename) => {
    return _downloadFile(`${config.BACKEND_URI}pdf/ce-document/articleRow/${articleRowId}?locale=${locale}`, `${filename}.pdf`);
};

export const downloadCEDocumentByOrderId = (orderId, locale, filename) => {
    return _downloadFile(`${config.BACKEND_URI}pdf/ce-document/order/${orderId}?locale=${locale}`, `${filename}.pdf`);
};

export const downloadModuleRampPdf = (articleRowId, filename, image) => {
    const req = {
        method: "POST",
        body: JSON.stringify({image}),
    }
    return _downloadFile(`${config.BACKEND_URI}pdf/module-ramp/${articleRowId}`, `${filename}.pdf`, req);
};

export const OrderStatuses = [
    {id:`cancelled`,name:"Cancelled",color:"#999"},
    {id:`started`,name:"Started",color:"#007bff"},
    {id:"waitSuppliers",name:"Waiting for suppliers",color:"#C679F2"},
    {id:"waitDeliver",name:"Waiting to be delivered",color:"#F279DC"},
    {id:`delivered`,name:"Delivered",color:"#BAF279"},
    {id:'done',name:"Done",color:"#28a745"}
    ];
export const numberSortMethod = (a,b,desc)=>
{
    const aN = parseFloat(a ?? '0');
    const bN = parseFloat(b ?? '0');
    return aN - bN;
};

export const PurchaseOrderStatus = [
    {id:`started`,name:"Draft",color:"#007bff"},
    {id:`drawqueue`,name:"Drawqueue",color:"#ffc107"},
    {id:`inProduction`,name:"In production",color:"#6c757d"},
    {id:`sent`,name:"Submitted",color:"#ffc107"},
    {id:`ready`,name:"Ready for shipping",color:"#F279DC"},
    {id:'done',name:"Done",color:"#28a745"},
    {id:`cancelled`,name:"Cancelled",color:"#999"},
];

export const QuoteStatuses = [
    {id:"active",name:"Active",color:"#007bff"},
    {id:"lost",name:"Lost",color:"#ae1000"}
];

// '#F2EA79'
// > rColor().hexString()
// '#C679F2'
// > rColor().hexString()
// '#79F2A4'

// > rColor().hexString()
// '#7995F2'


export const filterSelect = (options)=>
{
    return ({filter,onChange}) =>
    {
        return (<Select options={options}
                        isMulti
                        closeMenuOnSelect={false}
                        styles={{container:(provided,state)=>
                            {
                                const {menuIsOpen} = state.selectProps;
                                return ({
                                    ...provided,
                                    ...!menuIsOpen?{maxHeight:"40px"}:{},
                                    ...!menuIsOpen?{overflowY:"scroll"}:{}
                                })
                            }

                        }}
                        getOptionLabel={({name}) => name}
                        getOptionValue={({id}) => id}
                        onChange={selected =>{
                            onChange(selected?selected.map(x=>x.id):null);
                        }}
                        value={_.filter(options,o=>filter && filter.value.indexOf(o.id)!==-1)}
        />)
    };
};

export const boldRenderer =({value})=><strong>{value}</strong>;

export const italicRenderer = ({value})=><p style={{fontStyle:"italic"}}>{value}</p>;




const statusColumn = (statusList)=>
{
    return {
        Header: "Status",
        accessor: "status",
        Filter:filterSelect(statusList),
        filterable:true,
        width:200,
        Cell:({value})=>
        {
            const status = find(statusList,s=>s.id===value);
            if(status)
            {
                return <Badge size='lg' style={{backgroundColor:status.color}}>{status.name}</Badge>
            }
            else
            {
                return null;
            }
        }
    }
};

export const ReactTableOrderStatus = statusColumn(OrderStatuses);

export const ReactTablePurchaseOrderStatus = statusColumn(PurchaseOrderStatus);

export const ReactTableQuoteStatus = statusColumn(QuoteStatuses);

export const camundaDateFix = (date)=>
{
    if(!date)
    {
        return null;
    }
    if(typeof(date)=='string')
    {
        date = new Date(date);
    }
    const string = date.toISOString();
    if(!string.includes('Z'))
    {
        throw new Error(`Couldn't fix string as there were no Z for timezone`);
    }
    return string.replace('Z',"+0000")
};

export const nextSegmentIndex = (segments)=>
{
    if(segments && segments.length)
    {
        return _.maxBy(segments,"index").index + 1;
    }
    else
    {
        return 0;
    }
};

export const idToName = (list,value)=>
{
    if(value == null)
    {
        return null;
    }
    return _.get(_.find(list,lr=>lr.id===value),"name");
};

const GetCompany = gql`
    query getCompany($id:ID!)
    {
        company(id:$id)
        {
            id
            name
            contacts
            {
                id
                name
                isActive
            }
        }
    }`;

export const ProjectIdFormDef = (obj)=>({
    name:"projectId",
    label: "Project name",
    type: 'custom', input: function (model, onChange)
    {
        return <ProjectPicker model={obj} onChange={onChange}/>
    }
});


export const ContactIdFormDef = {
    name: "contactId",
    required: true, type: 'custom', input: function (model, onChange) {
        if (model.companyId)
        {
            return <StdQuery query={GetCompany} variables={{id: model.companyId}}>
                {(data) => {
                    return <div>
                        <CreateContact companyId={model.companyId} onCreate={contact=>onChange(contact)} />
                        {selectRenderer({
                                required: true,
                                name: 'contactId',
                                options: data.company.contacts.filter((e) => e.isActive)
                                    .sort((a, b) => a.name.localeCompare(b.name))
                            },
                        model.contactId, onChange)}
                    </div>
                }}
            </StdQuery>

        }
        else
        {
            return <p>..</p>;
        }

    }
};

export const quoteTotal = (quote)=>
{
    return _.sumBy(quote.articleRows,ar=>ar.price*ar.quantity*((100-ar.discount)/100)) + quote.shippingCost + (quote.packagingCost || 0);
};
export const orderTotal = (order)=>
{
    return _.sumBy(_.filter(order.articleRows,({quantity,price})=>price && quantity),
    ar=> ar.price*ar.quantity*((100-ar.discount)/100)) + order.shippingCost + (order.packagingCost || 0);
};
export const poTotal = po=>
{
    return _.sumBy(po.articleRows,ar=>ar.price*ar.quantity*((100-ar.discount)/100));
};

export const extractMeaningFromError = (exception)=>
{
    try
    {
        const errors = _.map(exception.graphQLErrors,error=>_.get(error,`exception.errors`));
        return _.sumBy(errors,errors=>_.sumBy(errors,error=>
        {
            return error.message;
        }));
    }
    catch(e)
    {
        return exception && exception.message;
    }
};

export const intoCamundaVariables = variables =>
{
    const res = {};
    _.forEach(variables, (value, key) => {
        if(value == null)
        {
            return;
        }
        switch(typeof(value))
        {
            case "string":
                res[key] = {value:value,type:"String"};
                break;
            case "boolean":
                res[key] = {value:value,type:"Boolean"};
                break;
            case "number":
                if(Number.isInteger(value))
                {
                    res[key] = {value,type:"Integer"};
                }
                else
                {
                    res[key] = {value,type:"double"};
                }
                break;
            case "object":
                if(_.isDate(value))
                {
                    res[key] = {value:camundaDateFix(value),type:"Date"};
                }
                else
                {
                    res[key] = value;
                }
                break;
            case "undefined":
                throw new Error(`Value for key ${key} is undefined`);
            default:
                throw new Error(`Value for key ${key} is of unknown type ${typeof(value)}`);

        }
    });
    return res;
};


export const lookup = (obj, key) => {
    return key.split('.').reduce((o, k) => o && o[k], obj);
}


export const TIMEZONE_SWEDEN = 'Europe/Stockholm';
export const FORMAT_DATE = 'yyyy-MM-dd'
export const FORMAT_DATE_TIME = 'yyyy-MM-dd HH:mm'
export const FORMAT_TIME = 'HH:mm'
export const FORMAT_WEEK_NUMBER = 'w/yyyy'

export const formatToSwedishTime = (date, pattern = FORMAT_DATE_TIME) => {
    if (date == null) {
        return "";
    }

    const swedenDate = utcToZonedTime(date, TIMEZONE_SWEDEN);
    return format(swedenDate, pattern, {timeZone: TIMEZONE_SWEDEN});
}

export const downloadShipmentPdf = (id, number) => {
    return _downloadFile(`${config.BACKEND_URI}pdf/shipment/${id}`, `shipment-${number}.pdf`);
}

export const downloadVismaInvoicePdf = (id, number) => {
    return _downloadFile(`${config.BACKEND_URI}pdf/visma-invoice/${id}`, `invoice-${number}.pdf`);
}

export const camelToTitle = text => {
    const result = text.replace(/([A-Z])/g, " $1");
    const title = result.charAt(0).toUpperCase() + result.slice(1)
    return title.trim()
}

export const getArticleName = (article, locale) => {
    switch (locale) {
        case "sv":
            return article.name
        case "de":
            return article.nameDe
        case "no":
            return article.nameNo
        case "en":
            return article.nameEn
        default:
            return article.name
    }
}