import React, {useState} from "react";
import {GridFilterOperator, GridFilterItem} from "@mui/x-data-grid";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {TextField} from "@mui/material";
import {FILTER_BETWEEN} from "../../Constants";
import dayjs from "dayjs";

const CustomDatePicker = ({label, value, onChange}) =>
{
    return <DatePicker
        displayWeekNumber
        label={label}
        value={value}
        onChange={onChange}
        format="YYYY-MM-DD"
        slots={{textField: TextField}}
        slotProps={{textField: {variant: "outlined"}}}/>
}

const BetweenDateInput = (props) =>
{
    const {item, applyValue} = props;
    const [start, setStart] = useState(item.value?.start ? dayjs(item.value?.start) : null);
    const [end, setEnd] = useState(item.value?.end ? dayjs(item.value?.end) : null);


    const handleChange = (type: "start" | "end", newValue) =>
    {
        const date = newValue ? newValue.format("YYYY-MM-DD") : null
        const newItemValue = {...item.value, [type]: date};
        if (type === "start")
        {
            setStart(date)
        }
        if (type === "end")
        {
            setEnd(date)
        }
        applyValue({...item, value: newItemValue});
    };

    return <div style={{display: "flex", gap: "8px", flexDirection: "column"}}>
        <CustomDatePicker
            label="Start Date"
            value={start}
            onChange={(newValue) => handleChange("start", newValue)}/>
        <CustomDatePicker
            label="End Date"
            value={end}
            onChange={(newValue) => handleChange("end", newValue)}/>
    </div>
};

const DateBetweenFilter: GridFilterOperator = {
    label: FILTER_BETWEEN,
    value: FILTER_BETWEEN,
    getApplyFilterFn: (filterItem: GridFilterItem) =>
    {
        if (!filterItem.value || !filterItem.value.start || !filterItem.value.end)
        {
            return null;
        }
        const {start, end} = filterItem.value;

        return (params) =>
        {
            const dateValue = params.value ? new Date(params.value) : null;
            if (!dateValue) return false;

            return dateValue >= new Date(start) && dateValue <= new Date(end);
        };
    },
    InputComponent: BetweenDateInput,
};

export default DateBetweenFilter