import FormRenderer from "../../common/FormRenderer";
import {downloadInvoicePDF} from "../../UTIL";
import React from 'react';
import {Modal, ModalBody,  ModalHeader} from "reactstrap";

export default ({order,isOpen,toggle})=>
{
    return <div>
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                Invoice document
            </ModalHeader>
            <ModalBody>
                <FormRenderer submitButtonText={"Download"} formDefinition={[{name:"invoiceNumber",}]} onSubmit={async(form)=>{
                    await downloadInvoicePDF(order.id,`${order.number}-${order.company.name}-rechnung`,form.object.invoiceNumber);
                    toggle();
                }}/>
            </ModalBody>
        </Modal>
    </div>
}
