import React, {useCallback, useEffect, useState} from 'react';
import {camundaDateFix} from "../../UTIL";
import OrderInfo from "../../common/task-workflow/OrderInfo";
import {Button, Form, FormGroup, Input, Label, Spinner} from "reactstrap";
import {format} from 'date-fns';
import {useGetOrderQuery} from "../../generated/graphql";

export default ({onSubmit, variables}) => {
    const [submitting, setSubmitting] = useState(false);
    const [invoiceNumberAndDate, setInvoiceNumberAndDate] = useState(null)
    const [inputDisabled, setInputDisabled] = useState(false)
    const {data, loading, error} = useGetOrderQuery({variables: {id: variables.orderId}})

    const formSubmit = useCallback(async (event, invoiceNumberAndDate) => {
        event.preventDefault();
        event.stopPropagation();

        if (!invoiceNumberAndDate || !invoiceNumberAndDate.invoiceDate || !invoiceNumberAndDate.invoiceNumber) {
            alert(`Please select invoice date and number`)
            return
        }

        if (submitting) {
            alert("Please wait for submitting.");
            return;
        }
        setSubmitting(true);
        onSubmit(
            {
                invoiceDate: {type: "Date", value: camundaDateFix(invoiceNumberAndDate.invoiceDate)},
                invoiceNumber: {type: "String", value: invoiceNumberAndDate.invoiceNumber}
            })
        setSubmitting(false);
    }, [onSubmit, submitting, setSubmitting]);

    const formInvoices = (order) => {
        return <Form onSubmit={async (event) => await formSubmit(event, invoiceNumberAndDate)}>
            {
                order.invoices?.map((e) => radioInvoice(e))
            }
            {radioOther(order.dateOfDelivery)}
            <br/>
            <Button color="success">Submit</Button>
        </Form>
    }

    const radioInvoice = (invoice) => {
        const {invoiceCreatedDateTime, referenceNumber} = invoice
        return <FormGroup check>
            <Input id={referenceNumber}
                   name="radioInvoice" type="radio"
                   onChange={_ => {
                       setInputDisabled(true)
                       handleOnChange(invoiceCreatedDateTime, referenceNumber)
                   }}/>
            <Label for={referenceNumber}>
                {`${format(new Date(invoiceCreatedDateTime), 'yyyy-MM-dd')} ${referenceNumber}`}
            </Label>
        </FormGroup>
    }

    const radioOther = (dateOfDelivery) => {
        return <FormGroup check>
            <Input id='other' type="radio" name="radioInvoice" defaultChecked={true} onChange={_ => {
                setInputDisabled(false)
                handleOnChange(null, null)
            }}/>
            <Label for='other'>
                other
            </Label>
            <br/>

            <Label id='invoiceDate'>
                Invoice Date(required)
            </Label>
            <Input id='invoiceDate'
                   type='date'
                   disabled={inputDisabled}
                   value={invoiceNumberAndDate?.invoiceDate}
                   onChange={e => handleOnChange(e.target.value, invoiceNumberAndDate?.invoiceNumber)}>
            </Input>

            <Label id='invoiceNumber'>
                Invoice Number(required)
            </Label>
            <Input id='invoiceNumber'
                   disabled={inputDisabled}
                   onChange={e => handleOnChange(invoiceNumberAndDate?.invoiceDate, e.target.value)}>
                invoiceNumber
            </Input>
        </FormGroup>
    }

    const handleOnChange = (date, number) => {
        setInvoiceNumberAndDate({
                invoiceDate: date,
                invoiceNumber: number
            }
        )
    }

    useEffect(() => {
        if (!data) {
            return
        }

        const dateOfDelivery = data.order?.dateOfDelivery
        if (!dateOfDelivery) {
            return null
        }

        setInvoiceNumberAndDate({
            invoiceDate: format(new Date(dateOfDelivery), 'yyyy-MM-dd')
        })
    }, [data])

    return <div>
        <OrderInfo orderId={variables.orderId}/>
        <h2>Input data for invoice</h2>
        {loading ?? <Spinner/>}
        {data && formInvoices(data.order)}
        {error && <p>{error}</p>}
    </div>
}
