import {useCallback, useEffect, useState} from "react";
import {
    Button,
    Modal,
    Table,
    Form,
    Row,
    Col,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label,
    Input
} from "reactstrap";
import React from 'react';
import gql from "graphql-tag";
import {formatCurrency} from "../../UTIL";
import {useLazyQuery} from "@apollo/client";
import {useStdMutation} from "../../spiralStair/lib/Util";
import {withRouter} from "react-router";
import Picker from "../../common/Picker";
import {DEFAULT_OPTIONS, unitOptions} from "./ArticleRows";
import {useLocale} from "../../LocaleProvider";

const getArticleQuery = gql`
    query($id:ID!)
    {
        article(id:$id)
        {
            name
            id
            purchasePrice
            purchaseCurrency
            quantity
        }
    }

`


const searchArticlesQuery = gql`
    query searchArticles($term:String!, $tenantId: TenantId!)
    {
        searchArticle(term: $term, tenantId: $tenantId)
        {
            id
            name
            artNo
        }
    }`;


const ArticleData = ({article}) => {
    const {quantity: articleQuantity, purchasePrice, purchaseCurrency} = article;
    return <div>
        <Table size="sm">
            <thead>
            <tr>
                <th>Quantity</th>
                <th>Default Purchase price</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>
                    {articleQuantity}
                </td>
                <td>
                    {formatCurrency(purchasePrice)} {purchaseCurrency}
                </td>
            </tr>
            </tbody>
        </Table>


    </div>
}

const CreateArticleRow = (props) => {
    const {currency, tenantId} = props
    const [open, setOpen] = useState();
    const [price, setPrice] = useState(0);
    const [quantity, setQuantity] = useState(0);
    const [unit, setUnit] = useState(DEFAULT_OPTIONS);
    const [getArticleData, {data: articleData}] = useLazyQuery(getArticleQuery);
    const {params} = props.match
    const [article, setArticle] = useState(null);
    const {t} = useLocale()

    const purchaseOrderId = parseInt(params.id);
    const updateArticleData = useCallback(async (article) => {
        await getArticleData({
            variables: {
                id: article.value
            }
        });
    }, [getArticleData]);
    useEffect(() => {
        if (article) {
            updateArticleData(article);
        }

    }, [article, updateArticleData]);
    useEffect(() => {
        if (articleData) {
            setPrice(articleData?.article.purchasePrice);
        }
    }, [articleData, setPrice]);
    const [create] = useStdMutation("Create article row", gql`
        mutation createArticleRow($ar:ArticleRowCreateInputType!)
        {
            createArticleRow(articleRow: $ar)
            {
                id
            }
        }`, {refetchQueries: ["getInventoryPurchaseOrder"]});

    const toggle = () => setOpen(o => !o);

    return <div>
        <Modal isOpen={open} toggle={toggle}>
            <Form onSubmit={async (e) => {
                e.preventDefault();
                await create({
                    variables: {
                        ar: {
                            articleId: parseInt(article.value), purchaseOrderId,
                            quantity: parseFloat(quantity), price: parseFloat(price),
                            unit: unit,
                        }
                    }
                });
                setArticle(null);
                setPrice(0);
                setQuantity(0);
                setUnit(DEFAULT_OPTIONS)
                toggle();
            }}>
                <ModalHeader>{t('Create Article Row')}</ModalHeader>
                <ModalBody>
                    <Picker query={searchArticlesQuery}
                            mapFn={article => ({
                                value: article.id,
                                label: `${article.artNo} - ${article.name}`,
                            })}
                            onChange={selected => setArticle(selected)}
                            tenantId={tenantId}/>
                    {(articleData && article) && <ArticleData article={articleData.article}/>}
                    {(articleData && article) && <div>
                        <Row>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label>{t('Quantity')}</Label>
                                    <Input type={"number"} lang={"en-150"} min={0} step={0.01} value={quantity}
                                           required onChange={e => setQuantity(e.target.value)}/>
                                </FormGroup>
                                <p>Total: {formatCurrency(price * quantity)} {currency}</p>
                            </Col>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label>{t('Price')}</Label>
                                    <div>
                                        <Input type={"number"} lang={"en-150"} value={price} required
                                               onChange={e => setPrice(e.target.value)}/>
                                        <small>{formatCurrency(price)}</small>
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label>{t('Unit')}</Label>
                                    <Input type={"select"} lang={"en-150"} value={unit} name={"unit"}
                                           onChange={e => setUnit(e.target.value)}>
                                        {unitOptions.map((e) => <option>{e}</option>)}
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>}
                </ModalBody>
                <ModalFooter>
                    <Button type="submit" color={"success"}>{t('Submit')}</Button>
                    <Button onClick={toggle}>{t('Cancel')}</Button>
                </ModalFooter>
            </Form>
        </Modal>
        <Button color="primary" onClick={() => setOpen(true)}>{t('Create Article Row')}</Button>
    </div>
};

export default withRouter(CreateArticleRow)