import StdQuery from "../StdQuery";
import GetOrder from "../../order/graphql/GetOrder.graphql";
import React from "react";
import OpenInNewTabIcon from "../OpenInNewTabIcon";
import {FORMAT_DATE, formatToSwedishTime} from "../../UTIL";
import BoldTitleWithMessage from "common/BoldTitleWithMessage";
import {useLocale} from "../../LocaleProvider";


const OrderInfoPanel = ({order}) =>
{
    const {id, number} = order;
    const {t} = useLocale()

    return <div>
        <h3>{t('Order')} {number}
            <OpenInNewTabIcon to={`/orderDetail/${id}`}/>
        </h3>
        <BoldTitleWithMessage title={`${t('Company')}:`} message={order?.company?.name}/>
        <BoldTitleWithMessage title={`${t('Project')}:`} message={order?.project?.name}/>
        <BoldTitleWithMessage title={`${t('Preferred delivery date')}:`} message={formatToSwedishTime(order?.preferredDeliveryDate, FORMAT_DATE)}/>
        <hr/>
    </div>
};

const OrderInfo = ({order, orderId}: { order?: any, orderId?: string }) =>
{
    if (order)
    {
        return <OrderInfoPanel order={order}/>
    }
    return <StdQuery query={GetOrder} variables={{id: orderId}}>
        {({order}) => order ? <OrderInfoPanel order={order}/> : "Failed to find order"}
    </StdQuery>
};

export default OrderInfo;