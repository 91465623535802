import React from 'react';
import ColumnId from "../../common/data-grid/columns/ColumnId";
import NonPaginatedDataGrid from "../../common/data-grid/NonPaginatedDataGrid";
import ColumnContactName from "../../common/data-grid/columns/ColumnContactName";
import ColumnEmail from "../../common/data-grid/columns/ColumnEmail";

const TABLE_KEY = "CONTACTS_SUB_PANEL"

const columns = () => {
    return [
        ColumnContactName(),
        {
            field: 'workPhone',
            headerName: 'Work phone',
            searchable: true,
        },
        {
            field: 'mobilePhone',
            headerName: 'Mobile phone',
            searchable: true,
        },
        ColumnEmail(),
        ColumnId(),
        {
            field: 'isActive',
            headerName: "Active",
            type: "boolean"
        },
    ]
}

export default ({contacts}) => {
    return (
        <NonPaginatedDataGrid definition={{
            tableKey: TABLE_KEY,
            columns: columns(),
            initState: {
                columns: {columnVisibilityModel: {id: false}}
            }
        }} data={contacts}/>
    )
}
