import {Badge} from "reactstrap";
import React from "react";

export const InvoiceStatusBadge = ({invoiceStatus}: { invoiceStatus: string }) =>
{
    let color;
    let text;

    switch (invoiceStatus)
    {
        case 'Open':
            color = 'warning';
            text = 'Not paid';
            break;
        case 'Closed':
            color = 'success';
            text = 'Paid';
            break;
        default:
            color = 'secondary';
            text = invoiceStatus;
    }

    return <Badge color={color} style={{marginLeft: '8px'}}>{text}</Badge>
}