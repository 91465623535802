import React, {Component,useState} from 'react';
import {Button, Col, Row, Input, Label, Table} from "reactstrap";
import ModalForm from "../common/ModalForm";
import Picker from "../common/Picker";
import gql from 'graphql-tag';
import {useMutation, useQuery} from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';
import _, {flowRight as compose,reduce,get} from 'lodash';
import ContactsSubPanel from './component/ContactsSubPanel';
import QuotesSubPanel from "../common/subpanels/QuotesSubPanel";
import GetCompanyQuery from './graphql/GetCompany.graphql';
import GetCompanyQuotes from './graphql/GetCompanyQuotes.graphql';
import AddressCard from './component/AddressCard';
import Collapser from "../common/Collapser";
import CreateQuote from "../common/CreateQuote";
import DetailRenderer from "../common/DetailRenderer";
import EditableDetailRenderer from "../common/EditableDetailRenderer";
import StdQuery from "../common/StdQuery";
import OrderSubPanel from "../order/OrderSubPanel";
import CreditRatingInfoBox from "./component/CreditRatingInfoBox";
import UpdateCompanyMutation from './graphql/UpdateCompany.graphql';
import CompanyFormDefinition from './CompanyFormDefinition';
import CreateTask from "../common/CreateTask";
import { formatCurrency } from '../UTIL';
import NotificationPopup from "../common/lib/NotificationPopup";
import GetStockArticle from '../common/graphql/GetStockArticle.graphql';
import CreditLimitInfoBox from "./component/CreditLimitInfoBox";
import {TenantData} from "../common/lib/TenantData";

const GetContacts = gql`
query getCompanyContacts($id:ID!)
{
    company(id:$id)
    {
        id
        contacts {
            id
            name
            email
            workPhone
            mobilePhone
            title
            department
            isActive
        }
    }
}`;

const GetOrders = gql`
query getCompanyOrders($id:ID!)
{
    company(id:$id)
    {
        id
        orders
        {
            id
            status
            dateOfDelivery
            orderSum
            {
                currency
                totalNoVAT
            }
            project
            {
                id
                name
            }
            projectStage
            assignee
            {
                displayName
                username
            }
            createdAt
            number
            preferredDeliveryDate
        }
    }
}`;


// const GetOldQuotes = gql`
// query getOldQuotes($id:ID!)
// {
//     company(id:$id)
//     {
//         id
//         oldQuotes
//         {
//             id
//             link
//             currency
//             quoteNumber
//             ownerName
//             status
//             {
//                 id
//                 name
//             }
//             price
//             createdAt
//             project
//             {
//                 id
//                 name
//             }
//             contact
//             {
//                 id
//                 name
//             }
//
//         }
//     }
// }`

const GetCompanyStats = gql`
query getCompanyStats($id:ID!)
{
    companyStats(id:$id)
    {
        thirtyDaysOrders
        sixtyDaysOrders
        ninetyDaysOrders
        ytdOrders
        thirtyDaysQuotes
        sixtyDaysQuotes
        ninetyDaysQuotes
        ytdQuotes
        thirtyDaysOrdersForecast
        sixtyDaysOrdersForecast
        ninetyDaysOrdersForecast
        afterNinetyDaysOrdersForecast
    }
    company(id:$id)
    {
        creditRatingLimitCurrency
        tenantId
    }
}`;

const createOrUpdateOnlinePriceMutation = gql`
mutation createOrUpdateOnlinePriceList($onlinePriceList:OnlinePriceListInputType)
{
    createOrUpdateOnlinePriceList(onlinePriceList:$onlinePriceList) {
        id
    }
}`;

const deleteOnlinePriceMutation = gql`
mutation deleteOnlinePriceList($id:ID!)
{
    deleteOnlinePriceList(id:$id)
}`;

const GetOnlinePrices = gql`
query getOnlinePrices($id:ID!)
{
    onlinePriceList(id:$id)
    {
        id
        stockArticleArtNo
        currency
        price
        stockArticle {
            name
        }
    }
}`;

const CreateOnlinePrice = ({id,toggle, refetch})=>
{
    const [stockArticle,setStockArticle] = useState(null);
    const [newPrice,setNewPrice] = useState(1);
    const [createOrUpdateOnlinePrice] = useMutation(createOrUpdateOnlinePriceMutation);
    return <ModalForm title={"New online price"} activationButtonText={"New online price"}>
        {toggle=>
        {
            return <div>
                <Picker mapFn={({name,id,artNo})=>({value:id,label:`${artNo} - ${name}`})} query={gql`
                query($term:String!)
{
Inventory_searchWebshopArticles(term: $term)
{
    id
    name
    artNo
}
}`} types={["stockArticle"]} onChange={
                    (selected)=>
                setStockArticle(selected)
                } />


                {stockArticle && <StdQuery query={GetStockArticle} variables={{id:stockArticle.value}} >
                    {data =>
                    {
                        if(!data.stockArticle)
                        {
                            return "Failed to fetch this stock article :(";
                        }
                        else
                        {
                            const { artNo, price, internalPrice, internalCurrency, currency } = data.stockArticle;
                            return <div>
                                <DetailRenderer hideUpdateInfo columns={2} object={{
                                    artNo,
                                    inPrice: `${internalPrice} ${internalCurrency}`,
                                    sellingPrice: `${price} ${currency}`
                                }} />
                                <Label>
                                    Set online price
                                </Label>
                                <Input type="number" value={newPrice} onChange={e=>setNewPrice(e.target.value)} />
                                <p> {currency} </p>
                                <br/>
                                <Button style={{float:"right"}} color={"success"} onClick={async()=>
                                {
                                    await createOrUpdateOnlinePrice({
                                        variables: {
                                            onlinePriceList: {
                                                companyId: parseInt(id),
                                                price: parseFloat(newPrice),
                                                currency: currency,
                                                stockArticleId: parseInt(data.stockArticle.id)
                                            }
                                        }
                                    });
                                    toggle();
                                    setStockArticle(null);
                                    refetch();
                                }}>
                                    Create online price
                                </Button>
                            </div>
                        }
                    }}
                </StdQuery>}
            </div>;
        }}
    </ModalForm>
};

const DeleteCompanyPrice = ({id,refetch}) =>
{
    const [deleteOnlinePrice] = useMutation(deleteOnlinePriceMutation);
    return <span style={{textAlign:'center',cursor:'pointer'}} title="Delete" onClick={async() => {
        if(window.confirm(`Are you sure you want to delete this price?`)) {
            console.log(id);
            await deleteOnlinePrice({
                variables: {
                    id
                }
            });
            refetch();
        }
    }}>❌</span>;
}

const OnlinePrices = ({id})=>
{
    const [onlinePriceModalOpen,toggleOnlinePriceModal] = useState(false);
    const {data, loading, error, refetch} = useQuery(GetOnlinePrices, {variables: {id:id}});
    if(loading) {
        return <p>Loading..</p>;
    }
    if(error) {
        console.error(error);
        return null;
    }
    const styleCenter = {textAlign: 'center'}
    return <Collapser label={"Show online prices"}>
        <Row>
            <Col sm={3}>
                <h2>Online prices</h2>
            </Col>
            <Col sm={2}>
                <CreateOnlinePrice refetch={refetch} id={id} isOpen={onlinePriceModalOpen}
                                   toggle={toggleOnlinePriceModal}/>
            </Col>
        </Row>
        <Table striped highlight responsive bordered>
            <thead>
            <tr>
                <th style={styleCenter}>Art No</th>
                <th style={styleCenter}>Name</th>
                <th style={styleCenter}>Currency</th>
                <th style={styleCenter}>Price</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            {data.onlinePriceList.map((item, index) => (
                <tr key={index}>
                    <td style={styleCenter}>{item.stockArticleArtNo}</td>
                    <td style={styleCenter}>{item.stockArticle.name}</td>
                    <td style={styleCenter}>{item.currency}</td>
                    <td style={styleCenter}>{formatCurrency(item.price)}</td>
                    <td><DeleteCompanyPrice id={item.id} refetch={refetch}/></td>
                </tr>
            ))}
            </tbody>
        </Table>
    </Collapser>
}

const Stats = ({id})=>
{
    const {data, loading, error} = useQuery(GetCompanyStats, {variables: {id:id}});
    if(loading) {
        return <p>Loading..</p>;
    }
    if(error) {
        console.error(error);
        return null;
    }

    const currency = TenantData[data.company.tenantId]?.defaultCurrency || "SEK";
    const deliveredOrdersTableData = [
        {
            type: `Orders delivered total ${currency}`,
            within30D: data.companyStats.thirtyDaysOrders,
            within60D: data.companyStats.sixtyDaysOrders,
            within90D: data.companyStats.ninetyDaysOrders,
            YTD: data.companyStats.ytdOrders
        },
        {
            type: `Active quotes ${currency}`,
            within30D: data.companyStats.thirtyDaysQuotes,
            within60D: data.companyStats.sixtyDaysQuotes,
            within90D: data.companyStats.ninetyDaysQuotes,
            YTD: data.companyStats.ytdQuotes
        },
    ];
    const undeliveredOrdersTableData = [
        {
            type: `Orders undelivered total ${currency}`,
            within30D: data.companyStats.thirtyDaysOrdersForecast,
            within60D: data.companyStats.sixtyDaysOrdersForecast,
            within90D: data.companyStats.ninetyDaysOrdersForecast,
            after90D: data.companyStats.afterNinetyDaysOrdersForecast,
        },
    ];

    const styleCenter = {textAlign: 'center'}

    return <>
        <Collapser label={"Show stats"} open>
            <Row>
                <Col sm={4}>
                    <h2>Delivered Orders</h2>
                    <span style={{"color": "gray"}}>This data is updated hourly</span>
                </Col>
            </Row>
            <Table striped highlight responsive bordered>
                <thead>
                <tr>
                    <th></th>
                    <th style={styleCenter}>within 30 days</th>
                    <th style={styleCenter}>31 - 60 days</th>
                    <th style={styleCenter}>61 - 90 days</th>
                    <th style={styleCenter}>YTD</th>
                </tr>
                </thead>
                <tbody>
                {deliveredOrdersTableData.map((row, index) => (
                    <tr key={index}>
                        <td>{row.type}</td>
                        <td style={styleCenter}>{formatCurrency(row.within30D)}</td>
                        <td style={styleCenter}>{formatCurrency(row.within60D)}</td>
                        <td style={styleCenter}>{formatCurrency(row.within90D)}</td>
                        <td style={styleCenter}>{formatCurrency(row.YTD)}</td>
                    </tr>
                ))}
                </tbody>
            </Table>
        </Collapser>
        <Collapser label={"Show stats"} open>
            <Row>
                <Col sm={4}>
                    <h2>Order Forecast</h2>
                    <span style={{"color":"gray"}}>This data is updated hourly</span>
                </Col>
            </Row>
            <Table striped highlight responsive bordered>
                <thead>
                <tr>
                    <th></th>
                    <th style={styleCenter}>30 days or earlier</th>
                    <th style={styleCenter}>31 - 60 days</th>
                    <th style={styleCenter}>61 - 90 days</th>
                    <th style={styleCenter}>after 90 days</th>
                </tr>
                </thead>
                <tbody>
                {undeliveredOrdersTableData.map((row, index) => (
                    <tr key={index}>
                        <td>{row.type}</td>
                        <td style={styleCenter}>{formatCurrency(row.within30D)}</td>
                        <td style={styleCenter}>{formatCurrency(row.within60D)}</td>
                        <td style={styleCenter}>{formatCurrency(row.within90D)}</td>
                        <td style={styleCenter}>{formatCurrency(row.after90D)}</td>
                    </tr>
                ))}
                </tbody>
            </Table>
        </Collapser>
    </>;
}

const Orders = ({id})=>
{
    return <Collapser label={"Show orders"} open>
        <Row>
            <Col sm={2}>
                <h2>Orders</h2>
            </Col>
        </Row>
        <Row>
            <Col>
                <StdQuery query={GetOrders} variables={{id}}>
                    {data =>
                    {
                        if (!data.company)
                        {
                            return `Failed to find company by id: ${id}`;
                        }
                        const {orders} = data.company;
                        return <OrderSubPanel orders={orders} />
                    }}
                </StdQuery>
            </Col>
        </Row>
    </Collapser>
};

const Details = ({company}) =>
{
    const [updateCompany] = useMutation(UpdateCompanyMutation, {
        refetchQueries: ["getCompany"]
    });
    const {id,organisationNumber,companyType,industry,
        productInterests,classification,website,phone,email,defaultTermsOfDelivery,
    defaultTermsOfPayment,defaultModeOfDelivery,description,name,locale,assignee,customerReferenceNumberRequired} = company;
    // const {locale,creator,assignee} = company;
    return (
      <EditableDetailRenderer
        formDefinition={CompanyFormDefinition(company)}
        object={{
          customerNumber: id,
          name,
          classification,
          defaultTermsOfDelivery,
          organisationNumber,
          website,
          defaultModeOfDelivery,
          companyType,
          phone,
          defaultTermsOfPayment,
          industry,
          email,
          description,
          productInterests,
          locale,
          assignee,
            customerReferenceNumberRequired
        }}
        columns={3}
        onSubmit={async ({ object }) => {
          try {
            await updateCompany({
              variables: {
                id: company.id,
                company: object,
              },
            });
          } catch (e) {
            let msg = `Failed to update company. `;
            if (e.graphQLErrors) {
              msg += reduce(
                e.graphQLErrors,
                (acc, error) => `${acc} ${error.message}`,
                ""
              );
            }
            const isUniqueError =
              get(e, "graphQLErrors[0].extensions.exception.name") ===
              "SequelizeUniqueConstraintError";
            if (isUniqueError) {
              NotificationPopup.error(
                `A company with this organisation number already exists..`
              );
            } else {
              NotificationPopup.error(msg);
            }
          }
        }}
        extra={
          <DetailRenderer
            object={_.pick(company, [
              "locale",
              "creator",
              "assignee",
              "createdAt",
              "updatedAt",
            ])}
          />
        }
      />
    );
};

class CompanyDetail extends Component
{

    constructor(props) {
        super();
        this.state = {
            taskModalOpen: false
        }
    }

    async removeCompany(company)
    {
        if(window.confirm(`Are you sure you want to remove ${company.name}?`))
        {
            await this.props.mutate({id:this.id});
            this.props.history.push("/companies");
        }
    }

    render()
    {
        const data = this.props.data;
        const toggleTaskModal = ()=>this.setState({taskModalOpen: !this.state.taskModalOpen});

        const quoteId = parseInt(this.props.match.params.id);
        if(data.loading)
        {
            return (<p>Loading..</p>);
        }
        else if(data.error)
        {
            console.error(data.error);
            return (<p>Error!</p>);
        }
        const company = data.company;
        return (
            <div>
                <Row>
                    <Col sm={10}>
                        <h1>{company.name} <CreateQuote company={company} button={(onClick)=>
                        <Button onClick={onClick} color="primary">Create Quote</Button>}   />&nbsp;
                        <CreateTask isOpen={this.state.taskModalOpen} toggle={toggleTaskModal} variables={{companyId:parseInt(company.id)}} />
                        <Button onClick={()=>toggleTaskModal()} color="primary">Create Task</Button></h1>
                        <hr/>
                    </Col>
                    <Col sm={2}>
                        <Button color='secondary' onClick={this.removeCompany.bind(this,company)}>Remove</Button>
                    </Col>

                </Row>
                <Row>
                    <Col sm={5}>
                        <Details company={company} />
                    </Col>
                    <Col sm={3}>
                        <AddressCard company={company}/>
                    </Col>
                    <Col sm={4}>
                        <CreditRatingInfoBox company={company} />
                        <CreditLimitInfoBox company={company} />
                    </Col>
                </Row>
                <Stats id={company.id} />
                <hr/>
                <Collapser label={"Show contacts"} open>
                    <Row>
                        <Col sm={2}>
                            <h2>Contacts</h2>
                        </Col>
                        <Col sm={2}>
                            <Link to={`/createContact/${company.id}/${encodeURIComponent(company.name)}`}>
                                <Button size='sm'>Create contact..</Button>
                            </Link>
                        </Col>
                    </Row>
                    <StdQuery query={GetContacts} variables={{id: company.id}}>
                        {data =>
                        {
                            return <ContactsSubPanel contacts={data.company.contacts}/>
                        }}
                    </StdQuery>
                </Collapser>
                <hr/>
                <OnlinePrices id={company.id} />
                <hr/>
                <Orders id={company.id} />
                <Collapser label={"Show quotes"} open>
                    <Row>
                        <Col sm={2}>
                            <h2>Quotes</h2>
                        </Col>
                        <Col sm={2}>
                            <CreateQuote company={company} refetchQuery={"getCompanyQuotes"} />
                        </Col>
                    </Row>
                    <StdQuery query={GetCompanyQuotes} variables={{id:quoteId}}>
                        {data=>
                        {
                            return <QuotesSubPanel quotes={_.get(data,"company.quotes")}  noCompanyName />
                        }}
                    </StdQuery>

                </Collapser>
                <hr/>
                {/*<Collapser label={"Show configurator quotes"} open>*/}
                {/*    <h2>Configurator Quotes</h2>*/}
                {/*    <StdQuery query={GetOldQuotes} variables={{id:company.id}} >*/}
                {/*        {data=>*/}
                {/*        {*/}
                {/*            if(!data.company)*/}
                {/*            {*/}
                {/*                return `Failed to find company by id ${company.id}`;*/}
                {/*            }*/}
                {/*            return <OldQuotesSubPanel quotes={data.company.oldQuotes} />*/}
                {/*        }}*/}
                {/*    </StdQuery>*/}

                {/*</Collapser>*/}
                <hr/>
            </div>);
    }


}
const removeCompanyMutation = gql`
    mutation removeCompany($id:ID!)
    {
        removeCompany(id:$id)
    }
`;

export default compose(graphql(UpdateCompanyMutation, {name: "updateCompany"}), graphql(GetCompanyQuery,{
    options:(props) =>
    {
        return {variables:{id:parseInt(props.match.params.id)}};
    }
}),graphql(removeCompanyMutation,{
    options:(props) =>
    {
        return {variables: {id: parseInt(props.match.params.id)}};
    }
}))(withRouter(CompanyDetail));
