import {
    ChangeLogEvent,
    ChangeLogFragmentFragment,
    ChangeLogType, useGetChangeLogsByOrderIdQuery,
    useModeOfDeliveryQuery,
    useTermsOfdeliveryQuery, useTermsOfPaymentQuery
} from "../../generated/graphql";
import React, {useCallback, useMemo} from "react";
import {camelToTitle} from "../../UTIL";
import ChangeLogsTable, {Difference, findDifferences, formatChanges} from "../../common/ChangeLogsTable";

const TABLE_KEY_CHANGE_LOGS = "TABLE_KEY_ORDER_CHANGE_LOGS"

const OrderChangeLogsPanel = ({orderId}: { orderId: string }) =>
{
    const {
        data: responseChangeLogs,
        loading: loadingChangeLogs
    } = useGetChangeLogsByOrderIdQuery({variables: {orderId}})
    const {data: responseTermsOfDelivery, loading: loadingTermsOfDelivery} = useTermsOfdeliveryQuery()
    const {data: responseModeOfDelivery, loading: loadingModeOfDelivery} = useModeOfDeliveryQuery()
    const {data: responseTermsOfPayment, loading: loadingTermsOfPayment} = useTermsOfPaymentQuery()

    const mapTermsOfDelivery = useCallback((id: number) =>
    {
        return responseTermsOfDelivery?.termsOfDelivery?.find((e) => e.id === `${id}`)?.name
    }, [responseTermsOfDelivery])

    const mapModeOfDelivery = useCallback((id: number) =>
    {
        return responseModeOfDelivery?.modeOfDelivery?.find((e) => e.id === `${id}`)?.name
    }, [responseModeOfDelivery])

    const mapTermsOfPaymentName = useCallback((id: number) =>
    {
        return responseTermsOfPayment?.termsOfPayment?.find((e) => e.id === `${id}`)?.name
    }, [responseTermsOfPayment])

    const mapOrderDiffData = useCallback((data: Difference, mapFunction: (id: any) => string | undefined) =>
    {
        return {
            oldValue: mapFunction(data?.oldValue),
            newValue: mapFunction(data?.newValue),
        };
    }, [])

    const handleArticleRowMessage = useCallback((changeLog: ChangeLogFragmentFragment) =>
    {
        const article = changeLog.newValue?.article ?? changeLog.oldValue?.article
        const name = changeLog.newValue?.name ?? changeLog.oldValue?.name
        const prefixMsg = article?.artNo ? `Article row with article no "${article?.artNo}"`
            : `Article row with name "${name}"`

        switch (changeLog.event)
        {
            case ChangeLogEvent.Create:
            {
                return `${prefixMsg} has been created`
            }
            case ChangeLogEvent.Delete:
            {
                return `${prefixMsg} has been deleted`
            }
            case ChangeLogEvent.Update:
                const hasPrice = changeLog.newValue?.price !== 0 || changeLog.oldValue?.price !== 0

                if (!hasPrice)
                {
                    return null
                }

                const diff = findDifferences(changeLog.oldValue, changeLog.newValue)
                const relevantDiffs = ["price", "quantity"].reduce((acc, key) =>
                {
                    if (diff[key])
                    {
                        acc[key] = diff[key];
                    }
                    return acc;
                }, {} as Difference);

                return Object.keys(relevantDiffs).length > 0 ? `${prefixMsg} has been updated: ${formatChanges(relevantDiffs)}` : null
        }
    }, [])

    const handleOrderMessage = useCallback((changeLog: ChangeLogFragmentFragment) =>
    {
        const diff = findDifferences(changeLog.oldValue, changeLog.newValue)

        const relevantDiffs = {
            preferredDeliveryDate: diff.preferredDeliveryDate,
            shippingCost: diff.shippingCost,
            packagingCost: diff.packagingCost,
            termsOfDelivery: diff.termsOfDeliveryId && mapOrderDiffData(diff.termsOfDeliveryId, mapTermsOfDelivery),
            modeOfDelivery: diff.modeOfDeliveryId && mapOrderDiffData(diff.modeOfDeliveryId, mapModeOfDelivery),
            termsOfPayment: diff.termsOfPaymentId && mapOrderDiffData(diff.termsOfPaymentId, mapTermsOfPaymentName),
        };

        const filteredDiffs = Object.entries(relevantDiffs).reduce((acc, [key, value]) =>
        {
            if (value)
            {
                acc[key] = value
            }
            return acc;
        }, {} as Difference);

        return Object.keys(filteredDiffs).length > 0 ? `Order has been updated: ${formatChanges(filteredDiffs)}` : null
    }, [mapTermsOfPaymentName, mapModeOfDelivery, mapTermsOfDelivery, mapOrderDiffData])

    const handleSpiralStairMessage = useCallback((changeLog: ChangeLogFragmentFragment) =>
    {
        const article = changeLog.newValue?.article ?? changeLog.oldValue?.article
        const diff = findDifferences(changeLog.oldValue, changeLog.newValue)
        return `Spiral stair "${article?.artNo}" has been updated: ${formatChanges(diff)}`
    }, [])

    const handleSpiralStairExtraMessage = useCallback((changeLog: ChangeLogFragmentFragment) =>
    {
        const article = changeLog.newValue?.article ?? changeLog.oldValue?.article
        const prefixMsg = `Spiral stair extra with article no "${article?.artNo}"`

        switch (changeLog.event)
        {
            case ChangeLogEvent.Create:
            {
                return `${prefixMsg} has been created: "Name": ${changeLog.newValue.name}, "Price": ${changeLog.newValue.price}`
            }
            case ChangeLogEvent.Delete:
            {
                return `${prefixMsg} has been deleted: "Name": ${changeLog.oldValue.name}, "Price": ${changeLog.oldValue.price}`
            }
            case ChangeLogEvent.Update:
                const diff = findDifferences(changeLog.oldValue, changeLog.newValue)
                return `${prefixMsg} has been updated: ${formatChanges(diff)}`
        }
    }, [])

    const handleSpiralStairSegmentsMessage = useCallback((changeLog: ChangeLogFragmentFragment) =>
    {
        const article = changeLog.newValue?.article ?? changeLog.oldValue?.article
        const index = changeLog.newValue?.index ?? changeLog.oldValue?.index

        const prefixMsg = `Spiral stair segment ${index + 1} with article no "${article?.artNo}"`

        switch (changeLog.event)
        {
            case ChangeLogEvent.Create:
            {
                return `${prefixMsg} has been created`
            }
            case ChangeLogEvent.Delete:
            {
                return `${prefixMsg} has been deleted`
            }
            case ChangeLogEvent.Update:
                const diff = findDifferences(changeLog.oldValue, changeLog.newValue)
                return `${prefixMsg} has been updated: ${formatChanges(diff)}`
        }
    }, [])

    const handleSpiralStairSurfaceTreatmentMessage = useCallback((changeLog: ChangeLogFragmentFragment) =>
    {
        if (!Array.isArray(changeLog.newValue) || !Array.isArray(changeLog.oldValue) || changeLog.newValue.length !== changeLog.oldValue.length)
        {
            return null
        }

        const article = changeLog.newValue[0]?.article ?? changeLog.oldValue[0]?.article
        const prefixMsg = `Spiral stair surface treatments with article no "${article?.artNo}"`

        const changesMessage = changeLog.oldValue.map((old, index) =>
        {
            const newValue = changeLog.newValue[index]
            const diff = findDifferences(old, newValue)
            return Object.keys(diff).length > 0 ? `${camelToTitle(old.partName)} - ${formatChanges(diff)}` : null
        }).filter((e) => e !== null)
            .join(", ")

        return changesMessage ? `${prefixMsg}: ${changesMessage}` : null
    }, [])

    const isLoading = useMemo(() =>
    {
        return loadingChangeLogs || loadingTermsOfDelivery || loadingModeOfDelivery || loadingTermsOfPayment
    }, [loadingChangeLogs, loadingTermsOfDelivery, loadingModeOfDelivery, loadingTermsOfPayment])

    const data = useMemo(() =>
    {
        if (isLoading)
        {
            return []
        }

        return responseChangeLogs?.changeLogsByOrderId?.sort((a, b) => a.createdAt > b.createdAt ? 1 : -1)
            .map((e) =>
            {
                let message: string | null

                switch (e.type)
                {
                    case ChangeLogType.ArticleRow:
                        message = handleArticleRowMessage(e)
                        break;
                    case ChangeLogType.Order:
                        message = handleOrderMessage(e)
                        break;
                    case ChangeLogType.SpiralStair:
                        message = handleSpiralStairMessage(e)
                        break;
                    case ChangeLogType.SpiralStairExtra:
                        message = handleSpiralStairExtraMessage(e)
                        break;
                    case ChangeLogType.SpiralStairSegment:
                        message = handleSpiralStairSegmentsMessage(e)
                        break;
                    case ChangeLogType.SpiralStairSurfaceTreatment:
                        message = handleSpiralStairSurfaceTreatmentMessage(e)
                        break;
                    default:
                        message = null
                }

                return {...e, message}
            }).filter((e) => e.message != null)
    }, [isLoading, responseChangeLogs?.changeLogsByOrderId, handleArticleRowMessage, handleOrderMessage,
        handleSpiralStairExtraMessage, handleSpiralStairMessage, handleSpiralStairSegmentsMessage, handleSpiralStairSurfaceTreatmentMessage])

    return <div>
        <ChangeLogsTable tableKey={TABLE_KEY_CHANGE_LOGS}
                         data={data}/>
    </div>
}

export default OrderChangeLogsPanel;