import React from 'react';
import StdQuery from "../../StdQuery";
import getFilesQuery from '../../../workflow-forms/DrawJob/graphql/GetFiles.graphql';
import {filter} from 'lodash';
import FileList from "../../file/FileList";
import {CustomerDocCategoryType} from '../../file/UploadFile';
import {useLocale} from "../../../LocaleProvider";


const CustomerDocsFileList = ({purchaseOrderId, onDelete}) => {
    const {t} = useLocale()

    return <StdQuery query={getFilesQuery} variables={{purchaseOrderId}}>
        {data => {
            const drawings = filter(data.files, file => file.fileCategory.id === CustomerDocCategoryType.id);
            return <React.Fragment>
                <h4>{t('Uploaded customer documentation files')}</h4>
                <FileList files={drawings}
                          onDelete={onDelete}/>
            </React.Fragment>
        }}
    </StdQuery>
};

export default CustomerDocsFileList