import React, {useCallback, useEffect, useMemo, useState} from 'react';
import './App.css';
import Login from './Login';
import { Col, Container, Row } from 'reactstrap';
import {withRouter} from 'react-router';
import 'assets/images/menu2.jpg';
import errorImage from 'assets/images/error.jpg';
import errorImage2 from 'assets/images/error2.gif';
import Sidebar from "./Sidebar";
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import Logger from "./Logger";
import Routes from "./Routes";
import Header from "./Header";
import Auth from "./Auth";
import 'assets/images/menu2.jpg';
import 'react-notifications-component/dist/theme.css'
import EventEmitter from "./common/EventEmitter";
import { LicenseInfo } from '@mui/x-license-pro';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import enGb from 'dayjs/locale/en-gb'
import InventoryHeader from "./inventory/components/InventoryHeader";
import {InventoryRoute, isInventoryPath} from "./inventory/InventoryRouters";
import {INVENTORY_TENANT_ID_LIST} from "./Constants";
import {useTenant} from "./TenantProvider";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        Logger.error(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            let errorImg = errorImage;
            if(Math.random() > 0.5) {
                errorImg = errorImage2;
            }
            return <div><h1>Something went wrong. Sorry about that. <span role="img" aria-label="Oops">😕</span></h1><div style={{textAlign: "center"}}><img src={errorImg} style={{height: "400px"}} /><br/><a href="/">Go back to start, do not pass Go</a></div></div>;
        }

        return this.props.children;
    }
}

const windowSize = {
    S: 1,
    MD: 2,
    L: 3
}

LicenseInfo.setLicenseKey('8aac466bf3d7484cf79f5b0df7e40c08Tz02MjYyNSxFPTE3MTExODc1NzQxMDYsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

enGb.weekStart = 1

const App =  (props)=>
{
    const [currentWindowSize,setWindowSize] = useState(1);
    const [isAuthenticated,setIsAuthenticated] = useState(Auth.isUserAuthenticated());
    const {tenantId} = useTenant()

    const update = useCallback((tenantId) => {
        const isUserAuthenticated = Auth.isUserAuthenticated()
        setIsAuthenticated(isUserAuthenticated);

        if (!isUserAuthenticated) {
            return
        }
        const isOnInventory = isInventoryPath(props.location)
        const isInventoryTenant = INVENTORY_TENANT_ID_LIST.includes(tenantId)
        if (isInventoryTenant && !isOnInventory) {
            props.history.push(`${InventoryRoute.INDEX}`);
            return;
        }
        if (!isInventoryTenant && isOnInventory) {
            props.history.push(`/`);
        }
    }, [setIsAuthenticated, props.location, props.history]);

    const handleResize = useCallback(()=>
    {
        console.log(`handleResize ${window.innerWidth}`);
        if (window.innerWidth > 1000)
        {
            setWindowSize(windowSize.L);
        }
        else if (window.innerWidth > 600)
        {
            setWindowSize(windowSize.MD);
        }
        else {
            setWindowSize(windowSize.S);
        }

    },[setWindowSize]);

    const isInInventoryPath = useCallback(() => {
        return isInventoryPath(props.location)
    }, [props.location])

    const isCorrectTenantId = useMemo(() => {
        if (isInInventoryPath()) {
            return INVENTORY_TENANT_ID_LIST.includes(tenantId)
        }
        return !INVENTORY_TENANT_ID_LIST.includes(tenantId)
    }, [tenantId, isInInventoryPath])

    useEffect(() =>
    {
        handleResize();
        window.addEventListener("resize", handleResize);
        return ()=>
        {
            window.removeEventListener("resize",handleResize);
        }
    }, [handleResize]);
    useEffect(()=>
    {
        EventEmitter.on("logout",()=>
        {
            setIsAuthenticated(false);
        });
    },[]);

    if(!isAuthenticated)
    {
        return <Login done={update}/>;
    }

    return <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={enGb.name}>
        <ErrorBoundary>
            <div className={"body"}>
                <ReactNotification/>
                <Sidebar logout={update} expand={currentWindowSize === windowSize.L}/>
                <div className="page-wrapper">
                    <Container fluid={true}>
                        {!isInInventoryPath() ? <Header/> : <InventoryHeader/>}
                        <Row>
                            <Col sm={12} style={{padding: 30}}>
                                {
                                    isCorrectTenantId ? <Routes inventoryClient={props.inventoryClient}/>
                                        : <p style={{color: 'red'}}>Error: The tenant({tenantId}) does not match the current path</p>
                                }
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </ErrorBoundary>
    </LocalizationProvider>
}
export default withRouter(App);
