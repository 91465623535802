import React, {memo, useCallback, useMemo, useState} from 'react';
import {
    Button, ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    Col, Input, Label,
    Modal,
    ModalBody, ModalFooter,
    ModalHeader,
    Row,
    Spinner,
    Table
} from "reactstrap";
import gql from "graphql-tag";
import {useMutation} from "@apollo/client";
import NotificationPopup from "../../common/lib/NotificationPopup";
import {IsEconomy} from "../../Auth";
import {Link} from "react-router-dom";
import {FORMAT_DATE, formatCurrency, formatToSwedishTime} from "../../UTIL";
import StdQuery from "../../common/StdQuery";
import {TenantData} from "../../common/lib/TenantData";
import CompanyChangeLogsPanel from "./CompanyChangeLogsPanel";

const requestRatingMutation = gql`
    mutation requestRating($id:ID!, $comment: String!)
    {
        requestCreditRating(id:$id, comment: $comment)
    }
`;

const getOutstandingAmounts = gql`
    query getOutstandingAmounts($id:ID!,$currency:String!)
    {
        company(id:$id)
        {
            id
            outstandingAmounts(currency:$currency)
            {
                activeOrders
                recentlyDeliveredOrders
                invoiceBalanceAmounts {
                    balanceInCurrency
                    currencyId
                }
            }
        }
    }
`;

const AddCommentModal = (props) => {
    const {toggle, isOpen, onSubmit} = props
    const [comment, setComment] = useState("")

    const isSubmitDisabled = useMemo(()=> {
        return comment.trim() === ""
    }, [comment])

    return <Modal toggle={toggle} isOpen={isOpen}>
        <ModalHeader toggle={toggle}>Request credit rating</ModalHeader>
        <ModalBody>
            <Label>Add comment(required)</Label>
            <Input type="textarea" onChange={(e) => setComment(e.target.value)}/>
        </ModalBody>
        <ModalFooter>
            <Button color="primary" onClick={() => onSubmit(comment)} disabled={isSubmitDisabled}>
                Submit
            </Button>
        </ModalFooter>
    </Modal>
}

export const RequestRatingButton = ({company, children}) => {
    const {id, organisationNumber, name} = company;
    const [isOpenCommentModal, setIsOpenCommentModal] = useState(false)
    const [requestRating, {loading}] = useMutation(requestRatingMutation);

    const toggleCommentModal = useCallback(() => {
        setIsOpenCommentModal((value) => !value)
    }, [setIsOpenCommentModal])

    const handleSubmit = useCallback(async (comment) => {
        toggleCommentModal()
        try {
            const {data} = await requestRating({variables: {id, comment}});
            if (data && data.requestCreditRating) {
                console.log(data.requestCreditRating);
                NotificationPopup.success(`Requested rating, economy has been notified.`);
            } else if (data.errors) {
                NotificationPopup.error(`Something went wrong when trying to request creditRating ${data.errors}`);
            }
        } catch (e) {
            NotificationPopup.error(`Something went wrong when trying to request creditRating, ${e.message}`);
        }
    }, [id, toggleCommentModal, requestRating])

    return <div>
        {!loading &&
            <Button color="primary" onClick={async () => {
                if (!organisationNumber) {
                    NotificationPopup.error(`${name} is missing organisation number. Please set an Organisation number 
                BEFORE requesting a credit rating.`);
                    return;
                }
                setIsOpenCommentModal(true)
            }}>
                {children}
            </Button>}
        {loading && <Spinner color={"warning"}/>}

        <AddCommentModal toggle={toggleCommentModal} isOpen={isOpenCommentModal} onSubmit={handleSubmit}/>
    </div>
};

const CreditRatingLogsModel = memo(({companyId}) => {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = useCallback(() => {
        setIsOpen((prevState) => !prevState)
    }, []);

    return <>
        <Button onClick={toggle} size={'sm'}>Logs</Button>
        <Modal isOpen={isOpen} toggle={toggle} size={'lg'}>
            <ModalHeader toggle={toggle}>Credit rating change logs</ModalHeader>
            <ModalBody>
                <CompanyChangeLogsPanel companyId={companyId}/>
            </ModalBody>
        </Modal>
    </>
})

export default ({company})=>
{

    const {creditRatingComment,creditRatingDate,creditRatingLimit,creditRatingLimitCurrency,tenantId} = company;

    const outstandingAmountCurrency = TenantData[tenantId]?.defaultCurrency || "SEK";
    return <Card>
        <CardHeader>
            <Row>
                <Col sm={6}>
                    <h5>
                        Credit Rating
                    </h5>
                </Col>
                <Col>
                    <IsEconomy>
                        <ButtonGroup vertical>
                            <Button size={"sm"} color='info'>
                                <Link style={{color: 'white'}} to={`/credit-rating/${company.id}`}>
                                    Edit Credit Rating
                                </Link>
                            </Button>
                            <CreditRatingLogsModel companyId={company.id}/>
                        </ButtonGroup>
                    </IsEconomy>
                </Col>
            </Row>
        </CardHeader>
        <CardBody>
            <StdQuery query={getOutstandingAmounts} variables={{id:company.id,
                currency: outstandingAmountCurrency}}>
                {(data)=>
                {
                    if(!data.company || !data.company.outstandingAmounts)
                    {
                        return <p>Failed to fetch outstanding amounts. Limit: <strong>{creditRatingLimit} {creditRatingLimitCurrency}</strong></p>;
                    }
                    const {company:{outstandingAmounts}} = data;
                    //const warnUser = outstandingAmounts.activeOrders > creditRatingLimit || outstandingAmounts.recentlyDeliveredOrders > creditRatingLimit;
                    return <React.Fragment>
                        <h5>Outstanding amounts</h5>
                        <Table bordered size={"sm"} striped>
                            <tbody>
                            <tr>
                                <td>
                                    Undelivered orders
                                </td>
                                <td>
                                    <strong> {formatCurrency(outstandingAmounts.activeOrders)} {outstandingAmountCurrency}</strong>
                                </td>
                            </tr>
                            {
                                !outstandingAmounts.invoiceBalanceAmounts.some((e) => e.balanceInCurrency > 0) &&
                                <tr>
                                    <td>
                                        Orders delivered past 35 days
                                    </td>
                                    <td>
                                        <strong>{formatCurrency(outstandingAmounts.recentlyDeliveredOrders)} {outstandingAmountCurrency}</strong>
                                    </td>
                                </tr>
                            }

                            {outstandingAmounts.invoiceBalanceAmounts.map((e) => {
                                if (e.balanceInCurrency === 0) {
                                    return <div></div>
                                }

                                return <tr>
                                    <td>
                                        Invoice Balance Amount
                                    </td>
                                    <td>
                                        <strong>{formatCurrency(e.balanceInCurrency)} {e.currencyId}</strong>
                                    </td>
                                </tr>
                            })}
                            </tbody>
                        </Table>
                    </React.Fragment>;
                }
                }
            </StdQuery>

            {creditRatingComment && <React.Fragment>
                <p style={{fontSize:"16px"}}>
                    {creditRatingComment} - {formatToSwedishTime(creditRatingDate, FORMAT_DATE)}
                </p>
                <RequestRatingButton company={company} >
                    Request New Rating
                </RequestRatingButton>
            </React.Fragment>}
            {!creditRatingComment && <React.Fragment>
                <RequestRatingButton company={company} >
                    Request credit check
                </RequestRatingButton>
                </React.Fragment>}
        </CardBody>
    </Card>
}
