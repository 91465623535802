// tenantId
import config from "./config";

export const TENANT_ID_ESPR = "ESPR"
export const TENANT_ID_ESPL = "ESPL"

// inventory
export const INVENTORY_TENANT_ID_LIST = [TENANT_ID_ESPR, TENANT_ID_ESPL]

// work flow
export const PROCESS_DEFINITION_KEY_ESPL_PURCHASE_ORDER = "ESPLPurchaseOrder"
export const PROCESS_DEFINITION_KEY_ESPL_DRAW_JOB = "ESPLDrawJob"
export const PROCESS_DEFINITION_KEY_ESPR_DRAW_JOB = "DrawJob"

// purchase order
export const PURCHASE_ORDER_EDITABLE_STATUS = ["started", "drawqueue"]

// supplier id
export const SUPPLIER_ID_ESPR = 568
export const SUPPLIER_ID_ESPL = 12

// data grid filter
export const FILTER_BETWEEN = "between"

// query params
export const QUERY_PARAMS_IS_INVENTORY = "isInventory"

// url
export const URL_PURCHASE_ORDER_INVOICE = `${config.BACKEND_URI}pdf/invoice/purchase-order/`