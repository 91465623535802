import React, { Component } from 'react';
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
} from 'reactstrap';
import { useMutation } from '@apollo/client';
import { graphql,withApollo } from '@apollo/client/react/hoc';
import { withRouter } from 'react-router';
import { flowRight as compose } from 'lodash';
import GetPurchaseOrder from './graphql/GetPurchaseOrder.graphql';
import DetailRenderer from "../common/DetailRenderer";
import EditableDetailRenderer from "../common/EditableDetailRenderer";
import PurchaseOrderFormDef from "./PurchaseOrderFormDef";
import ArticleRows from "../common/ArticleRows";
import CreateArticleRow from "../common/CreateArticleRow";
import UpdatePurchaseOrderMutation from './graphql/UpdatePurchaseOrder.graphql';
import RemovePurchaseOrderMutation from './graphql/RemovePurchaseOrder.graphql';
import WorkflowDetail from "../common/WorkflowDetail";
import BPMDiagram from "../common/BPMDiagram";
import Collapser from "../common/Collapser";
import PdfModal from "../order/components/PdfModal";
import Paragraph from "../common/Paragraph";
import { formatCurrency, poTotal } from "../UTIL";
import CommentList from "../common/comment/CommentList";
import CreateFreeTextRow from "../common/createArticle/CreateFreeTextRow";
import ButtonGroup from "reactstrap/es/ButtonGroup";
import {isInventoryPath} from "../inventory/InventoryRouters";
import NotificationPopup from "../common/lib/NotificationPopup";
import UploadFile from "../common/file/UploadFile";
import FilesSubpanel from "../common/subpanels/FilesSubpanel";
import {PURCHASE_ORDER_EDITABLE_STATUS, SUPPLIER_ID_ESPL} from "../Constants";
import {withLocale} from "../LocaleProvider";

const TAB_FILES = "TAB_FILES"

const Details = ({ po, isEditable, isOnInventoryPage }) => {
    const [updatePurchaseOrder] = useMutation(UpdatePurchaseOrderMutation, {
        refetchQueries: ["getPurchaseOrder"]
    });
    const { creator, assignee, status, createdAt, updatedAt, tenantId } = po;
    return <EditableDetailRenderer columns={3} isEditable={isEditable} formDefinition={PurchaseOrderFormDef(po, isOnInventoryPage)}
        onSubmit={({ object }) => updatePurchaseOrder({
            variables: {
                id: po.id,
                purchaseOrder: object,
            }
        })
        } object={po} extra={<DetailRenderer object={{
            creator, assignee, createdAt,
            updatedAt, status, tenantId,
        }} />} />
};

class PurchaseOrderDetailPage extends Component {


    constructor() {
        super();
        this.state = {
            activeTab: "default",
            dropdownOpen: false,
            pdf: false
        };
        this.downloadPDF = this.downloadPDF.bind(this);
        this.removePurchaseOrder = this.removePurchaseOrder.bind(this);
    }
    downloadPDF() {
        this.setState({ pdf: true });
        // const purchaseOrder = this.props.data.purchaseOrder;
        // downloadPurchaseOrderPDF(purchaseOrder);
    }

    async setDefaultDeliveryDestination() {
        await this.props.updatePurchaseOrder({
            variables: {
                id: parseInt(this.props.match.params.id),
                purchaseOrder: {
                    deliveryTitle: 'Eurostair Produktion AB',
                    deliveryStreet: 'Säterivägen 30',
                    deliveryCity: 'Säffle',
                    deliveryCountry: 'Sweden',
                    deliveryPostalCode: '661 30',

                }
            }
        });
    }

    renderAddress = (purchaseOrder) => {
        const {t} = this.props
        return <Card>
            <CardHeader>
                {t('Delivery address')}
            </CardHeader>
            <CardBody>
                <p>{purchaseOrder.deliveryTitle}</p>
                <p>{purchaseOrder.deliveryStreet}</p>
                <p>{purchaseOrder.deliveryPostalCode} {purchaseOrder.deliveryCity} {purchaseOrder.deliveryCountry}</p>
                <p>{purchaseOrder.deliveryFreeText}</p>
                {/*{!purchaseOrder.deliveryStreet &&*/}
                {/*<Button color='primary' onClick={this.setDefaultDeliveryDestination.bind(this)}>Set delivery address to Säffle.</Button>}*/}

            </CardBody>
        </Card>
    };

    setTab = (activeTab) => {
        this.setState({ activeTab });
    };

    async removePurchaseOrder() {
        if (window.confirm(`Are you sure you want to remove this purchase order?`)) {
            await this.props.removePurchaseOrder({
                variables:
                {
                    id: parseInt(this.props.match.params.id)
                }
            });
            this.props.history.push(`/purchaseOrders`);
        }
    }

    workflow(purchaseOrder) {
        const {t} = this.props
        if (purchaseOrder.status === 'done') {
            return <h2>{t('Workflow finished')}</h2>;
        }
        else if (!purchaseOrder.processInstanceId) {
            return <h2>{t('No workflow')}</h2>;
        }
        else {
            return <Collapser open>
                <h1>{t('Purchase Order workflow')}</h1>
                <BPMDiagram width={1200} height={450} processInstanceId={purchaseOrder.processInstanceId} activityIds={purchaseOrder.activityIds} />
            </Collapser>;
        }

    }

    isOnInventoryPage = () => {
        return isInventoryPath(window.location)
    }

    render() {
        const data = this.props.data;
        const {t} = this.props
        if (data.loading) {
            return (<p>{t('Loading')}..</p>);
        }
        else if (data.error) {
            console.error(data.error);
            return (<p>{t('Error')}!</p>);
        }
        else if (data.purchaseOrder == null) {
            return (<p>{t('Not found')} :(</p>);
        }
        const purchaseOrder = data.purchaseOrder;
        const status = purchaseOrder?.status
        const isEditable = purchaseOrder.supplier.id === `${SUPPLIER_ID_ESPL}` ? PURCHASE_ORDER_EDITABLE_STATUS.includes(status) : true
        return (
            <div>
                <PdfModal obj={purchaseOrder} isOpen={this.state.pdf} toggle={() => this.setState({ pdf: !this.state.pdf })} />
                <Nav tabs fill>
                    <NavItem>
                        <NavLink active={this.state.activeTab === 'default'} onClick={() => {
                            this.setTab('default');
                        }}>
                            {
                                this.isOnInventoryPage() ? <h4>{t('Order')} {purchaseOrder.number}</h4>
                                    : <h4>{t('Purchase Order')} {purchaseOrder.number}</h4>
                            }
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink active={this.state.activeTab === TAB_FILES} onClick={() => {
                            this.setTab(TAB_FILES);
                        }}>
                            <h4>{t('Files')}</h4>
                        </NavLink>
                    </NavItem>
                    {purchaseOrder.order &&
                        <NavItem>
                            <NavLink active={this.state.activeTab === 'comments'} onClick={() => {
                                this.setTab('comments');
                            }}>
                                <h4>{t('Comments')} <Badge color={"info"}>{purchaseOrder.comments.length > 0 && purchaseOrder.comments.length}</Badge></h4>
                            </NavLink>
                        </NavItem>
                    }
                    <NavItem>
                        <NavLink active={this.state.activeTab === 'workflow'} onClick={() => {
                            this.setTab('workflow');
                        }}>
                            <h4>{t('Workflow')}</h4>
                        </NavLink>
                    </NavItem>
                    {!this.isOnInventoryPage() && <Dropdown nav isOpen={this.state.dropdownOpen}
                                                        toggle={() => this.setState({dropdownOpen: !this.state.dropdownOpen})}>
                            <DropdownToggle nav>
                                <h4>Actions</h4>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem tag='span'>
                                    <Button color='secondary'
                                            onClick={() => NotificationPopup.error(`Please use the 'Edit' button located under the 'Purchase Order Number'`)}>
                                        Edit
                                    </Button>
                                </DropdownItem>
                                <DropdownItem header>
                                    PDFs
                                </DropdownItem>
                                <DropdownItem tag='span'>
                                    <Button disabled={purchaseOrder.status === "started" && purchaseOrder.processInstanceId}
                                            color='primary' onClick={this.downloadPDF.bind(this)}>Purchase Order
                                        PDF</Button>
                                    {purchaseOrder.status === "started" &&
                                        <small><Paragraph>Finish the task first..</Paragraph></small>}
                                </DropdownItem>
                                <DropdownItem tag='span'>
                                    <Button color='danger' onClick={() => this.removePurchaseOrder()}>Delete Purchase
                                        Order</Button>
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    }
                    <NavItem>
                    </NavItem>
                </Nav>

                <TabContent activeTab={this.state.activeTab} style={{ paddingTop: "35px" }}>
                    <TabPane tabId="default">
                        <Row>
                            <Col sm={4}>
                                {/*<DetailRenderer object={_.omit(purchaseOrder,OmittedFields)} />*/}
                                <Details po={purchaseOrder} isEditable={isEditable} isOnInventoryPage={this.isOnInventoryPage()}/>
                            </Col>
                            <Col sm={2}>
                                {this.renderAddress(purchaseOrder)}
                            </Col>
                            <Col sm={4}>
                                <WorkflowDetail purchaseOrderId={purchaseOrder.id} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h2>{t('Rows')}</h2>
                                {
                                    isEditable && <ButtonGroup>
                                        <CreateArticleRow purchaseOrderId={purchaseOrder.id}
                                                          locale={purchaseOrder?.order?.company?.locale}/>
                                        <CreateFreeTextRow purchaseOrderId={purchaseOrder.id}/>
                                    </ButtonGroup>
                                }

                                <ArticleRows articleRows={purchaseOrder.articleRows} currency={purchaseOrder.currency}
                                             displayReceivedQuantityAndDate={true}
                                             isEditable={isEditable}/>
                                <Row className="justify-content-between">
                                    <Col>
                                    </Col>
                                    <Col sm={4}>
                                        <p style={{ marginLeft: "55px" }}>
                                            Total: {formatCurrency(poTotal(purchaseOrder))} {purchaseOrder.currency}
                                        </p>
                                    </Col>
                                </Row>
                            </Col>

                        </Row>
                    </TabPane>
                    <TabPane tabId={TAB_FILES}>
                        <UploadFile noModal purchaseOrderId={purchaseOrder.id}/>
                        <FilesSubpanel files={purchaseOrder.files}/>
                    </TabPane>
                    {purchaseOrder && <TabPane tabId="comments">
                        <CommentList purchaseOrderId={purchaseOrder.id} comments={purchaseOrder.comments}
                                     assignee={purchaseOrder.assignee?.displayName}/>
                    </TabPane>
                    }
                    <TabPane tabId="workflow">
                        <Row>
                            <Col md={12}>
                                {this.state.activeTab === "workflow" && this.workflow(purchaseOrder)}
                            </Col>
                        </Row>
                    </TabPane>
                </TabContent>
            </div>);
    }


}


export default compose(graphql(GetPurchaseOrder, {
    options: (props) => {
        return { variables: { id: parseInt(props.match.params.id) } };
    }
}), graphql(RemovePurchaseOrderMutation, { name: 'removePurchaseOrder' }),
    graphql(UpdatePurchaseOrderMutation, { name: 'updatePurchaseOrder' }))(withRouter(withApollo(withLocale(PurchaseOrderDetailPage))));
