import {Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, UncontrolledTooltip} from "reactstrap";
import NewFormRenderer from "../common/NewFormRenderer";
import {GlossTypes, PlatingTypes} from "./definitions";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {
    useUpdateSurfaceTreatmentMutation,
    useUpdateSpiralStairMutation,
    SurfaceTreatment,
    SpiralStair
} from "../generated/graphql";
import * as _ from "lodash"
import {camelToTitle} from "../UTIL";


const fixto2 = number => {
    return parseFloat((number||0).toFixed(2))
}
export default ({data, loading, spiralStairId, prioritisation, surfaceTreatmentModalOpen, toggle, onSurfaceTreatmentSaved})=>{
    const [spiralStair, setSpiralStair] = useState<{ralCode?,glossType?,platingType?}>({ralCode:null, glossType:null, platingType:null});
    const [surfaceTreatment, setSurfaceTreatment] = useState<SurfaceTreatment[]>([]);
    const [sumOfGalvanizing, setSumOfGalvanizing] = useState(0);
    const [sumOfPowdercoating, setSumOfPowdercoating] = useState(0);
    const [currency, setCurrency] = useState("");
    const [updateSurfaceTreatment] = useUpdateSurfaceTreatmentMutation();
    const [updateSpiralStair] = useUpdateSpiralStairMutation()
    const selectAllPowderCoatingRef = useRef<any>();
    const selectAllPlatingRef = useRef<any>();
    const [selectAllPowderCoating, setSelectAllPowderCoating] = useState(false);
    const [selectAllPlating, setSelectAllPlating] = useState(false);
    const editingTextInput = useRef<any>();
    useEffect(()=>{
        if(!loading && data?.spiralStair && data?.surfaceTreatmentDetail){
            setSurfaceTreatment(data.surfaceTreatmentDetail as SurfaceTreatment[]);
            setSpiralStair(data.spiralStair as Partial<SpiralStair>);
            setSumOfGalvanizing(data.surfaceTreatmentDetail.reduce((sum, sur)=>(sum + (sur?.galvanizing?.price || 0)), 0));
            setSumOfPowdercoating(data.surfaceTreatmentDetail.reduce((sum, sur)=>(sum + (sur?.powdercoating?.price || 0)), 0));
            setCurrency(data.surfaceTreatmentDetail[0]?.currency || "");
        }
    }, [loading, data, setSurfaceTreatment]);

    useEffect(()=>{
        if(!surfaceTreatmentModalOpen || !selectAllPowderCoatingRef.current) return;

        let haveChecked = surfaceTreatment.filter(sur=>sur.editable).reduce((result, val)=>result || Boolean(val.powdercoating?.isChecked), false);
        let haveUnchecked = surfaceTreatment.filter(sur=>sur.editable).reduce((result, val)=>result || !Boolean(val.powdercoating?.isChecked), false);

        if(haveChecked === haveUnchecked){
            selectAllPowderCoatingRef.current.indeterminate = true;
            setSelectAllPowderCoating(false);
        }else {
            selectAllPowderCoatingRef.current.indeterminate = false;
            setSelectAllPowderCoating(haveChecked);
        }
    }, [surfaceTreatmentModalOpen, selectAllPowderCoatingRef, setSelectAllPowderCoating, surfaceTreatment]);

    useEffect(()=>{
        if(!surfaceTreatmentModalOpen || !selectAllPlatingRef.current) return;

        let haveChecked = surfaceTreatment.filter(sur=>sur.editable).reduce((result, val)=>result || Boolean(val.galvanizing?.isChecked), false);
        let haveUnchecked = surfaceTreatment.filter(sur=>sur.editable).reduce((result, val)=>result || !Boolean(val.galvanizing?.isChecked), false);

        if(haveChecked === haveUnchecked){
            selectAllPlatingRef.current.indeterminate = true;
            setSelectAllPlating(false);
        }else {
            selectAllPlatingRef.current.indeterminate = false;
            setSelectAllPlating(haveChecked);
        }
    }, [surfaceTreatmentModalOpen, selectAllPlatingRef, setSelectAllPlating, surfaceTreatment]);

    const onRalCodeApply = useCallback((ralCode) => {
        const newSurfaceTreatment =  _.cloneDeep(surfaceTreatment);
        newSurfaceTreatment.forEach(pc=>{
            if(pc.partName === "sum" || pc.partName === "costPerColor") return;
            pc.ralCode = ralCode;
        })
        setSurfaceTreatment(newSurfaceTreatment);
    }, [setSurfaceTreatment, surfaceTreatment]);

    const onSave = useCallback(async () => {
        const surfaceTreatmentData = surfaceTreatment
            .filter( sur => sur.editable )
            .map(sur=> {
                return {
                    ..._.pick(sur, ["partName", "ralCode"]),
                    isCheckedPC:sur.powdercoating?.isChecked,
                    isCheckedPl:sur.galvanizing?.isChecked,
                }
            } );

        let spiralStairData = _.pick(spiralStair, ["ralCode", "glossType", "platingType"]);

        await updateSpiralStair({
            variables: {spiralStair:spiralStairData, id:spiralStairId },
        });

        await updateSurfaceTreatment({
            variables: {surfaceTreatment:surfaceTreatmentData as any, spiralStairId},
            refetchQueries: [
                "getSurfaceTreatment",
                "spiralStairDetail"
            ],
            awaitRefetchQueries: true
        });

        if (onSurfaceTreatmentSaved) {
            await onSurfaceTreatmentSaved();
        }
    }, [updateSurfaceTreatment, surfaceTreatment, spiralStairId, spiralStair, updateSpiralStair, onSurfaceTreatmentSaved]);

    const galvanizationForm = ()=>{
        return [{
            name: "platingType",
            label: "Galvanization Type",
            type: "select",
            isClearable: true,
            options: _.map(PlatingTypes, v => v),
        }]
    }

    const powderCoatingForm = (onRalCodeApply) =>
    {
        const ralCodeApplyButton = onRalCodeApply ? {
            name: "applyRalCode",
            label: " ",
            type: "custom",
            input: (formObject) =>
            {
                return <Button color={"primary"} onClick={() =>
                {
                    onRalCodeApply(formObject.ralCode);
                }}>Apply to All RAL Code</Button>
            },
        } : {
            name: "blank",
            label: " ",
        }
        return [
            {
                name: "glossType",
                type: "select",
                hint: "30-40 Inside, 70-80 Outside",
                options: _.map(GlossTypes, v => v),
                isClearable: true,
            },
            {
                name: "ralCode",
                label: "RAL Code (for applyment only)",
                type: "text",
            },
            ralCodeApplyButton,
        ];
    }

    return <Modal size={"xl"} isOpen={surfaceTreatmentModalOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>
            Surface treatment
        </ModalHeader>
        <ModalBody>
            <Row>
                <Col>
                    <h5>Galvanization</h5>
                    <NewFormRenderer
                        formDefinition={galvanizationForm()}
                        object={spiralStair}
                        onChange={(name, value) =>
                        {
                            setSpiralStair(old => ({...old, [name]: value}));
                        }}
                    />
                </Col>
                <Col>
                    <h5>Powder Coating</h5>
                    <NewFormRenderer
                        formDefinition={powderCoatingForm(onRalCodeApply)}
                        object={spiralStair}
                        onChange={(name, value) =>
                        {
                            setSpiralStair(old => ({...old, [name]: value}));
                        }}
                    />
                </Col>
            </Row>
            <table className={"powderCoatingTable"}>
                <tr>
                    <th/>
                    <th colSpan={3} className={"plating"}>Galvanization</th>
                    <th colSpan={4} className={"powderCoating"}>Powder Coating</th>
                </tr>
                <tr>
                    <th style={{width:"170px"}}>Part Name</th>
                    <th style={{textAlign:"center", lineHeight: "0px", width:"35px"}} className={"plating"}>
                        <br/><input ref={selectAllPlatingRef} className='big-checkbox' type='checkbox'
                               checked={selectAllPlating}
                               onChange={(e) => {
                                   const newSurfaceTreatment = _.cloneDeep(surfaceTreatment);
                                   newSurfaceTreatment.filter(sur=>sur.editable).forEach(sur=>{
                                       sur.galvanizing.isChecked = e.target.checked;
                                   });
                                   setSurfaceTreatment(newSurfaceTreatment);
                               }}
                        />
                    </th>
                    <th className={"plating"} style={{width:"100px"}}>Unit Price</th>
                    <th className={"plating"}>Price (hover to show detail)</th>
                    <th style={{textAlign:"center", lineHeight: "0px", width:"35px"}} className={"powderCoating"}>
                        <br/><input ref={selectAllPowderCoatingRef} className='big-checkbox' type='checkbox'
                               checked={selectAllPowderCoating}
                               onChange={(e) => {
                                   const newSurfaceTreatment = _.cloneDeep(surfaceTreatment);
                                   newSurfaceTreatment.filter(sur=>sur.editable).forEach(sur=>{
                                       sur.powdercoating.isChecked = e.target.checked;
                                   })
                                   setSurfaceTreatment(newSurfaceTreatment);
                               }}
                        />
                    </th>
                    <th style={{width:"90px"}} className={"powderCoating"}>RAL Code</th>
                    <th className={"powderCoating"} style={{width:"100px"}}>Unit Price</th>
                    <th className={"powderCoating"}>Price (hover to show detail)</th>
                    <th style={{width:"140px"}}>Powder Coating + Galvanization</th>
                </tr>
                {surfaceTreatment.map((sur, index)=>{

                    return <tr key={"pc_"+index}>
                        <td>{camelToTitle(sur.partName)}{sur.partName === 'plate' && <><br/><small style={{color:"rgba(0,0,0,0.62)"}}>Only for railing type "Plate"</small></>}</td>
                        <td style={{textAlign:"center"}} className={"plating"}>
                            {sur.editable && <input className='big-checkbox' type='checkbox'
                                    checked={sur.galvanizing?.isChecked}
                                    onChange={(e) => {
                                        const newSurfaceTreatment = _.cloneDeep(surfaceTreatment);
                                        newSurfaceTreatment[index].galvanizing.isChecked = e.target.checked;
                                        setSurfaceTreatment(newSurfaceTreatment);
                                    }}
                            />}
                        </td>
                        <td className={"plating"}>
                            {sur.partName!=="costPerColor" && sur.partName!=="sum" && fixto2(sur.galvanizing?.unitPrice) }
                        </td>
                        <td className={"plating"}>
                            {data?.surfaceTreatmentDetail && data?.surfaceTreatmentDetail[index]?.galvanizing?.isChecked && <>
                                <span id={`galPrice_${sur.partName}`}>{fixto2(sur.galvanizing?.price)}</span>
                                <UncontrolledTooltip
                                    placement="right"
                                    target={"galPrice_" + sur.partName}
                                >
                                    {sur.galvanizing?.calculateDescription}
                                </UncontrolledTooltip>
                            </>}
                        </td>
                        <td style={{textAlign:"center"}} className={"powderCoating"}>
                            {sur.editable && <input className='big-checkbox' type='checkbox'
                                    checked={sur.powdercoating?.isChecked}
                                    onChange={(e) => {
                                        const newSurfaceTreatment = _.cloneDeep(surfaceTreatment);
                                        newSurfaceTreatment[index].powdercoating.isChecked = e.target.checked;
                                        setSurfaceTreatment(newSurfaceTreatment);
                                    }}
                            />}
                        </td>
                        <td className={"powderCoating"}>
                            {sur.editable && <input
                                style={{width: "100%"}}
                                type='text'
                                value={sur.ralCode === null ? "" : sur.ralCode}
                                onChange={(e) => {
                                    const newSurfaceTreatment = _.cloneDeep(surfaceTreatment);
                                    newSurfaceTreatment[index].ralCode = e.target.value;
                                    setSurfaceTreatment(newSurfaceTreatment);

                                    editingTextInput.current = "ralCode" + index;
                                }}
                            />}
                        </td>
                        <td className={"powderCoating"}>{sur.partName!=="sum" && sur.powdercoating?.unitPrice}</td>
                        <td className={"powderCoating"}>
                            {data?.surfaceTreatmentDetail && data?.surfaceTreatmentDetail[index]?.powdercoating?.isChecked && <>
                                <span id={`pcPrice_${sur.partName}`}>{fixto2(sur.powdercoating?.price)}</span>
                                <UncontrolledTooltip
                                    placement="right"
                                    target={"pcPrice_" + sur.partName}
                                >
                                    {sur.powdercoating?.calculateDescription}
                                </UncontrolledTooltip>
                            </>}
                        </td>
                        <td style={{textAlign:"right"}} className={sur.partName==="sum" ? "sum":""}>
                            {fixto2((sur.powdercoating?.price || 0)+(sur.galvanizing?.price||0))} {sur.currency}
                        </td>
                    </tr>;
                })}
                <tr>
                    <td>Sum</td>
                    <td className={"plating"} colSpan={2}/>
                    <td className={"plating"}>{fixto2(sumOfGalvanizing)}</td>
                    <td className={"powderCoating"} colSpan={3}/>
                    <td className={"powderCoating"}>{fixto2(sumOfPowdercoating)}</td>
                    <td style={{textAlign:"right"}}>{fixto2(sumOfGalvanizing + sumOfPowdercoating)} {currency}</td>
                </tr>

                {prioritisation && <>
                    <tr>
                        <td>Prioritisation</td>
                        <td className={"plating"} colSpan={2}/>
                        <td className={"plating"}>{fixto2((prioritisation.markup - 1) * sumOfGalvanizing)}</td>
                        <td className={"powderCoating"} colSpan={3}/>
                        <td className={"powderCoating"}>{fixto2((prioritisation.markup - 1) * sumOfPowdercoating)}</td>
                        <td style={{textAlign: "right"}}>{fixto2((prioritisation.markup - 1) * (sumOfGalvanizing + sumOfPowdercoating))} {currency}</td>
                    </tr>
                        <tr>
                        <td>Sum With Prioritisation</td>
                        <td className={"plating"} colSpan={2}/>
                        <td className={"plating"}>{fixto2((prioritisation.markup) * sumOfGalvanizing)}</td>
                        <td className={"powderCoating"} colSpan={3}/>
                        <td className={"powderCoating"}>{fixto2((prioritisation.markup) * sumOfPowdercoating)}</td>
                        <td className={"sum"} style={{textAlign:"right"}}>{fixto2((prioritisation.markup) * (sumOfGalvanizing + sumOfPowdercoating))} {currency}</td>
                    </tr>
                </>}
            </table>

            <small>Leave galvanization and powder coating unchecked if you want the part to be untreated.</small>

            <ModalFooter>
                <Button style={{float: "right"}} onClick={onSave}>Apply</Button>
                <Button
                    color={"primary"}
                    style={{float: "right", marginRight: "10px"}}
                    onClick={async () =>
                    {
                        await onSave();
                        toggle();
                    }
                    }>Save and close</Button>
            </ModalFooter>
        </ModalBody>
    </Modal>
}
