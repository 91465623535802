import React from "react";
import {Link} from "react-router-dom";
import {QUERY_PARAMS_IS_INVENTORY} from "../../../../Constants";

const ColumnDrawJobPurchaseOrder = (headerName: string, isInventory) =>
{
    return {
        field: 'purchaseOrder.number',
        headerName: headerName,
        width: 100,
        renderCell: (params) =>
        {
            const task = params.row;
            const number = params.value
            return <Link to={`/purchaseOrderDetail/${task.purchaseOrder.id}?${QUERY_PARAMS_IS_INVENTORY}=${isInventory}`}>{number}</Link>
        },
    }
}
export default ColumnDrawJobPurchaseOrder;