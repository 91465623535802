import {QUERY_PARAMS_IS_INVENTORY} from "../Constants";

export const isInventoryPath = (location) =>
{
    const pathName = location.pathname
    if (pathName === InventoryRoute.INDEX || pathName.includes(`${InventoryRoute.INDEX}/`))
    {
        return true
    }

    const queryParams = new URLSearchParams(location.search);
    return queryParams.get(QUERY_PARAMS_IS_INVENTORY) === 'true';
}

export class InventoryRoute
{
    static INDEX = "/inventory"

    static ARTICLES = `${InventoryRoute.INDEX}/articles`
    static ARTICLE_DETAIL = `${InventoryRoute.INDEX}/articleDetail`

    static PURCHASE_ORDERS = `${InventoryRoute.INDEX}/purchase-orders`
    static PURCHASE_DETAIL = `${InventoryRoute.INDEX}/purchaseOrderDetail`

    static SUPPLIERS = `${InventoryRoute.INDEX}/suppliers`
    static SUPPLIER_DETAIL = `${InventoryRoute.INDEX}/supplierDetail`

    static REPORTS = `${InventoryRoute.INDEX}/reports`
    static STOCK_TAKING = `${InventoryRoute.INDEX}/stocktaking`
    static CODE_SCANNER = `${InventoryRoute.INDEX}/codeScannerPage`

    static ORDERS = `${InventoryRoute.INDEX}/orders`

    static DRAW_JOBS = `${InventoryRoute.INDEX}/draw-overview`

    static SCHEDULER = `${InventoryRoute.INDEX}/scheduler`
    static FINISHED_TASKS = `${InventoryRoute.INDEX}/finishedTasks`
    static IN_PRODUCTION_TASKS = `${InventoryRoute.INDEX}/in-production-tasks`

    static PROFILE = `${InventoryRoute.INDEX}/profile`
}