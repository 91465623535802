import StdQuery from "../../StdQuery";
import getFilesQuery from "../../../workflow-forms/DrawJob/graphql/GetFiles.graphql";
import {filter} from "lodash";
import {DrawingsCategoryType, ProductionDrawingsCategoryType} from "../../file/UploadFile";
import React from "react";
import FileList from "../../file/FileList";
import {useLocale} from "../../../LocaleProvider";

const DrawingsFileList = ({purchaseOrderId}) => {
    const {t} = useLocale()
    return <StdQuery query={getFilesQuery} variables={{purchaseOrderId}}>
        {data => {
            const drawings = filter(data.files, file => file.fileCategory.id === DrawingsCategoryType.id || file.fileCategory.id === ProductionDrawingsCategoryType.id);
            return <React.Fragment>
                <h4>{t('Uploaded drawing files')}</h4>
                <FileList files={drawings}
                          onDelete={() => {}}/>
            </React.Fragment>
        }
        }
    </StdQuery>
};

export default DrawingsFileList