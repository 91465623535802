import React, {memo, useCallback, useEffect, useMemo, useState} from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Table
} from "reactstrap";
import Paragraph from "../../common/Paragraph";
import {formatCurrency} from "../../UTIL";
import Pen from "../../common/icons/Pen";
import Trash from "../../common/icons/Trash";
import NewFormRenderer from "../../common/NewFormRenderer";
import {StraightStairExtraFragmentFragment} from "../../generated/graphql";
import {useLocale} from "../../LocaleProvider";

type OnDeleteClicked = (id: string) => void

type SimpleExtra = { name: string, price: number }

type ExtraModalState = {
    isOpen: boolean
    extra?: StraightStairExtraFragmentFragment | null
}

type Props = {
    extras: StraightStairExtraFragmentFragment[]
    onAddClicked: (data: SimpleExtra) => void
    onDeleteClicked: OnDeleteClicked
    onUpdatedClicked: (extra: StraightStairExtraFragmentFragment) => void
}

const ExtraModal = memo(({isOpen, toggle, extra, onAddSubmit, onUpdateSubmit}: {
    isOpen: boolean,
    toggle: () => void,
    extra?: StraightStairExtraFragmentFragment | null,
    onAddSubmit: (extra: SimpleExtra) => void,
    onUpdateSubmit: (extra: StraightStairExtraFragmentFragment) => void
}) =>
{
    const [extraState, setExtra] = useState<SimpleExtra>(extra ?? {name: "", price: 0});
    const {t} = useLocale()

    const formDefinition = useMemo(() =>
    {
        return [
            {name: "name", required: true},
            {name: "price", required: true, label: `${t('Price')}(EUR)`, type: "currency"}
        ]
    }, [t])

    const onChange = useCallback((key, value) =>
    {
        setExtra((prevState) =>
        {
            return {...prevState, [key]: value}
        })
    }, [])

    const handleOnSubmit = useCallback(() =>
    {
        if (extra)
        {
            onUpdateSubmit({id: extra.id, ...extraState})
        } else
        {
            onAddSubmit(extraState)
        }

        toggle()
    }, [extra, extraState, onAddSubmit, onUpdateSubmit, toggle])

    useEffect(() =>
    {
        setExtra(extra ?? {name: "", price: 0})
    }, [extra])

    return <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>{t('Straight stair extra')}</ModalHeader>
        <ModalBody>
            <NewFormRenderer
                formDefinition={formDefinition}
                object={extraState}
                onChange={onChange}
            />
        </ModalBody>
        <ModalFooter>
            <Button color="success" onClick={handleOnSubmit}>{t('Submit')}</Button>
        </ModalFooter>
    </Modal>
})


const ExtraRow = memo(({extra, onEditClicked, onDeleteClicked}: {
    extra: StraightStairExtraFragmentFragment,
    onEditClicked: () => void,
    onDeleteClicked: OnDeleteClicked
}) =>
{
    const handleDeleteClicked = useCallback(() =>
    {
        if (window.confirm(`Are you sure you want to remove ${extra.name}?`))
        {
            onDeleteClicked(extra.id)
        }
    }, [extra, onDeleteClicked])

    return <tr>
        <td>
            {extra.name}
        </td>
        <td>
            {formatCurrency(extra.price)}
        </td>
        <td>
            <span className="link" onClick={onEditClicked}>
                 <Pen/>
            </span>
            <span className="link" onClick={handleDeleteClicked}>
                <Trash/>
            </span>
        </td>
    </tr>
})

const StraightStairExtraPanel = memo(({extras, onAddClicked, onDeleteClicked, onUpdatedClicked,}: Props) =>
{
    const [extraModalState, setExtraModalState] = useState<ExtraModalState>({isOpen: false, extra: null})
    const {t} = useLocale()

    const toggleExtraModal = useCallback(() =>
    {
        setExtraModalState({isOpen: false, extra: null})
    }, [])

    return <>
        <Card>
            <CardHeader>
                <Paragraph style={{fontSize: "18px"}}>{t('Price Additions')}</Paragraph>
            </CardHeader>
            <CardBody>
                <Button size={"sm"} onClick={() => setExtraModalState({isOpen: true})}>{t('Add')}</Button>

                <Table responsive>
                    <thead>
                    <tr>
                        <th>{t('Name')}</th>
                        <th>{t('Price')}(EUR)</th>
                        <th>{t('Actions')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {extras.map((extra, index) =>
                        <ExtraRow key={extra.id} extra={extra} onDeleteClicked={onDeleteClicked}
                                  onEditClicked={() => setExtraModalState({isOpen: true, extra: extra})}/>)}
                    </tbody>
                </Table>
            </CardBody>
        </Card>

        <ExtraModal isOpen={extraModalState.isOpen} toggle={toggleExtraModal}
                    extra={extraModalState.extra}
                    onAddSubmit={onAddClicked}
                    onUpdateSubmit={onUpdatedClicked}/>

    </>
})

export default StraightStairExtraPanel