import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { withRouter } from 'react-router';
import ProjectForm from './ProjectForm';
import CreateProjectMutation from './graphql/CreateProject.graphql';
import {withTenant} from "../TenantProvider";

class ProjectCreatePage extends Component {

    render() {
        return (<ProjectForm onSubmit={(project) => this.createProject(project)} />);
    }
    async createProject(project) {
        let res;
        try {
            res = await this.props.createProject({
                variables: {
                    project: { ...project.project, ...{ tenantId: this.props.tenantId } },
                }
            });
        } catch (e) {
            alert(e.message);
            return;
        }

        this.props.history.push(`/projectDetail/${res.data.createProject.id}`);
    }
}
export default graphql(CreateProjectMutation, { name: "createProject" })(withRouter(withTenant(ProjectCreatePage)));
