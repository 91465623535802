import {
    HandrailOverNoOfPolesFragmentFragment,
    HandrailOverNoOfPolesKey,
    useUpdateHandrailOverNoOfPolesMutation
} from "../../../generated/graphql";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Col, FormGroup, Input, Label, Row} from "reactstrap";
import {camelToTitle} from "../../../UTIL";
import {useMutationHandler} from "../../../custom-hook/useMutationHandler";
import {sumBy} from "lodash";

const HandrailOverNoOfPolesFrom = ({handrailOverNoOfPoles, onBlur}: {
    handrailOverNoOfPoles: HandrailOverNoOfPolesFragmentFragment,
    onBlur: (e: React.ChangeEvent<HTMLInputElement>) => void,
}) =>
{
    const {key} = handrailOverNoOfPoles
    const [value, setValue] = useState("")

    const label = useMemo(() =>
    {
        switch (handrailOverNoOfPoles.key)
        {
            case HandrailOverNoOfPolesKey.NoOfPlatform:
                return "No. of platform"
            case HandrailOverNoOfPolesKey.OneOverFour:
                return "over 4"
            case HandrailOverNoOfPolesKey.OneOverThree:
                return "over 3"
            case HandrailOverNoOfPolesKey.OneOverTwo:
                return "over 2"
            default:
                return camelToTitle(handrailOverNoOfPoles.key)
        }
    }, [handrailOverNoOfPoles.key])

    const placeholder = useMemo(() =>
    {
        switch (handrailOverNoOfPoles.key)
        {
            case HandrailOverNoOfPolesKey.Railing:
                return ""
            case HandrailOverNoOfPolesKey.HandrailHook:
                return "no. of hooks"
            case HandrailOverNoOfPolesKey.Other:
                return "other"
            default:
                return "no. of poles"
        }
    }, [handrailOverNoOfPoles.key])

    useEffect(() =>
    {
        setValue(handrailOverNoOfPoles.value)
    }, [handrailOverNoOfPoles.value])

    return <FormGroup>
        <Label for={key} style={{marginRight: '4px', whiteSpace: 'nowrap'}}>
            {label}
        </Label>
        <Input
            type={key === HandrailOverNoOfPolesKey.Railing ? "textarea" : "number"}
            name={key}
            id={key}
            placeholder={placeholder}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onBlur={onBlur}/>
    </FormGroup>
}

const PoleCountValidator = ({polesCount, stepCount}: { polesCount: number, stepCount: number }) =>
{
    if (polesCount < stepCount)
    {
        return <p style={{color: 'red'}}>
            {`Error: No. of poles(${polesCount}) is less than step count(${stepCount})`}
        </p>
    }
    if (polesCount > stepCount)
        return <p style={{color: 'orange'}}>
            {`Warning: No. of poles(${polesCount}) is greater than step count(${stepCount})`}
        </p>
    return <p style={{color: 'green'}}>
        {`No. of poles(${polesCount}) matches step count(${stepCount})`}
    </p>
}

const HandrailOverNoOfPolesListPanel = ({handrailOverNoOfPolesList, onUpdate, segments}: {
    handrailOverNoOfPolesList: HandrailOverNoOfPolesFragmentFragment[],
    onUpdate: (newHandrailOverNoOfPoles: HandrailOverNoOfPolesFragmentFragment | undefined) => void
    segments?: { stepHeights?: number | null | undefined }[] | null | undefined
}) =>
{
    const {executeMutation: updateMutation} = useMutationHandler(useUpdateHandrailOverNoOfPolesMutation)

    const handleOnBlur = useCallback(async (id: string, e: React.ChangeEvent<HTMLInputElement>) =>
    {
        await updateMutation({variables: {id: id, value: e.target.value}},
            {onSuccess: (response => onUpdate(response?.updateHandrailOverNoOfPoles))})
    }, [updateMutation, onUpdate]);

    const getOrder = useCallback((item: HandrailOverNoOfPolesFragmentFragment) =>
    {
        switch (item.key)
        {
            case HandrailOverNoOfPolesKey.HandrailHook:
                return 5
            case HandrailOverNoOfPolesKey.NoOfPlatform:
                return 3
            case HandrailOverNoOfPolesKey.OneOverFour:
                return 2
            case HandrailOverNoOfPolesKey.OneOverThree:
                return 1
            case HandrailOverNoOfPolesKey.OneOverTwo:
                return 0
            case HandrailOverNoOfPolesKey.Other:
                return 4
            case HandrailOverNoOfPolesKey.Railing:
                return 6
        }
    }, []);

    const sortedHandrailOverNoOfPolesList = useMemo(() =>
    {
        return handrailOverNoOfPolesList.sort((a, b) => getOrder(a) - getOrder(b))
    }, [handrailOverNoOfPolesList, getOrder])

    const stepCount = useMemo(() =>
    {
        return sumBy(segments, (segment) =>
        {
            const stepHeights = segment?.stepHeights ?? 0
            return stepHeights > 0 ? stepHeights - 1 : 0
        })
    }, [segments]);

    const polesCount = useMemo(() =>
    {
        return sumBy(handrailOverNoOfPolesList, (e) =>
        {
            const key = e.key
            if (!(key === HandrailOverNoOfPolesKey.OneOverFour || key === HandrailOverNoOfPolesKey.OneOverThree ||
                key === HandrailOverNoOfPolesKey.OneOverTwo))
            {
                return 0
            }

            let value = parseInt(e.value)
            value = isNaN(value) ? 0 : value
            switch (e.key)
            {
                case HandrailOverNoOfPolesKey.OneOverFour:
                    return value * 4
                case HandrailOverNoOfPolesKey.OneOverThree:
                    return value * 3
                case HandrailOverNoOfPolesKey.OneOverTwo:
                    return value * 2
                default:
                    return 0
            }
        })
    }, [handrailOverNoOfPolesList])

    return handrailOverNoOfPolesList.length > 0
        ? <>
            <h5>Handrail over no. of poles</h5>
            <Row>
                {
                    sortedHandrailOverNoOfPolesList.map((handrailOverNoOfPoles, index) =>
                    {
                        return <Col md={3} key={`handrail-form-${index}`}>
                            <HandrailOverNoOfPolesFrom handrailOverNoOfPoles={handrailOverNoOfPoles}
                                                       onBlur={(e) => handleOnBlur(handrailOverNoOfPoles.id, e)}/>
                        </Col>
                    })
                }
            </Row>
            <PoleCountValidator polesCount={polesCount} stepCount={stepCount}/>
            <hr/>
        </>
        : null
}

export default HandrailOverNoOfPolesListPanel;