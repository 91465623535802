import {useCallback, useMemo, useState} from "react";
import {UpdatePurchaseOrderPriceData} from "./UpdatePurchaseOrderPriceModal";
import NotificationPopup from "../lib/NotificationPopup";
import {useMutationHandler} from "../../custom-hook/useMutationHandler";
import {
    PriceSuggestionFragmentFragment,
    useUpdateArticleRowMutation
} from "../../generated/graphql";


type Props = {
    priceSuggestion: PriceSuggestionFragmentFragment | null;
    articleId: string;
    purchaseOrder: {
        number?: string | null | undefined
        currency?: string | null | undefined
        articleRows?: {
            id: string
            price?: number | null | undefined
            article?: {
                id?: string | null | undefined
            } | null | undefined
        }[] | null | undefined
    } | null | undefined;
    onSuccess?: (newArticleRow?: { id: string, price?: number | null | undefined }) => void;
};


const useUpdatePurchaseOrderPriceHook = ({purchaseOrder, priceSuggestion, articleId, onSuccess}: Props) =>
{
    const [newPriceModalData, setNewPriceModalData] = useState<UpdatePurchaseOrderPriceData | null>(null)

    const {
        executeMutation: updateArticleRowMutation,
        loading: updateArticleRowLoading
    } = useMutationHandler(useUpdateArticleRowMutation)

    const closeNewPriceModal = useCallback(() =>
    {
        setNewPriceModalData(null)
    }, []);

    const updatePurchaseOrderPrice = useCallback(async () =>
    {
        if (!newPriceModalData || !newPriceModalData.articleRow.id)
        {
            return
        }
        await updateArticleRowMutation({
            variables: {
                id: newPriceModalData.articleRow.id,
                articleRow: {price: newPriceModalData.newPrice}
            },
            refetchQueries: ['straightStairV2']
        }, {
            onSuccess: (response) =>
            {
                if (response?.updateArticleRow)
                {
                    onSuccess?.(response?.updateArticleRow)
                }
                closeNewPriceModal()
                NotificationPopup.success(`Updated Purchase Order`);
            }
        });
    }, [newPriceModalData, updateArticleRowMutation, closeNewPriceModal, onSuccess])

    const articleRowInPurchaseOrder = useMemo(() =>
    {
        return purchaseOrder?.articleRows?.find((e) => e?.article?.id === articleId)
    }, [purchaseOrder, articleId]);

    const checkNewPriceData = useCallback((newPrice?: number) =>
    {
        const newInPrice = newPrice || priceSuggestion?.inPrice;
        if (!purchaseOrder || !newInPrice)
        {
            return
        }

        const formatNewPrice = Math.round(newInPrice * 100) / 100

        if (articleRowInPurchaseOrder && articleRowInPurchaseOrder.price !== formatNewPrice)
        {
            setNewPriceModalData({
                articleRow: articleRowInPurchaseOrder,
                newPrice: formatNewPrice,
                purchaseOrder: purchaseOrder,
            })
        }
    }, [priceSuggestion, purchaseOrder, articleRowInPurchaseOrder]);

    return {
        newPriceModalData, closeNewPriceModal,
        updatePurchaseOrderPrice, checkNewPriceData,
        updateArticleRowLoading,
        articleRowInPurchaseOrder
    }
}

export default useUpdatePurchaseOrderPriceHook