import React, {Component} from 'react';
import {graphql} from "@apollo/client/react/hoc";
import {withRouter} from 'react-router';
import _ from 'lodash';
import FormRenderer from "../common/FormRenderer";
import CreatePurchaseOrderMutation from './graphql/CreatePurchaseOrder.graphql';
import logger from "../Logger";
import {PurchaseOrderCreateFormDef} from "./PurchaseOrderFormDef";
import {Col, Row} from "reactstrap";
import {withTenant} from "../TenantProvider";

class PurchaseOrderCreatePage extends Component
{



    async createPurchaseOrder(form)
    {
        const res = await this.props.createPurchaseOrder({
            variables: {
                purchaseOrder: _.extend(form.object, {tenantId: this.props.tenantId})
            }
        });
        if(!res.data)
        {
            //TODO: NOTIFICATION ERROR
            logger.error(`Error when creating purchase order`,{res});
            alert(`Something went wrong..`);
        }
        else
        {
            const id = res.data.createPurchaseOrder.id;
            this.props.history.push(`/purchaseOrderDetail/${id}`);
        }

    }

    render()
    {
        return <Row>
            <Col sm={4}>
                <FormRenderer columns={1} onSubmit={this.createPurchaseOrder.bind(this)}
                              formDefinition={PurchaseOrderCreateFormDef}/>
            </Col>
        </Row>

    }



}

export default graphql(CreatePurchaseOrderMutation, {name: "createPurchaseOrder"})(withRouter(withTenant(PurchaseOrderCreatePage)));

