import React, {useCallback} from 'react';
import StdQuery from "./StdQuery";
import {Link} from "react-router-dom";
import {Button} from "reactstrap";
import gql from "graphql-tag";
import {withRouter} from "react-router";
import {useLocale} from "../LocaleProvider";

const GetArticleRow = gql`
    query articleRow($id:ID!)
    {
        articleRow(id:$id)
        {
            id
            quoteId
            orderId
            purchaseOrderId
        }
    }
`;

const CustomButton = ({style, label, onClick, link}) => {
    const content = <Button style={style} size="sm" color="warning" onClick={onClick}>
        {label}
    </Button>

    return link ? <Link to={link} style={{textDecoration: 'none'}}>
            {content}
        </Link>
        : content
}

const BackButton = ({articleRowId, style = {}, history}) => {
    const {t} = useLocale()

    const handleBack = useCallback(() => {
        history.goBack()
    }, [history])

    if (history.length > 1) {
        return <CustomButton style={style} onClick={handleBack} label={t('Back')}/>
    }

    if(articleRowId)
    {
        return <StdQuery noLoadingRender query={GetArticleRow} variables={{id:articleRowId}}>
            {data=>
            {
                if (data.articleRow) {
                    const {quoteId, orderId, purchaseOrderId} = data.articleRow;
                    return <React.Fragment>
                        {orderId && <CustomButton style={style} label={'Go back to order'}
                                                  link={`/orderDetail/${orderId}`}/>}
                        {quoteId && <CustomButton style={style} label={'Go back to quote'}
                                                  link={`/quoteDetail/${quoteId}`}/>}
                        {purchaseOrderId && <CustomButton style={style} label={'Go back to PO'}
                                                          link={`/purchaseOrderDetail/${purchaseOrderId}`}/>}
                    </React.Fragment>
                }
                return null;
            }
            }
        </StdQuery>
    }
    else
    {
        return null;
    }
};

export default withRouter(BackButton);
