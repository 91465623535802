import {Dispatch, SetStateAction, useCallback} from "react";
import {StraightStairV2FragmentFragment, StringerMaterial} from "../../generated/graphql";
import {convertStepTypeToPlatformType, isAsnEnabled} from "../StraightStairUtils";

type Props = {
    setStraightStairV2: Dispatch<SetStateAction<StraightStairV2FragmentFragment | null>>
}

const useUpdateStraightStairHook = ({setStraightStairV2}: Props) =>
{
    const updateRailingAttributes = useCallback((stair: StraightStairV2FragmentFragment, value: any) =>
    {
        const updatedStair = {...stair, ...value};
        updatedStair.segments.forEach(segment =>
        {
            segment.platformRailingExecution = value.railingStandard;
            segment.platformRailingType = value.railingType;
        });
        return updatedStair;
    }, [])

    const updateStepType = useCallback((stair: StraightStairV2FragmentFragment) =>
    {
        if (isAsnEnabled(stair.stepType))
        {
            stair.close = false;
        } else
        {
            stair.asn = false;
        }
        stair.segments.forEach(segment =>
        {
            segment.platformType = convertStepTypeToPlatformType(stair.stepType, segment.platformWidth);
        });
    }, [])

    const updateSegmentPreferredMaterial = useCallback((stair: StraightStairV2FragmentFragment, isSame: boolean) =>
    {
        stair.segments.forEach(segment =>
        {
            if (isSame || segment.platformIntegrated)
            {
                segment.platformMaterialType = stair.preferredMaterial;
            }
        });
    }, [])

    const updateSegmentStringerMaterial = useCallback((stair: StraightStairV2FragmentFragment, isSame: boolean) =>
    {
        stair.segments.forEach(segment =>
        {
            if (isSame || segment.platformIntegrated)
            {
                segment.platformFrameMaterial = stair.stringerMaterial;
            }
        });
    }, [])

    const updateSegmentMaxLoad = useCallback((stair: StraightStairV2FragmentFragment) =>
    {
        stair.segments.forEach(segment =>
        {
            segment.platformMaxLoad = stair.maxLoad;
        });
    }, [])

    const updateStraightStair = useCallback((key: string, value: any) =>
    {
        setStraightStairV2(prevState =>
        {
            if (!prevState)
            {
                return null
            }
            let newStair = {...prevState};

            if (key === "stairTypeAndRailing")
            {
                newStair = updateRailingAttributes(newStair, value);
            } else
            {
                newStair = {...prevState, [key]: value}
            }

            if (key === "stepType")
            {
                updateStepType(newStair)
            }
            if (key === "close")
            {
                newStair.kickplate = true
            }

            if (key === "maxLoad")
            {
                updateSegmentMaxLoad(newStair)
            }

            if (key === "preferredMaterial" || key === "isSamePreferredMaterial")
            {
                updateSegmentPreferredMaterial(newStair, newStair.isSamePreferredMaterial)
            }

            if (key === "stringerMaterial" || key === "isSameStringerMaterial")
            {
                updateSegmentStringerMaterial(newStair, newStair.isSameStringerMaterial)
            }
            return newStair
        })
    }, [setStraightStairV2, updateRailingAttributes, updateSegmentMaxLoad,
        updateSegmentPreferredMaterial, updateSegmentStringerMaterial, updateStepType])

    const updateStringerMaterial = useCallback((options: StringerMaterial[]) =>
    {
        if (options.length <= 0)
        {
            return
        }
        setStraightStairV2(prevState =>
        {
            if (!prevState)
            {
                return prevState
            }

            const oldStringerMaterial = prevState.stringerMaterial
            let newStringMaterial = oldStringerMaterial
            const minOption = options[0]

            const includeOldValue = options.includes(oldStringerMaterial)
            if (!includeOldValue)
            {
                newStringMaterial = minOption
            } else if (prevState.isMinStringerMaterial && minOption !== oldStringerMaterial)
            {
                newStringMaterial = minOption
            }

            const needUpdateSegments = prevState.segments.filter((segment) =>
            {
                if (segment.platformIntegrated || prevState.isSameStringerMaterial)
                {
                    return segment.platformFrameMaterial !== newStringMaterial
                }
                return !options.includes(segment.platformFrameMaterial)
            })

            if (newStringMaterial !== oldStringerMaterial || needUpdateSegments.length > 0)
            {
                needUpdateSegments?.forEach((e) => e.platformFrameMaterial = newStringMaterial)
                return {...prevState, stringerMaterial: newStringMaterial}
            }
            return prevState
        })
    }, [setStraightStairV2])

    return {updateStraightStair, updateStringerMaterial}
}

export default useUpdateStraightStairHook;