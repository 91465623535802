import React, {useMemo, useState} from 'react';
import gql from 'graphql-tag';
import {useMutation} from '@apollo/client';
import _, {filter, get} from 'lodash';
import {Link} from 'react-router-dom';
import {Button, ButtonGroup, Input, Modal, ModalBody, ModalHeader, Table} from "reactstrap";
import StdQuery from "../common/StdQuery";
import updateArticleRowMutation from '../common/graphql/UpdateArticleRow.graphql';
import NotificationPopup from "../common/lib/NotificationPopup";
import Clear from "../common/icons/Clear";
import Dynamic from "../common/Dynamic";
import Checkmark from "../common/icons/Checkmark";
import SurfaceTreatmentTag from "../common/SurfaceTreatmentTag";
import ColumnId from "../common/data-grid/columns/ColumnId";
import ColumnSupplierName from "../common/data-grid/columns/ColumnSupplierName";
import ColumnDate from "../common/data-grid/columns/ColumnDate";
import PaginatedDataGrid from "../common/data-grid/PaginatedDataGrid";
import {Comparator, PurchaseOrdersLogisticsDocument} from "../generated/graphql";
import ColumnAssignee from "../common/data-grid/columns/ColumnAssignee";
import ColumnCreatedAt from "../common/data-grid/columns/ColumnCreatedAt";
import {articleDetailLink} from "../utils/ArticleUtils";
import {SUPPLIER_ID_ESPL} from "../Constants";

const markAsReceived = gql`
    mutation markAsReceived($id:ID!)
    {
        markAsReceived(id:$id)
    }`;


const getPO = gql`
    query getPurchaseOrder($id:ID!)
    {
        purchaseOrder(id:$id)
        {
            id
            articleRows
            {
                id
                quantity
                name
                receivedQuantity
                article
                {
                    id
                    artNo
                }
            }
        }
    }`;

const columns = (itemClicked) => {
    return [
        {
            field: 'number',
            headerName: 'Number',
            searchable: true,
            renderCell: (params) => <Link to={`/purchaseOrderDetail/${params.id}`}>{params.value}</Link>
        },
        ColumnSupplierName(),
        {
            field: 'order.number',
            headerName: 'Order number',
            searchable: true,
        },
        ColumnAssignee(),
        ColumnDate('deliveryDate', 'Delivery date'),
        {
            field: 'articleRows',
            headerName: 'Articles',
            sortable: false,
            searchable: true,
            customFilterField: 'articleRows.some.article.artNo',
            customSearchField: 'articleRows.some.article.artNo',
            renderCell: (params) => {
                const articleRows = params.value
                const articles = filter(articleRows, ar => ar.article)
                    .map(ar => ar.article);
                const stockArticles = filter(articleRows, ar => ar.stockArticle);
                return <div>
                    {articles.map(article =>
                        <p key={article.id} style={{margin: 0}}>
                            {articleDetailLink(article, article.artNo)} {article.spiralStair &&
                            <SurfaceTreatmentTag spiralStair={article.spiralStair}/>}
                        </p>)}
                    {stockArticles.length > 0 && <p style={{margin: 0}}>{stockArticles.length} Stock articles</p>}
                </div>
            }
        },
        {
            field: 'markAsReceived',
            headerName: 'Mark as received',
            width: 170,
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return <Button color="warning"
                               onClick={() => {
                                   itemClicked(params.row)
                               }
                               }>
                    Received goods
                </Button>
            }
        },
        ColumnCreatedAt(),
        ColumnId(),
    ]
}

const defaultSort = {sortModel: [{field: 'createdAt', sort: 'desc'}]}

const columnVisibilityModel = {
    columnVisibilityModel: {
        id: false,
        createdAt: false,
    }
}

const KEY_TABLE = "PURCHASE_ORDER_LOGISTICS"

export const Actions = ({articleRow: {id, receivedQuantity, quantity}}) => {
    const [inputQuantity, setInputQuantity] = useState(1.0);
    const [updateArticleRow] = useMutation(updateArticleRowMutation, {refetchQueries: ["getPurchaseOrder"]});
    const setQuantity = async (quantity) => {
        if (!quantity) {
            NotificationPopup.error(`Please fill in the quantity`)
            return
        }

        await updateArticleRow({
            variables: {
                id, articleRow: {receivedQuantity: quantity, receivedDate: new Date()}
            }
        });
        NotificationPopup.success(`Updated received quantity.`);
    };
    return <React.Fragment>
        <Input type={"number"} style={{display: "inline", width: "70px"}} value={inputQuantity}
               onChange={e => setInputQuantity(parseFloat(e.target.value))}/>
        <ButtonGroup style={{display: "inline", fontSize: "12px"}} vertical={false}>
            {/*<span className={"link"} style={{height:"100%",color:"green",paddingLeft:"15px",paddingRight:"25px"}}*/}
            {/*       >*/}

            {/*</span>*/}
            <Button color={"success"} size={"sm"} onClick={() => setQuantity(inputQuantity + receivedQuantity)}>
                <Checkmark/>
            </Button>
            <Button size={"sm"} color={"info"} onClick={() => setQuantity(0.0)}>
                <Clear/>
            </Button>
            <Button color={"warning"} size={"sm"} onClick={() => setQuantity(quantity)}>
                All <Checkmark/>
            </Button>

        </ButtonGroup>
    </React.Fragment>
};

const PurchaseOrderLogistics = () => {
    const [markRec] = useMutation(markAsReceived, {refetchQueries: ["PurchaseOrdersLogistics"]});
    const [purchaseOrder, setPurchaseOrder] = useState(null);
    const toggle = () => {
        setPurchaseOrder(null);
    };

    const persistFilter = useMemo(() => {
        return [
            {key: 'status', value: ['sent'], comparator: Comparator.In},
            {key: 'supplierId', value: SUPPLIER_ID_ESPL, comparator: Comparator.NotEqual}
        ]
    }, []);

    return <div>
        <Dynamic load={() => import('./BarcodeScanner')}/>
        {purchaseOrder && <Modal size={"lg"} isOpen={purchaseOrder != null} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                Receiving goods
            </ModalHeader>
            <ModalBody>
                <StdQuery query={getPO} variables={{id: purchaseOrder.id}}>
                    {data => {
                        if (!data.purchaseOrder) {
                            return <p color={"red"}>Failed to get Purchase order data.</p>
                        }
                        const purchaseOrder = data.purchaseOrder;
                        return <Table striped bordered responsive>
                            <thead>
                            <tr>
                                <th>Art No</th>
                                <th>Name</th>
                                <th>Quantity</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {purchaseOrder.articleRows.map((ar) => {
                                const {id, receivedQuantity, quantity, article, name} = ar;
                                return <tr key={id}>
                                    <td>{get(article, `artNo`)}</td>
                                    <td>
                                        <div dangerouslySetInnerHTML={{
                                            __html: _.reduce(_.chunk(name.split(""), 10), (acc, chunk) => {
                                                return acc + _.reduce(chunk, (acc, char) => acc + char, "") + "<br/>";
                                            }, "")
                                        }}/>
                                    </td>
                                    <td><span style={{color: "green"}}>{receivedQuantity}</span> / <span
                                        className={"text-primary"}>{quantity}</span></td>
                                    <td style={{width: "350px"}}>
                                        <Actions articleRow={ar}/>
                                    </td>
                                </tr>

                            })}
                            </tbody>
                        </Table>
                    }}
                </StdQuery>

                <Button color={"warning"} onClick={async () => {
                    if (window.confirm(`Has everything in this PO been received?`)) {
                        try {
                            await markRec({
                                variables: {
                                    id: purchaseOrder.id
                                }
                            });
                        } catch (e) {
                            console.error(e);
                            NotificationPopup.error(`Failed to mark purchase order as ready. ${e.message}`)
                        } finally {
                            setPurchaseOrder(null);
                        }

                    }
                }
                }>Mark PO as ready</Button>
            </ModalBody>
        </Modal>}

        <div style={{height: '8px'}}></div>
        <PaginatedDataGrid
            definition={{
                tableKey: KEY_TABLE,
                columns: columns((purchaseOrder) => {
                    setPurchaseOrder(purchaseOrder)
                }),
                initState: {
                    sorting: defaultSort,
                    columns: columnVisibilityModel,
                },
            }}
            query={PurchaseOrdersLogisticsDocument}
            persistFilter={persistFilter}
        />
    </div>
};

export default PurchaseOrderLogistics