import React, {useMemo} from "react";
import {camelToTitle} from "../UTIL";
import ColumnDate from "./data-grid/columns/ColumnDate";
import NonPaginatedDataGrid from "./data-grid/NonPaginatedDataGrid";


export type Difference = {
    [key: string]: { oldValue: any; newValue: any };
};

export const findDifferences = (oldValue: any, newValue: any, path: string = ""): Difference =>
{
    const differences: Difference = {};

    const keys = new Set([...Object.keys(oldValue), ...Object.keys(newValue)]);
    const ignoreKeys = ["id", "updatedAt", "createdAt"]
    const keysArray = Array.from(keys).filter(key => !ignoreKeys.includes(key));

    for (const key of keysArray)
    {
        const fullPath = path ? `${path}.${key}` : key;
        const valueA = oldValue[key];
        const valueB = newValue[key];

        if (Array.isArray(valueA) && Array.isArray(valueB))
        {
            if (valueA.length !== valueB.length)
            {
                differences[fullPath] = {oldValue: valueA, newValue: valueB};
            } else
            {
                for (let i = 0; i < valueA.length; i++)
                {
                    const diff = findDifferences(valueA[i], valueB[i], `${fullPath}[${i}]`);
                    Object.assign(differences, diff);
                }
            }
        } else if (typeof valueA === "object" && typeof valueB === "object" && valueA && valueB)
        {
            const nestedDifferences = findDifferences(valueA, valueB, fullPath);
            Object.assign(differences, nestedDifferences);
        } else if (valueA !== valueB)
        {
            differences[fullPath] = {oldValue: valueA, newValue: valueB};
        }
    }

    return differences;
}

export const formatChanges = (changes: Difference): string =>
{
    return Object.entries(changes)
        .map(([key, {oldValue, newValue}]) => `"${camelToTitle(key)}" from ${oldValue} to ${newValue}`)
        .join(', ');
}

type Data = {
    message: string | null
    createdAt: string
    creator?: { displayName: string } | undefined | null
}

const ChangeLogsTable = ({tableKey, data}: { tableKey: string, data?: Data[] }) =>
{
    const columns = useMemo(() =>
    {
        return [
            {
                field: 'message',
                sortable: false,
                width: 500,
            },
            {
                field: 'creator',
                valueGetter: (params) => params.value?.displayName
            },
            ColumnDate("createdAt", "Date")
        ]
    }, [])

    return <NonPaginatedDataGrid
        definition={{
            tableKey: tableKey,
            pageSize: 100,
            columns: columns,
        }}
        data={data}/>
}

export default ChangeLogsTable