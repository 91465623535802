import React, {useCallback, useMemo, useState} from 'react';
import gql from "graphql-tag";
import StdQuery from "../common/StdQuery";
import {Card, CardBody, Col, Table} from "reactstrap";
import _, {filter, get} from "lodash";
import Collapser from "../common/Collapser";
import GetTaskQuery from '../new-task/graphql/GetTask.graphql'
import GenericWorkflowForm from "../common/GenericWorkflowForm";
import NonPaginatedDataGrid from "../common/data-grid/NonPaginatedDataGrid";
import ColumnDate from "../common/data-grid/columns/ColumnDate";
import ColumnCreatedAt from "../common/data-grid/columns/ColumnCreatedAt";
import {useLocale} from "../LocaleProvider";
import {
    PROCESS_DEFINITION_KEY_ESPL_DRAW_JOB,
    PROCESS_DEFINITION_KEY_ESPR_DRAW_JOB,
    TENANT_ID_ESPL,
} from "../Constants";
import ColumnDrawJobPurchaseOrder from "../common/data-grid/columns/draw-job/ColumnDrawJobPurchaseOrder";
import ColumnDrawJobOrder from "../common/data-grid/columns/draw-job/ColumnDrawJobOrder";
import ColumnPurchaseOrderPrioritisation from "../common/data-grid/columns/ColumnPurchaseOrderPrioritisation";
import ColumnDrawJobArticles from "../common/data-grid/columns/draw-job/ColumnDrawJobArticles";
import {useTenant} from "../TenantProvider";
import {isInventoryPath} from "../inventory/InventoryRouters";
import {withRouter} from "react-router";
import Auth from "../Auth";
import {useClaimTaskMutation} from "../generated/graphql";

const TABLE_KEY = "TABLE_DRAW_JOB"

const query = gql`
    query tasks($processDefinitionKey:String!)
    {
        tasks(processDefinitionKey:$processDefinitionKey)
        {
            id
            taskDefinitionKey
            activityIds
            name
            createdAt
            variables
            assignee
            purchaseOrder {
                id
                number
                deliveryDate
                articleRows {
                    id
                    article {
                        id
                        artNo
                        spiralStair {
                            id
                            stepType
                            segments {
                                id
                            }
                            surfaceTreatmentOverview {
                                type
                            }
                        }
                        straightStair {
                            id
                        }
                        straightStairV2 {
                            id
                        }
                    }
                }
            }
            order{
                number
                id
                preferredDeliveryDate
                assignee{
                    displayName
                    username
                }
                company {
                    id
                    name
                }
                project {
                    id
                    name
                }
                prioritisation {
                    id
                    name
                    level
                }
            }
        }
    }
`;

const sectionCount = tasks => _.sumBy(_.flatMap(tasks, "variables.articles")
    .concat(_.flatMap(tasks, "variables.autoDrawArticles")), article =>
    _.get(article, "spiralStair.segments.length") || 0);

const TasksCard = ({label, tasks, collapsed = false, columns}) => {
    return <Card key={label} style={{border: "0", marginBottom: "15px"}}>
        <CardBody style={{padding: "0"}}>
            <h4>{label}</h4>
            <Collapser open={!collapsed}>
                <NonPaginatedDataGrid definition={{
                    tableKey: TABLE_KEY,
                    columns: columns,
                    pageSize: 100,
                    initState: {
                        sorting: {sortModel: [{field: "createdAt", sort: "asc"}]}
                    }
                }} data={tasks ?? []}/>
            </Collapser>
        </CardBody>
    </Card>
}

const SummarizeTableCell = ({task, shouldDisplaySection}) => {
    const {t} = useLocale()
    return <div>
        <div style={{whiteSpace: 'nowrap'}}>{`${task.length} ${t('draw jobs')}.`}</div>
        <div
            style={{whiteSpace: 'nowrap'}}>{`${t('With')} ${_.sumBy(task, 'variables.articles.length')} ${t('articles')}.`}</div>
        {shouldDisplaySection && <>
            <div
                style={{whiteSpace: 'nowrap'}}>{`(${_.sumBy(task, "variables.autoDrawArticles.length") || 0} auto)`}</div>
            <div style={{whiteSpace: 'nowrap'}}>{`${t('Sections')}: ${sectionCount(task)}`}</div>
        </>}
    </div>
}

const DrawJobOverviewPage = (props) => {
    const [selectedTask, setSelectedTask] = useState(null)
    const {t} = useLocale()
    const {tenantId: currentTenantId} = useTenant()
    const [claimTaskMutation] = useClaimTaskMutation()

    const tenantId = useMemo(() => {
        return props?.match?.params?.tenantId?.toUpperCase() ?? currentTenantId
    }, [props?.match?.params?.tenantId, currentTenantId])

    const isESPL = useMemo(() => {
        return tenantId === TENANT_ID_ESPL
    }, [tenantId])

    const processDefinitionKey = useMemo(() => {
        return isESPL
            ? PROCESS_DEFINITION_KEY_ESPL_DRAW_JOB
            : PROCESS_DEFINITION_KEY_ESPR_DRAW_JOB
    }, [isESPL])

    const claimTask = useCallback(async (task) => {
        if (window.confirm(`Are you sure you wish to assign the task: "${task.name}" to you?`)) {
            await claimTaskMutation({
                variables: {
                    id: task.id,
                },
                refetchQueries: ["tasks"]
            });
            setSelectedTask(task)
        }
    }, [claimTaskMutation, setSelectedTask])

    const columns = useMemo(() => {
            const isOnInventory = isInventoryPath(props.location)

            const po = isOnInventory ? ColumnDrawJobPurchaseOrder('Order No.', true)
                : ColumnDrawJobPurchaseOrder('Purchase order no.', false)

            const orderHeader = isOnInventory ? 'Customer order no.' : 'Order No.'
            const poPreferredDeliveryDateHeader = isOnInventory ? "Preferred Delivery Date" : "PO Preferred Delivery Date"
            const orderPreferredDeliveryDateHeader = isOnInventory ? "Customer Order Preferred Delivery Date" : "Preferred Delivery Date"

            return [
                {
                    field: 'claimTask',
                    headerName: 'Claim task',
                    filterable: false,
                    sortable: false,
                    width: 100,
                    renderCell: (params) => {
                        const task = params.row
                        if (!task.assignee) {
                            return <span className='link' onClick={() => claimTask(task)}>
                                    {t('Claim task')}
                                </span>
                        }
                        if (task.assignee === Auth.getUsername()) {
                            return <span className="link" onClick={() => setSelectedTask(task)}>
                                    {t('Open task')}
                                </span>
                        }
                        return <span>{t('Claimed')}</span>
                    },
                },
                po,
                ColumnDrawJobOrder(orderHeader),
                ColumnPurchaseOrderPrioritisation('order.prioritisation'),
                {
                    field: 'order.assignee.displayName',
                    headerName: 'Order Assignee',
                },
                {
                    field: 'order.company.name',
                    headerName: 'Company name',
                    renderCell: (params) => <strong>{params.value}</strong>
                },
                {
                    field: 'order.project.name',
                    headerName: 'Project name',
                    renderCell: (params) => <p style={{fontStyle: "italic"}}>{params.value}</p>
                },
                ColumnCreatedAt(),
                ColumnDrawJobArticles(isOnInventory),
                ColumnDate("purchaseOrder.deliveryDate", poPreferredDeliveryDateHeader),
                ColumnDate("order.preferredDeliveryDate", orderPreferredDeliveryDateHeader),
                {
                    field: 'assignee',
                    headerName: 'Task Assignee',
                },
                {
                    field: isESPL ? 'variables.engineer' : 'variables.drafter',
                    headerName: 'Drafter'
                },
            ]
        },
        [props.location, isESPL, t, claimTask])

    const userTasks = useMemo(() => {
        return [
            {key: "UploadCustomerDrawings", label: t("Draw Jobs")},
            {key: "ProvideMoreInformation", label: t("Seller provides more information")},
            {key: "ReviewMoreInformation", label: t("Engineer reviews provided information")},
            {key: "SendCustomerDrawing", label: t("Send Customer Drawing"), collapsed: true},
            {key: "CustomerResponse", label: t("Waiting for customer's approval"), collapsed: true},
            {key: "ReviseDrawing", label: t("Customer waiting for new drawings")},
            {key: "ProvideInformationForRevise", label: t("Seller provides more information for revise")},
            {key: "UploadProductionDrawing", label: t("Waiting for production drawing")},
            {key: "ReviewDrawings", label: t("Review drawings")},
        ];
    }, [t]);

    const task = selectedTask;
    return (
        <div>
            {task && <StdQuery noLoadingRender query={GetTaskQuery} variables={{id: get(task, "id")}}>
                {data => {
                    return <GenericWorkflowForm isOpen={true} task={data.task}
                                                toggle={() => setSelectedTask(null)}/>;
                }}
            </StdQuery>}
            <StdQuery query={query} variables={{processDefinitionKey: processDefinitionKey}}>
                {data => {
                    const groupedTasks = _.groupBy(data.tasks, task => task.taskDefinitionKey);
                    return <div>
                        <Col sm={12}>
                            <Table bordered responsive>
                                <thead>
                                <tr>
                                    {_.map(userTasks, ({key, label}) => {
                                            if (key === "UploadCustomerDrawings") {
                                                return <React.Fragment key={key}>
                                                    <th key={key}>Unassigned {label}</th>
                                                    <th key={key}>Assigned {label}</th>
                                                </React.Fragment>
                                            }
                                            return <th key={key}>{label}</th>
                                        }
                                    )}
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    {_.map(userTasks, ({key, label}) => {
                                        const tasks = groupedTasks[key] || [];
                                        if (key === "UploadCustomerDrawings") {
                                            const assignedTasks = filter(tasks, t => t.assignee);
                                            const unAssignedTasks = filter(tasks, t => !t.assignee);

                                            return <React.Fragment key={key}>
                                                <td>
                                                    <SummarizeTableCell task={unAssignedTasks}
                                                                        shouldDisplaySection={!isESPL}/>
                                                </td>
                                                <td>
                                                    <SummarizeTableCell task={assignedTasks}
                                                                        shouldDisplaySection={!isESPL}/>
                                                </td>
                                            </React.Fragment>
                                        }
                                        return <td key={key}>
                                            <SummarizeTableCell task={tasks}
                                                                shouldDisplaySection={!isESPL}/>
                                        </td>
                                    })}
                                </tr>
                                </tbody>
                            </Table>
                        </Col>

                        <p>{`${data.tasks.length} ${t('draw jobs')}. 
                        ${t('With')} ${_.sumBy(data.tasks, 'variables.articles.length') + _.sumBy(data.tasks, 'variables.autoDrawArticles.length')} ${t('articles')}.
                        ${!isESPL ? `${t('Sections')}:` + sectionCount(data.tasks) : ''}`}</p>
                        {_.map(userTasks, obj => {
                            const key = obj.key;
                            const label = obj.label;
                            const tasks = groupedTasks[key];
                            if (key === "UploadCustomerDrawings") {
                                return <React.Fragment key={key}>
                                    <TasksCard label={`Unassigned ${label}`} tasks={filter(tasks, t => !t.assignee)}
                                               columns={columns}/>
                                    <TasksCard label={`Assigned ${label}`} tasks={filter(tasks, t => t.assignee)}
                                               columns={columns}/>
                                </React.Fragment>
                            } else {
                                return <TasksCard label={label} tasks={tasks} collapsed={obj.collapsed}
                                                  columns={columns}/>
                            }
                        })}
                    </div>
                }}
            </StdQuery>
        </div>
    );
}

export default withRouter(DrawJobOverviewPage);
