import React, {useCallback, useEffect, useState} from 'react';
import './App.css';
import {
    Col,
    Collapse,
    Dropdown,
    DropdownItem, DropdownMenu,
    DropdownToggle,
    Nav,
    Navbar,
    NavbarToggler,
    NavItem,
    Row
} from 'reactstrap';
import {NavLink} from 'react-router-dom';
import Auth from './Auth';
import FreeTextSearch from './FreeTextSearch';
import 'assets/images/menu2.jpg';
import NavBarDropDown from "./NavBarDropDown";
import NewTasksIcon from "./common/NewTasksIcon";
import PastDueTasksIcon from "./common/PastDueTasksIcon";
import 'react-notifications-component/dist/theme.css'
import config, {environment} from "./config";
import {withRouter} from "react-router";


const LogisticsNavDropDown = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = useCallback(() => {
        setIsOpen(prevState => !prevState)
    }, [setIsOpen])

    const to = useCallback((path) => {
        props.history.push(path);
    }, [props.history])

    return <Dropdown isOpen={isOpen} toggle={toggle}>
        <DropdownToggle nav caret>
            Logistics
        </DropdownToggle>
        <DropdownMenu>
            <DropdownItem onClick={() => to('/logistics')}>
                Logistics
            </DropdownItem>
            <DropdownItem onClick={() => to('/shipments')}>
                Shipment
            </DropdownItem>
            <DropdownItem onClick={() => to('/packages')}>
                Package
            </DropdownItem>
        </DropdownMenu>
    </Dropdown>
}

const DrawJobDropDown = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = useCallback(() => {
        setIsOpen(prevState => !prevState)
    }, [setIsOpen])

    const to = useCallback((path) => {
        props.history.push(path);
    }, [props.history])

    return <Dropdown isOpen={isOpen} toggle={toggle}>
        <DropdownToggle nav caret>
            Draw job
        </DropdownToggle>
        <DropdownMenu>
            <DropdownItem onClick={() => to('/draw-overview/espr')}>
                ESPR
            </DropdownItem>
            <DropdownItem onClick={() => to('/draw-overview/espl')}>
                ESPL
            </DropdownItem>
        </DropdownMenu>
    </Dropdown>
}

const Header = (props)=>
{
    const [isOpen,setIsOpen] = useState(false);
    const [navBarClass, setNavBarClass] = useState("");
    const toggle = ()=>setIsOpen(open=>!open);

    useEffect(()=>{
        if(config.environment === environment.LOCAL){
            setNavBarClass("debugMode");
        }
        if(config.environment === environment.DEVELOP){
            setNavBarClass("debugMode");
        }
    }, [setNavBarClass]);


    return <Row>
        <Col sm={12} style={{ padding: 0 }}>
            <Navbar className={`header-navbar text-dark navbar-light ${navBarClass}`} expand="md">
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isOpen} navbar>

                    <Nav navbar pills>
                        {config.environment === environment.LOCAL && <span style={{color:"#e8ca9b", fontWeight:"bold", paddingTop:"8px"}}>DEBUG</span>}
                        {config.environment === environment.DEVELOP && <span style={{color:"#e8ca9b", fontWeight:"bold", paddingTop:"8px"}}>DEVELOP</span>}
                        <NavItem>
                            <NavLink exact to="/" className="nav-link">Tasks &nbsp;<NewTasksIcon />&nbsp;<PastDueTasksIcon /></NavLink>
                        </NavItem>
                        {/*Tasks first, quotes, companies, orders, draw job overview, scheduler and logistics.*/}
                        <NavItem>
                            <NavLink to={`/quotes`}
                                     className="nav-link">
                                Quotes
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to="/companies" className="nav-link">Companies</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink isActive={(match, location) => location.pathname === "/orders"}
                                     to={`/orders`}
                                     className="nav-link">Orders
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <DrawJobDropDown history={props.history}/>
                    <LogisticsNavDropDown history={props.history}/>
                    <NavBarDropDown />
                    <Col sm={3} style={{ marginBottom: 10 }}>
                        <FreeTextSearch className="freetext-search" getSearchAllTenant={Auth.getSearchAllTenant}/>
                    </Col>
                </Collapse>
            </Navbar>

        </Col>
    </Row>
}

export default withRouter(Header);