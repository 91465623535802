import React, {createContext, useCallback, useContext, useEffect, useState} from 'react';
import gettextParser from "gettext-parser";
import {LocaleType} from "./types/LocaleType";
import gql from "graphql-tag";
import {useQuery} from "@apollo/client";

type LocaleContextType = {
    locale: string;
    setLocale: (locale: LocaleType) => void;
    t: (key: string) => string;
};

type Translations = {
    [key: string]: string;
};

const defaultLocaleQuery = gql`
    query userProfile
    {
        userProfile
        {
            locale
        }
    }`;

const LocaleContext = createContext<LocaleContextType | undefined>(undefined);

export const LocaleProvider: React.FC<{ children: React.ReactNode }> = ({children}) =>
{
    const [locale, setLocale] = useState<LocaleType>(LocaleType.en);
    const [translations, setTranslations] = useState({})
    const {data} = useQuery(defaultLocaleQuery)

    const t = useCallback((key: string) =>
    {
        return translations[key] || key;
    }, [translations]);

    const loadTranslations = useCallback(async (locale: string) =>
    {
        if (locale === 'en')
        {
            setTranslations({})
            return
        }

        const filePath = `/i8n/locales/${locale}/messages.po`;
        const response = await fetch(filePath);
        const poContent = await response.text();
        const parsed = gettextParser.po.parse(poContent);

        const translations = Object.keys(parsed.translations[''] || {}).reduce((acc, key) =>
        {
            const translation = parsed.translations[''][key];
            if (translation && translation.msgstr[0])
            {
                acc[key] = translation.msgstr[0];
            }
            return acc;
        }, {} as Translations);

        setTranslations(translations)
    }, [])

    useEffect(() =>
    {
        loadTranslations(locale).then()
    }, [locale, loadTranslations]);

    useEffect(() =>
    {
        const locale = data?.userProfile?.locale
        if (locale)
        {
            setLocale(locale)
        }
    }, [data])

    return <LocaleContext.Provider value={{locale, setLocale, t}}>
        {children}
    </LocaleContext.Provider>;
};

export const useLocale = (): LocaleContextType =>
{
    const context = useContext(LocaleContext);
    if (!context)
    {
        throw new Error('useLocale must be used within a LocaleProvider');
    }
    return context;
};


export const withLocale = (WrappedComponent: any) =>
{
    return function LocaleComponent(props: any)
    {
        const {t} = useLocale();
        return <WrappedComponent {...props} t={t}/>;
    };
}