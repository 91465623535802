import _ from "lodash";
import {SurfaceTreatmentType} from "../../../../generated/graphql";
import {articleDetailLink} from "../../../../utils/ArticleUtils";
import {UncontrolledTooltip} from "reactstrap";
import Wood from "../../../icons/Wood";
import PaintBucket from "../../../icons/PaintBucket";
import React from "react";

const ColumnDrawJobArticles = (isOnInventory: boolean) =>
{
    return {
        field: 'articles',
        headerName: 'Articles',
        filterable: false,
        sortable: false,
        renderCell: (params) =>
        {
            const task = params.row
            return <div>
                {
                    task.variables.articles.concat(task.variables.autoDrawArticles || [])
                        .map(a =>
                        {
                            const purchaseOrder = task.purchaseOrder;
                            const article = purchaseOrder?.articleRows?.find((e) => e?.article?.id === a.id)?.article
                            if (!article)
                            {
                                return null
                            }
                            const sectionCount = _.get(article, "spiralStair.segments.length");
                            const isAuto = _.some(task.variables.autoDrawArticles, a => a.id === article.id);
                            const hasWoodSteps = _.get(article, "spiralStair.stepType") === "oak30mm" || _.get(article, "spiralStair.innerRailingType") === "oak";
                            const woodId = `wood-${article.id}`;
                            const powderId = `powderCoat-${article.id}`;
                            const surfaceTreatment = article.spiralStair?.surfaceTreatmentOverview;
                            let hasPowderCoating = false;
                            if (surfaceTreatment?.type)
                            {
                                hasPowderCoating = [SurfaceTreatmentType.Pulverlack, SurfaceTreatmentType.VfzLack].includes(surfaceTreatment.type);
                            }

                            return (
                                <p key={article.id} style={{margin: 0, whiteSpace: 'nowrap'}}>
                                    {articleDetailLink(article, article.artNo, isOnInventory)}
                                    {isAuto && <span style={{fontSize: "20px", color: "green"}}>A</span>}
                                    {sectionCount != null && <small>({sectionCount} sections)</small>}
                                    {hasWoodSteps && (
                                        <>
                                            <UncontrolledTooltip target={woodId}>Wooden
                                                steps</UncontrolledTooltip>
                                            <span id={woodId}
                                                  style={{textDecoration: "underline", fontSize: "5px"}}><Wood/></span>
                                        </>
                                    )}
                                    {hasPowderCoating && (
                                        <>
                                            <UncontrolledTooltip target={powderId}>Powder
                                                Coated</UncontrolledTooltip>
                                            <span id={powderId}
                                                  style={{
                                                      textDecoration: "underline",
                                                      fontSize: "9px"
                                                  }}><PaintBucket/></span>
                                        </>
                                    )}
                                </p>
                            );
                        })
                }
            </div>
        }
    }

}
export default ColumnDrawJobArticles;