import React, {useState} from "react";
import OrderInfo from "../../common/task-workflow/OrderInfo";
import CustomerDocsFileList from "../../common/task-workflow/files/CustomerDocsFileList";
import UploadFile, {CustomerDocCategoryType} from "../../common/file/UploadFile";
import TaskFooter from "../../common/task-workflow/TaskFooter";
import CommentsPanel from "../../common/task-workflow/comment/CommentsPanel";
import ArticlesAndAutoDrawArticlesOverview from "../../common/task-workflow/ArticlesAndAutoDrawArticlesOverview";
import {useLocale} from "../../LocaleProvider";

export default ({variables, onSubmit, toggle}) => {
    const [files, setFiles] = useState([]);
    const {t} = useLocale()

    const {articles, orderId, autoDrawArticles, purchaseOrderId} = variables;
    const [comments, setComments] = useState(variables.comments ?? [])

    return <div>
        <OrderInfo orderId={orderId}/>
        <ArticlesAndAutoDrawArticlesOverview articles={articles} autoDrawArticles={autoDrawArticles} orderId={orderId}/>
        <CustomerDocsFileList purchaseOrderId={purchaseOrderId}/>
        <hr/>

        <h4>{t('Upload files')}</h4>
        <UploadFile purchaseOrderId={purchaseOrderId} fileCategory={CustomerDocCategoryType}
                    onSubmit={files => setFiles(oldFiles => oldFiles.concat(files))}/>

        <hr/>

        <CommentsPanel comments={comments}
                       setComments={(newComments)=> {
                          setComments(newComments)
                      }}/>

        <TaskFooter submit={() => onSubmit({
            moreInformationAddedFiles: {
                type: "json",
                value: JSON.stringify(files)
            },
            comments: {
                value: JSON.stringify(comments),
                type: "json"
            }
        })} toggle={toggle}/>

    </div>
}