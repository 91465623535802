import React, {useEffect, useState} from 'react';
import gql from 'graphql-tag';
import {withApollo} from '@apollo/client/react/hoc'
import {useClassificationsLazyQuery} from "../generated/graphql";
import {Link} from "react-router-dom";
import {formatCurrency} from "../UTIL";
import PaginatedTable from "../common/data-grid/PaginatedDataGrid";
import ColumnAssignee from "../common/data-grid/columns/ColumnAssignee";
import ColumnCreatedAt from "../common/data-grid/columns/ColumnCreatedAt";
import {FilterOperatorString} from "../common/data-grid/FilterOperatorDefinitions";
import ColumnDate from "../common/data-grid/columns/ColumnDate";

const query = gql`
    query companiesV2($pagination: PaginationInputType, $filtering: FilteringInputType, $ordering: OrderingInputType, $searching: SearchingInputType) {
        result: companiesV2(options: {
            pagination: $pagination,
            filtering: $filtering,
            ordering: $ordering,
            searching: $searching
        }) {
            list {
                id
                idAsString
                name
                phone
                billingCity
                billingStreet
                billingPostalCode
                deliveryPostalCode
                visitingStreet
                website
                organisationNumber
                sni
                scbId
                assignee
                {
                    username
                    displayName
                }
                industry{
                    id
                    name
                }
                classification
                {
                    id
                    name
                }
                createdAt
                creditRatingLimit
                creditRatingDate
                creditRatingComment
                creditRatingLimitCurrency
            }
            total
        }
    }
`;

const KEY_TABLE = 'CompanyTable'

const CompanyListPage = () =>
{

    const [getClassifications] = useClassificationsLazyQuery();
    const [classifications, setClassifications] = useState<any>();

    const columns = [
        {
            field: 'id',
            headerName: 'Customer number',
            searchable: true,
            customSearchField: "idAsString",
            customFilterField: "idAsString",
            width: 150,
            customFilterOperators: FilterOperatorString,
            parseFilterValue: (value) => value
        },
        {
            field: 'name',
            headerName: 'Name',
            width: 150,
            renderCell: (params) =>
            {
                return <Link to={`/companyDetail/${params.id}`}>{params.value}</Link>
            },
            searchable: true,
        },
        ColumnAssignee(),
        {
            field: 'classification.id',
            headerName: 'Classification',
            type: 'singleSelect',
            valueOptions: classifications?.map((e) =>
            {
                return {value: e.id, label: e.name}
            }) ?? [],
            width: 150,
            searchable: true,
            customSearchField: 'classification.name',
            parseFilterValue: (value) => Number(value)
        },
        {
            field: 'industry.name',
            headerName: 'Industry',
            width: 150,
            searchable: true,
        },
        {
            field: 'billingCity',
            headerName: 'Billing City',
            width: 100,
            searchable: true,
        },
        {
            field: 'organisationNumber',
            headerName: 'Organisation number',
            width: 150,
            searchable: true,
        },
        {
            field: 'creditRatingLimit',
            headerName: 'Credit Rating Limit',
            type: 'number',
            width: 170,
            parseFilterValue: (value) => Number(value),
            renderCell: params =>
            {
                const value = params.value
                const currency = params.row.creditRatingLimitCurrency
                return value ? <div>{`${formatCurrency(value)} ${currency}`}</div> : null
            }
        },
        {
            field: 'creditRatingComment',
            headerName: 'Credit Rating Comment',
        },
        ColumnDate('creditRatingDate', 'Credit Rating Date',),
        {field: 'billingPostalCode'},
        {field: 'deliveryPostalCode'},
        ColumnCreatedAt(),
    ];

    useEffect(() =>
    {
        const asyncFun = async () =>
        {
            const responseClassifications = await getClassifications();
            setClassifications(responseClassifications.data?.classifications)
        }

        asyncFun().then();
    }, [getClassifications])

    return (
        <div>
            {classifications &&
                <PaginatedTable
                    definition={{
                        tableKey: KEY_TABLE,
                        columns: columns,
                        initState: {
                            columns: {
                                columnVisibilityModel: {
                                    id: false,
                                }
                            },
                        }
                    }}
                    query={query}
                    filterTenantId={true}/>                
            }
        </div>
    )
}


export default withApollo(CompanyListPage);
