import React, {useState} from "react";
import _ from 'lodash';
import FormRenderer from "./FormRenderer";
import DetailRenderer from "./DetailRenderer";
import {Button, Col, Row} from "reactstrap";
import {idToName} from "../UTIL";
import {useLocale} from "../LocaleProvider";


export default ({formDefinition,object,onSubmit,columns=4,extra,open, isEditable = true})=>
{
    const [editing,setEditing] = useState(open);
    const {t} = useLocale()
    const {createdAt,updatedAt,creator} = object;
    const newObj = {createdAt,updatedAt,creator};
    let blankCounter = 0;
    _.each(formDefinition,def =>
    {
        if(def.hideField && def.hideField(object))
        {
            newObj[`blank${blankCounter++}`] = "";
        }
        else if(def.transformValue)
        {
            newObj[def.label||def.name] = def.transformValue({value:object[def.name],object});
        }
        else if(/Id$/.test(def.name))
        {
            const key = def.name.substr(0,def.name.length - 2);
            newObj[key] = object[key];
        }
        else if(def.options && def.type === "select")
        {
            newObj[def.label||def.name] = idToName(def.options,object[def.name]);
        }
        else
        {
            newObj[def.label||def.name] = object[def.name];
        }

    });
    if(!editing )
    {
        return <div>
            {isEditable &&
                <Button size="sm" onClick={() => setEditing(true)}>
                    {t('Edit')}
                </Button>
            }
            <DetailRenderer columns={columns} object={newObj} hideUpdateInfo={!!extra} extra={extra} />
        </div>
    }
    else
    {
        return <div>
            <FormRenderer customSubmit={
                <Row className="justify-content-between">
                    <Col sm={3}>
                        <Button color="secondary" onClick={()=>setEditing(false)} >{t('Cancel editing')}</Button>
                    </Col>
                    <Col sm={2}>
                        <Button color="success" type="submit">{t('Save')}</Button>
                    </Col>
                </Row>
            } columns={columns} object={object} formDefinition={formDefinition} onSubmit={(form)=>
            {
                if(!open)
                {
                    setEditing(false);
                }
                onSubmit(form);
            }} />
        </div>
    }

};
