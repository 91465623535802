import {Link} from "react-router-dom";
import {POStatuses, StatusEnum, StatusValueList} from "../purchase-order/Status";
import {Badge, Col} from "reactstrap";
import React, {useMemo} from "react";
import {InventoryRoute} from "../InventoryRouters";
import {
    useGetPurchaseOrdersByArtNoV2Query
} from "../../generated/inventoryGraphql";
import {FilterOperatorStringEquals} from "../../common/data-grid/FilterOperatorDefinitions";
import ColumnCreatedAt from "../../common/data-grid/columns/ColumnCreatedAt";
import NonPaginatedDataGrid from "../../common/data-grid/NonPaginatedDataGrid";
import {useLocale} from "../../LocaleProvider";

const KEY_TABLE = "TABLE_INVENTORY_ARTICLE_PURCHASE_ORDERS"

export default ({artNo}: { artNo: string }) =>
{
    const {t} = useLocale()
    const {data} = useGetPurchaseOrdersByArtNoV2Query({
        variables: {artNo, pagination: {pageSize:9999, pageIndex: 0}}
    })

    const columns = useMemo(() =>
    {
        return [
            {
                field: 'id',
                headerName: 'Number',
                customFilterOperators: FilterOperatorStringEquals,
                parseFilterValue: (value) => Number(value),
                renderCell: (params) =>
                {
                    const value = params.value
                    return <div style={{textAlign: "right"}}>
                        <Link to={`${InventoryRoute.PURCHASE_DETAIL}/${value}`}>{value}</Link>
                    </div>;
                }
            },
            {
                field: 'status',
                type: 'singleSelect',
                valueOptions: Object.entries(StatusEnum).map(([key, value]) => ({value: value, label: key})),
                renderCell: (params) =>
                {
                    const value = params.value
                    const v: StatusValueList = POStatuses[value];
                    return <Badge color={v.variant}>{t(v.label)}</Badge>
                }
            },
            {
                field: 'supplier.name',
                headerName: 'Supplier',
            },
            ColumnCreatedAt(),
            {
                field: 'creator',
            },
        ]
    }, [t])

    return <Col>
        <NonPaginatedDataGrid
            definition={{
                tableKey: KEY_TABLE,
                columns: columns,
                initState: {
                    sorting: {sortModel: [{field: 'id', sort: 'desc'}]},
                    columns: {columnVisibilityModel: {id: true}}
                }
            }}
            data={data?.result?.list ?? []}/>
    </Col>
}
