import {useEffect, useState} from "react";
import {PURCHASE_ORDER_EDITABLE_STATUS} from "../Constants";
import gql from "graphql-tag";
import {useQuery} from "@apollo/client";

const queryArticle = gql`
    query getArticle($id: ID!) {
        article(id: $id)
        {
            articleRows {
                purchaseOrder {
                    status
                }
            }
        }
    }
`

const useIsArticleEditableHook = (articleId: string) =>
{
    const {data} = useQuery(queryArticle, {variables: {id: articleId}});

    const [isEditable, setIsEditable] = useState(false)

    useEffect(() =>
    {
        const article = data?.article
        if (!article)
        {
            return
        }
        const isEditable = article.articleRows?.every((ar) =>
        {
            const poStatus = ar?.purchaseOrder?.status
            if (!poStatus)
            {
                return true
            }
            return PURCHASE_ORDER_EDITABLE_STATUS.includes(poStatus)
        }) ?? true
        setIsEditable(isEditable)
    }, [data?.article])

    return {isEditable}
}

export default useIsArticleEditableHook