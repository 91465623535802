import React, {Component} from 'react';
import CommentsPanel from "../../common/task-workflow/comment/CommentsPanel";
import OrderInfo from "../../common/task-workflow/OrderInfo";
import TaskFooter from "../../common/task-workflow/TaskFooter";
import DrawingsFileList from "../../common/task-workflow/files/DrawingsFileList";
import ArticlesAndAutoDrawArticlesOverview from "../../common/task-workflow/ArticlesAndAutoDrawArticlesOverview";

export default class SendCustomerDrawing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            comments: props.variables.comments ?? [],
        };
    }
    render()
    {
        const {articles, orderId, purchaseOrderId, autoDrawArticles} = this.props.variables;

        return <div>
            <OrderInfo orderId={orderId}/>
            <ArticlesAndAutoDrawArticlesOverview articles={articles} autoDrawArticles={autoDrawArticles} orderId={orderId}/>
            <CommentsPanel comments={this.state.comments}
                           setComments={(newComments) => {
                              this.setState({comments: newComments});
                          }}/>
            <hr/>
            <DrawingsFileList purchaseOrderId={purchaseOrderId}/>
            <TaskFooter toggle={this.props.toggle}
                        submit={() => this.props.onSubmit({
                            comments: {
                                value: JSON.stringify(this.state.comments),
                                type: "json"
                            }
                        })}
                        submitLabel={`Complete task`}/>
        </div>
    }
}

