import React, {useCallback, useState} from "react";
import Auth from "../../../Auth";
import {Button, Col, FormGroup, Input, Label, Row} from "reactstrap";
import WorkflowComment from "../../../types/WorkflowComment";
import {useLocale} from "../../../LocaleProvider";

export const AddCommentForm = ({
                                      submit,
                                      comments,
                                      btnText
                                  }: { submit: (newComments) => void, comments?: WorkflowComment[], btnText?: string }) =>
{
    const [comment, setComment] = useState("");
    const {t} = useLocale()

    const handleOnSubmit = useCallback(() =>
    {
        const newComment = {
            comment: comment,
            user: Auth.getUsername() ?? '',
            createdAt: new Date()
        }

        const newComments = comments ?? []
        newComments?.push(newComment)

        setComment("")

        submit(newComments)
    }, [comment, submit, comments]);

    return <div>
        <FormGroup>
            <Label>{t('Comment')}</Label>
            <Input type="textarea" value={comment} onChange={e => setComment(e.target.value)}/>
        </FormGroup>

        <Row style={{marginTop: "20px", justifyContent: "space-between"}}>
            <Col>
            </Col>
            <Col sm={3}>
                <Button color={"success"} onClick={() => handleOnSubmit()}>
                    {btnText == null ? t('Submit') : t(btnText)}
                </Button>
            </Col>
        </Row>
    </div>
}