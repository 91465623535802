import IconOpenInNew from "../assets/images/open_in_new.svg";
import React from "react";
import {Link} from "react-router-dom";

const OpenInNewTabIcon = ({to}: { to: string }) =>
{
    return <Link to={to} target="_blank">
        <img src={IconOpenInNew} style={{width: '20px', marginLeft: "4px"}} alt={'open_in_new'}/>
    </Link>
}
export default OpenInNewTabIcon;