import React, {useCallback, useEffect, useMemo, useState} from "react";
import {
    OrderWithDeliveryInfoFragmentFragment,
    PackageFragmentFragment,
    useCreateShipmentMutation
} from "../generated/graphql";
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Spinner
} from "reactstrap";
import NewFormRenderer from "../common/NewFormRenderer";
import {ok} from "assert";
import NotificationPopup from "../common/lib/NotificationPopup";
import SelectPackagesForShipmentPanel from "./component/SelectPackagesForShipmentPanel";
import {PaymentInstructionTypes} from "./model/PaymentInstructionType";
import {TransportServiceTypes} from "./model/TransportServiceTypes";
import {DEFAULT_CONSIGNOR_ACCOUNT} from "./model/ConsignorAccountList";
import PackageDataGrid from "../package/component/PackageNonPaginatedDataGrid";
import {isProd} from "../workflow-forms/DrawJob/util";
import {shipmentFormDefinitions, shipmentPaymentFormDefinitions} from "./model/ShipmentFormDefinitions";
import {omit} from "lodash";
import {useTenant} from "../TenantProvider";

type ShipmentNumberAndConsigneeRef = {
    number: string,
    consigneesReference: string,
    paymentInstruction: string,
    paymentAccount: string | null
}

export const WarningTermsOfDelivery = ["Mottagarfrakt", "EXW"]

const ConfirmCreateShipmentModal = ({isOpen, toggle, handleOnCreate, packages}: {
    isOpen: boolean,
    toggle: () => void,
    handleOnCreate: (data: ShipmentNumberAndConsigneeRef) => Promise<void>,
    packages: PackageFragmentFragment[]
}) =>
{
    const [data, setData] = useState<ShipmentNumberAndConsigneeRef>({
        number: '',
        consigneesReference: '',
        paymentInstruction: '',
        paymentAccount: ''
    })

    const [isLoading, setIsLoading] = useState(false)

    const formDefinition = useMemo(() =>
    {
        return [{name: "number", label: "Shippers ref", required: true, type: "text"},
            {name: "consigneesReference", type: "text"},
            ...shipmentPaymentFormDefinitions(true)]
    }, [])

    const onChange = useCallback((key, value) =>
    {
        setData(prevState => ({...prevState, [key]: value}))
    }, [])

    useEffect(() =>
    {
        if (!packages)
        {
            return
        }
        const items = packages.flatMap((e) => e.packageItems)

        const orderMap = new Map<string, { order: OrderWithDeliveryInfoFragmentFragment, artNoSet: Set<string> }>()
        items.forEach((e) =>
        {
            const order = e?.articleRow?.order
            const orderNumber = order?.number
            if (!order || !orderNumber)
            {
                return
            }

            if (!orderMap.has(orderNumber))
            {
                orderMap.set(orderNumber, {order: order, artNoSet: new Set()})
            }

            const stockArticleNo = e?.articleRow?.stockArticle?.artNo
            const artNo = e?.articleRow?.article?.artNo

            const number = stockArticleNo ? stockArticleNo : artNo
            if (number)
            {
                const set = orderMap.get(orderNumber)?.artNoSet
                if (!set?.has(number))
                {
                    set?.add(number)
                }
            }
        })

        const numbers: string[] = [];
        const consigneesReferences: string[] = []
        let consignee: string | null = null

        orderMap.forEach((value) =>
        {
            const order = value.order
            const artNoSet = value.artNoSet
            const artNoArray = Array.from(artNoSet);
            const contactName = order.contact?.name ?? ''
            numbers.push(`${order.number} - ${artNoArray.join(' ')} / ${contactName}`)

            let consigneesReferenceText = contactName
            const customerOrderAnnotation = order.customerOrderAnnotation
            if (customerOrderAnnotation)
            {
                consigneesReferenceText += ` - ${customerOrderAnnotation}`
            }
            consigneesReferences.push(consigneesReferenceText)

            if (order.consignee)
            {
                consignee = order.consignee
            }
        })

        setData({
            number: numbers.join(', '),
            consigneesReference: consigneesReferences.join(', '),
            paymentInstruction: consignee ? PaymentInstructionTypes.consignee : PaymentInstructionTypes.consignor,
            paymentAccount: consignee ? consignee : DEFAULT_CONSIGNOR_ACCOUNT
        })
    }, [packages]);

    const onCreateClick = useCallback(async () =>
    {
        setIsLoading(true)
        await handleOnCreate(data)
        setIsLoading(false)
    }, [data, handleOnCreate])

    return <Modal isOpen={isOpen} toggle={toggle} size={'lg'}>
        <ModalHeader toggle={toggle}>Create shipment</ModalHeader>
        <ModalBody>
            <NewFormRenderer
                formDefinition={formDefinition}
                object={data}
                columns={2}
                onChange={onChange}/>
            <h4>Packages</h4>
            <PackageDataGrid packages={packages ?? []}/>
        </ModalBody>
        <ModalFooter>
            <Button color='success' onClick={onCreateClick} disabled={isLoading}>
                Submit
                {isLoading && <Spinner color='warning'/>}
            </Button>
        </ModalFooter>
    </Modal>
}

export default (props) =>
{
    const [isOpen, setIsOpen] = useState(false)
    const [selectedPackages, setSelectedPackages] = useState<PackageFragmentFragment[]>([])
    const {tenantId} = useTenant()

    const toggle = useCallback(() =>
    {
        setIsOpen(prevState => !prevState)
    }, [])

    const [createdShipment, setCreatedShipment] = useState<any>(
        {
            template: isProd ? "Inrikes Eurostair AB Säffle" : "Package",
            transportService: TransportServiceTypes[0].id,
            pickupDate: new Date(),
            pickupEarliest: "07:00",
            pickupLatest: "16:00",
        }
    )

    const [createShipmentMutation] = useCreateShipmentMutation()

    const formDefinition = useMemo(() =>
    {
        return shipmentFormDefinitions(true)
    }, [])

    const onChange = useCallback(async (key, value) =>
    {
        setCreatedShipment({...createdShipment, [key]: value})
    }, [setCreatedShipment, createdShipment])

    const isCreateButtonDisabled = useCallback(() =>
    {
        const required = formDefinition.filter((e) => e.required)
        const keys = Object.keys(createdShipment)

        return !required.every((e) => keys.includes(e.name))
    }, [createdShipment, formDefinition])

    const createShipment = useCallback(async (numberAndConsigneeRef: ShipmentNumberAndConsigneeRef) =>
    {
        try
        {
            const warningPackages = selectedPackages?.filter((e) =>
            {
                return e.packageItems?.some((item) =>
                {
                    const name = item.articleRow?.order?.termsOfDelivery?.name
                    return name && WarningTermsOfDelivery.includes(name)
                }) ?? false
            })

            if (warningPackages && warningPackages.length > 0)
            {
                const marks = warningPackages.map((e, index) => `${e.mark}${index !== warningPackages.length - 1 ? ', ' : ''}`)
                if (!window.confirm(`The delivery terms for the following packages(${marks}) are EXW or Mottagarfrakt. Are you sure you want to create a shipment?`))
                {
                    return
                }
            }

            const packageIds = selectedPackages.map((e) => e.id)

            const response = await createShipmentMutation({
                variables: {
                    shipment: {
                        ...omit(createdShipment, ["bookedAt", "savedAt"]),
                        ...numberAndConsigneeRef,
                        tenantId: tenantId,
                        packageIds: packageIds,
                    }
                }
            })
            ok(!response.errors, `${response.errors}`)

            const id = response.data?.createShipment?.id
            NotificationPopup.success(`Create shipment success`)
            props.history.push(`/shipment-detail/${id}`)
        } catch (e)
        {
            NotificationPopup.error(`${e}`)
        }
    }, [createdShipment, createShipmentMutation, props.history, selectedPackages, tenantId])

    return <div>
        <NewFormRenderer
            formDefinition={formDefinition}
            object={createdShipment}
            columns={3}
            onChange={onChange}/>

        <h5 style={{marginTop: "16px"}}>Packages</h5>
        <SelectPackagesForShipmentPanel selectedPackages={selectedPackages}
                                        setSelectedPackages={setSelectedPackages}>
            <Button color="primary"
                    style={{marginTop: "16px"}}
                    disabled={isCreateButtonDisabled()}
                    onClick={toggle}>Create shipment</Button>
        </SelectPackagesForShipmentPanel>
        <ConfirmCreateShipmentModal isOpen={isOpen} toggle={toggle} handleOnCreate={createShipment}
                                    packages={selectedPackages}/>
    </div>
}
