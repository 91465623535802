import React from 'react';
import {Link} from "react-router-dom";
import {Badge} from "reactstrap";
import {useMutation} from '@apollo/client';
import Trash from "../common/icons/Trash";
import RemoveQuoteMutation from './graphql/DeleteQuote.graphql';
import {formatCurrency, QuoteStatuses, quoteTotal} from "../UTIL";
import _ from 'lodash';
import Paragraph from "../common/Paragraph";
import PaginatedDataGrid from "../common/data-grid/PaginatedDataGrid";
import {QuotesV2Document} from "../generated/graphql";
import ColumnAssignee from "../common/data-grid/columns/ColumnAssignee";
import ColumnCreatedAt from "../common/data-grid/columns/ColumnCreatedAt";
import Auth from "../Auth";
import {FilterOperatorString} from "../common/data-grid/FilterOperatorDefinitions";
import ColumnsCompany from "../common/data-grid/columns/ColumnsCompany";
import ColumnsProject from "../common/data-grid/columns/ColumnsProject";
import ColumnsStatus from "../common/data-grid/columns/ColumnStatus";
import ColumnId from "../common/data-grid/columns/ColumnId";
import ColumnPrioritizedProject from "../common/data-grid/columns/ColumnPrioritizedProject";
import ColumnDate from "../common/data-grid/columns/ColumnDate";

const articleDetailURL = (article) => {
    if (article.spiralStair) {
        return `/spiralStairDetail/${article.id}`;
    } else if (article.straightStair) {
        return `/straightStairDetail/${article.id}`
    } else if (article.straightStairV2) {
        return `/straightStairV2Detail/${article.id}`
    } else if (article.moduleRamp) {
        return `/moduleRampDetail/${article.moduleRamp.id}`
    } else {
        return `/articleDetail/${article.id}`
    }
};

export const QuoteTableDefinitionV2 = (removeQuote, history) => {
    return [
        {
            field: 'number',
            headerName: 'Number',
            customFilterOperators: FilterOperatorString,
            searchable: true,
            width: 110,
            parseFilterValue: (value) => value,
            renderCell: (params) => {
                return <Link to={`/quoteDetail/${params.id}`}>{params.value}</Link>
            },
        },
        ColumnsCompany(),
        ColumnsProject(),
        {
            field:'projectStage',
            headerName: 'Part of project',
        },
        {
            field: 'contact.name',
            headerName: 'Contact name',
            searchable: true,
            renderCell: (params) => {
                return params.value
                    ? <Link to={`/contactDetail/${params.row.contact.id}`}>{params.value}</Link>
                    : <div>{"Missing contact"}</div>
            },
        },
        ColumnsStatus(QuoteStatuses),
        ColumnPrioritizedProject(),
        {
            field: 'value',
            headerName: 'Value',
            filterable: false,
            sortable: false,
            type: 'number',
            renderCell: (params) => {
                const quote = {
                    articleRows: params.row.articleRows,
                    shippingCost: params.row.shippingCost,
                    packagingCost: params.row.packagingCost
                }
                return <span>{formatCurrency(quoteTotal(quote))} {params.row.currency}</span>
            },
        },
        {
            field: 'articleRows',
            headerName: 'Articles',
            filterable: false,
            sortable: false,
            width: 230,
            renderCell: (params) => {
                const articleRows = _.filter(params.value, ar => ar.article && !ar.stockArticle);
                const stockArticles = _.filter(params.value, ar => ar.stockArticle);
                const chunkedArticles = _.chunk(articleRows, 3);
                return <div>
                    {chunkedArticles.map((chunk, index) => {
                            return <Paragraph key={index}>
                                <small>
                                    {chunk.map(articleRow => {
                                            const article = articleRow.article || articleRow.stockArticle;
                                            let color = "secondary";
                                            if (article.spiralStair) {
                                                color = "primary";
                                            } else if (article.straightStair) {
                                                color = "info";
                                            }
                                            return <Badge color={color} key={article.id} style={{margin: 0, cursor: "pointer"}}
                                                          onClick={() => {
                                                              history.push(`${articleDetailURL(article)}/${articleRow.id}`);
                                                          }}>
                                                {_.truncate(articleRow.name, {length: 14})}
                                            </Badge>
                                        }
                                    )}
                                </small>
                            </Paragraph>
                        }
                    )}
                    {stockArticles.length > 0 && <p style={{margin: 0}}>{stockArticles.length} Stock article(s)</p>}
                </div>
            },
        },
        ColumnDate("preferredDeliveryDate",'Preferred delivery date',),
        ColumnCreatedAt(),
        ColumnAssignee(),
        {
            field: 'remove',
            headerName: 'Remove',
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return <span className="link" onClick={() => {
                    if (window.confirm(`Are you sure you want to remove quote ${params.row.number}?`)) {
                        removeQuote({variables: {id: params.row.id}});
                    }
                }
                }>
                <Trash/>
            </span>;
            },
        },
        ColumnId(),
    ]
}

const KEY_TABLE = "TABLE_QUOTE"

export const QuoteTableDefaultSort = {sortModel: [{field: 'createdAt', sort: 'desc'}]}

const filterPresets = [
    {
        name: "My Prioritized projects",
        filters: {
            items: [
                {field: 'prioritizedProject', value: ["high"], operator: 'in'},
                {field: 'assignee.username', value: Auth.getUsername(), operator: 'is'},
            ],
        },
        sort: [{field: 'createdAt', sort: 'desc'}]
    }
]

export default ({history}) => {
    const [removeQuote] = useMutation(RemoveQuoteMutation);

    return (
        <div>
            <PaginatedDataGrid
                definition={{
                    tableKey: KEY_TABLE,
                    assignedToMeLabel: 'My Quotes',
                    columns: QuoteTableDefinitionV2(removeQuote, history),
                    initState: {
                        sorting: QuoteTableDefaultSort,
                        filter: {
                            filterModel: {
                                items: [{field: 'assignee.username', value: Auth.getUsername(), operator: 'is'}]
                            }
                        }
                    },
                    buttons: filterPresets
                }}
                query={QuotesV2Document}
                filterTenantId={true}/>
        </div>
    );
};
