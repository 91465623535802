import React, { useState} from 'react';
import {useMutation} from '@apollo/client';
import CreateOrderMutation from './graphql/CreateOrderMutation.graphql';
import {withRouter} from "react-router";
import Auth from "../Auth";
import {ContactIdFormDef, ProjectIdFormDef} from "../UTIL";
import {Button, Col, Form, Row} from "reactstrap";
import NewFormRenderer from "../common/NewFormRenderer";
import CreditRatingInfoBox from "../company/component/CreditRatingInfoBox";
import StdQuery from "../common/StdQuery";
import gql from "graphql-tag";
import {TenantData} from "../common/lib/TenantData";
import DatePickerWithWeek from "../common/DatePickerWithWeek";
import PredictedWorkloadButton from "../common/PredictedWorkloadButton";
import {useTenant} from "../TenantProvider";

const getCompanyQuery = gql`
query($id:ID!)
{
    company(id:$id)
    {
        id
        name
        organisationNumber
        creditRatingComment
        creditRatingDate
    }
}

`;

const submit = async(form,match,history,createOrder, tenantId)=>
{
    const res = await createOrder({variables:{
            id:parseInt(match.params.id),
            order:{...form,contactId:form.contactId,tenantId:tenantId,assigneeId:Auth.getUsername()}
        }});
    history.push(`/orderDetail/${res.data.createOrder.id}`);
};

export default withRouter(({history,match})=>
{
    const [createOrder] = useMutation(CreateOrderMutation);
    const {tenantId} = useTenant()

    const defaultCurrency = TenantData[tenantId].defaultCurrency;
    const [form,setForm] = useState({currency:defaultCurrency});
    return <Row>
        <Col sm={4}>
            <Form onSubmit={e=>{e.preventDefault();submit(form,match,history,createOrder, tenantId)}}>
                <NewFormRenderer onChange={(key,val)=>
                {
                    let value = val;
                    if(val.id)
                    {
                        value = val.id;
                    }
                    else if(val.value)
                    {
                        value = val.value;
                    }
                    setForm({...form,[key]:value})
                }}
                                 customSubmit={<Button type={"submit"} color={"success"} >Submit</Button>}
                                 columns={1} object={form} formDefinition={
                    [
                        {name:'companyId',label:"Select Company",type:"search",
                            required:true,
                            types:["company"],
                        },
                        ProjectIdFormDef(),
                        ContactIdFormDef,
                        {
                            name: "preferredDeliveryDate", type: "custom", required: true,
                            input: function (model, onChange) {
                                return <div>
                                    <DatePickerWithWeek defaultValue={model["preferredDeliveryDate"]}
                                                        onChange={onChange}/>
                                    <PredictedWorkloadButton/>
                                </div>
                            }
                        },
                        {name:"currency",type:"currencyPicker",required:true,defaultValue:defaultCurrency}
                    ]
                }
                />
            </Form>

        </Col>
        <Col sm={4}>
            <div style={{marginTop:"25px"}}>
                {form.companyId && <StdQuery query={getCompanyQuery} variables={{id:form.companyId}}>
                    {data =>
                    {
                        if(data.company)
                        {
                            return <CreditRatingInfoBox company={data.company}/>;
                        }
                        else
                        {
                            return <p style={{color:"red"}}>Failed to find credit rating for this company.</p>;
                        }
                    }
                    }
                </StdQuery>
                }
                {!form.companyId && <p>Select a company to view credit rating.. </p> }
            </div>

        </Col>
    </Row>
});


