import {GridPaginationModel, GridSortModel, GridValidRowModel,} from '@mui/x-data-grid';
import React, {memo, useCallback, useEffect, useRef, useState} from "react";
import queryString from "qs";
import {withRouter} from "react-router";
import {GridFilterModel} from "@mui/x-data-grid/models/gridFilterModel";
import _, {omit} from 'lodash';
import {Comparator, FilteringItemInputType, FilterLogicOperator,} from "../../generated/graphql";
import {useLazyQuery} from "@apollo/client";
import Auth from "../../Auth";
import {Button, ButtonToolbar} from "reactstrap";
import {lookup} from "../../UTIL";
import BaseDataGrid, {KEY_PAGE_SIZE, TableButton} from "./BaseDataGrid";
import {DataGridProProps} from "@mui/x-data-grid-pro";
import NotificationPopup from "../lib/NotificationPopup";
import {FILTER_BETWEEN} from "../../Constants";
import DataGridBaseDefinition from "../../types/data-grid/DataGridBaseDefinition";
import {useLocale} from "../../LocaleProvider";
import {useTenant} from "../../TenantProvider";

type QueryState = {
    filter?: GridFilterModel
    sort?: GridSortModel
    page?: GridPaginationModel
}

type PaginatedTableDefinition = DataGridBaseDefinition & {
    assignedToMeLabel?: string
}

type Props = DataGridProProps<GridValidRowModel> & {
    definition: PaginatedTableDefinition
    query: any
    queryVariables: any
    filterTenantId?: boolean
    persistFilter?: FilteringItemInputType[]
    history: any
}

const DEFAULT_PAGE_SIZE = 20

const QUERY_PAGE = 'page'
const QUERY_PAGE_SIZE = 'pageSize'
const QUERY_SORT_ORDER = 'sortOrder'
const QUERY_ID = 'id'
const QUERY_SEARCH = 'searching'
const QUERY_FILTER_OPERATOR = 'filterOperator'

const FilterButtons = ({definition, queryState, setQueryState}) =>
{
    const {columns, buttons, assignedToMeLabel} = definition;
    const {t} = useLocale()
    const assignable = _.some(columns, {field: 'assignee.username'});
    const assignFilter = [{field: 'assignee.username', value: Auth.getUsername(), operator: 'is'}];

    const isFilterButtonOutline = useCallback((btn: TableButton) =>
    {
        const queryFilterItems = queryState.filter?.items.map((e) => omit(e, "id"))
        return !_.isEqual(btn.filters?.items, queryFilterItems)
    }, [queryState.filter])

    const onAssigneeToMeClick = useCallback((assignFilter) =>
    {
        setQueryState(
            {
                ...queryState,
                filter: {items: assignFilter},
                page: {
                    page: 0,
                    pageSize: DEFAULT_PAGE_SIZE
                }
            })
    }, [queryState, setQueryState])

    const onClearFilterClick = useCallback(() =>
    {
        setQueryState(
            {
                ...queryState,
                filter: {items: []},
                page: {
                    page: 0,
                    pageSize: DEFAULT_PAGE_SIZE
                }
            })
    }, [queryState, setQueryState])

    return <ButtonToolbar style={{marginBottom: '16px'}}>
        {assignable && <Button outline style={{marginRight: "8px"}} color="primary"
                               active={_.isEqual(queryState.filter?.items, assignFilter)}
                               onClick={() => onAssigneeToMeClick(assignFilter)}>
            {assignedToMeLabel ?? "Assigned to me"}
        </Button>}
        {buttons && buttons.map(preset =>
        {
            return <Button outline={isFilterButtonOutline(preset)} style={{marginRight: "8px"}} key={preset.name}
                           active={_.isEqual(queryState.filter?.items, preset.filters)}
                           onClick={() =>
                           {
                               const change = {
                                   filter: preset.filters,
                                   sort: preset.sort ? preset.sort : queryState.sort
                               };

                               setQueryState(change)
                           }}>
                {preset.name}
            </Button>;
        })}
        <Button outline color="warning"
                active={_.isEqual(queryState.filter?.items, [])}
                onClick={onClearFilterClick}>
            {t('Clear all filters')}
        </Button>
    </ButtonToolbar>
}

const PaginatedDataGrid = (props: Props) =>
{
    const {definition, query, history, filterTenantId, persistFilter, queryVariables} = props
    const [lazyQuery, {data: response, loading}] = useLazyQuery(query)
    const [data, setData] = useState({rows: [], totalCount: 0})
    const prevIsLoading =  useRef(false);
    const hadRequestFetchWhenLoading = useRef(false);
    const {tenantId} = useTenant()

    const parsedParams = queryString.parse(window.location.search, {ignoreQueryPrefix: true, allowDots: true});

    const getFilterItems = () =>
    {
        if (Object.keys(parsedParams).length === 0)
        {
            return definition.initState?.filter?.filterModel?.items ?? []
        }
        return _.map(_.omit(parsedParams, [QUERY_PAGE, QUERY_PAGE_SIZE, QUERY_SORT_ORDER, QUERY_ID, QUERY_SEARCH, QUERY_FILTER_OPERATOR]), (value) => value)
    }

    const [queryState, setQueryState] = useState<QueryState>(
        {
            filter: {
                items: getFilterItems(),
                logicOperator: parsedParams.filterOperator,
                quickFilterValues: parsedParams.searching ? [parsedParams.searching] : undefined,
            },
            sort: parsedParams.id && parsedParams.sortOrder
                ? [{field: parsedParams.id, sort: parsedParams.sortOrder}]
                : definition.initState?.sorting?.sortModel
                ?? [{field: "id", sort: "asc"}],
            page: {
                pageSize: parsedParams.pageSize
                    ? parseInt(parsedParams.pageSize)
                    : parseInt(localStorage.getItem(`${definition.tableKey}${KEY_PAGE_SIZE}`) ?? `${DEFAULT_PAGE_SIZE}`),
                page: parsedParams.page ? parseInt(parsedParams.page) : 0,
            }
        }
    )

    const mapComparator = useCallback((operator?: string) =>
    {
        switch (operator?.toLowerCase())
        {
            case 'contains':
                return Comparator.Contains;
            case 'equals':
            case 'is':
            case '=':
                return Comparator.Equals;
            case 'not':
            case '!=':
                return Comparator.NotEqual;
            case 'after':
            case 'onorafter': // onOrAfter
            case "gte":
            case ">=":
                return Comparator.Gte;
            case 'before':
            case 'onorbefore': // onOrBefore
            case "lte":
            case "<=":
                return Comparator.Lte;
            case "in":
                return Comparator.In;
            case "startswith": // startsWith
                return Comparator.StartsWith;
            case 'isnotempty': //isNotEmpty
                return Comparator.IsNotEmpty
            default:
                throw new Error(`unknown comparator: ${operator}`)
        }
    }, []);

    const addTenantIdToFilter = useCallback((tenant, filters) =>
    {
        if (!tenant || tenant === "" || tenant === "null")
        {
            throw Error(`You don't have permission to access.`)
        }
        filters.push({key: 'tenantId', value: tenant, comparator: Comparator.Equals})
    }, []);

    const fetchData = useCallback(async (definition, queryState, lazyQuery, history, filterTenantId, persistFilter, queryVariables) =>
    {
        try
        {
            const variables: any = {}
            let search = '?';

            // page
            const pageIndex = queryState.page?.page ?? 0;
            const pageSize = queryState.page?.pageSize ?? DEFAULT_PAGE_SIZE;
            search += `${QUERY_PAGE}=${pageIndex}&${QUERY_PAGE_SIZE}=${pageSize}`;
            variables['pagination'] = {
                pageIndex: pageIndex,
                pageSize: pageSize
            }

            // filter
            const filterItems = queryState.filter?.items
            const filters = filterItems && filterItems.length > 0
                ? filterItems.filter((e) =>
                {
                    const operator = e.operator
                    if (operator === 'isNotEmpty')
                    {
                        return true
                    }
                    const value = e.value
                    if (operator === FILTER_BETWEEN)
                    {
                        return value?.start && value?.end
                    }
                    return value != null && value !== ''
                })
                    .flatMap((e) =>
                    {
                        const field = e.field;
                        const data = definition.columns.find((e) => e.field === field)
                        const key = data && data.customFilterField ? data.customFilterField : e.field

                        let value = e.value ?? ''
                        if (data?.type === 'number')
                        {
                            value = Number(value);
                        }

                        const operator = e.operator;
                        if (operator === FILTER_BETWEEN)
                        {
                            return [{key: key, value: value.start, comparator: Comparator.Gte},
                                {key: key, value: value.end, comparator: Comparator.Lte}]
                        }

                        return [{
                            key: key,
                            value: data && data.parseFilterValue ? data.parseFilterValue(value) : value,
                            comparator: mapComparator(e?.operator)
                        }]
                    })
                : []

            if (filterTenantId)
            {
                addTenantIdToFilter(tenantId, filters)
            }

            if (persistFilter)
            {
                persistFilter.forEach((e) => filters.push(e))
            }

            if (filters.length > 0)
            {
                search += `&${queryString.stringify(filterItems, {allowDots: true})}`;

                const logicOperator = queryState.filter?.logicOperator ?? FilterLogicOperator.And
                search += `&${QUERY_FILTER_OPERATOR}=${logicOperator}`;

                variables['filtering'] = {
                    items: filters,
                    operator: logicOperator?.toUpperCase()
                }
            }

            // search
            if (queryState?.filter?.quickFilterValues && queryState?.filter?.quickFilterValues.length > 0)
            {
                let value = ""
                queryState?.filter?.quickFilterValues.forEach((e) => value += `${e} `)
                value = value.trim()

                const searchFields = definition.columns.map((e) =>
                {
                    if (e.searchable)
                    {
                        return e.customSearchField ?? e.field
                    }
                    return null
                })
                    .filter((e) => e != null)

                const searchInput = {fields: searchFields, value: queryState?.filter?.quickFilterValues}
                search += `&${QUERY_SEARCH}=${value}`;
                variables['searching'] = searchInput
            }

            // order
            let ordering: any = null
            if (queryState?.sort && queryState?.sort?.length > 0)
            {
                const sort = queryState.sort[0]
                const sortId = definition.columns.find((e) => e.field === sort.field)
                    ?.customSortField ?? sort.field
                ordering = {sortOrder: sort.sort, id: sortId}
                search += `&${queryString.stringify(ordering)}`;
                variables['ordering'] = ordering
            }

            if (window.location.search !== search)
            {
                history.replace(`${window.location.pathname}${search}`);
            }
            await lazyQuery({variables: {...variables, ...queryVariables}})
        } catch (e: any)
        {
            NotificationPopup.error(`Failure to load data for datagrid. ${e.message}`);
        }
    },[addTenantIdToFilter, mapComparator, tenantId])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceFetchData = useCallback(_.debounce(fetchData, 500), []);

    useEffect(() => { // debounce and prevent fetch during loading. Put off the fetch till previous fetch is done.
        const fetch = async () => {
            debounceFetchData(definition, queryState, lazyQuery, history, filterTenantId, persistFilter, queryVariables);
        }

        if(loading && prevIsLoading.current){ // if the update is during loading
            hadRequestFetchWhenLoading.current = true;
            return;
        }

        if((prevIsLoading.current && !loading && hadRequestFetchWhenLoading.current) || // if loading finish but have some updates during loading
            (!loading && !prevIsLoading.current) // simply some updates
        ){
            hadRequestFetchWhenLoading.current = false;
            fetch().then();
        }

        prevIsLoading.current = loading;

        // Since 'definition' and 'queryVariables' changes every render, will cause additional fetch, remove them from dependency array
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, queryState, lazyQuery, history, filterTenantId, persistFilter, debounceFetchData]);

    const onFilterChange = useCallback((filterModel: GridFilterModel) =>
    {
        setQueryState({...queryState, filter: filterModel});
    }, [queryState]);

    const onSortChange = useCallback((sortModel: GridSortModel) =>
    {
        setQueryState({...queryState, sort: sortModel});
    }, [queryState]);

    const onPageChange = useCallback((pageModel: GridPaginationModel) =>
    {
        setQueryState({...queryState, page: pageModel});
    }, [queryState]);

    useEffect(() =>
    {
        if (!response?.result)
        {
            return
        }
        const rows = response.result.list.map((e) =>
            {
                let row: any = {}
                definition.columns.forEach((column) =>
                {
                    row[column.field] = lookup(e, column.field)
                })
                return {...row, ...e}
            }
        )
        setData({rows: rows, totalCount: response.result.total});
        // Since 'definition' changes every render, will cause additional fetch, remove them from dependency array
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response]);

    return (
        <div>
            <FilterButtons definition={definition} queryState={queryState} setQueryState={setQueryState}/>
            <BaseDataGrid
                {...props}
                loading={loading}
                tableKey={definition.tableKey}
                disableTooBar={definition.disableTooBar}
                exportGroup={definition.exportGroup}
                initialState={
                    {
                        filter: {filterModel: queryState.filter},
                        sorting: {sortModel: queryState.sort},
                        pagination: {paginationModel: queryState.page},
                        columns: definition.initState?.columns ?? {columnVisibilityModel: {id: false}}
                    }
                }
                rows={data.rows}
                columns={definition.columns}
                paginationModel={queryState.page}
                onPageChange={onPageChange}
                pageSizeOptions={[5, 10, 20, 50, 100]}
                paginationMode="server"
                rowCount={data.totalCount}
                filterModel={queryState.filter}
                filterMode="server"
                onFilterModelChange={onFilterChange}
                sortingMode="server"
                sortModel={queryState.sort}
                onSortModelChange={onSortChange}
            />
        </div>
    );
}


export default memo(withRouter(PaginatedDataGrid));
