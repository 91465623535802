import React, {createContext, useCallback, useContext, useState} from "react";
import Auth from "./Auth";

type TenantContextType = {
    tenantId: string | null
    setTenantId: (tenantId: string) => void
}

const TenantContext = createContext<TenantContextType | undefined>(undefined);

export const TenantProvider = ({children}) =>
{
    const [tenantId, setTenantId] = useState(Auth.getTenant())

    const handleSetTenantId = useCallback((tenantId: string) =>
    {
        setTenantId(tenantId)
        Auth.setTenant(tenantId)
    }, [])

    return <TenantContext.Provider value={{tenantId, setTenantId: handleSetTenantId}}>
        {children}
    </TenantContext.Provider>
}

export const useTenant = (): TenantContextType =>
{
    const context = useContext(TenantContext);
    if (!context)
    {
        throw new Error('useTenant must be used within a TenantContext');
    }
    return context;
}

export const withTenant = (WrappedComponent: any) =>
{
    return function TenantComponent(props: any)
    {
        const {tenantId, setTenantId} = useTenant();
        return <WrappedComponent {...props} tenantId={tenantId} setTenantId={setTenantId}/>;
    };
}