import React, {useCallback, useState} from 'react';
import UploadFile, {
    CustomerDocCategoryType,
    DrawingsCategoryType,
    ProductionDrawingsCategoryType
} from "../../common/file/UploadFile";
import {filter, map} from 'lodash';
import FileList from "../../common/file/FileList";
import TaskFooter from "../../common/task-workflow/TaskFooter";
import SelectUser from "../../common/SelectUser";
import {Alert, Col, Row} from "reactstrap";
import {useGetFilesQuery} from "../../generated/graphql";
import DefaultQueryHandler from "../../common/DefaultQueryHandler";
import CommentsPanel from "../../common/task-workflow/comment/CommentsPanel";
import ArticlesAndAutoDrawArticlesOverview from "../../common/task-workflow/ArticlesAndAutoDrawArticlesOverview";
import {useLocale} from "../../LocaleProvider";
import DrawJobOrderInfo from "../../common/task-workflow/DrawJobOrderInfo";

export default ({variables,onSubmit,update,toggle, task})=>
{
    const res = useGetFilesQuery({variables:{purchaseOrderId:variables.purchaseOrderId}});
    const [files,setFiles] = useState(variables.productionDrawings || []);
    const [reviewer,setReviewer] = useState(variables.reviewer || null);
    const [comments, setComments] = useState(variables.comments ?? [])
    const {t} = useLocale()

    const save = useCallback(async (toggle)=>
    {
        await update({
            productionDrawings: {
                value: JSON.stringify(map(files)),
                type: "json",
            },
            reviewer:
            {
                value:reviewer,
                type:"string"
            },
            comments: {
                value: JSON.stringify(comments),
                type: "json"
            }
        },toggle)
    },[update,files,reviewer, comments]);
    const submit = useCallback(async ()=>
    {
        const submit = async ()=>
        {
            await onSubmit({
                productionDrawings: {
                    value: JSON.stringify(map(files)),
                    type: "json",
                },
                reviewer:
                {
                    value:reviewer,
                    type:"string"
                },
                comments: {
                    value: JSON.stringify(comments),
                    type: "json"
                }
            })
        };
        if(!reviewer)
        {
            window.alert(`Please select a reviewer!`);
            return;
        }
        if(files.length > 0)
        {
            await submit();
        }
        else
        {
            if(window.confirm(t(`You have not uploaded any drawings. Are you sure you want to proceed?`)))
            {
                await submit();
            }
        }
    },[onSubmit,reviewer,files, comments, t]);

    const drawings = filter(res.data?.files,file=>file.fileCategory?.id === DrawingsCategoryType.id || file.fileCategory?.id === ProductionDrawingsCategoryType.id);
    const customerDocumentation = filter(res.data?.files,file=>file.fileCategory?.id === CustomerDocCategoryType.id);
    const {articles,comment,orderId,autoDrawArticles,initiator,drawingComment} = variables;
    return <DrawJobOrderInfo task={task} variables={variables}>
        <>
            {comment && <div>
                <h2>{t('Comment')}</h2>
                <p>
                    {comment}
                </p>
            </div>}
            <ArticlesAndAutoDrawArticlesOverview articles={articles} autoDrawArticles={autoDrawArticles}
                                                 orderId={orderId}/>
            <hr/>
            {drawingComment && drawingComment.length > 0 && <><h4>Comment from {initiator}</h4>
                <Alert color="warning">
                     <pre>
                         {drawingComment}
                     </pre>
                </Alert></>}

            <DefaultQueryHandler res={res}>
                <React.Fragment>
                    <h4>{t('Customer documentation files')}</h4>
                    <FileList files={customerDocumentation}/>
                    <hr/>
                    <h4>{t('Uploaded drawing files')}</h4>
                    <FileList files={drawings}
                              onDelete={() =>
                              {
                              }}/>
                </React.Fragment>
            </DefaultQueryHandler>

            <hr/>
            <h4>{t('Uploaded files')}</h4>
            <FileList files={files}
                      onDelete={(deletedFile) =>
                      {
                          setFiles(files => filter(files, file => file.id !== deletedFile.id));
                          save(false);
                      }
                      }/>
            <UploadFile fileCategory={ProductionDrawingsCategoryType}
                        onSubmit={uploadedFiles => setFiles(files => files.concat(uploadedFiles))}/>
            <hr/>
            <h4>{t('Select reviewer')}</h4>
            <Row>
                <Col sm={6}>
                    <SelectUser userId={reviewer} onChange={(selected) =>
                    {
                        setReviewer(selected.value);
                    }}/>
                </Col>
            </Row>

            <hr/>
            <CommentsPanel comments={comments}
                           setComments={(newComments) =>
                          {
                              setComments(newComments)
                          }}/>

            <TaskFooter toggle={toggle} save={() => save(false)} submit={() => submit()}/>
        </>
    </DrawJobOrderInfo>
}

