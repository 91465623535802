import {Link} from "react-router-dom";
import React from "react";
import {QUERY_PARAMS_IS_INVENTORY} from "../Constants";

export const articleDetailLink = (article, label, isInventory = false, target = "_blank") => {
    if (!label) {
        label = article.artNo;
    }

    if (article.spiralStair) {
        return <Link to={`/spiralStairDetail/${article.id}?${QUERY_PARAMS_IS_INVENTORY}=${isInventory}`}
                     target={target}>{label}</Link>
    }
    if (article.straightStair) {
        return <Link to={`/straightStairDetail/${article.id}?${QUERY_PARAMS_IS_INVENTORY}=${isInventory}`}
                     target={target}>{label}</Link>
    }
    if (article.straightStairV2) {
        return <Link to={`/straightStairV2Detail/${article.id}?${QUERY_PARAMS_IS_INVENTORY}=${isInventory}`}
                     target={target}>{label}</Link>
    }
    if (article.moduleRamp) {
        return <Link to={`/moduleRampDetail/${article.moduleRamp.id}?${QUERY_PARAMS_IS_INVENTORY}=${isInventory}`}
                     target={target}>{label}</Link>
    }
    return <Link to={`/articleDetail/${article.id}?${QUERY_PARAMS_IS_INVENTORY}=${isInventory}`}
                 target={target}>{label}</Link>;
};