import {
    useGetChangeLogsByCompanyIdQuery,
} from "../../generated/graphql";
import React, {useMemo} from "react";
import ChangeLogsTable, {Difference, findDifferences, formatChanges} from "../../common/ChangeLogsTable";

const TABLE_KEY_CHANGE_LOGS = "TABLE_KEY_COMPANY_CHANGE_LOGS"

const CompanyChangeLogsPanel = ({companyId}: { companyId: string }) =>
{
    const {data: response, loading} = useGetChangeLogsByCompanyIdQuery({variables: {companyId}})

    const data = useMemo(() =>
    {
        if (loading)
        {
            return []
        }

        return response?.changeLogsByCompanyId?.sort((a, b) => a.createdAt > b.createdAt ? 1 : -1)
            .map((changeLog) =>
            {
                const diff = findDifferences(changeLog.oldValue, changeLog.newValue)
                const relevantDiffs = ["creditRating", "creditRatingComment", "creditRatingLimit", "creditRatingLimitCurrency"]
                    .reduce((acc, key) =>
                    {
                        if (diff[key])
                        {
                            acc[key] = diff[key];
                        }
                        return acc;
                    }, {} as Difference);

                const message = Object.keys(relevantDiffs).length > 0 ? `Updated: ${formatChanges(relevantDiffs)}` : null

                return {...changeLog, message}
            }).filter((e) => e.message != null)
    }, [response, loading])

    return <div>
        <ChangeLogsTable tableKey={TABLE_KEY_CHANGE_LOGS}
                         data={data}/>
    </div>
}

export default CompanyChangeLogsPanel;