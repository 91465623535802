import React, {useCallback, useEffect, useMemo, useState} from "react";
import CommentsPanel from "../../common/task-workflow/comment/CommentsPanel";
import NewFormRenderer from "../../common/NewFormRenderer";
import TaskFooter from "../../common/task-workflow/TaskFooter";
import NotificationPopup from "../../common/lib/NotificationPopup";
import OrderInfo from "../../common/task-workflow/OrderInfo";
import {useGetOrderQuery, useGetPurchaseOrderQuery, useUpdatePurchaseOrderMutation} from "../../generated/graphql";
import {useLocale} from "../../LocaleProvider";
import GetTermsOfDeliveryQuery from "../../common/graphql/GetTermsOfDelivery.graphql";
import GetTermsOfPayment from "../../common/graphql/GetTermsOfPayment.graphql";
import GetModeOfDelivery from "../../common/graphql/GetModeOfDelivery.graphql";


const SubmitPurchaseOrderDetail = ({variables, onSubmit, toggle}) =>
{
    const [comments, setComments] = useState(variables.confirmDetailComments ?? [])
    const [formData, setFormData] = useState<any>(null);
    const {t} = useLocale()

    const {orderId, purchaseOrderId} = variables
    const {data} = useGetOrderQuery({variables: {id: orderId}})
    const {data: responsePO} = useGetPurchaseOrderQuery({variables: {id: purchaseOrderId}})
    const [updatePOMutation] = useUpdatePurchaseOrderMutation({refetchQueries: ["getPurchaseOrder"]})

    const formDefinition = useMemo(() =>
    {
        const purchaseOrder = responsePO?.purchaseOrder
        const locale = purchaseOrder ? purchaseOrder?.supplier?.locale : null;
        return [
            {
                name: "deliveryDate", type: "date", required: true, disablePast: true,
                text: "Not order delivery date"
            },
            {name: "blank", type: "hidden", hideField: () => true},
            {name: "blank", type: "hidden", hideField: () => true},
            {name: 'deliveryTitle'},
            {name: 'deliveryStreet', required: true},
            {name: 'deliveryPostalCode', pattern: "[0-9 ]+"},
            {name: 'deliveryCity'},
            {name: 'deliveryCountry'},
            {name: 'deliveryFreeText'},
            {
                name: "termsOfDeliveryId", label: "Terms of delivery",
                type: "select", query: GetTermsOfDeliveryQuery, required: true,
                variables: {
                    locale: locale
                }
            },
            {
                name: "termsOfPaymentId", label: "Terms of payment",
                type: "select", query: GetTermsOfPayment, required: true,
                variables: {
                    locale: locale
                }
            },
            {
                name: "modeOfDeliveryId", label: "Mode of delivery",
                type: "select", query: GetModeOfDelivery, required: true,
                variables: {
                    locale: locale
                }
            }]
    }, [responsePO?.purchaseOrder]);

    const handleSubmit = useCallback(async () =>
    {
        const {deliveryDate, termsOfDeliveryId, termsOfPaymentId, modeOfDeliveryId} = formData
        if (!deliveryDate || !termsOfDeliveryId || !termsOfPaymentId || !modeOfDeliveryId)
        {
            NotificationPopup.error(t(`The information is incomplete.`))
            return
        }

        await updatePOMutation({
            variables: {
                id: purchaseOrderId,
                purchaseOrder: formData
            }
        })

        const submitVariable = {
            confirmDetailComments: {
                value: JSON.stringify(comments),
                type: "json"
            }
        }
        onSubmit(submitVariable)
    }, [onSubmit, comments, formData, purchaseOrderId, updatePOMutation, t])

    const onChange = useCallback((key, value) =>
    {
        setFormData(prevState =>
        {
            return prevState ? ({...prevState, [key]: value}) : prevState
        })
    }, []);

    useEffect(() =>
    {
        const po = responsePO?.purchaseOrder
        if (!po)
        {
            return
        }

        const data = {
            deliveryDate: po.deliveryDate,
            deliveryTitle: '',
            deliveryStreet: '',
            deliveryCity: '',
            deliveryCountry: '',
            deliveryPostalCode: '',
            modeOfDeliveryId: po.modeOfDelivery?.id,
            termsOfPaymentId: po.termsOfPayment?.id,
            termsOfDeliveryId: po.termsOfDelivery?.id,
        }

        if (!po.deliveryStreet && !po.deliveryTitle && !po.deliveryStreet && !po.deliveryCity && !po.deliveryCountry && !po.deliveryPostalCode)
        {
            data.deliveryTitle = 'Eurostair Produktion AB'
            data.deliveryStreet = 'Säterivägen 30'
            data.deliveryCity = 'Säffle'
            data.deliveryCountry = 'Sweden'
            data.deliveryPostalCode = '661 30'
        } else
        {
            data.deliveryTitle = po.deliveryTitle ?? ''
            data.deliveryStreet = po.deliveryStreet ?? ''
            data.deliveryCity = po.deliveryCity ?? ''
            data.deliveryCountry = po.deliveryCountry ?? ''
            data.deliveryPostalCode = po.deliveryPostalCode ?? ''
        }
        setFormData(data)
    }, [responsePO]);

    return <>
        {data?.order && <OrderInfo order={data?.order}/>}
        <h4>{t('Confirm Purchase Order Detail')}</h4>
        {formData && <NewFormRenderer formDefinition={formDefinition}
                                      columns={3}
                                      object={formData} onChange={onChange}/>}
        <hr/>
        <CommentsPanel comments={comments}
                       setComments={(newComments) => setComments(newComments)}/>
        <TaskFooter toggle={toggle} submit={handleSubmit}/>
    </>
}

export default SubmitPurchaseOrderDetail