import React, {useCallback, useEffect, useMemo, useState} from "react";
import TaskFooter from "../../common/task-workflow/TaskFooter";
import { useGetPurchaseOrderQuery, useUpdatePurchaseOrderMutation } from "../../generated/graphql";
import Spinner from "reactstrap/lib/Spinner";
import ESPLOrderDeliveryInfo from "../../common/task-workflow/espl/ESPLOrderDeliveryInfo";
import NewFormRenderer from "../../common/NewFormRenderer";
import {downloadPurchaseOrderInvoicePDF} from "../../UTIL";
import {Button} from "reactstrap";
import NotificationPopup from "../../common/lib/NotificationPopup";
import Auth from "../../Auth";
import {useLocale} from "../../LocaleProvider";
import {URL_PURCHASE_ORDER_INVOICE} from "../../Constants";

type FormDataType = {
    invoiceNumber: string | null | undefined
    invoiceDate: any
}

const PrintInvoice = ({ onSubmit, variables, toggle }) => {
    const { purchaseOrderId } = variables;
    const [formData, setFormData] = useState<FormDataType | null>(null);
    const [showPdf, setShowPdf] = useState(false);
    const {t} = useLocale()

    const { data, loading } = useGetPurchaseOrderQuery({
        variables: { id: purchaseOrderId }
    });

    const [updatePOMutation] = useUpdatePurchaseOrderMutation({
        refetchQueries: ["getPurchaseOrder"]
    });

    const formDefinition = useMemo(() =>
    {
        return [
            {
                name: "invoiceNumber",
                type: "text",
                required: true,
            },
            {
                name: "invoiceDate",
                type: "date",
                required: true,
            },
        ];
    }, [])

    useEffect(() => {
        if (data?.purchaseOrder) {
            setFormData({
                invoiceNumber: data.purchaseOrder.invoiceNumber,
                invoiceDate: data.purchaseOrder.invoiceDate
            });
        }
    }, [data]);

    const onChange = useCallback((key: string, value: any) => {
        setFormData(prevState => prevState ? ({...prevState, [key]: value}) : null);
    }, []);

    const handleSubmit = useCallback(async () => {
        if (!formData?.invoiceNumber || !formData?.invoiceDate) {
            NotificationPopup.error("Please enter an invoice number and date");
            return;
        }

        try {
            await updatePOMutation({
                variables: {
                    id: purchaseOrderId,
                    purchaseOrder: formData
                }
            });
            setShowPdf(true);
        } catch (error:any) {
            NotificationPopup.error(`Failed to update invoice number: ${error.message}`);
        }
    }, [formData, purchaseOrderId, updatePOMutation]);

    if (loading) {
        return <Spinner />;
    }
    if (data?.purchaseOrder && showPdf) {
        const download = () => downloadPurchaseOrderInvoicePDF(
            data.purchaseOrder?.id,
            `PO-${data.purchaseOrder?.number}-${data.purchaseOrder?.supplier?.name}-invoice`,
        );

        const src = `${URL_PURCHASE_ORDER_INVOICE}${data.purchaseOrder.id}?token=${Auth.getToken()}`;

        return (
            <div>
                <div style={{height: "750px"}}>
                    <embed src={src} type="application/pdf" width="100%" height="100%"/>
                </div>
                <TaskFooter
                    toggle={toggle}
                    submit={onSubmit}
                    submitLabel="Complete task"
                    customButtons={[
                        <>
                            {!loading &&
                                <Button color="primary" style={{float:'right'}} onClick={async () => {
                                    await download();
                                }}>
                                    {t('Download')}
                                </Button>
                            }
                        </>
                    ]}
                />
            </div>
        );
    }

    return (
        <>
            <h5 style={{padding: '0', margin: '4px 0 0 0'}}>{t('Delivery Info')}</h5>
            <ESPLOrderDeliveryInfo purchaseOrder={data?.purchaseOrder}/>
            {
                formData && <>
                    <h5 style={{marginTop: '10px'}}>{t('Enter Invoice Details')}</h5>
                    <NewFormRenderer formDefinition={formDefinition}
                                     columns={2}
                                     object={formData}
                                     onChange={onChange}/>

                    <TaskFooter
                        toggle={toggle}
                        submit={handleSubmit}
                        submitLabel="Generate Invoice PDF"
                    />
                </>
            }
        </>
    );
};

export default PrintInvoice;