import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
    Button,
    Nav,
    Navbar,
    NavbarBrand,
    NavItem,
    PopoverBody,
    PopoverHeader,
    UncontrolledPopover
} from "reactstrap";

import {Link, NavLink, Route, Switch} from "react-router-dom";
import {withRouter} from "react-router";
import PropTypes from 'prop-types'
import Auth from "./Auth";
import DefaultSidebar from './DefaultSidebar';
import logo from 'assets/images/Eurostair_vit_CMYK.png';
import ProjectSidebar from "./project/ProjectSidebar";
import ContactSideBar from "./contact/ContactSidebar";
import CompanySideBar from './company/CompanySidebar';
import {version} from '../package.json';
import config from './config';
import SupplierSidebar from "./supplier/SupplierSidebar";
import PurchaseOrderSidebar from "./purchaseOrder/PurchaseOrderSidebar";
import OrderSidebar from "./order/OrderSidebar";
import preval from 'preval.macro';
import ArrowRight from "./common/icons/ArrowRight";
import PackageSidebar from "./package/component/PackageSidebar";
import ShipmentSidebar from "./shipment/component/ShipmentSidebar";
import {useGetUserGroupsAndTenantsQuery} from "./generated/graphql";
import {InventoryRoute, isInventoryPath} from "./inventory/InventoryRouters";
import {INVENTORY_TENANT_ID_LIST, QUERY_PARAMS_IS_INVENTORY} from "./Constants";
import {useLocale} from "./LocaleProvider";
import {useTenant} from "./TenantProvider";


const DeviationReportButton = ({isOnInventoryPage}) => {
    return <Link to={`/deviation-report?${QUERY_PARAMS_IS_INVENTORY}=${isOnInventoryPage}`} target="_blank">
        <Button>
            Deviation Report
        </Button>
    </Link>
}

const ToggleButton = ({onClick}) => {
    const [expand, setExpand] = useState(false);
    const thisOnClick = useCallback(() => {
        setExpand(!expand);
        onClick && onClick();
    }, [expand, setExpand, onClick]);
    return <div onClick={thisOnClick} className={"toggleButton"}>
        <div className={"toggleIcon " + (expand ? "expand" : "")}>
            <ArrowRight fill="#7a8aa1"/>
        </div>
    </div>
}

const TenantSelects = (props) => {
    const {userTenants, isOnInventoryPage} = props
    const {tenantId, setTenantId} = useTenant()

    const handleInventoryTenantChanged = useCallback(() => {
        if (isOnInventoryPage) {
            props.history.go(0);
            return
        }
        props.history.push(InventoryRoute.INDEX);
    }, [isOnInventoryPage, props.history])

    const handleCRMTenantChanged = useCallback(() => {
        if (isOnInventoryPage) {
            props.history.push('/');
            return
        }
        props.history.go(0);
    }, [isOnInventoryPage, props.history])

    const onChange = useCallback((event) => {
        const value = event.target.value
        setTenantId(value)

        if (INVENTORY_TENANT_ID_LIST.includes(value)) {
            handleInventoryTenantChanged()
            return
        }

        handleCRMTenantChanged()
    }, [handleInventoryTenantChanged, handleCRMTenantChanged, setTenantId])

    if (!userTenants || userTenants.length === 0) {
        return null
    }

    return <>
        <select className="nav-link" value={tenantId}
                onChange={onChange}>
            {userTenants.map(item => <option key={item}
                                             value={item}>{item}</option>)}
        </select>
    </>
}

const Sidebar = (props) => {
    const [backendVersion, setBackendVersion] = useState("Loading..");
    const [camundaVersion, setCamundaVersion] = useState("Loading..");
    const [userExpand, setUserExpand] = useState(null);
    const {t} = useLocale()

    const {data} = useGetUserGroupsAndTenantsQuery()

    const toggleButtonClick = () => {
        if (userExpand === null) {
            setUserExpand(!props.expand)
        } else {
            setUserExpand(!userExpand)
        }
    }

    useEffect(() => {
        async function fetchVersion() {
            try {
                const res = await fetch(config.BACKEND_URI + "version");
                const json = await res.json();
                setBackendVersion(json.version);
                setCamundaVersion(json.camundaVersion);
            } catch (e) {
                console.error(e);
            }
        }

        fetchVersion();
    }, []);

    useEffect(() => {
        if (data && data.groups && data.groups.length > 0) {
            Auth.setGroups(data.groups);
        }
    }, [data])

    const isOnInventoryPage = useMemo(()=> {
        return isInventoryPath(props.location)
    },[props.location])

    const userTenants = useMemo(() => {
        return data?.userTenants
    }, [data?.userTenants])

    const expand = (userExpand === null) ? props.expand : userExpand;
    return (
        <div className={"sidebar-nav " + (expand ? "expand" : "")}>
            <ToggleButton onClick={toggleButtonClick}/>
            <div style={{width: "100%", overflow: "hidden", height: "100%"}}>
                <div style={{minWidth: "200px", position: "relative", height: "100%"}}>
                    <div style={{margin: "15px"}}>
                        <img src={logo} style={{width: "150px", height: "auto", marginBottom: 25}}/>
                    </div>
                    <Navbar className="text-light" dark expand="md">
                        <NavbarBrand className="d-block d-sm-none">Eurostair CRM</NavbarBrand>

                        <Nav pills vertical>
                            <TenantSelects userTenants={userTenants}
                                           history={props.history}
                                           isOnInventoryPage={isOnInventoryPage}/>
                            {
                                !isOnInventoryPage && <Switch>
                                    <Route exact path='/*compan*' component={CompanySideBar}/>
                                    <Route exact path='/*contact*' component={ContactSideBar}/>
                                    <Route exact path='/*project*' component={ProjectSidebar}/>
                                    <Route exact path='/*supplier*' component={SupplierSidebar}/>
                                    <Route exact path='/*purchase*' component={PurchaseOrderSidebar}/>
                                    <Route exact path='/*order*' component={OrderSidebar}/>
                                    <Route exact path='/*package*' component={PackageSidebar}/>
                                    <Route exact path='/*shipment*' component={ShipmentSidebar}/>
                                    <Route exact path='/*' component={DefaultSidebar}/>
                                </Switch>
                            }
                            <NavItem>
                                <NavLink to={isOnInventoryPage ? InventoryRoute.PROFILE : "/profile"}
                                         className="nav-link">{t('Profile')}</NavLink>
                            </NavItem>
                            <NavItem>
                                <a href="/#" onClick={() => {
                                    Auth.deauthenticateUser();
                                    props.logout();
                                }} className="nav-link">{t('Log out')}</a>
                            </NavItem>
                        </Nav>
                    </Navbar>

                    <React.Fragment>
                        <div style={{position: "absolute", bottom: 45, left: 15, fontSize: 18}}>
                            <DeviationReportButton isOnInventoryPage={isOnInventoryPage}/>
                        </div>
                        <UncontrolledPopover trigger="legacy" placement="top" target="version-info">
                            <PopoverHeader>
                                System Info
                            </PopoverHeader>
                            <PopoverBody>
                        <span style={{fontSize: "13px"}}>
                            Version: {version}
                            <br/>
                            Build time:
                            <br/>
                            {preval`module.exports = new Date().toLocaleString();`}
                            <br/>
                            Backend version: {backendVersion}
                            <br/>
                            Camunda version: {camundaVersion}
                            {
                                data?.groups?.length > 0 &&
                                <div>
                                    Groups:
                                    <ul style={{paddingLeft: '10px', listStyleType: "none"}}>
                                        {data.groups.map(grp => <li key={grp}>{grp}</li>)}
                                    </ul>
                                </div>}
                        </span>
                            </PopoverBody>
                        </UncontrolledPopover>
                        <div id="version-info"
                             style={{
                                 cursor: "pointer",
                                 position: "absolute",
                                 bottom: 20,
                                 left: 15,
                                 color: "wheat"
                             }}>
                            System-info
                        </div>
                    </React.Fragment>
                </div>
            </div>
        </div>
    );

}

Sidebar.propTypes =
    {
        logout: PropTypes.func
    };

export default withRouter(Sidebar)