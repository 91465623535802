import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {downloadFile, formatToSwedishTime} from "../../UTIL";
import {Table} from "reactstrap";
import {isEmpty, sortBy} from 'lodash';
import Trash from "../icons/Trash";
import {graphql} from "@apollo/client/react/hoc";
import gql from "graphql-tag";
import {withLocale} from "../../LocaleProvider";

const deleteFileMutation = gql`
    mutation deleteFile($id:ID!)
    {
        deleteFile(id:$id)
    }
`;
class FileList extends Component
{
    constructor()
    {
        super();
        this.deleteFile = this.deleteFile.bind(this);
    }

    async deleteFile(file)
    {
        if(window.confirm(`Are you sure you want to remove file ${file.filename}?`))
        {
            await this.props.deleteFile({variables:{id:file.id},refetchQueries:["getFiles"]});
            this.props.onDelete(file);
        }


    }

    render()
    {
        const files = this.props.files;
        const {t} = this.props

        if(isEmpty(files))
        {
            return <p>{t('No files')}..</p>;
        }
        const deleteEnabled = !!this.props.onDelete;
        return <div>
            <Table striped bordered hover>
                <thead>
                <tr>
                    <th>{t('Filename')}</th>
                    <th>{t('Created at')}</th>
                    <th>{t('Creator')}</th>
                    <th>{t('Category')}</th>
                    {deleteEnabled && <th>{t('Remove')}</th>}
                </tr>
                </thead>
                <tbody>
                {sortBy(files,file=>file.createdAt).reverse().map(file => <tr key={file.id}>
                    <td><span className='link' onClick={async () => {
                        await downloadFile(file.id, file.filename);
                    }}>{file.filename}</span>
                    </td>
                    <td>{formatToSwedishTime(file.createdAt)}</td>
                    <td>{file.creator?file.creator.displayName:(file.creatorId || "N/A")}</td>
                    <td>
                        {file.fileCategory?.name || ''}
                    </td>
                    {deleteEnabled && <td><p className='link' onClick={()=>this.deleteFile(file)}><Trash/></p></td>}
                </tr>)}
                </tbody>
            </Table>
        </div>
    }
}

FileList.propTypes =
{
    files:PropTypes.arrayOf(PropTypes.object),
    onDelete:PropTypes.func,
};

export default graphql(deleteFileMutation,{name:"deleteFile"})(withLocale(FileList));
