import React from 'react';
import {withApollo} from '@apollo/client/react/hoc'
import {Link} from 'react-router-dom';
import ColumnId from "../common/data-grid/columns/ColumnId";
import ColumnCreatedAt from "../common/data-grid/columns/ColumnCreatedAt";
import PaginatedDataGrid from "../common/data-grid/PaginatedDataGrid";
import {SuppliersV2Document} from "../generated/graphql";
import ColumnDate from "../common/data-grid/columns/ColumnDate";


const supplierColumns = () => {
    return [
        {
            field: "name",
            headerName: "Name",
            searchable: true,
            renderCell: (params) => {
                return (
                    <Link to={`/supplierDetail/${params.id}`}>{params.value}</Link>
                )
            }
        },
        {
            field: 'eanValue',
            headerName: 'EAN Value',
            type: 'number',
            parseFilterValue: (value) => Number(value),
            renderCell: (params) => <div>{params.value.toString().padStart(2, "0")}</div>
        },
        {
            field: 'abbreviation'
        },
        ColumnDate("evaluateAt","Evaluated At"),

        ColumnCreatedAt(),
        ColumnId(),
    ]
}

const KEY_TABLE = 'SUPPLIER'

const defaultSort = {sortModel: [{field: 'createdAt', sort: 'asc'}]}

const SupplierListPage = () => {
    return (
        <PaginatedDataGrid definition={{
            tableKey: KEY_TABLE,
            columns: supplierColumns(),
            initState: {sorting: defaultSort},
        }} query={SuppliersV2Document}/>
    )

}


export default withApollo(SupplierListPage);
