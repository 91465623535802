import React, {useCallback, useState} from "react";
import {Button, Col, Row, Spinner} from "reactstrap";
import {useLocale} from "../../LocaleProvider";

const TaskApproveFooter = ({toggle, onReject, onApprove}: {
    toggle: () => void,
    onReject: () => Promise<void>,
    onApprove: () => Promise<void>
}) =>
{
    const [loading, setLoading] = useState(false);
    const {t} = useLocale()

    const handleApproveClick = useCallback(async () =>
    {
        setLoading(true);
        await onApprove();
        setLoading(false);
    }, [onApprove])

    return <>
        <hr/>
        <Row className="justify-content-between">
            <Col sm={2}>
                <Button onClick={() => toggle()}>
                    {t('Cancel')}
                </Button>
            </Col>

            <Col sm={4} style={{textAlign: 'right'}}>
                <Button color={"danger"} onClick={onReject}>
                    {t('Reject')}
                </Button>
                {
                    loading ? <Spinner color={'warning'}/>
                        : <Button color="success" style={{marginLeft: "8px"}}
                                  onClick={handleApproveClick}>
                            {t('Approve')}
                        </Button>
                }
            </Col>
        </Row>
    </>
}

export default TaskApproveFooter;