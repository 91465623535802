import {priceCellRenderer} from "../lib/UTIL";
import React, {useState} from "react";
import {Col, Spinner, Modal, ModalHeader, ModalBody} from "reactstrap";
import Pen from "../icons/Pen";
import Trash from "../icons/Trash";
import gql from "graphql-tag";
import {useMutation, useQuery} from "@apollo/client";
import FormRenderer from "../../common/FormRenderer";
import TableGrid from "../components/TableGrid";
import NotificationPopup from "../../common/lib/NotificationPopup";
import {useLocale} from "../../LocaleProvider";

const onlinePriceListForArticle = gql`
    query onlinePriceListForArticle($artNo:String!) {
        onlinePriceListForArticle(artNo:$artNo) {
            id
            price
            currency
            companyName
            companyId
            stockArticleId
        }
    }`;

const updateOnlinePriceListMutation = gql`
    mutation updateOnlinePriceList($onlinePriceList:OnlinePriceListInputType!)
    {
        updateOnlinePriceList(onlinePriceList:$onlinePriceList) {
            id
        }
    }
`;

const deleteOnlinePriceListMutation = gql`
    mutation deleteOnlinePriceList($id:ID!)
    {
        deleteOnlinePriceList(id:$id)
    }
`;

const UpdateOnlinePrice = ({onlinePrice, toggle, refetch}) =>
{
    const [updateOnlinePrice] = useMutation(updateOnlinePriceListMutation);
    if (!onlinePrice) return null;
    return <Modal isOpen={true} toggle={toggle}>
        <ModalHeader toggle={toggle}>
            Update online price
        </ModalHeader>
        <ModalBody>
            <FormRenderer
                columns={null}
                customSubmit={null}
                object={onlinePrice}
                onSubmit={async ({object}) =>
                {
                    toggle();
                    object.companyId = onlinePrice.companyId;
                    object.stockArticleId = onlinePrice.stockArticleId;
                    await updateOnlinePrice({variables: {onlinePriceList: object}});
                    refetch();
                }}
                formDefinition={[
                    {name: "price", type: "currency", required: true},
                    {name: "currency", type: "currencyPicker", required: true}
                ]}
            />
        </ModalBody>
    </Modal>
}

export default ({pageActive, artNo}) =>
{
    const [selectedOnlinePrice, setSelectedOnlinePrice] = useState(null);
    const {t} = useLocale()
    const [deleteOnlinePriceList] = useMutation(deleteOnlinePriceListMutation);
    const {data, loading, refetch} = useQuery(onlinePriceListForArticle, {variables: {artNo}});
    if (!pageActive) return null;
    if (loading)
    {
        return <Spinner color={"warning"} animation='border'/>
    }
    return <>
        <Col lg={6}>
            {data?.onlinePriceListForArticle?.length && data?.onlinePriceListForArticle?.length !== 0 ?
                <TableGrid
                    data={data.onlinePriceListForArticle}
                    columns={[
                        {Header: "Company", accessor: "companyName", Cell: ({row, value}) => value},
                        {Header: "Price", accessor: "price", Cell: priceCellRenderer()},
                        {
                            Header: "Actions", Cell: ({row: {original}}) =>
                            {
                                return <div>
                                    <span style={{paddingRight: '20px'}} className={"link"} onClick={() =>
                                    {
                                        setSelectedOnlinePrice(original);
                                    }}>
                                        <Pen/>
                                    </span>
                                    <span className={"link"} onClick={async () =>
                                    {
                                        try
                                        {
                                            if (window.confirm(`Are you sure you want to the online price for ${original.companyName}?`))
                                            {
                                                await deleteOnlinePriceList({variables: {id: parseInt(original.id)}});
                                                refetch();
                                                NotificationPopup.info(`Deleted online price for ${original.companyName}`);
                                            }
                                        } catch (e: any)
                                        {
                                            NotificationPopup.error(`Failed to delete online price for company ${e.message}`);
                                        }
                                    }}>
                                        <Trash/>
                                    </span>
                                </div>
                            }
                        }
                    ]}/>
                : <div>{t('No online prices found')}</div>
            }
        </Col>
        <UpdateOnlinePrice onlinePrice={selectedOnlinePrice}
                           toggle={() => setSelectedOnlinePrice(null)} refetch={refetch}/>
    </>
}