import './priceList.css';
import {ChangeEvent, createContext, KeyboardEvent, useCallback, useContext, useEffect, useState} from "react";
import {
    PriceList,
    useGetPriceListQuery,
    useNewPriceListVersionMutation,
} from "../generated/graphql";
import {Button, Modal, ModalBody, ModalHeader, UncontrolledTooltip} from "reactstrap";
import {useMutationHandler} from "../UTILmore";
import {compact, isNaN} from "lodash";
import NotificationPopup from "../common/lib/NotificationPopup";

const ModifiedData = createContext<any>({});

const MyInput = ({path, iniValue}:{path:any[], iniValue:number})=>{
    const {iniData, diffData, setDiffData} = useContext(ModifiedData);
    const [value, setValue] = useState(iniValue);
    const [className, setClassName] = useState("");

    useEffect(()=>{//when initialized
        setClassName("");
    },[iniData]);

    const onChange = useCallback((e:ChangeEvent<any>)=>
    {
        const newDiffData = diffData.slice(0);
        let currentNode:any = newDiffData;
        let nodesOfPath:any[] = [newDiffData];
        for(let i=0; i<path.length-1; i++){
            let key = path[i];
            if(typeof currentNode[key] === "undefined"){
                Object.assign(currentNode,{[key]:{}});
            }
            currentNode = currentNode[key];
            nodesOfPath[i+1] = currentNode;
        }
        (currentNode as any)[path[path.length-1]]= parseFloat(e.target.value);

        newDiffData[path[0]].name = iniData[path[0]].name;
        setDiffData(newDiffData);

        if(parseFloat(e.target.value)!==iniValue){//have to use '!=' not '!=='
            setClassName("changed");
        }else{
            setClassName("");
            delete currentNode[path[path.length-1]];
            //clear empty node
            for(let i=nodesOfPath.length-1; i>0; i--){
                let keys = Object.keys(nodesOfPath[i])
                if(keys.length===0 || (keys.length===1 && keys[0]==="name" ) ){
                    delete nodesOfPath[i-1][path[i-1]];
                }else{
                    break;
                }
            }
        }

        setValue(e.target.value);
        console.log(diffData);
    },[path, iniValue, iniData, diffData, setDiffData]);

    const preventNaN=(evt:KeyboardEvent) => evt.key === 'e' && evt.preventDefault()

    return <input type={'number'} className={className} value={value} onKeyDown={preventNaN} onChange={onChange}/>;
}

const InputRow = ({path, label, iniValue}:{path:any[], label?:string, iniValue:number })=>{
    return (
        <div className="row">
            <label>{label}</label>
            <MyInput path={path} iniValue={iniValue}/>
        </div>
    );
}
const TableField = ({priceListData, onNext}:{priceListData?: PriceList[], onNext:()=>void })=>{
    if(typeof priceListData==="undefined")return null;

    return (
        <>{
            priceListData?.map( (priceList,index)=>
            <fieldset key={index} className="price-list">
                <legend>
                    {priceList.name}
                    {priceList.description?(<>
                        <span id={"info"+index} className="infoIcon">ⓘ</span>
                        <UncontrolledTooltip
                            placement="right"
                            target={"info"+index}
                        >
                            {priceList.description}
                        </UncontrolledTooltip>
                        </>)
                        :null}
                </legend>

                {typeof(priceList.price) === "number"?
                    <InputRow key={index} path={[index,"price"]} iniValue={priceList.price as number}/>
                    :
                    Object.keys(priceList.radiusPrice as KeyValue).map(e=>{
                        if(priceList.radiusPrice && e!=="__typename") {
                            return <InputRow key={e}
                                             path={[index, "radiusPrice", e]}
                                             label={e}
                                             iniValue={(priceList.radiusPrice as any)[e]}/>;
                        }
                        return null
                    })
                }
            </fieldset>
            )
        }
            <div className="fixedButtonBox"><Button color={"light"} onClick={onNext}>Next</Button></div>
        </>
    )

}

const ConfirmationPage = ({onCancel,onSubmit}
                              :{onCancel:()=>void,onSubmit:()=>void})=>{
    let counting=0;
    const {iniData, diffData} = useContext(ModifiedData);
    const [diffCount, setDiffCount] = useState(0);
    useEffect(()=>{
        setDiffCount(counting);
        console.log("setCount");
    }, [counting])

    return (
        <div className="dialog">
            <p>{diffCount} changes total</p>
            {
                (diffData as PriceList[]).map( (diffPriceList,index)=>{
                    if(typeof(diffPriceList.price) === "number"){
                        counting++;
                        return (
                            <fieldset key={index} className="price-list">
                                <legend>{diffPriceList.name}</legend>
                                <div className="row">
                                    <label/>
                                    <span>{iniData[index].price as number}</span><span>→</span>
                                    <span>{diffPriceList.price as number}</span>
                                </div>
                            </fieldset>
                        );
                    }else{
                        return (
                            <fieldset key={index} className="price-list">
                                <legend>{diffPriceList.name}</legend>
                                {
                                    Object.keys(diffPriceList.radiusPrice as KeyValue).map(e=>{
                                        counting++;
                                        return (<div key={e} className="row">
                                            <label>{e}</label>
                                            <span>{(iniData[index].radiusPrice as any)[e]}</span><span>→</span>
                                            <span>{(diffPriceList.radiusPrice as any)[e]}</span>
                                        </div>);
                                    })
                                }
                            </fieldset>
                        );
                    }
                })
            }
            <div style={{"float":"left"}}><Button onClick={onCancel}>Back</Button></div>
            <div style={{"float":"right"}}>
                <Button disabled={counting===0} color={"success"} onClick={onSubmit}>Submit</Button>
            </div>

        </div>
    );
}

export default () =>{
    const [modelOpen, setModelOpen]=useState(false);
    const [iniData, setModifiedData]=useState<PriceList[]|undefined>(undefined);
    const [diffData, setDiffData]=useState<PriceList[]|undefined>([]);
    const resData = useGetPriceListQuery({variables:{}});
    const [newPriceListVersionMut] = useNewPriceListVersionMutation({refetchQueries:["getPriceList","priceListVersions"]});
    const newPriceListVersion = useMutationHandler(newPriceListVersionMut,"Update price list",isSuccess => {
        if(isSuccess) {
            setModelOpen(false);
            setDiffData([])
        }
    });

    useEffect(()=>{
        setModifiedData(resData.data?.priceList?.sort((a, b) => (a?.name ?? '') > (b?.name ?? '') ? 1 : -1) as PriceList[]);
    },[resData.data])

    function onNext(){
        setModelOpen(true);
    }
    function onBack(){
        setModelOpen(false);
    }
    async function onSubmit(){
        const missingPriceFields = diffData?.filter((e) => isNaN(e.price)) ?? []
        if (missingPriceFields.length > 0)
        {
            NotificationPopup.error(`Missing price in fields ${missingPriceFields.map(e => e.name).join(', ')}`);
            return
        }

        await newPriceListVersion({"variables": {"priceListData": compact(diffData)}});
    }
console.log("resData", resData)
    return (
    <div className={"price-list"}>
        <ModifiedData.Provider value={{iniData, diffData, setDiffData}}>
            {resData?.data?.priceList?
                    (<h2>Price List Ver. {resData.data.priceList[0]?.priceListId }</h2>):null
            }
            <TableField priceListData={iniData} onNext={onNext}/>
            <Modal  size={"lg"} style={{"maxWidth":"800px"}} isOpen={modelOpen} toggle={()=>{setModelOpen(!modelOpen)}}>
                <ModalHeader>
                    Confirm Changes
                </ModalHeader>
                <ModalBody>
                    <ConfirmationPage onCancel={onBack} onSubmit={onSubmit}/>
                </ModalBody>
            </Modal>
        </ModifiedData.Provider>
    </div>);

}


interface KeyValue{
    [key:string] :number
}

