import React, {useState} from 'react';
import gql from "graphql-tag";
import {Button, Modal, ModalBody, ModalHeader} from "reactstrap";
import {useStdMutation} from "../../spiralStair/lib/Util";
import FormRenderer from "../../common/FormRenderer";
import {useLocale} from "../../LocaleProvider";

export default ({article}) => {
    const [open, setOpen] = useState(false);
    const {t} = useLocale()
    const [createSt] = useStdMutation("Create stock transaction", gql`
        mutation createSt($stockTransaction:StockTransactionCreateInputType!)
        {
            createStockTransaction(stockTransaction: $stockTransaction)
            {
                id
                quantity
            }
        }`, {refetchQueries: ["getInventoryArticle"]})
    const toggle = () => setOpen(e => !e);
    return <div>
        <Button style={{marginBottom: "5px"}} onClick={() => setOpen(true)}>{t(`Create stock transaction`)}</Button>
        <Modal isOpen={open} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                {t(`Create stock transaction`)}
            </ModalHeader>
            <ModalBody>
                <h5>{t('Article')}: {article.artNo} - {article.name}</h5>
                <FormRenderer object={{transactedAt: new Date().toISOString()}}
                              onSubmit={({object}) => {
                                  toggle();
                                  createSt({variables: {stockTransaction: {...object, articleId: article.id}}})
                              }}
                              formDefinition={[
                                  {
                                      name: "description", type: "textarea", required: true,
                                  },
                                  {
                                      name: "quantity", type: "currency", required: true
                                  },
                                  {
                                      name: "price", type: "number"
                                  },
                                  {
                                      name: "currency", type: "currencyPicker"
                                  },
                                  {
                                      name: "transactedAt", type: "date", required: true
                                  }
                              ]}/>
            </ModalBody>
        </Modal>
    </div>
}
