import React, {useCallback, useEffect, useState} from 'react';
import {Button, FormGroup, Label, Spinner} from 'reactstrap';
import config from './config';
import Auth from './Auth';
import Select from './common/FixRequiredSelect';
import {map} from 'lodash';
import * as msal from '@azure/msal-browser';
import NotificationPopup from "./common/lib/NotificationPopup";
import {useTenant} from "./TenantProvider";


const client = new msal.PublicClientApplication({
    auth:
    {
        clientId:"5e6f90b6-2036-47f8-b505-700280ef5dc0",
        authority: "https://login.microsoftonline.com/67ce1261-8b0d-418b-b4be-bf7ef8dfd35c",
        redirectUri: config.REDIRECT_URL,
    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
});
const scopes = ["api://ad2f4bab-a68a-4626-a245-204c90bfde20/ebs_access"];




export default ({done})=>
{
    const [error,setError] = useState(null);
    const [loading,setLoading] =  useState(false);
    const {setTenantId} = useTenant()

    const login = async ()=>
    {
        setLoading(true);
        const res = await client.loginPopup({
            redirectUri:config.REDIRECT_URI,
            scopes,
            prompt: "select_account"
        });
        const response = await fetch(`${config.BACKEND_URI}login`,{
            method:"POST",
            headers:{
                Authorization:`Bearer ${res.accessToken}`
            }
        });
        if(response.ok)
        {
            setError(null);
            const json = await response.json();
            Auth.authenticateUser(json.token);

            const tenantValue = currentTenant.value
            const validate = json.tenants.includes(tenantValue)
            if (!validate) {
                setLoading(false);
                setError(`You don't have permission to access ${tenantValue}. Please contact the administrator for assistance.`);
                return
            }

            Auth.setUsername(json.username);
            setTenantId(tenantValue)

            setLoading(false);
            done(tenantValue);
        }
        else
        {
            if(response.headers.get("content-type").includes("json"))
            {
                const json = await response.json();
                setError(json.message);
            }
            else
            {
                setError(response.statusText);
            }
            setLoading(false);
        }
    }
    const [tenants,setTenants] = useState([]);
    const [currentTenant,setCurrentTenant] = useState(null);

    const onChange = useCallback((selected) => {
        setCurrentTenant(selected)
    }, [setCurrentTenant])

    const getTenants = useCallback(async ()=>
    {
        try
        {
            const res = await fetch(config.BACKEND_URI + "tenants");
            if(res.ok)
            {
                const data = await res.json();
                const tenants =map(data,tenant => ({value:tenant.name,label:tenant.name}))
                setTenants(tenants);
            }
            else
            {
                NotificationPopup.error(`Failure to get tenants. ${res.statusText}. System may be down, try again in a little bit.`);
            }

        }
        catch(e)
        {
            NotificationPopup.error(`Failure to get tenants. ${e.message}. System may be down, try again in a little bit.`);
        }
    },[setTenants])
    useEffect(()=>
    {
        getTenants();
    },[getTenants]);
    if(loading)
    {
        return <Spinner color={"warning"} />
    }

    return <form className="col-sm-4" action="/" onSubmit={e=>{e.preventDefault();login();}}>
        <h2>Login</h2>
        <FormGroup>
            <Label>Current tenant:</Label>
            <Select required={true} value={currentTenant} onChange={onChange} options={tenants}/>
        </FormGroup>
        {error && <p style={{color:"red"}}>{error}</p>}
        <Button type='submit'
        label="Log in" color="success">
            Submit
        </Button>
    </form>
}

