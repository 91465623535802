import React, {useCallback, useState} from 'react';
import UploadFile, {
    DrawingsCategoryType
} from "../../common/file/UploadFile";
import {filter, map} from 'lodash';
import FileList from "../../common/file/FileList";
import TaskFooter from "../../common/task-workflow/TaskFooter";
import CommentsPanel from "../../common/task-workflow/comment/CommentsPanel";
import RequestMoreInformationButton from "../../common/task-workflow/RequestMoreInformationButton";
import CustomerAndDrawingFileList from "../../common/task-workflow/files/CustomerAndDrawingFileList";
import ArticlesAndAutoDrawArticlesOverview from "../../common/task-workflow/ArticlesAndAutoDrawArticlesOverview";
import {useLocale} from "../../LocaleProvider";
import DrawJobOrderInfo from "../../common/task-workflow/DrawJobOrderInfo";

export default ({update,onSubmit,variables,toggle, task})=>
{
    const [files,setFiles] = useState(variables.customerDrawings || []);
    const [comments, setComments] = useState(variables.comments ?? [])
    const {t} = useLocale()

    const {articles, comment, orderId, purchaseOrderId, autoDrawArticles} = variables;

    const handleRequestMoreInformationClick = useCallback(() => {
        onSubmit({
            comments: {
                value: JSON.stringify(comments),
                type: "json"
            },
            needMoreInformation: {
                value: true,
                type: "Boolean"
            }
        })
    }, [onSubmit, comments])

    const submit = useCallback(() => {
        const submit = () => {
            onSubmit({
                customerDrawings: {
                    value: JSON.stringify(map(files)),
                    type: "json",
                },
                needMoreInformation: {
                    value: false,
                    type: "Boolean",
                },
                comments: {
                    value: JSON.stringify(comments),
                    type: "json"
                },
            })
        };
        if (files.length > 0) {
            submit();
            return
        }
        if (window.confirm(t(`You have not uploaded any drawings. Are you sure you want to proceed?`))) {
            submit();
        }
    }, [onSubmit, files, comments, t])

    return <DrawJobOrderInfo task={task} variables={variables}>
        {comment && <div>
            <h2>Comment from customer</h2>
            <p>
                {comment}
            </p>
        </div>}
        <ArticlesAndAutoDrawArticlesOverview articles={articles} autoDrawArticles={autoDrawArticles} orderId={orderId}/>
        <hr/>
        <CustomerAndDrawingFileList purchaseOrderId={purchaseOrderId}/>
        <hr/>
        <h4>{t('Uploaded files')}</h4>
        <FileList files={files}
                  onDelete={(deletedFile) => {
                      setFiles(filter(files, file => file.id !== deletedFile.id));
                      update({
                          customerDrawings: {
                              value: JSON.stringify(map(files)),
                              type: "json",
                          }
                      }, false);
                  }
                  }/>
        <UploadFile fileCategory={DrawingsCategoryType}
                    onSubmit={files => setFiles(oldFiles => oldFiles.concat(files))}/>
        <hr/>

        <CommentsPanel comments={comments}
                       setComments={(newComments) => {
                          setComments(newComments)
                      }}/>

        <RequestMoreInformationButton onConfirm={handleRequestMoreInformationClick}/>

        <TaskFooter toggle={toggle} save={() => {
            update({
                customerDrawings: {
                    value: JSON.stringify(map(files)),
                    type: "json",
                },
                comments: {
                    value: JSON.stringify(comments),
                    type: "json"
                },
            }, true);
        }} submit={submit}/>
    </DrawJobOrderInfo>;
}
