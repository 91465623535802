import {Link} from "react-router-dom";
import Pen from "../icons/Pen";
import React, {useMemo, useState} from "react";
import {Col, Row} from "reactstrap";
import UpdateStockTransaction from "../components/UpdateStockTransaction";
import {InventoryRoute} from "../InventoryRouters";
import NonPaginatedDataGrid from "../../common/data-grid/NonPaginatedDataGrid";
import ColumnDate from "../../common/data-grid/columns/ColumnDate";
import {useLocale} from "../../LocaleProvider";

const KEY_TABLE = "TABLE_INVENTORY_PURCHASE_STOCK_TRANSACTIONS"


export default ({purchaseOrder}) =>
{
    const [selectedSt, setSelectedSt] = useState(null);
    const {t} = useLocale()

    const columns = useMemo(() =>
    {
        return [
            {
                field: 'article',
                headerName: 'Article No.',
                renderCell: (params) =>
                {
                    const article = params.value
                    if(!article){
                        return '-'
                    }
                    return <Link to={`${InventoryRoute.ARTICLE_DETAIL}/${article.id}`}>
                        {article.artNo}
                    </Link>
                }
            },
            {field: 'quantity'},
            ColumnDate('transactedAt', 'Received At'),
            {field: 'description'},
            {field: 'creator'},
            {
                field: 'actions',
                renderCell: (params) =>
                {
                    return <>
                            <span className={"link"} onClick={() => setSelectedSt(params.row)}>
                                <Pen/>
                            </span>
                    </>
                }
            },
        ]
    }, [])

    return <Row style={{marginTop: "17px"}}>
        <Col>
            <h4>{t('Stock Transactions')}</h4>
            <UpdateStockTransaction stockTransaction={selectedSt}
                                    toggle={() => setSelectedSt(null)}/>

            <NonPaginatedDataGrid
                style={{marginTop: "20px"}}
                definition={{
                    tableKey: KEY_TABLE,
                    columns: columns,
                    pageSize: 20,
                    disableTooBar: true
                }}
                data={purchaseOrder.stockTransactions}/>
        </Col>
    </Row>
}
