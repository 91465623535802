import React, {Component} from 'react';
import FormRenderer from "../../common/FormRenderer";
import {camundaDateFix} from "../../UTIL";
import OrderInfo from "../../common/task-workflow/OrderInfo";


export const shippingCompanies = [
    {id:"schenker",name:"Schenker"},
    {id:"freja",name:"Freja"},
    {id:"loadmaster",name:"Loadmaster"},
    {id:"dhl",name:"DHL"},
    {id:"bring",name:"Sandahls"},
    {id:"other",name:"Other"},
    {id:"pousiTransportAB",name:"Pousi Transport AB"}];

export default class DeliverOrder extends Component {

    render()
    {
        const {orderId} = this.props.variables;
        return <div>
            <OrderInfo orderId={orderId}/>
            <FormRenderer formDefinition={[
                {name:"deliveryDate",type:"date",required:true},
                {name:"shippingCompany",type:"select",options:shippingCompanies,required:true},
                {name:"shippingNumber",required:true},
                {name:"skipSendMail",label:"Do not send e-mail notification to customer.",type:"checkbox"}
            ]} onSubmit={form =>
            {
                const {deliveryDate,shippingCompany,shippingNumber,skipSendMail} = form.object;
                this.props.onSubmit(
                {
                    deliveryDate: {type: "Date", value: camundaDateFix(deliveryDate)},
                    shippingCompany:{type:"String",value:shippingCompany},
                    shippingNumber:{type:"String",value:shippingNumber},
                    skipSendMail:{
                        value:skipSendMail,type:"Boolean"
                    }
                })
            }}/>
        </div>
    }
}
