import React from 'react';
import {Nav, NavLink} from 'reactstrap';
import {useLocale} from "../../LocaleProvider";

interface NavBarProps<T>
{
    onClick: (item: T) => void;
    activeTab: T;
    enumType: { [key: string]: T };
}

const NavBar = <T extends string>({onClick, activeTab, enumType}: NavBarProps<T>) =>
{
    const {t} = useLocale()
    return <Nav tabs>
        {
            Object.entries(enumType).map(([key, value]) =>
            {
                const tab = enumType[key];
                return (
                    <NavLink key={key} active={activeTab === tab} onClick={() => onClick(tab)}>
                        <h4>{t(value)}</h4>
                    </NavLink>
                );
            })
        }
    </Nav>
};

export default NavBar;
