import React, { Component } from 'react';
import UploadFile, {DrawingsCategoryType} from "../../common/file/UploadFile";
import {filter, map} from 'lodash';
import FileList from "../../common/file/FileList";
import TaskFooter from "../../common/task-workflow/TaskFooter";
import { Alert } from 'reactstrap';
import CommentsPanel from "../../common/task-workflow/comment/CommentsPanel";
import RequestMoreInformationButton from "../../common/task-workflow/RequestMoreInformationButton";
import CustomerDocsFileList from "../../common/task-workflow/files/CustomerDocsFileList";
import ArticlesAndAutoDrawArticlesOverview from "../../common/task-workflow/ArticlesAndAutoDrawArticlesOverview";
import {withLocale} from "../../LocaleProvider";
import DrawJobOrderInfo from "../../common/task-workflow/DrawJobOrderInfo";



class UploadCustomerDrawing extends Component {

    constructor(props) {
        super(props);
        this.onUpload = this.onUpload.bind(this);
        this.state = {
            files: props.variables.customerDrawings || [],
            moreInformationModal:false,
            comments: props.variables.comments ?? [],
        };
    }

    onUpload(files)
    {
        this.setState({files:this.state.files.concat(files)});
    }

    save = (toggle = true) => {
        this.props.update({
            customerDrawings: {
                value: JSON.stringify(map(this.state.files)),
                type: "json",
            },
            comments: {
                value: JSON.stringify(this.state.comments),
                type: "json"
            }
        }, toggle);
    };

    submit = ()=>
    {
        const submit = ()=>
        {
            this.props.onSubmit({
                requiresMoreInformation: {
                    value: false,
                    type: "Boolean",
                },
                customerDrawings: {
                    value: JSON.stringify(map(this.state.files)),
                    type: "json",
                },
                comments: {
                    value: JSON.stringify(this.state.comments),
                    type: "json"
                }
            })
        };
        if(this.state.files.length > 0)
        {
            submit();
        }
        else
        {
            const {t} = this.props
            if(window.confirm(t(`You have not uploaded any drawings. Are you sure you want to proceed?`)))
            {
                submit();
            }
        }
    };

    handleRequestMoreInformationConfirm = () => {
        this.props.onSubmit({
            comments: {
                value: JSON.stringify(this.state.comments),
                type: "json"
            },
            requiresMoreInformation: {
                value: true,
                type: "Boolean",
            },
            customerDrawings: {
                value: JSON.stringify(map(this.state.files)),
                type: "json",
            },
        })
    }

    render()
    {
        const {t} = this.props
        const {
            articles,
            comment,
            orderId,
            purchaseOrderId,
            autoDrawArticles,
            initiator,
            drawingComment
        } = this.props.variables;
        return <DrawJobOrderInfo variables={this.props.variables} task={this.props.task}>
            {comment && <div>
                <h4>{t('Comment')}</h4>
                <p>
                    {comment}
                </p>
            </div>}
            <ArticlesAndAutoDrawArticlesOverview articles={articles} orderId={orderId} autoDrawArticles={autoDrawArticles}/>
            {drawingComment && drawingComment.length > 0 && <><h4>Comment from {initiator}</h4>
                <Alert color="warning">
                     <pre>
                         {drawingComment}
                     </pre>
                </Alert></>}

            <CustomerDocsFileList purchaseOrderId={purchaseOrderId}/>
            <hr/>
            <h4>{t('Uploaded files')}</h4>
            <FileList files={this.state.files}
                      onDelete={(deletedFile) => {
                          this.setState({files: filter(this.state.files, file => file.id !== deletedFile.id)});
                          this.save(false);
                      }
                      }/>
            <UploadFile fileCategory={DrawingsCategoryType} onSubmit={this.onUpload}/>
            <hr/>

            <CommentsPanel comments={this.state.comments}
                           oldComment={comment}
                           setComments={(newComments) => {
                              this.setState({comments: newComments});
                          }}/>
            <RequestMoreInformationButton onConfirm={this.handleRequestMoreInformationConfirm}/>
            <TaskFooter toggle={this.props.toggle} save={() => this.save()} submit={() => this.submit()}/>
        </DrawJobOrderInfo>
    }
}

export default withLocale(UploadCustomerDrawing)