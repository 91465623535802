import React, {useState} from 'react';
import {Button, ButtonGroup, Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import Auth from "../../Auth";
import config from '../../config';
import {useMutation} from '@apollo/client';
import {downloadOrderPDF, downloadPurchaseOrderPDF} from "../../UTIL";
import gql from "graphql-tag";
import Spinner from "reactstrap/es/Spinner";

const saveOrderPdf = gql`
mutation saveOrderPdf($id:ID!)
{
    saveOrderPDF(id:$id)
}`;


const savePOPDf = gql`
    mutation savePOPDF($id:ID!)
    {
        savePurchaseOrderPDF(id:$id)
    }`;

export default (props)=>
{
    const [loading,setLoading] = useState(false);
    const toggle = props.toggle;

    //PO or order..
    const obj = props.obj;
    const isDisplayDeliveryDate = props.isDisplayDeliveryDate ?? true;

    let savePdf;
    let urlComponent;
    let download;
    if(obj.supplier)
    {
        download = () => downloadPurchaseOrderPDF(obj);
        savePdf = useMutation(savePOPDf,{refetchQueries:["getPurchaseOrder"],variables:{id:obj.id}})[0];
        urlComponent = "purchaseOrder";
    }
    else
    {
        download = () =>  downloadOrderPDF(obj.id,`OR-${obj.number}-${obj.company.name}`, isDisplayDeliveryDate);
        urlComponent = "order";
        savePdf = useMutation(saveOrderPdf,{refetchQueries:["getOrder"],variables:{id:obj.id, isDisplayDeliveryDate}})[0];
    }
    const body = ()=>
    {
        return <div>
            <div style={{height: "750px"}}>
                <embed src={src} type="application/pdf" width="100%" height="100%"/>
            </div>
            <hr/>
            <Row className="justify-content-between">
                <Col sm={2}>
                    <Button onClick={() => toggle()}>
                        Cancel
                    </Button>
                </Col>
                <Col sm={3}>
                    {!loading &&<ButtonGroup>
                        <Button color="success" onClick={async () =>
                        {
                            setLoading(true);
                            await savePdf();
                            setLoading(false);
                        }}>
                            Save to files
                        </Button>
                        <Button color="primary" onClick={async () =>
                        {
                            setLoading(true);
                            await download();
                            setLoading(false);
                        }}>
                            Download
                        </Button>
                    </ButtonGroup>}
                    {loading && <div>Hold on loading..<Spinner color="warning"/></div>}
                </Col>
                {props.button && <Col sm={2}> {props.button()}</Col>}
            </Row>
        </div>
    };

    const src = `${config.BACKEND_URI}pdf/${urlComponent}/${obj.id}?token=${Auth.getToken()}&isDisplayDeliveryDate=${isDisplayDeliveryDate}`;
    if(props.noModal)
    {
        return body();
    }
    else
    {
        return <Modal size="lg" style={{maxWidth:"1200px"}} isOpen={props.isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                Order PDF {obj.number}
            </ModalHeader>
            <ModalBody>
                {body()}
            </ModalBody>
        </Modal>;
    }

}
