import React, {useCallback, useState} from "react";
import gql from "graphql-tag";
import {Button, ButtonGroup, Col, Row, TabContent, TabPane} from "reactstrap";
import {useStdMutation} from "../../spiralStair/lib/Util";
import StdQuery from "../../common/StdQuery";
import {withRouter} from "react-router";
import {GetInventoryPurchaseOrderDocument} from "../../generated/inventoryGraphql";
import ReceiveAll from "./ReceiveAll";
import Auth from "../../Auth";
import config from "../../config";
import PurchaseOrderDetailPanel from "./PurchaseOrderDetailPanel";
import {InventoryRoute} from "../InventoryRouters";
import ArticleRows from "./ArticleRows";
import FileSaver from "file-saver";
import StockTransactionsList from "./StockTransactionsList";
import FileUploadPage from "../components/FileUploadPage";
import NavBar from "../components/NavBar";
import {useLocale} from "../../LocaleProvider";

enum NavTab
{
    DETAIL = "Detail",
    STOCK_TRANSACTION = "Stock transaction",
    FILES = "Files"
}

const DetailPanel = (props) =>
{
    const {purchaseOrder, id} = props
    const {t} = useLocale()

    const [updateStatus] = useStdMutation("Update purchase order status",
        gql`mutation($id:ID!,$po:PurchaseOrderStatusInputType!)
        {
            updatePurchaseOrderStatus(id:$id,purchaseOrder: $po)
            {
                id
            }
        }`, {refetchQueries: ["getInventoryPurchaseOrder"]});

    const [removePurchaseOrderMutation] = useStdMutation("Remove purchase order",
        gql`mutation($id:ID!)
        {
            removePurchaseOrder(id:$id)
        }`, {refetchQueries: ["getPOs"], awaitRefetchQueries: true});

    const removePurchaseOrder = async (id) =>
    {
        if (window.confirm(`Are you sure you want to remove this purchase order?`))
        {
            await removePurchaseOrderMutation({
                variables: {
                    id: id,
                }
            });
            props.history.push(InventoryRoute.PURCHASE_ORDERS);
        }
    }

    return <Row>
        <Col>
            <ButtonGroup>
                <ReceiveAll articleRows={purchaseOrder.articleRows}/>
                <Button color={"info"} onClick={() =>
                {
                    updateStatus({
                        variables: {
                            id: purchaseOrder.id,
                            po: {status: "finished"}
                        }
                    });
                }}>
                    {t('Mark as finished')}
                </Button>
                <Button onClick={async () =>
                {
                    const token = Auth.getToken();
                    const res = await fetch(`${config.INVENTORY_URI}purchase-orders/getPdf/${purchaseOrder.id}`, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                    const blob = await res.blob();
                    FileSaver.saveAs(blob, `purchase-order-${purchaseOrder.id}.pdf`);
                }}>
                    {t('Download PDF')}
                </Button>
                <Button color={"info"} onClick={async () =>
                {
                    await removePurchaseOrder(purchaseOrder.id);
                }}>
                    {t('Remove PO')}
                </Button>
            </ButtonGroup>
            <hr/>

            <PurchaseOrderDetailPanel purchaseOrder={purchaseOrder} id={id}/>
            <br/>
            <ArticleRows currency={purchaseOrder.currency} articleRows={purchaseOrder.articleRows} tenantId={purchaseOrder.tenantId}/>
        </Col>
    </Row>
}

const InventoryPurchaseOrderDetailPage = (props) =>
{
    const {params} = props.match;

    const id = parseInt(params.id);

    const [activeTab, setActiveTab] = useState(NavTab.DETAIL)
    const {t} = useLocale()

    const onTabClick = useCallback((newTab: NavTab) =>
    {
        setActiveTab(newTab)
    }, [setActiveTab])

    return <div>
        <StdQuery query={GetInventoryPurchaseOrderDocument} variables={{id}}>
            {({purchaseOrder}) =>
            {
                if (!purchaseOrder)
                {
                    return <p>Failed to find Purchase order with id: {id}</p>
                }
                return <div>
                    <h2>{t('Purchase order')} - {id}</h2>
                    <NavBar enumType={NavTab} onClick={onTabClick} activeTab={activeTab}/>
                    <Row style={{marginTop: "17px", marginBottom: "50px"}}>
                        <Col sm={12}>
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId={NavTab.DETAIL}>
                                    <DetailPanel purchaseOrder={purchaseOrder} id={id}/>
                                </TabPane>
                                <TabPane tabId={NavTab.STOCK_TRANSACTION}>
                                    <StockTransactionsList purchaseOrder={purchaseOrder}/>
                                </TabPane>
                                <TabPane tabId={NavTab.FILES}>
                                    <FileUploadPage purchaseOrderId={parseInt(purchaseOrder.id)}/>
                                </TabPane>
                            </TabContent>
                        </Col>
                    </Row>
                </div>
            }}
        </StdQuery>
    </div>;
}


export default withRouter(InventoryPurchaseOrderDetailPage)