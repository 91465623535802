import React, {useEffect, useMemo} from "react";
import {Spinner} from "reactstrap";
import OpenInNewTabIcon from "../../OpenInNewTabIcon";
import gql from "graphql-tag";
import {useLazyQuery} from "@apollo/client";
import {useLocale} from "../../../LocaleProvider";
import {QUERY_PARAMS_IS_INVENTORY} from "../../../Constants";

const queryPurchaseOrder = gql`
    query getPurchaseOrder($id: ID!) {
        purchaseOrder(id: $id) {
            id
            number
        }
    }
`;

const ESPLOrderInfo = ({purchaseOrder, purchaseOrderId}: { purchaseOrder?: any, purchaseOrderId?: string }) =>
{
    const {t} = useLocale()
    const [getPurchaseOrderQuery, {data, loading, error}] = useLazyQuery(queryPurchaseOrder)

    const localPurchaseOrder = useMemo(() =>
    {
        return purchaseOrder ?? data?.purchaseOrder
    }, [purchaseOrder, data])

    useEffect(() =>
    {
        if (purchaseOrderId && !purchaseOrder)
        {
            getPurchaseOrderQuery({variables: {id: purchaseOrderId}})
        }
    }, [purchaseOrder, purchaseOrderId, getPurchaseOrderQuery])

    if (error)
    {
        return <p>Failed to find order</p>
    }
    if (loading)
    {
        return <Spinner/>
    }
    return <>
        <h3>{t('Order')} {localPurchaseOrder?.number}
            <OpenInNewTabIcon to={`/purchaseOrderDetail/${localPurchaseOrder?.id}?${QUERY_PARAMS_IS_INVENTORY}=true`}/>
        </h3>
    </>
};

export default ESPLOrderInfo