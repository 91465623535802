import {
    PreferredMaterialType,
    StepType, StraightStairV2FragmentFragment,
    StringerMaterial
} from "generated/graphql";
import {maxBy} from "lodash";
import StringerCalculatorData from "./StringerCalculatorData.json";

export const generateNewStringer = (stair: StraightStairV2FragmentFragment): StringerMaterial[] =>
{
    const allSegmentGeometry = stair.segments.map(e => e.geometry)
    const lx = maxBy(allSegmentGeometry, (e) => e?.stairsHorizontalLength ?? 0)?.stairsHorizontalLength ?? 0
    const lc = maxBy(allSegmentGeometry, (e) => e?.stringerLength ?? 0)?.stringerLength ?? 0
    let width = maxBy(stair.segments, (e) => e?.stepWidth ?? 0)?.stepWidth ?? 0

    if (width < 810)
    {
        width = 800
    } else if (width <= 910)
    {
        width = 900
    } else if (width <= 1010)
    {
        width = 1000
    } else if (width <= 1210)
    {
        width = 1200
    } else if (width <= 1310)
    {
        width = 1300
    } else
    {
        width = 1500
    }

    const def = stair.deflection === "L/250" ? 1 : 0
    const preferredMaterial = stair.preferredMaterial === PreferredMaterialType.Pls ? 1 : 0

    let stepType: number;
    switch (stair.stepType)
    {
        case StepType.StandardGrate:
        case StepType.TightMeshGrate:
        case StepType.PrepForCarpet:
        case StepType.PrepForWood:
        case StepType.Tearplate:
            stepType = 0
            break;
        case StepType.PlateForConcrete:
        case StepType.PrepForTiles:
        case StepType.PrepForTerrazzo:
            stepType = 1
            break;
    }

    const stringerCalculatorData = StringerCalculatorData.find((e) =>
    {
        return e.stepWidth === width &&
            e.horizontalLength >= lx &&
            e.stringerLength >= lc &&
            e.maxLoad === stair.maxLoad &&
            e.stepType === stepType &&
            e.preferredMaterial === preferredMaterial &&
            e.deflection === def
    })

    if (!stringerCalculatorData)
    {
        return []
    }

    const stringer = stringerCalculatorData.stringer

    if (stringerCalculatorData.stringer >= 7)
    {
        return generateUpeOptions(stringer)
    }
    return generatePlsOptions(stringer)
}

const generateUpeOptions = (stringer: number): StringerMaterial[] =>
{
    const items: StringerMaterial[] = [];
    if (stringer <= 7)
    {
        items.push(StringerMaterial.Upe140);
    }
    if (stringer <= 8)
    {
        items.push(StringerMaterial.Upe160);
    }
    if (stringer <= 9)
    {
        items.push(StringerMaterial.Upe180);
    }
    items.push(StringerMaterial.Upe200);
    return items
}

const generatePlsOptions = (stringer: number): StringerMaterial[] =>
{
    const items: StringerMaterial[] = [];
    if (stringer <= 1)
    {
        items.push(StringerMaterial.Pls150x8);
    }
    if (stringer <= 2)
    {
        items.push(StringerMaterial.Pls150x10);
    }
    if (stringer <= 3)
    {
        items.push(StringerMaterial.Pls200x8);
    }
    if (stringer <= 4)
    {
        items.push(StringerMaterial.Pls200x10);
    }
    if (stringer <= 5)
    {
        items.push(StringerMaterial.Pls250x8);
    }
    items.push(StringerMaterial.Pls250x10);

    return items
}