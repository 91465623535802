import React, {Component} from 'react';
import StdQuery from "../../common/StdQuery";
import {filter, get} from 'lodash';
import {Button, Table} from "reactstrap";
import gql from "graphql-tag";
import config from '../../config';
import OrderInfo from "../../common/task-workflow/OrderInfo";

const query = gql`

    query getOrder($id:ID!)
    {
        order(id:$id)
        {
            id
            articleRows
            {
                id
                name
                article
                {
                    id
                    artNo
                    spiralStair
                    {
                        id
                    }
                    stockArticle{
                        id
                    }
                    supplier
                    {
                        id
                        name
                    }
                }
                quantity
            }
        }
    }
`;
export default class PackOrder extends Component {


    spiralStairs(order)
    {
        const spiralArticleRows = filter(order.articleRows,ar=>
        {
            return get(ar,"article.spiralStair.id");
        });
        return <div>
            <h4>Spiral stairs</h4>
            <Table hover bordered striped>
                <thead>
                <tr>
                    <th>
                        Link to packingslip
                    </th>
                    <th>
                        Quantity
                    </th>
                </tr>
                </thead>
                <tbody>
                {spiralArticleRows.map(sp=>
                {
                    return <tr key={sp.id}>
                        <td>
                            <a href={`${config.QM_URI}packlista/${sp.article.artNo}`} target="_blank">
                                {sp.article.artNo}
                            </a>
                        </td>
                        <td>{sp.quantity}</td>
                    </tr>
                })}
                </tbody>
            </Table>
        </div>
    }


    stockArticles(order)
    {
        const stockArticleRows = filter(order.articleRows,ar=>
        {
            return get(ar,"article.stockArticle.id");
        });
        return <div>
            <h4>Stock articles</h4>
            <Table hover bordered striped>
                <thead>
                <tr>
                    <th>
                        Art no.
                    </th>
                    <th>
                        Quantity
                    </th>
                </tr>
                </thead>
                <tbody>
                {stockArticleRows.map(sp=>
                {
                    return <tr key={sp.id}>
                        <td>
                            {sp.article.artNo}
                        </td>
                        <td>{sp.quantity}</td>
                    </tr>
                })}
                </tbody>
            </Table>
        </div>
    }

    other(order)
    {
        const otherArticleRows = filter(order.articleRows,ar=>
        {
            return get(ar,"article.stockArticle.id") == null && get(ar,"article.spiralStair.id") == null;
        });
        return <div>
            <h4>Other articles</h4>
            <Table hover bordered striped>
                <thead>
                <tr>
                    <th>
                        Art no.
                    </th>
                    <th>
                        Quantity
                    </th>
                    <th>
                        Supplier
                    </th>
                </tr>
                </thead>
                <tbody>
                {otherArticleRows.map(sp=>
                {
                    return <tr key={sp.id}>
                        <td>
                            {get(sp,"article.artNo") || sp.name}
                        </td>
                        <td>{sp.quantity}</td>
                        <td>
                            {get(sp,"article.supplier.name") || "None" }
                        </td>
                    </tr>
                })}
                </tbody>
            </Table>
        </div>
    }

    render()
    {
        const {orderId} = this.props.variables;
        return <div>
            <StdQuery query={query} variables={{id:orderId}}>
                {data=>
                {
                    const order = data.order;
                    return <div>
                        <OrderInfo order={order}/>
                        <br/>

                        {this.spiralStairs(order)}
                        {this.stockArticles(order)}
                        {this.other(order)}
                        <Button onClick={()=>
                        {
                            if(window.confirm(`Have you packed everything for this order?`))
                            {
                                this.props.onSubmit()
                            }
                        }}>Complete task</Button>
                    </div>
                }}
            </StdQuery>
        </div>
    }
}
