import OrderInfo from "../../common/task-workflow/OrderInfo";
import CustomerDocsFileList from '../../common/task-workflow/files/CustomerDocsFileList';
import TaskFooter from '../../common/task-workflow/TaskFooter';
import React, {useState} from 'react';
import UploadFile, { CustomerDocCategoryType } from '../../common/file/UploadFile';
import CommentsPanel from "../../common/task-workflow/comment/CommentsPanel";
import ArticlesAndAutoDrawArticlesOverview from "../../common/task-workflow/ArticlesAndAutoDrawArticlesOverview";
import {useLocale} from "../../LocaleProvider";


export default ({variables,onSubmit,toggle})=>
{
    const {articles, orderId, purchaseOrderId, autoDrawArticles, requiresMoreInformationComment} = variables;

    const [files,setFiles] = useState([]);
    const [comments, setComments] = useState(variables.comments ?? [])
    const {t} = useLocale()

    return <div>
        <OrderInfo orderId={orderId}/>
        <ArticlesAndAutoDrawArticlesOverview articles={articles} autoDrawArticles={autoDrawArticles} orderId={orderId}/>
        <CustomerDocsFileList purchaseOrderId={purchaseOrderId}/>

        <h4>{t('Upload files')}</h4>
        <UploadFile purchaseOrderId={purchaseOrderId} fileCategory={CustomerDocCategoryType}
                    onSubmit={files=>setFiles(oldFiles=>oldFiles.concat(files))} />

        <hr/>

        <CommentsPanel comments={comments}
                       oldComment={requiresMoreInformationComment}
                       setComments={setComments}/>

        <TaskFooter submit={() => onSubmit({
            moreInformationAddedFiles: {
                type: "json",
                value: JSON.stringify(files)
            },
            comments: {
                value: JSON.stringify(comments),
                type: "json"
            }
        })} toggle={toggle}/>

    </div>
}
