import React, {memo, useCallback, useMemo, useState} from "react";
import {
    CreateStraightStairExtraRailingMutation,
    MontageType,
    PlatformRailingType,
    RailingStandardType,
    StraightStairExtraRailingFragmentFragment,
    StraightStairV2FragmentFragment
} from "../../generated/graphql";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, Table} from "reactstrap";
import NewFormRenderer from "../../common/NewFormRenderer";
import Pen from "../../common/icons/Pen";
import Trash from "../../common/icons/Trash";
import {enumToOptions} from "../../UTILmore";
import {floor, round} from "lodash";
import {useLocale} from "../../LocaleProvider";

const calculateNumberOfSectionAndSectionLength = (extraRailing: StraightStairExtraRailingFragmentFragment) =>
{
    const railingLength = extraRailing.length
    const maxSectionLength = extraRailing.maxSectionLength

    let numberOfSection = 0
    let sectionLength = 0
    if (maxSectionLength > 0)
    {
        const n1 = railingLength / maxSectionLength
        const n2 = floor(railingLength / maxSectionLength)
        if (n1 - n2 > 0)
        {
            numberOfSection = n2 + 1;
            if (round(railingLength / numberOfSection, 2) < 150)
            {
                numberOfSection = n2;
            }
        }
        if (n1 - n2 === 0)
        {
            numberOfSection = n2;
        }
    }

    if (numberOfSection > 0)
    {
        sectionLength = floor(railingLength / numberOfSection)
    }

    return {numberOfSection, sectionLength}
}

const ExtraRailingModal = memo(({extraRailing, isOpen, toggle, onUpdateClick}:
                                    {
                                        extraRailing: StraightStairExtraRailingFragmentFragment,
                                        isOpen: boolean, toggle: () => void
                                        onUpdateClick: (extraRailing: StraightStairExtraRailingFragmentFragment) => void
                                    }) =>
{
    const [extraRailingData, setExtraRailingData] = useState(extraRailing)
    const {t} = useLocale()

    const onChange = useCallback((key: string, value: string) =>
    {
        setExtraRailingData(prevState => ({...prevState, [key]: value}))
    }, [])

    const onUpdate = useCallback(() =>
    {
        onUpdateClick(extraRailingData)
        toggle()
    }, [onUpdateClick, extraRailingData, toggle])

    const numberOfSectionAndSectionLength = useCallback(() =>
    {
        const {numberOfSection, sectionLength} = calculateNumberOfSectionAndSectionLength(extraRailingData)
        return <>
            <p>{t('Number of section')}: {numberOfSection}</p>
            <p>{t('Section length')}: {sectionLength}</p>
        </>
    }, [extraRailingData, t])

    const formDefinition = useMemo(() =>
    {
        return [
            {
                name: "name",
            },
            {
                name: "quantity", type: 'number'
            },
            {
                name: "length",
                type: "number",
                step: 10,
                hint: "(min 150mm)",
                min: 150,
            },
            {
                name: "type",
                type: "select",
                options: [{id: PlatformRailingType.Railing, name: "Railing"},
                    {id: PlatformRailingType.Handrail, name: "Handrail"}]
            },
            {
                name: "maxSectionLength",
                label: "Max section length",
                type: "number",
                step: 10,
                hint: "(max 4000mm)",
                max: 4000,
                min: 10,
            },
            {
                name: "montage",
                type: "select",
                options: enumToOptions(MontageType,
                    (value) => value.replace("Material", ""))
            },
        ]
    }, [])

    return <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader isOpen={isOpen} toggle={toggle}>{t('Edit Extra Railing')}</ModalHeader>
        <ModalBody>
            <NewFormRenderer
                columns={2}
                formDefinition={formDefinition}
                object={extraRailingData}
                onChange={onChange}/>
            {numberOfSectionAndSectionLength()}
        </ModalBody>
        <ModalFooter>
            <Button color="success" onClick={onUpdate}>{t('Update')}</Button>
        </ModalFooter>
    </Modal>
})

const ExtraRailingRow = memo(({extraRailing, onEditClick, onDeleteClick}: {
    extraRailing: StraightStairExtraRailingFragmentFragment,
    onEditClick: (id: string) => void,
    onDeleteClick: (id: string) => void
}) =>
{
    const textAlignRight: any = {textAlign: "right"}
    const {numberOfSection, sectionLength} = calculateNumberOfSectionAndSectionLength(extraRailing)
    return <tr key={`extraRailing${extraRailing.id}`}>
        <td>{extraRailing.name}</td>
        <td style={textAlignRight}>{extraRailing.quantity}</td>
        <td>{extraRailing.type}</td>
        <td style={textAlignRight}>{extraRailing.length}</td>
        <td style={textAlignRight}>{extraRailing.maxSectionLength}</td>
        <td style={textAlignRight}>{numberOfSection}</td>
        <td style={textAlignRight}>{sectionLength}</td>

        <td>{extraRailing.montage}</td>
        <td>
            <span className="link" onClick={() => onEditClick(extraRailing.id)}>
                <Pen/>
            </span>
            <span className="link" style={{marginLeft: "12px"}}
                  onClick={() => onDeleteClick(extraRailing.id)}>
                <Trash/>
            </span>
        </td>
    </tr>
})

const StraightStairExtraRailingPanel = memo(({
                                                 straightStairV2, updateStraightStair,
                                                 extraRailings, addExtraRailing, deleteExtraRailing, updateExtraRailing
                                             }:
                                                 {
                                                     straightStairV2: StraightStairV2FragmentFragment,
                                                     updateStraightStair: (key: string, value: string) => void,
                                                     extraRailings: StraightStairExtraRailingFragmentFragment[],
                                                     addExtraRailing: () => Promise<CreateStraightStairExtraRailingMutation | null | undefined>,
                                                     deleteExtraRailing: (id: string) => void,
                                                     updateExtraRailing: (extraRailings: StraightStairExtraRailingFragmentFragment) => void
                                                 }) =>
{
    const [extraRailing, setExtraRailing] = useState<StraightStairExtraRailingFragmentFragment | null>(null)
    const {t} = useLocale()

    const toggleSupportModal = useCallback(() => setExtraRailing(null), [])

    const onEditClick = useCallback((id) =>
    {
        const extraRailing = extraRailings.find(e => e.id === id)
        if (extraRailing)
        {
            setExtraRailing(extraRailing)
        }
    }, [extraRailings])

    const handleDeleteClick = useCallback((id: string) =>
    {
        if (window.confirm(t(`Are you sure you want to delete extra railing?`)))
        {
            deleteExtraRailing(id)
        }
    }, [deleteExtraRailing, t])

    const handleOnAddClick = useCallback(async () =>
    {
        const response = await addExtraRailing()
        if (response?.createStraightStairExtraRailing)
        {
            setExtraRailing(response.createStraightStairExtraRailing)
        }
    }, [addExtraRailing])

    const formDefinition = useMemo(() =>
    {
        return [
            {
                name: "extraRailingKickplate",
                type: "checkbox",
                hideField: () => straightStairV2.extraRailingExecution !== RailingStandardType.Industrial
            }
        ]
    }, [straightStairV2.extraRailingExecution])

    return <>
        <h3>{t('Extra Railings')}</h3>
        <NewFormRenderer
            columns={3}
            formDefinition={formDefinition}
            object={straightStairV2}
            onChange={(key, value) => updateStraightStair(key, value)}
        />

        {
            extraRailings.length > 0
                ? <Table striped responsive bordered>
                    <thead>
                    <tr>
                        <th>{t('Name')}</th>
                        <th>{t('Quantity')}</th>
                        <th>{t('Type')}</th>
                        <th>{t('Length')}(mm)</th>
                        <th>{t('Max section length')}(mm)</th>
                        <th>{t('Number of section')}</th>
                        <th>{t('Section length')}(mm)</th>
                        <th>{t('Montage')}</th>
                        <th>{t('Action')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        extraRailings.map((extraRailing) =>
                        {
                            return <ExtraRailingRow key={`extraRailing${extraRailing.id}`}
                                                    extraRailing={extraRailing}
                                                    onEditClick={onEditClick}
                                                    onDeleteClick={handleDeleteClick}/>
                        })
                    }
                    </tbody>
                </Table>
                : <p>{t('No extra railings')}</p>
        }

        <Button color="primary" style={{marginTop: "4px"}} onClick={handleOnAddClick}>{t('Add Extra Railing')}</Button>
        {extraRailing &&
            <ExtraRailingModal extraRailing={extraRailing} isOpen={true} toggle={toggleSupportModal}
                               onUpdateClick={updateExtraRailing}/>}
        <hr/>
    </>
})

export default StraightStairExtraRailingPanel