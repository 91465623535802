import React, {useState} from 'react';
import {Button, Modal, ModalBody, ModalHeader} from "reactstrap";
import {useMutation} from "@apollo/client";
import CreateQuoteMutation from '../quote/graphql/CreateQuote.graphql';
import {withRouter} from "react-router";
import FormRenderer from "./FormRenderer";
import {useTenant} from "../TenantProvider";

export default withRouter(({company,project,history,button}) =>
{
    const [createQuote] = useMutation(CreateQuoteMutation);
    const [modalIsOpen,setModalIsOpen] = useState(false);
    const {tenantId} = useTenant()

    const toggle = ()=>setModalIsOpen(prev=>!prev);
    const quote = {tenantId: tenantId};
    if(company)
    {
        quote.companyId = company.id;
    }
    if(project)
    {
        quote.projectId = project.id;
    }
    const onClick = async ()=>
    {
        setModalIsOpen(true);
    };
    if(!button)
    {
        button = ()=><Button className="subpanel-button" size='sm' onClick={onClick}>
            Create quote..
        </Button>;
    }
    const hideField = model=>model.noWorkFlow;
    return <React.Fragment>
        <Modal isOpen={modalIsOpen} toggle={toggle} size="lg">
            <ModalHeader toggle={toggle}>
                When do you want to follow up this quote?
            </ModalHeader>
            <ModalBody>
                <FormRenderer object={{due:new Date()}} formDefinition={[
                {name:"noWorkFlow",label:"I do not wish to follow-up this quote.",type:"checkbox"},
                    {name:"subject",required:true,hideField,defaultValue:"Follow-up"},
                    {name:"description",type:"textarea",hideField},
                    {required:true,name:"due",label:"Select date for follow-up",defaultValue:new Date(),type:"datetime",hideField}
                    ]}
                      onSubmit={async ({object:{due,description,subject,noWorkFlow}})=>
                      {
                          const res = await createQuote({variables:
                              {
                                  quote,
                                  due,
                                  description,
                                  subject,
                                  noWorkFlow
                              }});
                          history.push(`/quoteDetail/${res.data.createQuote.id}`)
                      }} />
            </ModalBody>
        </Modal>
        {button(onClick)}
    </React.Fragment>;
});
