import React from 'react';
import gql from 'graphql-tag';
import {FORMAT_DATE, formatToSwedishTime, OrderStatuses} from "../UTIL";
import {Badge} from "reactstrap";
import ColumnId from "../common/data-grid/columns/ColumnId";
import ColumnOrderNumber from "../common/data-grid/columns/ColumnOrderNumber";
import ColumnTenantId from "../common/data-grid/columns/ColumnTenantId";
import ColumnsCompany from "../common/data-grid/columns/ColumnsCompany";
import ColumnsProject from "../common/data-grid/columns/ColumnsProject";
import ColumnDate from "../common/data-grid/columns/ColumnDate";
import ColumnsStatus from "../common/data-grid/columns/ColumnStatus";
import ColumnAssignee from "../common/data-grid/columns/ColumnAssignee";
import PaginatedDataGrid from "../common/data-grid/PaginatedDataGrid";
import {withApollo} from "@apollo/client/react/hoc";

import {InvoiceStatusBadge} from "../common/invoice/InvoiceStatusBadge";

const GetOrdersV2 = gql`
    query ordersV2(
        $pagination: PaginationInputType
        $filtering: FilteringInputType
        $ordering: OrderingInputType
        $searching: SearchingInputType
    ) {
        result: ordersV2(
            options: {
                pagination: $pagination
                filtering: $filtering
                ordering: $ordering
                searching: $searching
            }
        ) {
            list {
                id
                number
                status
                invoiceDate
                invoiceNumber
                internalInvoiceDate
                internalInvoiceNumber
                project
                {
                    id
                    name
                }
                termsOfPayment
                {
                    id
                    name
                }
                dateOfDelivery
                company
                {
                    id
                    name
                }
                createdAt
                updatedAt
                assignee
                {
                    username
                    displayName
                }
                tenantId
                invoices {
                    id
                    referenceNumber
                    status
                    documentDate
                }
            }
            total
        }
    }
`;


const columns = () => {
    return [
        ColumnTenantId(),
        ColumnOrderNumber(),
        ColumnsCompany(),
        ColumnsProject(),
        {
            field: 'invoices',
            headerName: 'Invoices',
            filterable: false,
            renderCell: (params) => {
                return <div>{params.value.map((e) =>
                    <div>{`${formatToSwedishTime(e.documentDate, FORMAT_DATE)} ${e.referenceNumber} `}
                        <InvoiceStatusBadge invoiceStatus={e.status}/>
                    </div>)}
                </div>
            }
        },
        {
            field: 'invoiceNumber',
            headerName: 'External Invoice',
            renderCell: (params) => {
                const {invoiceDate, invoiceNumber} = params.row;
                if (invoiceDate) {
                    return <Badge color="danger">{formatToSwedishTime(invoiceDate, FORMAT_DATE)} - {invoiceNumber}</Badge>;
                } else {
                    return null;
                }
            }
        },
        {
            field: 'internalInvoiceNumber',
            headerName: 'Internal Invoice',
            renderCell: (params) => {
                const {internalInvoiceDate, internalInvoiceNumber} = params.row;
                if (internalInvoiceDate) {
                    return <Badge color="primary">{formatToSwedishTime(internalInvoiceDate, FORMAT_DATE)} - {internalInvoiceNumber}</Badge>;
                } else {
                    return null;
                }
            }
        },
        ColumnsStatus(OrderStatuses),
        ColumnDate('dateOfDelivery', 'Date of delivery'),
        ColumnAssignee(),
        ColumnId(),
    ]
}


const defaultSort = {sortModel: [{field: 'dateOfDelivery', sort: 'asc'}]}

const defaultFilter = {
    filterModel: {
        items: [
            {field: 'status', value: ['delivered'], operator: 'in'},
        ],
    }
}

const KEY_TABLE = "ECONOMY_ORDER_CONTROL"

const EconomyOrderControlPage = () => {
    return <PaginatedDataGrid
        definition={{
            tableKey: KEY_TABLE,
            columns: columns(),
            initState: {
                sorting: defaultSort,
                filter: defaultFilter
            },
        }}
        query={GetOrdersV2}
    />
}

export default withApollo(EconomyOrderControlPage)