import React, {Component} from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import FormRenderer from "../FormRenderer";
import {graphql,withApollo} from '@apollo/client/react/hoc'
import CreateArticleRowMutation from '../graphql/CreateArticleRow.graphql';
import PropTypes from 'prop-types';
import {withLocale} from "../../LocaleProvider";

export const NoArticleDef = [
        {
            name: "name",
            required:true
        },
        {
            name: "price",
            type: "currency",
        },
        {
            name: "unit",
        },
        {
            name: "quantity",
            type: "number",
            min: 1,
            step:"any"
        },
        {
            name: "discount",
            type: "number",
            min: 0,
            max:100,
        },

    ];

class CreateFreeTextRow extends Component
{
    toggle()
    {
        this.setState({
            modal:!this.state.modal

        });
    }
    constructor(props)
    {
        super(props);
        this.state = {
            modal:false,
            formType:'article',
            createdArticle:null,
        };

        this.toggle = this.toggle.bind(this);
    }

    async save(form)
    {
        try
        {
            form.object.orderId = this.props.orderId;
            form.object.purchaseOrderId = this.props.purchaseOrderId;
            form.object.quoteId = this.props.quoteId;

            await this.props.createArticleRow({refetchQueries:["getOrder", "getPurchaseOrder", "quote"],
                variables:{articleRow:form.object}});
            this.toggle();
        }
        catch(e)
        {
            //TODO:Show notification
            alert(e.message);
            console.error(e);
        }

    }

    modal()
    {
        return <Modal isOpen={this.state.modal} toggle={this.toggle}>
            <ModalHeader toggle={this.toggle}>Create row</ModalHeader>
            <ModalBody>
                <FormRenderer onSubmit={this.save.bind(this)} formDefinition={NoArticleDef}/>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={this.toggle}>Cancel</Button>
            </ModalFooter>
        </Modal>
    }
    render()
    {
        const {t} = this.props
        return <>
            <Button outline={true} onClick={this.toggle}>{t('Free text')}</Button>
            {this.modal.bind(this)()}
        </>
    }
}
CreateFreeTextRow.propTypes = {
    orderId:PropTypes.string,
    purchaseOrderId:PropTypes.string,
    quoteId: PropTypes.string,
};
export default graphql(CreateArticleRowMutation, {name: "createArticleRow"})(withApollo(withLocale(CreateFreeTextRow)));
